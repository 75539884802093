import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { paths } from '@env/paths';
import { TranslocoService } from '@ngneat/transloco';
import { expertToolConstants } from '@shared/constants/ExpertToolConstants';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { ExtConstants } from '@shared/lists/flashFileExtension';
import { Application } from '@shared/models/Application';
import { Breadcrumb } from '@shared/models/Breadcrumb';
import { CustomNgOption } from '@shared/models/CustomNgOption';
import { CalculateKeyValGeneric } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateKeyValGeneric';
import { Menu } from '@shared/models/Menu';
import { Spinner } from '@shared/models/spinner';
import { Subject } from 'rxjs';

import { ExperttoolDataModelingService } from './experttool-data-modeling/experttool-data-modeling.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  application!: Application;
  languageSubject = new Subject();
  translationSubject = new Subject();
  seriesSubject = new Subject();
  spinnerSubject = new Subject<Spinner>();
  responseFromFinlog;
  constructor(
    private router: Router,
    private translateService: TranslocoService,
    private storageService: StorageService,
    private expertoolDataModelingService: ExperttoolDataModelingService
  ) {}

  findMenu(menu: Menu, currentRoute: string) {
    return menu?.subMenu?.find((submenu: Menu) =>
      submenu.path === currentRoute.substring(1)
        ? submenu.path === currentRoute.substring(1)
        : submenu?.subMenu?.find(
            (subMenu: Menu) => subMenu.path === currentRoute.substring(1)
          )
    );
  }

  getMenuItems(): Breadcrumb[] {
    if (!this.application) {
      return [];
    }
    const menus = this.application?.menus;
    const currentRoute = this.router.url;
    const selectedMenu = menus.find((menu) =>
      this.findMenu(menu, currentRoute)
    );

    const path =
      selectedMenu?.path.split('/')[selectedMenu?.path.split('/').length - 1];
    let subItem = selectedMenu?.subMenu?.find((menu) =>
      menu.subMenu
        ? menu.subMenu.find((mnu) => mnu.path === currentRoute.substring(1))
        : menu.path === currentRoute.substring(1)
    );
    let flag = false;
    if (!subItem) {
      subItem = selectedMenu?.subMenu?.find(
        (menu) => menu.path === currentRoute.substring(1)
      );
      flag = true;
    }

    const parentItem = {
      path: '',
      title: subItem ? subItem.title : '',
      entitlementKey: selectedMenu?.entitlementKey,
    };

    let subPath = subItem?.path.split('/')[subItem?.path.split('/').length - 1];
    let parentPath;
    let psubItem;
    if (subItem?.subMenu && !flag) {
      psubItem = subItem.subMenu.find(
        (mnu) => mnu.path === currentRoute.substring(1)
      );

      parentPath = `${path}/${
        psubItem?.path.split('/')[psubItem?.path.split('/').length - 2]
      }`;
      subPath = `${
        psubItem?.path.split('/')[psubItem?.path.split('/').length - 2]
      }/${psubItem?.path.split('/')[psubItem?.path.split('/').length - 1]}`;
      parentItem.path = parentPath;
    }

    const menuItems: Breadcrumb[] = [
      {
        path: `${paths.fcsc.uploadtool.root}`,
        title: '',
        entitlementKey: selectedMenu?.entitlementKey,
      },
      {
        path: `${path}`,
        title: selectedMenu ? selectedMenu.title : '',
        entitlementKey: selectedMenu?.entitlementKey,
      },
      {
        path: `${path}/${subPath}`,
        title: psubItem ? psubItem.title : subItem ? subItem.title : '',
        entitlementKey: psubItem
          ? psubItem.entitlementKey
          : subItem?.entitlementKey,
      },
    ];
    if (parentPath) {
      menuItems.splice(menuItems.length - 1, 0, parentItem);
    }
    return menuItems;
  }

  translatedEnum(item: any): any {
    return Object.keys(item).map((key: string) => {
      return {
        name: this.translateService.translate(item[key as keyof typeof item]),
      };
    });
  }

  private removeAll(list) {
    const index = list.findIndex(
      (lt) => lt.brName === 'All' || lt.brName === 'Alle'
    );
    if (index > -1) {
      list.splice(index, 1);
    }
    return list;
  }

  addAll(list: any, key?: any) {
    const allModel: CustomNgOption = { brName: '', name: '' };
    list = this.removeAll(list);
    if (typeof list !== 'undefined') {
      const newList = JSON.parse(JSON.stringify(list));
      allModel.brName = key;
      allModel.name = key;
      newList.unshift(allModel);
      return newList;
    }
  }

  translateForAll(key: any) {
    this.translateService.selectTranslate(key).subscribe((translation) => {
      this.translationSubject.next(translation);
    });
  }

  translateKey(key: any) {
    return this.translateService.selectTranslate(key);
  }

  getValueConstant(enumKey: any, value: string): any {
    return Object.keys(enumKey)[Object.values(enumKey).indexOf(value)];
  }

  public getFlashType(): string {
    let flashType = '';
    if (this.isCffExtension()) {
      flashType = ExtConstants.CFF && '.' + ExtConstants.CFF;
    } else if (this.isSmrfExtension()) {
      flashType = ExtConstants.CFF && '.' + ExtConstants.SMRF;
    }
    return flashType;
  }

  private isCffExtension(): boolean {
    return this.getFlashExtention().toUpperCase() === ExtConstants.CFF;
  }

  private isSmrfExtension(): boolean {
    return this.getFlashExtention().toUpperCase() === ExtConstants.SMRF;
  }

  private getFlashExtention(): string {
    const calcResponse =
      this.storageService.getData(sessionStorageKeys.calculationData) || {};
    const flashFileExtension =
      this.expertoolDataModelingService.findDynamicValueFromKey(
        this.expertoolDataModelingService.getHeaderFromResponse(
          calcResponse
        ) as CalculateKeyValGeneric[],
        expertToolConstants.keys.flashFileExtension
      ) as string;
    return flashFileExtension;
  }

  public resetDatatableScroll() {
    const element: any = document.querySelector(
      '.ngx-datatable .datatable-body'
    );

    const elementModal: any = document.querySelector(
      '.modal-body .ngx-datatable .datatable-body'
    );
    if (element) {
      element.scrollTop = 0;
    }

    if (elementModal) {
      elementModal.scrollTop = 0;
    }
  }
}
