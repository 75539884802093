import { HttpErrorResponse } from '@angular/common/http';
import {AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { FileEncryptionService } from '@core/services/files/import/fileEncryption/file-encryption.service';
import { StorageService } from '@core/services/storage.service';
import {
  ValidateVinInput,
  ValidationService,
} from '@core/services/validation.service';
import { VehicleDataService } from '@core/services/vehicle-data.service';
import { entitlements } from '@env/entitlements';
import { NgbModal, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmatonModalComponent } from '@shared/components/confirmaton-modal/confirmaton-modal.component';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { ExpertToolCalculation } from '@shared/models/expertool-dynamic-models/ExpertToolCalculation';
import { VedocData } from '@shared/models/experttool/VedocData';
import { Spinner } from '@shared/models/spinner';
import { Observable, OperatorFunction, Subject, merge } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';

import { FcscFinlogComponent } from './components/fcsc-finlog/fcsc-finlog.component';
import { FcscFwVedocDetailsComponent } from './components/fcsc-fw-vedoc-details/fcsc-fw-vedoc-details.component';
import { ExperttoolDataModelingService } from '@core/services/experttool-data-modeling/experttool-data-modeling.service';
import { AdminSeriesConfig } from '@shared/models/filetype/AdminSeriesConfig';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { SharedSeriesService } from '@core/services/shared-series.service';

@Component({
  selector: 'app-fcsc-fw-ermittlung',
  templateUrl: './fcsc-fw-ermittlung.component.html',
  styleUrls: ['./fcsc-fw-ermittlung.component.scss'],
})
export class FcscFwErmittlungComponent implements OnInit, AfterContentChecked{
  @ViewChild('instance', { static: true }) instance: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  @ViewChild('details') details!: FcscFwVedocDetailsComponent;
  @ViewChild('finlog') finlog!: FcscFinlogComponent;
  vedocData!: VedocData;
  vin!: string;
  form!: UntypedFormGroup;
  showDiv = false;
  selectedSdpName!: string;
  selectedBdpName!: string;
  selectedBdpModalRow: any;
  selectedExtentionName!: string;
  selectedSumsCheck = false;
  calculationData!: ExpertToolCalculation | null;
  savedVins: string[] = [];
  xentryReleaseData: string[];
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;
  fromReader!: boolean;
  vedocAbfragenEntitlements = [
    entitlements.VUS.FLASHWARE_CALCULATION,
    entitlements.VUS.FCSC_TEST_FLASHWARE_CALCULATION,
    entitlements.VUS.FCSC_VORAB_FLASHWARE_CALCULATION,
  ];

  constructor(
    private cdref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private vehicleDataService: VehicleDataService,
    private translocoService: TranslocoService,
    private modalService: NgbModal,
    private appService: AppService,
    private notificationService: ErrorHandlerService,
    private storageService: StorageService,
    private fileEncrptionService: FileEncryptionService,
    private validationService: ValidationService,
    private expertoolDataModelingService: ExperttoolDataModelingService,
    private steuerdatenService : SteuerdatenService,
    private sharedSeriesService : SharedSeriesService
  ) {}

  ngOnInit() {
    const savedVins = localStorage.getItem('savedVins');
    if (savedVins) {
      this.savedVins = JSON.parse(savedVins);
      this.savedVins.reverse();
    } else {
      localStorage.setItem('savedVins', JSON.stringify(this.savedVins || ''));
    }

    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };

    this.loadDataFromStore();
    this.createForm();
    if (this.storageService.getData(sessionStorageKeys.calculationData)) {
      this.calculationData = this.storageService.getData(
        sessionStorageKeys.calculationData
      );
    }

    this.appService?.spinnerSubject?.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });

    setTimeout(() => {
      if (
        window.opener &&
        window.opener[sessionStorageKeys.populateData] &&
        window.opener[sessionStorageKeys.calculationData] &&
        window.opener[sessionStorageKeys.fileName] &&
        window.opener[sessionStorageKeys.selected]
      ) {
        const event = window.opener[sessionStorageKeys.populateData]();
        const calcResp = window.opener[sessionStorageKeys.calculationData]();
        const selected = window.opener[sessionStorageKeys.selected]();
        this.storageService.saveData(
          sessionStorageKeys.calculationData,
          calcResp
        );
        this.storageService.saveData(sessionStorageKeys.readFile, true);
        this.storageService.saveData(sessionStorageKeys.selected, selected);
        this.fileEncrptionService.fileName =
          window.opener[sessionStorageKeys.fileName]();
        this.populateReadedFinlogFileData(event);
      }
    }, 200);
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  scrollToResult() {
    document.getElementById('calculate-result')?.scrollIntoView();
  }

  private loadDataFromStore() {
    this.showDiv = false;
    const data = this.storageService.getData(sessionStorageKeys.vedocData);
    if (!!data) {
      this.vedocData = data;
      this.showDiv = true;
    }
    this.vin = this.storageService.getData(sessionStorageKeys.vin) || '';
  }

  private createForm() {
    this.form = this.formBuilder.group({
      vin: new UntypedFormControl(this.vin, [ValidateVinInput]),
      finlogDataReader: this.formBuilder.group({
        files: [],
      }),
    });
  }

  handleBlur() {
    const vin = this.form.get('vin')?.value;
    this.form.get('vin')?.setValue(vin?.trim());
  }

  loadSection(event, formData) {
    this.vedocData = event;
    event.codes = event?.codes && event?.codes?.sort();
    this.storageService.saveData(sessionStorageKeys.vedocData, event);
    if ( window.opener && window.opener[sessionStorageKeys.baureiheData]){
      const baureiheList = window.opener[sessionStorageKeys.baureiheData]()
      const detectedModelSeries = baureiheList.find(obj => obj.brName ===  this.sharedSeriesService.getExtractedSerie(this.vedocData));
      this.storageService.saveData(sessionStorageKeys.modelSeries, detectedModelSeries);
    }
    if (event && (event.vin || event.fin)) {
      this.showDiv = true;
      this.fromReader = true;
      this.forwardCalculation(formData);
    }
  }

  populateReadedFinlogFileData(event): void {
    this.showDiv = false;
    const data =
      this.storageService.getData(sessionStorageKeys.calculationData) || {};
    this.expertoolDataModelingService.calculationRequestData = data
    const responseFromFinlog = this.appService.responseFromFinlog;
    if (responseFromFinlog) {
      this.showDiv = false;
      this.calculationData = null;
      setTimeout(() => {
        this.loadSection(event, data);
      });
    } else {
      if (!event) {
        this.showDiv = false;
        this.calculationData = null;
        return;
      }
      this.loadSection(event, data);
    }
  }

  saveVin(): void {
    this.storageService.saveData(sessionStorageKeys.vin, this.form.value.vin);
  }

  loadDataFromVedoc(): void {
    const vinValue: string = this.form.get('vin')?.value;
    if (this.validateFormVin()) {
      this.fileEncrptionService.fileName = '';
      if (this.vedocData) {
        this.openRealoadConfirmModal();
      } else {
        this.getvehicleData(vinValue);
        this.storageService.saveData(sessionStorageKeys.readType, 'loadFromFINNumber');
      }
    } else {
      this.storageService.clearData();
      this.storageService.saveData(sessionStorageKeys.readType, 'loadFromFINNumber');
      this.showDiv = false;
    }
  }

  handleChange(event) {
    if (event.code === 'Enter' && this.validateFormVin()) {
      this.loadDataFromVedoc();
    }
  }

  setHeaderAttribEtn(details): void {
    this.details.updateHeaderAttribEtn(details);
  }

  openRealoadConfirmModal(): void {
    const vinValue: string = this.form.get('vin')?.value;
    const modalRef = this.modalService.open(ConfirmatonModalComponent, {
      size: 'lg',
      windowClass: 'confirmation-modal',
      backdrop: 'static',
      keyboard: false,
    });
    const message = (modalRef.componentInstance.title =
      this.translocoService.translate(
        'modules.expert-tool.modal-load-new-vehicle-data-title'
      ));
    modalRef.componentInstance.subText = this.translocoService.translate(
      'modules.expert-tool.modal-load-new-vehicle-data-subtitle'
    );
    modalRef.componentInstance.buttonText = this.translocoService.translate(
      'modules.expert-tool.btn-load-new-vehicle-data'
    );
    modalRef.componentInstance.buttonSecondaryText =
      this.translocoService.translate('global.cancel');

    modalRef.result.then((result) => {
      if (result && result.ok) {
        this.getvehicleData(vinValue);
        this.storageService.clearData();
        this.storageService.saveData(sessionStorageKeys.vin, vinValue);
        this.storageService.saveData(
          sessionStorageKeys.vedocData,
          this.vedocData
        );
        this.storageService.saveData(sessionStorageKeys.readType, 'loadFromFINNumber');
      }
    });
  }

  saveVinsLocal(vin: string) {
    const savedVinsString = localStorage.getItem('savedVins');
    if (savedVinsString) {
      const savedVins = JSON.parse(savedVinsString);

      if (savedVins) {
        const foundVin = savedVins.find((svin) => svin === vin);
        if (!foundVin) {
          if (savedVins && savedVins.length >= 200) {
            savedVins.shift();
          }
          savedVins.push(vin);
        } else {
          const foundVinIndex = savedVins.findIndex((svin) => svin === vin);
          if (foundVinIndex > -1) {
            savedVins.splice(foundVinIndex, 1);
            savedVins.push(vin);
          }
        }
        this.savedVins = savedVins;
        localStorage.setItem('savedVins', JSON.stringify(this.savedVins));
        this.savedVins.reverse();
      } else {
        localStorage.setItem('savedVins', JSON.stringify(this.savedVins));
      }
    }
  }

  async getvehicleData(vin: string) {
    this.showDiv = false;
    this.calculationData = null;
    this.spinnerObject.isLoading = true;
    this.appService?.spinnerSubject?.next(this.spinnerObject);
    const baureiheList : any = await this.sharedSeriesService.getSeries()  || []; 
    this.vehicleDataService.getVehicleDataFromVedoc(vin).subscribe(
      (data: VedocData) => {
        this.saveVinsLocal(vin);
        this.vedocData = data;
        data.codes = data?.codes?.sort();
        this.storageService.saveData(sessionStorageKeys.vedocData, data);
        this.expertoolDataModelingService.baureiheList = baureiheList;
        this.spinnerObject.isLoading = false;
        this.fromReader = false;
        this.appService?.spinnerSubject?.next(this.spinnerObject);
        if (data && data.fin) {
          this.showDiv = true;
        } else {
          this.showDiv = false;
          this.spinnerObject.isLoading = false;
          this.appService?.spinnerSubject?.next(this.spinnerObject);
          this.notificationService.showError(
            '',
            this.translocoService.translate('modules.expert-tool.fin-not-found')
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.spinnerObject.isLoading = false;
        this.appService?.spinnerSubject?.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.expert-tool.fin-not-found'
        );
      }
    );
  }

  private getExtractedSerie(): string {
    const filteredSerie = `C${
      this.steuerdatenService.extractSerieFromFinVin(
        this.vedocData && this.vedocData.fin
      ) ||
      this.steuerdatenService.extractSerieFromFinVin(
        this.vedocData && this.vedocData.vin
      )
    }`;
    return filteredSerie;
  }

  selectSdpName(event: string) {
    this.selectedSdpName = event;
  }

  selectBdpName(event: string) {
    this.selectedBdpName = event;
  }

  selectBdpModalRow(event: any) {
    this.selectedBdpModalRow = event;
  }

  selectedExtention(event: string) {
    this.selectedExtentionName = event;
  }

  selectSumsCheck(event: boolean) {
    this.selectedSumsCheck = event;
  }

  forwardCalculation(event: ExpertToolCalculation | null) {
    this.calculationData = event;
    this.storageService.saveData(
      sessionStorageKeys.calculationData,
      this.calculationData
    );
  }

  search: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance?.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
          ? this.savedVins
          : this.savedVins.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )
    );
  };

  handleSelection() {
    this.finlog.handleEcuSelection();
  }

  private validateFormVin(): boolean {
    if (this.form.valid) {
      this.saveVin();
    }
    return this.form.valid;
  }

  isInputInvalid(controlName: string): boolean | undefined {
    return this.validationService.checkInputValidity(this.form, controlName);
  }

  handleCalculationRequestDataEvent(event){
      this.expertoolDataModelingService.calculationRequestData = event
  }

  handleXentryReleaseApiData(event){
      this.xentryReleaseData = event
  }
}
