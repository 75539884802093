<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>
<div class="outer-container">
  <app-fcsc-filter-log-history
    (refreshVisibleTable)="setFilter($event)"
    [data]="logHistoryData"
  >
  </app-fcsc-filter-log-history>

  <div class="minimum-height"></div>

  <div class="row">
    <div class="col-12 round-button-container">
      <span 
        [ngClass]="{'no-drop-cursor': !selected.length}"
        title="{{
          'modules.expert-tool.log-history.tooltip.download-icon' | transloco
        }}"
      >
        <span
          [ngClass]="{'disabled': !selected.length}"
          (click)="downloadZipData()"
          class="main-icon"
        >
          <i class="bi bi-download"></i>
        </span>
      </span>
    </div>

    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="logHistoryData"
          [selected]="selected"
          [selectionType]="dataTableSelection"
          [selectAllRowsOnPage]="false"
          (select)="onSelect($event)"
          [footerHeight]="56"
          (page)="setPage($event)"
          (sort)="sortCallback($event)"
          [sorts]="sortConfig"
          [messages]="{ emptyMessage: emptyMessage | transloco }"
        >
          <ngx-datatable-column
            [cellClass]="'text-center'"
            name=""
            prop="selected"
            [flexGrow]="0"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            [width]="24"
          >
            <ng-template
              ngx-datatable-cell-template
              let-value="value"
              let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn"
            >
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  [checked]="isSelected"
                  (change)="onCheckboxChangeFn($event)"
                />
                <span class="checkmark"></span>
              </label>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="FIN"
            prop="vin"
            [flexGrow]="4"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="FIN"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.series' | transloco }}"
            prop="baureihe"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{ 'global.series' | transloco: { value: value } }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="Request"
            prop="request"
            [flexGrow]="1.5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="Request"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{
              'modules.expert-tool.result.log.datatable.file-name' | transloco
            }}"
            prop="fileName"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ getFileNameValue(value) }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'modules.expert-tool.result.log.datatable.file-name'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="System"
            prop="system"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ getSystemValue(value) }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="System"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{
              'modules.expert-tool.result.log.datatable.file-created'
                | transloco
            }}"
            prop="createdAt"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value | date: "dd.MM.yy HH:mm:ss":'UTC' }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'modules.expert-tool.result.log.datatable.file-created'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.actions' | transloco }}"
            [flexGrow]="2"
            [canAutoResize]="true"
            [sortable]="false"
            [resizeable]="false"
          >
            <ng-template
              let-row="row"
              let-expanded="expanded"
              ngx-datatable-cell-template
              let-rowIndex="rowIndex"
            >
              <span 
                title="{{
                  'modules.expert-tool.result.log.tooltip.download-icon'
                  | transloco
                }}"
                (click)="exportFile(row)"
              >
                <i class="bi bi-download bi-download-small"></i>
              </span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              ngx-datatable-header-template
            >
              <span
                ngbTooltip="{{
                  'global.actions' | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer *ngIf="true">
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-offset="offset"
              let-curPage="curPage"
            >
              <div class="col-8">
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [count]="response?.totalElements || 0"
                  [page]="page.pageNumber"
                  [size]="page.size"
                  (change)="setPage($event)"
                >
                </datatable-pager>
              </div>

              <div class="col-4 page-result-section server-side">
                <span class="float-end">
                  <span class="result-text">{{
                    "components.footer.result-per-page" | transloco
                  }}</span>
                  <span
                    class="result-value"
                    [ngClass]="{ active: page.size === 10 }"
                    (click)="setPageSize(10)"
                    >10</span
                  >|
                  <span
                    class="result-value margin-override"
                    [ngClass]="{ active: page.size === 30 }"
                    (click)="setPageSize(30)"
                    >30</span
                  >|
                  <span
                    class="result-value"
                    [ngClass]="{ active: page.size === 100 }"
                    (click)="setPageSize(100)"
                    >100</span
                  >
                  <span>
                    {{ "components.footer.files" | transloco }}:
                    {{
                      response && (response.totalElements || 0) > 0
                        ? (response?.number || 0) * page.size + 1
                        : 0
                    }}
                    -
                    {{
                      isNaN(
                        (response?.number || 0) * page.size +
                          (response?.numberOfElements || 0)
                      )
                        ? 0
                        : (response?.number || 0) * page.size +
                          (response?.numberOfElements || 0)
                    }}
                  </span>
                  <span>
                    {{ "components.footer.of" | transloco }}
                    {{
                      response?.totalElements ? response?.totalElements : 0
                    }}</span
                  >
                </span>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
