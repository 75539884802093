<p></p>

<div class="row">
  <div class="col-12">
    <div class="button-area float-end">
      <div class="displayed scroll-section">
        <div class="scroll-sub-section">
          <div class="col right">
            <span class="float-to-right"
            >{{ "global.scroll-to-flashware" | transloco }}
              <span
                (click)="scrollToFlashware()"
              >
                <i class="bi bi-chevron-double-up"></i>
              </span>
            </span>
          </div>
          <div *ngIf="fsfIsDownloadable">
            <button type="button" class="btn btn-primary" (click)="downloadFsf()">
              {{
                "modules.expert-tool.result.log.btn-result-file-download-fsf"
                  | transloco
              }}
            </button>
          </div>
          <div *ngIf="!fsfIsDownloadable">
            <button type="button" class="btn btn-custom" (click)="openFsfInfoModal()">
              {{
                "modules.expert-tool.result.log.btn-result-file-download-fsf"
                  | transloco
              }}
            </button>
          </div>
        </div>

        <div class="scroll-sub-section">
          <div class="col right">
            <span class="float-to-right"
            >{{ "global.scroll-to-fin-entry" | transloco }}
              <span
                (click)="scrollToTop()"
              >
                <i class="bi bi-chevron-double-up"></i>
              </span>
            </span>
          </div>
          <button type="button" class="btn btn-primary" (click)="downloadLog()">
            {{
              "modules.expert-tool.result.log.btn-result-file-download-log"
                | transloco
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
