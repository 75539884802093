import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { BdpName } from '@shared/models/filetype/BdpName';
import { SeriesBdp } from '@shared/models/filetype/SeriesBdp';
import {
  BdpDocumentation,
  BdpDocumentationContent,
} from '@shared/models/filetype/SeriesBdpRollback';
import { SeriesSteuerDatenQueue } from '@shared/models/filetype/SeriesSteuerDaten';
import { ModalResult } from '@shared/models/ModalResult';
import Stepper from 'bs-stepper';

@Component({
  selector: 'app-fcsc-serien-bdp-rollback-modal',
  templateUrl: './fcsc-serien-bdp-rollback-modal.component.html',
  styleUrls: ['./fcsc-serien-bdp-rollback-modal.component.scss'],
})
export class FcscSerienBdpRollbackModalComponent implements OnInit {
  seriesBdpDocumentation!: BdpDocumentation;
  seriesReplacedBdpDocumentation!: BdpDocumentation;
  seriesBdpDocumentationContent: BdpDocumentationContent[] = [];
  seriesReplacedBdpDocumentationContent: BdpDocumentationContent[] = [];
  showQueueDiv = false;
  steuerDatenQueue: SeriesSteuerDatenQueue[] = [];
  initialStep = 0;
  currentStep!: number;
  stepper!: Stepper;

  @Input() title!: string;
  @Input() subTitle!: string;
  @Input() notificationMessage!: string;
  @Input() queueTitle!: string;
  @Input() seriesBdpData!: SeriesBdp;
  @Input() bdpName!: string;

  constructor(
    public activeModal: NgbActiveModal,
    private readonly elementRef: ElementRef,
    private steuerdatenService: SteuerdatenService,
    private translocoService: TranslocoService,
    private notificationService: ErrorHandlerService
  ) {}

  ngOnInit(): void {
    this.createStepperHandler();
    this.handleStepperNavgation(this.initialStep);
  }

  createStepperHandler() {
    const stepperElement =
      this.elementRef.nativeElement.querySelector('#stepper-rollback');
    stepperElement.addEventListener('show.bs-stepper', (event) => {
      this.currentStep = event.detail.to;
    });

    this.stepper = new Stepper(stepperElement, {
      linear: false,
      animation: true,
    });
  }

  populateBdpQueue() {
    this.steuerdatenService.getBdpReleaseQueue().subscribe(
      (response: SeriesSteuerDatenQueue[]) => {
        this.steuerDatenQueue = response;
        if (this.steuerDatenQueue && this.steuerDatenQueue.length > 0) {
          this.showQueueDiv = true;
          this.queueTitle = this.translocoService.translate(
            'modules.data-management.serien-daten.modal.bdp-activation-queue-title',
            { length: this.steuerDatenQueue.length }
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  handleStepperNavgation(currentStep: number) {
    switch (currentStep) {
      case 0:
        this.populateDetails();
        break;
      case 1:
        break;
      case 2:
        this.populateBdpQueue();
        break;
      case 3:
        break;
      default:
    }
  }

  private populateDetails() {
    const currentBdp = `${BdpName.SERIEN}${this.seriesBdpData.bdpName}`;
    const replacedBdp = `${BdpName.SERIEN}${this.bdpName}`;
    this.steuerdatenService
      .getBdpDocumentation(currentBdp)
      .subscribe((resp: BdpDocumentation) => {
        this.seriesBdpDocumentation = JSON.parse(JSON.stringify(resp));
        resp.content = resp?.content?.filter((cont) => cont.baureihe !== null);
        this.populateSeriesBdpDocumentationTable('currentBdp', resp.content);
      });
    this.steuerdatenService
      .getBdpDocumentation(replacedBdp)
      .subscribe((resp: BdpDocumentation) => {
        this.seriesReplacedBdpDocumentation = JSON.parse(JSON.stringify(resp));
        resp.content = resp?.content?.filter((cont) => cont.baureihe !== null);

        this.populateSeriesBdpDocumentationTable('replacedBdp', resp.content);
      });
  }

  private populateSeriesBdpDocumentationTable(
    type: string,
    content: BdpDocumentationContent[]
  ) {
    content.forEach((element) => {
      if (!element?.sdpName) {
        element.sdpName = this.translocoService.translate(
          'modules.data-management.serien-daten.modal.sdp-empty'
        );
      }
    });
    switch (type) {
      case 'currentBdp':
        this.seriesBdpDocumentationContent = content;
        break;
      case 'replacedBdp':
        this.seriesReplacedBdpDocumentationContent = content;
        break;
      default:
    }
  }

  // modal buttons actions
  public back(): void {
    this.currentStep = this.currentStep - 1;
    this.stepper.previous();
    this.handleStepperNavgation(this.currentStep);
  }

  public next(): void {
    this.currentStep = this.currentStep + 1;
    this.stepper.next();
    this.handleStepperNavgation(this.currentStep);
  }

  public submit(form): void {
    const result = {
      ok: ModalResult.ACTIVATION_SUCCESSFUL,
      form,
    };
    this.activeModal.close(result);
  }

  public close(): void {
    this.activeModal.close(ModalResult.ACTIVATION_CANCEL);
  }
}
