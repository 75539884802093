import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult } from '@shared/models/ModalResult';

@Component({
  selector: 'app-confirmaton-modal',
  templateUrl: './confirmaton-modal.component.html',
  styleUrls: ['./confirmaton-modal.component.scss'],
})
export class ConfirmatonModalComponent implements OnInit {
  @Input() title!: string;
  @Input() subText!: string;
  @Input() buttonText!: string;
  @Input() buttonSecondaryText!: string;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  proceed(): void {
    const result = {
      ok: ModalResult.DELETE_OK || ModalResult.WARNING_OK,
    };
    this.activeModal.close(result);
  }

  cancel(): void {
    const result = {
      cancel: ModalResult.DELETE_CANCEL,
    };
    this.activeModal.close(result);
  }
}
