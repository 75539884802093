<div class="filter" *ngIf="formLoaded">
  <form [formGroup]="form" (keyup.enter)="filterRows()">
    <div>
      <div class="row row-cols-auto">
        <div class="col">
          <div class="form-group load-vin load-vin-filter">
            <label for="vin">FIN</label>
            <input
            [ngbTypeahead]="searchTypeAhead"
            (focus)="focus$.next($any($event).target.value)"
            (click)="click$.next($any($event).target.value)"
            (blur)="handleBlur()"
            type="text"
            autocomplete="off"
            class="form-control vin-loader"
            id="vin"
            placeholder="{{
              'modules.expert-tool.log-history.filter.input-fin-placeholder' | transloco
            }}"
            formControlName="vin"
          />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="baureihe">{{
              "modules.data-management.table-header.series" | transloco
            }}</label>
            <ng-select
              [searchable]="true"
              [clearable]="false"
              notFoundText="{{ 'global.dropdown-not-found-text' | transloco }}"
              [hideSelected]="true"
              [items]="seriesList"
              bindLabel="brName"
              bindValue="brName"
              formControlName="baureihe"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item.brName }}
              </ng-template>
              <ng-template
                ng-label-tmp
                ng-option-selected
                let-item="item"
                let-index="index"
              >
                {{ item.brName }}
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="request">Request</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="request"
              formControlName="request"
              placeholder="{{
                'modules.expert-tool.log-history.filter.input-request-placeholder'
                  | transloco
              }}"
              (input)="OnInput($event)"
              (paste)="OnPaste($event)"
              (keyup.enter)="filterRows()"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="fileName">{{
              "modules.expert-tool.log-history.filter.file" | transloco
            }}</label>
            <ng-select
              [searchable]="true"
              [clearable]="false"
              notFoundText="{{ 'global.dropdown-not-found-text' | transloco }}"
              [hideSelected]="true"
              [items]="fileNameList"
              bindLabel="name"
              bindValue="value"
              formControlName="fileName"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item.name }}
              </ng-template>
              <ng-template
                ng-label-tmp
                ng-option-selected
                let-item="item"
                let-index="index"
              >
              {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="system">{{ "System" | transloco }}</label>
            <ng-select
              [searchable]="false"
              [hideSelected]="true"
              [items]="systemList"
              bindLabel="name"
              bindValue="value"
              formControlName="system"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item.name }}
              </ng-template>
              <ng-template
                ng-label-tmp
                ng-option-selected
                let-item="item"
                let-index="index"
              >
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="createdAtFrom">{{
              "modules.expert-tool.log-history.filter.from-date" | transloco
            }}</label>

            <div class="input-group">
              <input
                #searchCreatedAtFrom
                class="form-control dp"
                placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                id="createdAtFrom"
                name="dp"
                formControlName="createdAtFrom"
                ngbDatepicker
                #d="ngbDatepicker"
                readonly
                (click)="removeCreatedAtFromFocus()"
              />
              <div class="input-group-append">
                <i class="dp" (click)="d.toggle()" class="bi bi-calendar3"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="createdAtUntil">{{
              "modules.expert-tool.log-history.filter.to-date" | transloco
            }}</label>

            <div class="input-group">
              <input
                #searchCreatedAtUntil
                class="form-control dp"
                placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                id="createdAtUntil"
                name="dp"
                formControlName="createdAtUntil"
                ngbDatepicker
                #dto="ngbDatepicker"
                readonly
                (click)="removeCreatedAtUntilFocus()"
              />
              <div class="input-group-append">
                <i class="dp" (click)="dto.toggle()" class="bi bi-calendar3"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="button-area">
            <button
              type="button"
              class="btn btn-primary"
              (click)="filterRows()"
            >
              {{ "modules.data-management.filter.btn-search" | transloco }}
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              (click)="resetFilteredRows()"
            >
              {{ "modules.data-management.filter.btn-reset" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
