import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Status } from '@core/enums/status-color.enum';
import { StatusSdpQueue } from '@core/enums/status-sdp-queue.enum';
import { AppService } from '@core/services/app.service';
import { StorageService } from '@core/services/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { SeriesSteuerDatenQueue } from '@shared/models/filetype/SeriesSteuerDaten';

import { FcscBdsSeriesQueueModalComponent } from './fcsc-bds-series-queue-modal/fcsc-bds-series-queue-modal.component';

@Component({
  selector: 'app-fcsc-bdp-series-queue',
  templateUrl: './fcsc-bdp-series-queue.component.html',
  styleUrls: ['./fcsc-bdp-series-queue.component.scss'],
})
export class FcscBdpSeriesQueueComponent implements OnInit, OnChanges {
  @Input() steuerDatenQueue!: SeriesSteuerDatenQueue[];
  @Input() rollbackData: SeriesSteuerDatenQueue[];
  @Output() refreshTable = new EventEmitter<any>();

  currentSdp?: SeriesSteuerDatenQueue = undefined;
  form!: UntypedFormGroup;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private translocoService: TranslocoService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private appService: AppService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.loadCurrentSdp();
    this.setCurrentSdpForm();
  }

  loadCurrentSdp() {
    const inQueueKey = this.appService.getValueConstant(
      StatusSdpQueue,
      StatusSdpQueue.IN_QUEUE
    );
    this.currentSdp = this.steuerDatenQueue.find(
      (sdp) => sdp && sdp.status !== inQueueKey
    );
  }

  CheckFailedSdpAcivation(): boolean {
    const errorKey = this.appService.getValueConstant(
      StatusSdpQueue,
      StatusSdpQueue.ERROR
    );
    const isError = this.steuerDatenQueue.filter(
      (val) => val.status === errorKey
    );

    if (isError && isError.length) {
      return true;
    } else {
      return false;
    }
  }

  setCurrentSdpForm() {
    this.form = this.formBuilder.group({
      baureihe: new UntypedFormControl(this.currentSdp?.baureihen),
      sdp: new UntypedFormControl(this.currentSdp?.sdpNames),
      createdAt: new UntypedFormControl(
        this.datePipe.transform(this.currentSdp?.createdAt, 'HH:mm:ss')
      ),
      username: new UntypedFormControl(this.currentSdp?.username),
      status: new UntypedFormControl(this.getStatusText(this.currentSdp?.status)),
    });
  }

  openQueueModal() {
    const modalRef = this.modalService.open(FcscBdsSeriesQueueModalComponent, {
      size: 'lg',
      windowClass: 'details-modal',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.steuerDatenQueue = this.steuerDatenQueue;
    modalRef.componentInstance.currentSdp = this.currentSdp;
    modalRef.componentInstance.refreshTable = this.refreshTable;
  }

  public getStatusText(value?: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(StatusSdpQueue[value as keyof typeof StatusSdpQueue])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  public getBarStatusText() {
    if (this.currentSdp && this.currentSdp.sdpNames.length > 1) {
      return this.translocoService.translate(
        this.currentSdp.isRollback === true
          ? 'modules.data-management.bdp-rollback-active'
          : 'modules.data-management.sdp-multi-switch-active'
      );
    } else if (this.currentSdp && this.currentSdp.sdpNames.length === 1) {
      return this.translocoService.translate(
        this.currentSdp.isRollback === true
          ? 'modules.data-management.bdp-rollback-active'
          : 'modules.data-management.sdp-switch-active',
        { name: this.form.get('sdp')?.value }
      );
    }
    return "";
  }
}
