<div class="modal-header">
  <span class="modal-title">{{ title }}</span>
</div>

<div class="modal-sub-text">{{ subText }}</div>

<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-6">
        <div class="form-group">
          <label for="diogName"
            >{{ 'modules.expert-tool.diog-name' | transloco }}</label
          >
          <input
            appTrim
            type="text"
            autocomplete="off"
            class="form-control small-size"
            id="diogName"
            formControlName="diogName"
          />
        </div>
      </div>

      <div class="col-6">
        <div class="form-group">
          <label for="hwSnr"
            >{{ 'modules.expert-tool.hwsnr' | transloco }}</label
          >
          <input
            appTrim
            type="text"
            autocomplete="off"
            class="form-control small-size"
            id="hwSnr"
            formControlName="hwSnr"
            [class.is-invalid]= "isInputInvalid('hwSnr')"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-8 form-group add-space">
        <label for="swSnrs"
          >{{ 'modules.expert-tool.fw-on-cu' | transloco }}</label
        >
        <textarea
          appTrim
          class="form-control"
          id="swSnrs"
          formControlName="swSnrs"
          [class.is-invalid]= "isInputInvalid('swSnrs')"
          rows="6"
        ></textarea>
      </div>
    </div>

    <div class="row">
      <div class="col-4 form-group add-space">
        <label for="attributesVpRate"
          >{{ 'modules.expert-tool.attributes-vp-rate-label' | transloco }}</label
        >
        <ng-select
          [searchable]="false"
          [hideSelected]="true"
          [items]="attributesVpRateItems"
          bindLabel="name"
          formControlName="attributesVpRate"
          (change)="changeEditable()"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            {{ item.name }}
          </ng-template>
          <ng-template
            ng-label-tmp
            ng-option-selected
            let-item="item"
            let-index="index"
          >
            {{ item.name }}
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="row">
      <div class="col-8 form-group add-space">
        <label for="attributesVpRate"
          >{{ 'modules.expert-tool.attributes-label' | transloco }}</label
        >
        <div
          tabindex="-1"
          id="editable-content"
          (click)="activateEdit()"
          [ngClass]="{'focus-div': activateEditFlag}"
        >
          <span
            id="block2"
            class="editable-attr"
            *ngIf="!activateEditFlag"
            [innerHtml]="getAtrributes()"
          ></span>
          <span *ngIf="activateEditFlag">
            <textarea
              appTrim
              id="edit-text-area"
              class="form-control attr"
              rows="0"
              formControlName="editedValue"
              (blur)="activateEditFlag = false; resetValue()"
            ></textarea>
          </span>
        </div>
      </div>

      <div *ngIf="form.invalid" class="custom-pos-relative">
        <app-upload-duration
        [isError]= true
          [translationKey]="
            'global.validation.input-length-10'
          "
        ></app-upload-duration>
      </div>

    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-end">
          <div class="displayed">
            <button type="button" class="btn btn-secondary" (click)="cancel()">
              {{ 'global.cancel' | transloco }}
            </button>
            <button type="button" class="btn btn-primary" (click)="save()">
              {{buttonText}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
