import { Component, Input, OnInit } from '@angular/core';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { CurrentUserEnt } from '@shared/models/CurrentUser';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-fcsc-admin-fsf-manipulator-entitlement',
  templateUrl: './fcsc-admin-fsf-manipulator-entitlement.component.html',
  styleUrls: ['./fcsc-admin-fsf-manipulator-entitlement.component.scss']
})
export class FcscAdminFsfManipulatorEntitlementComponent implements OnInit {
  @Input()
  userEntitlements: any;
  entitlementsList = [];
  constructor(
    public activeModal: NgbActiveModal,
    public clipboardService: ClipboardService,
    public notificationService: ErrorHandlerService,
    public translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    console.log('FcscAdminFsfManipulatorEntitlementComponent')
    this.createEntitlementsRows(this.userEntitlements)
    console.log(this.userEntitlements)
  }

  private createEntitlementsRows(userEntitlements): void {
    this.entitlementsList = userEntitlements.map((entitl, index) => {
      return new CurrentUserEnt(entitl, index);
    });
    this.userEntitlements = this.entitlementsList;
  }

  copyEntitlements(text): void {
    const entitlements = text.map((elem) => elem.entitlementName);
    let entitlementText = '';
    entitlements.forEach((entitlement) => {
      entitlementText = entitlementText + entitlement + '\n';
    });
    this.clipboardService.copy(entitlementText);
    this.notificationService.showSuccess(
      '',
      this.translocoService.translate(
        'components.header.modal.entitlements-copy-success'
      )
    );
  }

}
