import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { CalculationService } from '@core/services/calculation.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { ExperttoolDataModelingService } from '@core/services/experttool-data-modeling/experttool-data-modeling.service';
import { FileEncryptionService } from '@core/services/files/import/fileEncryption/file-encryption.service';
import { StorageService } from '@core/services/storage.service';
import { VehicleDataService } from '@core/services/vehicle-data.service';
import { TranslocoService } from '@ngneat/transloco';
import { expertToolConstants } from '@shared/constants/ExpertToolConstants';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { ControlUnits } from '@shared/models/ControlUnits';
import { ExpertToolCalculation } from '@shared/models/expertool-dynamic-models/ExpertToolCalculation';
import { VedocData } from '@shared/models/experttool/VedocData';
import { FlashwareResponseInitial } from '@shared/models/flashwareResponseInitial';
import { Spinner } from '@shared/models/spinner';
import { SharedSeriesService } from '@core/services/shared-series.service';

@Component({
  selector: 'app-fcsc-finlog-data-reader',
  templateUrl: './fcsc-finlog-data-reader.component.html',
  styleUrls: ['./fcsc-finlog-data-reader.component.scss'],
})
export class FcscFinlogDataReaderComponent implements OnInit {
  form!: UntypedFormGroup;
  @Input() formGroupName!: string;
  @Input() initialFwRes!: FlashwareResponseInitial;
  @Output() populateData = new EventEmitter<VedocData | null>();
  file: File | null = null;
  convertedJsonData!: ExpertToolCalculation | null;
  controlUnits: ControlUnits[];
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;

  constructor(
    private rootFormGroup: FormGroupDirective,
    private storageService: StorageService,
    private translocoService: TranslocoService,
    private notificationService: ErrorHandlerService,
    private fileEncrptionService: FileEncryptionService,
    private calculationService: CalculationService,
    private appService: AppService,
    private vehicleDataService: VehicleDataService,
    private expertoolDataModelingService: ExperttoolDataModelingService,
    private sharedSeriesService : SharedSeriesService,
  ) {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.control.get(this.formGroupName) as UntypedFormGroup;

    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };

    this.appService?.spinnerSubject?.subscribe(
      (spinnerObject: Spinner) => {
        this.loadingTitle = spinnerObject.loadingTitle;
        this.loadingSubtitle = spinnerObject.loadingSubtitle;
        this.isLoading = spinnerObject.isLoading;
      },
      (error) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  readFinlogFile() {
    this.storageService.saveData(sessionStorageKeys.readType, 'loadFromFINLog');
    this.file = this.form.get('files')?.value;
    const fileReader: FileReader = new FileReader();
    const file = this.file ? this.file[0] : null;
    const fileSplit = file.name.split('.');
    fileReader.onload = (e) => {
      this.fileEncrptionService.fileName = file?.name;
      if (this.getLogExtention(file, fileSplit)) {
        this.convertLogFileToJson(fileReader?.result);
      } else if (this.getXmlExtention(file, fileSplit)) {
        this.convertXmlFileToJson(fileReader?.result);
      } else if (this.getExfExtention(file, fileSplit)) {
        this.decryptFinLog(fileReader.result);
      }
    };
    this.checkFilesReading(fileReader);
  }

  public emitVedocData(vedocData: VedocData | null) {
    if (!vedocData?.fin && vedocData?.vin) {
      this.spinnerObject.isLoading = true;
      this.appService?.spinnerSubject?.next(this.spinnerObject);
      this.vehicleDataService.getVehicleDataFromVedoc(vedocData?.vin).subscribe(
        (resp) => {
          this.spinnerObject.isLoading = false;
          this.appService?.spinnerSubject?.next(this.spinnerObject);
          vedocData.fin = resp?.fin;
          this.populateData.emit(vedocData);
        },
        (error: HttpErrorResponse) => {
          this.spinnerObject.isLoading = false;
          this.appService?.spinnerSubject?.next(this.spinnerObject);
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.admin-section.config-modal.insert-edit-api-error-message'
          );

          this.storageService.removeItem(sessionStorageKeys.calculationData);
          this.storageService.removeItem(sessionStorageKeys.flashForm);
          this.storageService.removeItem(sessionStorageKeys.readFile);
        }
      );
    } else {
      this.populateData.emit(vedocData);
    }
  }

  private decryptFinLog(data) {
    this.spinnerObject.isLoading = true;
    this.appService?.spinnerSubject?.next(this.spinnerObject);
    this.fileEncrptionService.getFileContentDecrypted(data).subscribe(
      (response: HttpResponse<any>) => {
        this.spinnerObject.isLoading = false;
        this.appService?.spinnerSubject?.next(this.spinnerObject);
        const base64response = response.body.content + '';
        const decryptedData = atob(base64response);
        this.convertXmlFileToJson(decryptedData);

        this.storageService.saveData(
          sessionStorageKeys.calculationData,
          this.convertedJsonData
        );
      },
      (error) => {
        this.spinnerObject.isLoading = false;
        this.appService?.spinnerSubject?.next(this.spinnerObject);
        const file = this.file ? this.file[0] : null;
        const fileSplit = file.name.split('.');
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.expert-tool.encrypt-error',
          {
            file: fileSplit && fileSplit[0],
          }
        );
      }
    );
  }

  private async convertXmlFileToJson(data) {
    this.spinnerObject.isLoading = true;
    this.appService?.spinnerSubject?.next(this.spinnerObject);
    this.expertoolDataModelingService.baureiheList = await this.sharedSeriesService.getSeries()
    this.calculationService.putConvertXmlToJson(data).subscribe(
      (response: HttpResponse<ExpertToolCalculation | null>) => {
        this.spinnerObject.isLoading = false;
        this.appService?.spinnerSubject?.next(this.spinnerObject);
        this.convertedJsonData = response.body;
        this.storageService.saveData(
          sessionStorageKeys.calculationData,
          this.convertedJsonData
        );

        this.storageService.saveData(sessionStorageKeys.readFile, true);
        this.createVedocDataFromResponse();
      },
      (error) => {
        this.spinnerObject.isLoading = false;
        this.appService?.spinnerSubject?.next(this.spinnerObject);
        this.clearDataWhenError();

        this.notificationService.handleServerErrorNotification(
          error,
          'modules.expert-tool.encrypt-error'
        );
      }
    );
  }

  private convertLogFileToJson(data) {
    this.spinnerObject.isLoading = true;
    this.appService?.spinnerSubject?.next(this.spinnerObject);

    this.calculationService.putConvertIniToJson(data).subscribe(
      (response: HttpResponse<ExpertToolCalculation>) => {
        this.spinnerObject.isLoading = false;
        this.appService?.spinnerSubject?.next(this.spinnerObject);
        this.convertedJsonData = response.body;
        this.storageService.saveData(
          sessionStorageKeys.calculationData,
          this.convertedJsonData
        );

        this.storageService.saveData(sessionStorageKeys.readFile, true);
        this.createVedocDataFromResponse();
      },
      (error) => {
        this.spinnerObject.isLoading = false;
        this.appService?.spinnerSubject?.next(this.spinnerObject);

        this.clearDataWhenError();

        this.notificationService.handleServerErrorNotification(
          error,
          'modules.expert-tool.encrypt-error'
        );
      }
    );
  }

  private createVedocDataFromResponse() {
    const vedocData =
      this.expertoolDataModelingService.mapVedocDataFromCalculationResponse(
        this.convertedJsonData as ExpertToolCalculation
      );
    this.expertoolDataModelingService.createVedocDataFromResponse(
      this.convertedJsonData as ExpertToolCalculation,
      vedocData
    );
    this.emitVedocData(vedocData); 
  }

  checkFilesReading(fileReader) {
    const vin = this.storageService.getData(sessionStorageKeys.vin);
    if (this.checkAllowedExtentions()) {
      if (this.convertedJsonData || vin) {
        this.storageService.clearData();
        this.storageService.saveData(sessionStorageKeys.readType, 'loadFromFINLog');
        this.populateData.emit(null);
      }
      setTimeout(() => {
        fileReader.readAsText(this.file ? this.file[0] : null);
      }, 100);
    }
  }

  private checkAllowedExtentions(): string {
    const file = this.file ? this.file[0] : null;
    const fileSplit = file.name.split('.');
    const allowedExtentions =
      this.getLogExtention(file, fileSplit) ||
      this.getXmlExtention(file, fileSplit) ||
      this.getExfExtention(file, fileSplit);

    if (!allowedExtentions) {
      this.clearDataWhenError();
    }
    return allowedExtentions;
  }

  private getLogExtention(file, fileSplit) {
    return (
      file &&
      fileSplit[fileSplit.length - 1] === expertToolConstants.general.log
    );
  }

  private getXmlExtention(file, fileSplit) {
    return (
      file &&
      fileSplit[fileSplit.length - 1] === expertToolConstants.general.xml
    );
  }

  private getExfExtention(file, fileSplit) {
    return (
      file &&
      fileSplit[fileSplit.length - 1] === expertToolConstants.general.exf
    );
  }

  private clearDataWhenError() {
    this.storageService.clearData();
    this.storageService.saveData(sessionStorageKeys.readType, 'loadFromFINNumber');
    this.populateData.emit(null);
    this.notificationService.showError(
      '',
      this.translocoService.translate('modules.expert-tool.wrong-finlog-file')
    );
  }
}
