export class TestautomationTestSuccess {
    
  public timeStamp: string;
  public duration: number;
  public successfulTestCases: number;
  public unsuccessfulTestCases: number;
  public username: string;

  constructor(timeStamp: string, duration: number, successfulTestCases: number, unsuccessfulTestCases: number, username: string) {
      this.timeStamp = timeStamp;
      this.duration = duration;
      this.successfulTestCases = successfulTestCases;
      this.unsuccessfulTestCases = unsuccessfulTestCases;
      this.username = username;
  }

}

export class TestautomationTestCancelled {

  public id: number;
  public username: string;
  public timeStamp: string | null;
  public end_time: string | null;
  public status: string;

  constructor(id: number, username: string, timeStamp: string | null, end_time: string | null, status: string) {
      this.id = id;
      this.username = username;
      this.timeStamp = timeStamp;
      this.end_time = end_time;
      this.status = status;
  }

}

export class TestautomationTest {
  public success: TestautomationTestSuccess;
  public cancelled: TestautomationTestCancelled;

  constructor(success: TestautomationTestSuccess, cancelled: TestautomationTestCancelled) {
      this.success = success;
      this.cancelled = cancelled;
  }
}
