export const serialUploadListHeadersTitles = [
  'global.series',
  'modules.data-management.serien-daten.upload-list.hist-date',
  'modules.data-management.serien-daten.upload-list.min-date',
  'modules.data-management.serien-daten.upload-list.min-date',
  'modules.data-management.serien-daten.upload-list.contact-person',
  'modules.data-management.serien-daten.upload-list.prio',
  'modules.data-management.serien-daten.upload-list.requirement',
  'global.comment',
];

export const includedSerialUploadListExport = [
  'baureihe',
  'minDatenstand',
  'histDate',
  'uploadDate',
  'contactPerson',
  'prio',
  'requirement',
  'comment',
];
