<div class="modal-body">
  <div class="row control-units-table">
    <!-- 1st table -->
    <div class="col-5">
      <div class="custom-margin-b">
        <span class="sub-title">{{
          "modules.data-management.serien-daten.modal.rollback-actual-status-title"
            | transloco
        }}</span>
      </div>
      <div>
        <div class="data-table">
          <ngx-datatable
            #dataTable
            class="material datatable-scrollable"
            [columnMode]="'flex'"
            [headerHeight]="56"
            rowHeight="auto"
            [scrollbarH]="false"
            [scrollbarV]="false"
            [rows]="seriesBdpDocumentationContent"
            [sorts]="sortConfig"
            [messages]="{ emptyMessage: emptyMessage }"
          >
            <ngx-datatable-column
              [cellClass]="'text-right'"
              name="{{
                'modules.data-management.serien-daten.modal.series' | transloco
              }}"
              prop="baureihe"
              [flexGrow]="2"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <span *ngIf="value">{{ value }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.serien-daten.modal.series'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{
                'modules.data-management.serien-daten.modal.sdp' | transloco
              }}"
              prop="sdpName"
              [flexGrow]="8"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <span class="" *ngIf="value">{{ value }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.data-management.serien-daten.modal.sdp'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>

    <div class="col-2">
      <div class="app-divider"></div>
    </div>

    <!-- 2nd table -->
    <div class="col-5">
      <div class="custom-margin-b">
        <span class="sub-title">{{
          "modules.data-management.serien-daten.modal.rollback-after-status-title"
            | transloco
        }}</span>
      </div>

      <div>
        <div class="data-table">
          <ngx-datatable
            #queueDataTable
            class="material datatable-scrollable"
            [columnMode]="'flex'"
            [headerHeight]="56"
            rowHeight="auto"
            [scrollbarH]="false"
            [scrollbarV]="false"
            [rows]="seriesReplacedBdpDocumentationContent"
            [sorts]="sortConfig"
            [messages]="{ emptyMessage: emptyMessage }"
          >
            <ngx-datatable-column
              [cellClass]="'text-right'"
              name="{{
                'modules.data-management.serien-daten.modal.series' | transloco
              }}"
              prop="baureihe"
              [flexGrow]="3"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <span *ngIf="value">{{ value }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.serien-daten.modal.series'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column
              name="{{
                'modules.data-management.serien-daten.modal.sdp' | transloco
              }}"
              prop="sdpName"
              [flexGrow]="9"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <span *ngIf="value">{{ value }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.data-management.serien-daten.modal.sdp'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [cellClass]="'text-center'"
              name=""
              [flexGrow]="2"
              [sortable]="false"
              [resizeable]="false"
            >
              <ng-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <span>
                  <span 
                    [ngClass]="checkVisibility(row)"
                    class="header-icon"
                    title="{{
                      'modules.data-management.serien-daten.modal.info-icon-tooltip'
                        | transloco
                    }}"
                  >
                    <i class="bi bi-info-circle"></i>
                  </span>
                </span>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-start">
          <button
            type="button"
            class="btn btn-secondary back"
            (click)="handleBack()"
          >
            {{ "global.back" | transloco }}
          </button>
        </div>
        <div class="button-area float-end">
          <div class="displayed">

            <button
            type="button"
            class="btn btn btn-secondary"
            (click)="handleClose()"
          >
            {{ "global.cancel" | transloco }}
          </button>

            <button
              type="button"
              class="btn btn btn-primary"
              (click)="handleNext()"
            >
              {{ "global.next" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
