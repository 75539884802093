export const expertToolConstants = {
  keys: {
    ecuRefer: 'ECU_Refer' as const,
    sgFittingFlashsw: 'SG_FITTINGFLASHSW' as const,
    sgDiogname: 'SG_DiogName' as const,
    sgRefnr: 'SG_REFNR',
    fwKette: 'FW_KETTE' as const,
    vpStatus: 'VP_STATUS' as const,
    vpStatusVp5: 'VP_STATUS_VP5' as const,
    cpWebInfo: 'CPweb_Info' as const,
    errorMsg: 'ERROR_MSG' as const,
    header: 'HEADER' as const,
    etn: 'ETN' as const,
    ecus: 'ecus' as const,
    attrib: 'ATTRIB' as const,
    fwmbs: 'FW_MBS' as const,
    sgCurrentSw: 'SG_Current_SW' as const,
    vpRateModules: 'Vp_Rate_Modules' as const,
    vpRateModulesStaticKey: 'Vp_Rate_Modules=' as const,
    flashbedarf: 'Flashbedarf' as const,
    finInternal: 'FIN_INTERNAL' as const,
    ecuRequest: 'ECU_REQUEST' as const,
    fin: 'FIN' as const,
    vin: 'VIN' as const,
    ttz: 'TTZ' as const,
    saCode: 'SACode' as const,
    diebkz: 'DIEBKZ' as const,
    flashFileExtension: 'FlashFileExtension' as const,
    logLevel: 'LogLevel' as const,
    flashData: 'FlashData' as const,
    diagnosisRelease : 'DiagnosisRelease' as const
  } as const,

  values: {
    byPriority: 'ByPriority' as const,
    always: 'Always' as const,
    allModules: 'AllModules' as const,
    none: 'None' as const,
    applicationName: 'Expertentool' as const,
    applicationVersion: '1.0.0' as const,
    taskProcess: 'calculate' as const,
    taskMode: 'online' as const,
    userID: 'Xentry_USER_001' as const,
    userOrganisationID: 'E-DE71032A' as const,
    destinationService: 'Xentry' as const,
    destinationAction: 'calculate' as const,
    localeCountry: 'DE' as const,
    payloadCategory: 'PKW' as const,
    payloadPoolIdentifier: 'a' as const,
    payloadSparte: 'PKW' as const,
    payloadSourceSystem: 'ExpTool' as const,
    payloadSymptomVP: 'No' as const,
  } as const,

  general: {
    yes: 'YES' as const,
    exf: 'exf' as const,
    log: 'log' as const,
    xml: 'xml' as const,
  },
};
