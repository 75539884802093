<div class="modal-body">
  <div class="custom-margin-b">
    <span class="sub-title">{{
      "modules.data-management.serien-daten.modal.rollback-queue-title"
        | transloco
    }}</span>
  </div>

  <ngx-datatable
    #dataTable
    class="material datatable-scrollable mt-4"
    [columnMode]="'flex'"
    [headerHeight]="56"
    rowHeight="auto"
    [scrollbarH]="false"
    [scrollbarV]="false"
    [rows]="steuerDatenQueue"
    [sorts]="sortConfigQueue"
    [messages]="{
      emptyMessage:
        'modules.data-management.serien-daten.modal.rollback-empty-queue'
        | transloco
    }"
  >
    <ngx-datatable-column
      name="{{ 'modules.data-management.table-header.sd-packet' | transloco }}"
      prop="sdpNames"
      [flexGrow]="3"
      [resizeable]="false"
    >
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <span
          *ngIf="value"
          class="text-pre-wrap"
          placement="bottom"
          [ngbTooltip]="value"
          data-container="body"
          container="body"
          tooltipClass="note-tooltip"
          >{{ truncateValue(value) }}</span
        >
      </ng-template>

      <ng-template
        let-value="value"
        let-column="column"
        let-rowIndex="rowIndex"
        let-sort="sortFn"
        ngx-datatable-header-template
      >
        <span
          class="header-sort"
          ngbTooltip="{{
            'modules.data-management.table-header.sd-packet' | transloco
          }}"
          data-container="body"
          container="body"
          placement="bottom"
          tooltipClass="note-tooltip"
          (click)="sort()"
          >{{ column.name }}</span
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'modules.data-management.table-header.series' | transloco }}"
      prop="baureihen"
      [flexGrow]="2"
      [resizeable]="false"
    >
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <span
          *ngIf="value"
          class="text-pre-wrap"
          placement="bottom"
          [ngbTooltip]="value"
          data-container="body"
          container="body"
          tooltipClass="note-tooltip"
          >{{ truncateValue(value) }}</span
        >
      </ng-template>

      <ng-template
        let-value="value"
        let-column="column"
        let-rowIndex="rowIndex"
        let-sort="sortFn"
        ngx-datatable-header-template
      >
        <span
          class="header-sort"
          ngbTooltip="{{
            'modules.data-management.table-header.series' | transloco
          }}"
          data-container="body"
          container="body"
          placement="bottom"
          tooltipClass="note-tooltip"
          (click)="sort()"
          >{{ column.name }}</span
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'modules.data-management.table-header.start-time' | transloco }}"
      prop="createdAt"
      [flexGrow]="3"
      [resizeable]="false"
    >
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <span *ngIf="value">{{ value | date: "HH:mm:ss":'UTC' }}</span>
      </ng-template>

      <ng-template
        let-value="value"
        let-column="column"
        let-rowIndex="rowIndex"
        let-sort="sortFn"
        ngx-datatable-header-template
      >
        <span
          class="header-sort"
          ngbTooltip="{{
            'modules.data-management.table-header.start-time' | transloco
          }}"
          data-container="body"
          container="body"
          placement="bottom"
          tooltipClass="note-tooltip"
          (click)="sort()"
          >{{ column.name }}</span
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'modules.data-management.table-header.user' | transloco }}"
      prop="username"
      [flexGrow]="3"
      [resizeable]="false"
    >
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <span *ngIf="value">{{ value }}</span>
      </ng-template>

      <ng-template
        let-value="value"
        let-column="column"
        let-rowIndex="rowIndex"
        let-sort="sortFn"
        ngx-datatable-header-template
      >
        <span
          class="header-sort"
          ngbTooltip="{{
            'modules.data-management.table-header.user' | transloco
          }}"
          data-container="body"
          container="body"
          placement="bottom"
          tooltipClass="note-tooltip"
          (click)="sort()"
          >{{ column.name }}</span
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      name="{{ 'global.status' | transloco }}"
      prop="status"
      [flexGrow]="3"
      [resizeable]="false"
    >
      <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
        <span *ngIf="value"> {{ getStatusText(value) }}</span>
      </ng-template>

      <ng-template
        let-value="value"
        let-column="column"
        let-rowIndex="rowIndex"
        let-sort="sortFn"
        ngx-datatable-header-template
      >
        <span
          class="header-sort"
          ngbTooltip="{{ 'global.status' | transloco }}"
          data-container="body"
          container="body"
          placement="bottom"
          tooltipClass="note-tooltip"
          (click)="sort()"
          >{{ column.name }}</span
        >
      </ng-template>
    </ngx-datatable-column>

    <ngx-datatable-column
      [cellClass]="'text-center'"
      name=""
      [flexGrow]="1"
      [sortable]="false"
      [resizeable]="false"
    >
      <ng-template
        let-rowIndex="rowIndex"
        let-row="row"
        let-value="value"
        ngx-datatable-cell-template
      >
      </ng-template>

      <ng-template
        let-value="value"
        let-column="column"
        let-rowIndex="rowIndex"
        ngx-datatable-header-template
      >
        <span
          ngbTooltip="{{ 'global.actions' | transloco: { value: value } }}"
          data-container="body"
          container="body"
          placement="bottom"
          tooltipClass="note-tooltip"
          >{{ column.name }}</span
        >
      </ng-template>
    </ngx-datatable-column>
  </ngx-datatable>
  <div class="custom-margin-notification"></div>

  <!-- ---form -->
  <form [formGroup]="emailNotificationForm">
    <div class="row form-group">
      <div *ngIf="showQueueDiv">
          <app-upload-duration
            [translationKey]="
              'modules.data-management.serien-daten.modal.rollback-queue-notification'
            "
          ></app-upload-duration>
      </div>
      <div
        class="row"
        *ngIf="steuerDatenQueue && steuerDatenQueue.length"
      >
        <div class="col-8">
          <div class="form-group">
            <label class="checkbox-container">
              <input type="checkbox" formControlName="selectedNotify" />
              <span class="checkmark"></span>
              <span class="lb-alignment">{{
                "modules.data-management.serien-daten.modal.rollback-mail-checkbox"
                  | transloco
              }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>

  <!-- end form -->
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-start">
          <button
            type="button"
            class="btn btn-secondary back"
            (click)="handleBack()"
          >
            {{ "global.back" | transloco }}
          </button>
        </div>

        <div class="button-area float-end">
          <div class="displayed">
            <button
              type="button"
              class="btn btn btn-secondary"
              (click)="handleClose()"
            >
              {{ "global.cancel" | transloco }}
            </button>

            <button
              [disabled]="
                emailNotificationForm.get('selectedNotify')?.value === false &&
                steuerDatenQueue?.length
              "
              type="button"
              class="btn btn btn-primary"
              (click)="handleNext()"
            >
              {{ "global.next" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
