import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ModalResult } from '@shared/models/ModalResult';

@Component({
  selector: 'app-fcsc-upload-vorab-delete-confirmation',
  templateUrl: './fcsc-upload-vorab-delete-confirmation.component.html',
  styleUrls: ['./fcsc-upload-vorab-delete-confirmation.component.scss'],
})
export class FcscUploadvorabDeleteConfirmationComponent implements OnInit {
  @Input() message!: string;
  title!: string;

  constructor(
    public activeModal: NgbActiveModal,
    private translocoService: TranslocoService
  ) {
    this.title = this.translocoService.translate(
      'modules.data-management.delete-data-packet-title'
    );
  }

  ngOnInit() {}

  deleteSDP(): void {
    const result = {
      ok: ModalResult.DELETE_OK,
    };
    this.activeModal.close(result);
  }

  cancelSDP(): void {
    const result = {
      cancel: ModalResult.DELETE_CANCEL,
    };
    this.activeModal.close(result);
  }
}
