import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { paths } from '@env/paths';
import { Observable } from 'rxjs';

import { SecurityService } from './core/services/security.service';

@Injectable()
export class AppAuthGuard  {
  path!: ActivatedRouteSnapshot[];
  route!: ActivatedRouteSnapshot;
  constructor(
    private router: Router,
    private securityService: SecurityService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.isAccessAllowed(route, state);
  }

  isAccessAllowed(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return new Promise(async (resolvePromise, reject) => {
      if (!!route.data.requiredEntitlements) {
        this.checkEntitlements(route.data.requiredEntitlements).then(
          (authorized: boolean) => {
            resolvePromise(authorized);
          }
        );
      } else {
        this.checkEntitlementAtLeastOne(route.data.oneOfThem).then(
          (authorized: boolean) => {
            resolvePromise(authorized);
          }
        );
      }
    });
  }

  /**
   * Check if at least one entitlement is matching
   *
   * @param entitlements which are getting checked
   */
  public checkEntitlementAtLeastOne(entitlements: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      entitlements?.map((entitlement) => {
        if (this.securityService.getEntitlements().indexOf(entitlement) > -1) {
          resolve(true);
        }
      });
      this.router.navigate([paths.common.forbidden]);
    });
  }

  /**
   *
   * @param requiredEntitlements which are getting checked if they match
   */
  public checkEntitlements(requiredEntitlements: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let foundEntitlements = 0;

      if (!requiredEntitlements || requiredEntitlements.length === 0) {
        resolve(true);
      } else {
        requiredEntitlements.map((requiredEntitlement) => {
          if (
            this.securityService
              .getEntitlements()
              .indexOf(requiredEntitlement) > -1
          ) {
            foundEntitlements++;
          }
        });
      }

      if (foundEntitlements === requiredEntitlements.length) {
        resolve(true);
      } else {
        this.router.navigate([paths.common.forbidden]);
      }
    });
  }
}
