import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '@core/services/admin.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { FileImportService } from '@core/services/files/import/file-import.service';
import { entitlements } from '@env/entitlements';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import {
  AdminSeriesConfig,
  AdminSeriesRequest,
} from '@shared/models/filetype/AdminSeriesConfig';
import { ModalResult } from '@shared/models/ModalResult';

import { FcscSeriesOperationComponent } from './components/fcsc-series-operation/fcsc-series-operation.component';
import { FcscSeriesConfigDeleteConfirmationComponent } from './components/fcsc-series-config-delete-confirmation/fcsc-series-config-delete-confirmation.component';

@Component({
  selector: 'app-fcsc-series-configuration',
  templateUrl: './fcsc-series-configuration.component.html',
  styleUrls: ['./fcsc-series-configuration.component.scss'],
})
export class FcscSeriesConfigurationComponent implements OnInit {
  sortConfig = [{ prop: 'createdAt', dir: 'desc' }];
  public tableListEntitlement = [
    entitlements.VUS.FCSC_FLASHWARE_CALCULATION_CONFIGURATION,
  ];
  adminSeriesConfig: AdminSeriesConfig[] = [];
  adminSerieData!: AdminSeriesConfig;
  emptyMessage!: string;
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;

  constructor(
    public adminService: AdminService,
    public notificationService: ErrorHandlerService,
    public translocoService: TranslocoService,
    private modalService: NgbModal,
    private fileImportService: FileImportService
  ) {}

  ngOnInit(): void {
    this.loadingTitle = 'modules.admin-section.config-modal.spinner-title';
    this.loadingSubtitle =
      'modules.admin-section.config-modal.spinner-subtitle';

    this.getAdminSeriesConfig();
  }

  public addNewSerieConfig(): void {
    const modalRef = this.modalService.open(FcscSeriesOperationComponent, {
      size: 'lg',
      windowClass: 'uploader-modal',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.dataList = this.adminSeriesConfig;
    modalRef.componentInstance.data = this.adminSerieData;
    modalRef.componentInstance.isEditOperation = false;

    modalRef.result.then(async (result) => {
      if (result && result.ok === ModalResult.IMPORT_SUCCESSFUL) {
        this.isLoading = true;
        const form = result.form.controls;
        const fileDataText =
          result.filesData &&
          (await this.fileImportService.getFileDataAsText(result.filesData));
        const payload: AdminSeriesRequest = {
          comment: form.comment.value,
          smoketestFinLog: fileDataText,
          brName: form.selectedSeries.value,
          skipable: form.skipable.value,
          hasSmoketestFinLog:
            result.filesData && result.filesData.name ? true : false,
        };
        this.createNewSerie(payload);
      }
    });
  }

  public editSerieConfig(data: AdminSeriesConfig, index?: number): void {
    const modalRef = this.modalService.open(FcscSeriesOperationComponent, {
      size: 'lg',
      windowClass: 'uploader-modal',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.data = data;
    modalRef.componentInstance.isEditOperation = true;
    modalRef.result.then(async (result) => {
      if (result && result.ok === ModalResult.IMPORT_SUCCESSFUL) {
        this.isLoading = true;
        const form = result.form.controls;
        const fileDataText =
          result.filesData &&
          (await this.fileImportService.getFileDataAsText(result.filesData));
        const payload: AdminSeriesRequest = {
          comment: form.comment.value,
          smoketestFinLog: fileDataText,
          brName: form.selectedSeries.value,
          skipable: form.skipable.value,
          hasSmoketestFinLog:
            result.filesData && result.filesData.name ? true : false,
        };
        this.updateSerie(data?.id, payload);
      }
    });
  }

  public truncateNote(value: string): string {
    const isExpanded = value.length > 250;
    return value.substring(0, 250) + (isExpanded ? '...' : '');
  }

  private getAdminSeriesConfig(): void {
    this.adminService.getSeriesConfig().subscribe(
      (adminSeriesConfig: AdminSeriesConfig[]) => {
        this.adminSeriesConfig = adminSeriesConfig;
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  createNewSerie(payload: AdminSeriesRequest) {
    this.adminService.createSerie(payload).subscribe(
      () => {
        this.isLoading = false;
        this.notificationService.showSuccess(
          '',
          this.translocoService.translate(
            'modules.data-management.upload-success'
          )
        );
        this.getAdminSeriesConfig();
      },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  updateSerie(id: number, payload: AdminSeriesRequest) {
    this.adminService.updateSerie(id, payload).subscribe(
      () => {
        this.isLoading = false;
        this.getAdminSeriesConfig();
        this.notificationService.showSuccess(
          '',
          this.translocoService.translate(
            'modules.data-management.upload-success'
          )
        );
      },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  createSeriesMap(list) {
    return list.map(item => item.value).join(', ');
  }
  
  public deleteData(row: AdminSeriesConfig): void {
    const modalRef = this.modalService.open(
      FcscSeriesConfigDeleteConfirmationComponent,
      {
        size: 'lg',
        windowClass: 'confirmation-modal',
        backdrop: 'static',
        keyboard: false,
      }
    );
    // const isSelected = this.form.get('selectedOriginalSdps')?.value;
    // const filter = this.selectedFilter || {};
    // const filterCheckBox = this.selectedFilterCheckBox || {};
    // const selectedFilter = isSelected ? filterCheckBox : filter;

    modalRef.componentInstance.message = this.translocoService.translate(
      'modules.admin-section.config-modal.delete-serie-warning',
      { name: row.brName }
    );
    modalRef.result.then((result) => {
      if (result && result.ok === ModalResult.DELETE_OK) {
        this.adminService.deleteSerie(row.id).subscribe(
          () => {
            this.notificationService.showSuccess(
              '',
              this.translocoService.translate(
                'modules.admin-section.config-modal.delete-success',
                { name: row.brName }
              )
            );
            this.getAdminSeriesConfig();
            this.isLoading = false;
          },
          (error: HttpErrorResponse) => {
            this.isLoading = false;
            this.notificationService.handleServerErrorNotification(error, '');
          }
        );
      }
    });
  }
}
