import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fcsc-admin-section',
  templateUrl: './fcsc-admin-section.component.html',
  styleUrls: ['./fcsc-admin-section.component.scss'],
})
export class FcscAdminSectionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
