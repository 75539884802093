import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  NgSelectOption,
} from '@angular/forms';
import { Status } from '@core/enums/status-color.enum';
import { AppService } from '@core/services/app.service';
import { I18n } from '@core/services/custom-datepicker-i18n.service';
import { SecurityService } from '@core/services/security.service';
import { SharedSeriesService } from '@core/services/shared-series.service';
import { entitlements } from '@env/entitlements';
import { NgOption } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { SteuerDatenMap } from '@shared/models/filetype/SteuerDaten';
import { combineLatest } from 'rxjs';
@Component({
  selector: 'app-fcsc-filter-bdp-inserie-select',
  templateUrl: './fcsc-filter-bdp-inserie-select.component.html',
  styleUrls: ['./fcsc-filter-bdp-inserie-select.component.scss'],
})
export class FcscFilterBdpInserieSelectComponent implements OnInit {
  form!: UntypedFormGroup;
  statusList!: NgOption[];
  seriesList!: NgOption[];
  formLoaded!: boolean;
  translatedAll!: any;
  @Output() refreshVisibleTable = new EventEmitter<any>();
  @Input() data!: SteuerDatenMap[];
  @Input() selectFilter!: boolean;
  @ViewChild('search') search!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private appService: AppService,
    private translocoService: TranslocoService,
    private _i18n: I18n,
    public sharedSeriesService: SharedSeriesService,
    public securityService: SecurityService
  ) {}

  ngOnInit(): void {
    this.initialize();
    this.handleTranslationKeys();
  }

  private initialize() {
    setTimeout(() => {
      this.appService.languageSubject.next(localStorage.getItem('language'));
    }, 50);

    if (!this.seriesList?.length) {
      const seriesEntitlements = entitlements.VUS.FCSC_VORAB_DATA;
      if (
        this.securityService
          .getEntitlements()
          .some(() =>
            this.securityService.getEntitlements().includes(seriesEntitlements)
          )
      ) {
        this.sharedSeriesService.getAdminSeriesList();
      }
    }
  }

  private handleTranslationKeys() {
    this.appService.languageSubject.subscribe((lang: any) => {
      this._i18n.language = lang;
      this.appService.translateForAll('modules.data-management.select-option');
    });

    this.appService.seriesSubject.subscribe((series: any) => {
      if (this.translatedAll) {
        this.setList(this.appService.addAll(series, this.translatedAll));
      } else {
        this.seriesList = series;
      }
    });

    this.appService
      .translateKey('modules.data-management.select-option')
      .subscribe((translation?) => {
        if (this.seriesList?.length) {
          this.setList(this.appService.addAll(this.seriesList, translation));
        }
      });

    this.appService.translationSubject.subscribe((translation) => {
      this.translatedAll = translation;
      this.createFilterForm(translation);
      const statusObs = Object.keys(Status).map((key: string) => {
        return this.translocoService.selectTranslate(
          Status[key as keyof typeof Status]
        );
      });
      combineLatest(statusObs).subscribe((resp) => {
        this.statusList = Object.keys(Status).map((key: string, index) => {
          return { value: key, name: resp[index] };
        });
      });
    });
  }

  private setList(list) {
    this.seriesList = list;
  }

  private createFilterForm(translation?) {
    this.form = this.formBuilder.group({
      baureihe: new UntypedFormControl(translation),
      sdp: new UntypedFormControl(''),
      uploaded_at: new UntypedFormControl(''),
      username: new UntypedFormControl(''),
    });
    this.formLoaded = true;

    this.form.get('baureihe')?.valueChanges.subscribe((resp) => {
      if (this.selectFilter) {
        this.filterRows();
      }
    });
  }

  public filterRows() {
    const form = this.form;
    const params = {};
    for (const key of Object.keys(form.controls)) {
      if (
        form.get(key) &&
        form.get(key)?.value &&
        form.get(key)?.value !== '' &&
        !Array.isArray(form.get(key)?.value)
      ) {
        let val = form.get(key)?.value;
        if (
          key === 'status' &&
          val === this.appService.getValueConstant(Status, Status.ALL)
        ) {
          val = '';
        }
        params[key] =
          val &&
          typeof val !== 'object' &&
          val?.toLowerCase() === this.translatedAll.toLowerCase()
            ? ''
            : val;
        if (key === 'uploaded_at' && params[key]) {
          params[key] =
            params[key].year.toString() +
            params[key].month.toString().padStart(2, '0') +
            params[key].day.toString().padStart(2, '0');
        }
      }
    }

    this.refreshVisibleTable.emit(params);
  }

  public resetFilteredRows() {
    this.initialize();
    this.refreshVisibleTable.emit();
  }

  public removeFocus() {
    this.search.nativeElement.blur();
  }
}
