import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Application } from 'src/app/shared/models/Application';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent {
  @Input() application!: Application;

  constructor(private router: Router) {}

  public navigateToLink(path: string, pathActive: string) {
    if (path) {
      this.router.navigate([path]);
    }
  }
}
