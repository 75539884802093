<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<div class="outer-container first">
  <div>
    <span class="title">{{
      "modules.data-management.serien-daten.bdp.series-bdp-productive-title"
        | transloco
    }}</span>
  </div>
  <p></p>

  <div>
    <div class="row row-cols-auto">
      <div class="col">
        <div class="form-group">
          <label>{{
            "modules.data-management.serien-daten.modal.name-lb" | transloco
          }}</label
          ><br />
          <label *ngIf="seriesBdpData && seriesBdpData.bdpName">
            {{ seriesBdpData.bdpName }}</label
          >
        </div>
      </div>

      <div class="col">
        <div class="form-group spacer">
          <label>{{
            "modules.data-management.serien-daten.modal.inSeriesSince-lb"
              | transloco
          }}</label
          ><br />
          <label *ngIf="seriesBdpData && seriesBdpData.setInSerieAt">
            {{
              seriesBdpData.setInSerieAt | date : "dd.MM.yy, HH:mm:ss":'UTC'
            }}</label
          >
        </div>
      </div>

      <div class="col">
        <div class="form-group spacer">
          <label>{{
            "modules.data-management.serien-daten.modal.changeReason-lb"
              | transloco
          }}</label
          ><br />
          <label *ngIf="seriesBdpData && seriesBdpData.reason">
            {{ getReasonTranslatedDefaultValue() }}</label
          >
        </div>
      </div>

      <div class="col">
        <div class="form-group spacer">
          <label>{{
            "modules.data-management.serien-daten.modal.series-count-lb"
              | transloco
          }}</label
          ><br />
          <label *ngIf="seriesBdpData && seriesBdpData.bdpContentCount">
            {{ seriesBdpData.bdpContentCount }}
            {{
              "modules.data-management.serien-daten.bdp.series-bdp-count"
                | transloco
            }}</label
          >
        </div>
      </div>

      <div class="col">
        <div class="form-group spacer">
          <label>{{
            "modules.data-management.serien-daten.modal.series-vp-versions-lb"
              | transloco
          }}</label
          ><br />
          <label *ngIf="seriesBdpData && seriesBdpData.vpVersion">
            {{ seriesBdpData.vpVersion }}</label
          >
        </div>
      </div>
    </div>
    <p></p>
    <div class="row row-cols-auto">
      <div class="col-9">
        <div class="form-group">
          <label>{{
            "modules.data-management.serien-daten.modal.series-bdp-comments-lb"
              | transloco
          }}</label
          ><br />
          <div class="display-box">
            <label *ngIf="seriesBdpData && seriesBdpData.comment">
              {{ seriesBdpData.comment }}</label
            >
          </div>
        </div>
      </div>

      <div class="col">
        <button
          type="button"
          class="btn btn-secondary back justify"
          (click)="
            bdpHisoryDetails(
              seriesBdpData,
              seriesBdpData && seriesBdpData.bdpName
            )
          "
        >
          {{
            "modules.data-management.serien-daten.modal.show-inserie-bdp-productive-btn"
              | transloco
          }}
        </button>
      </div>
    </div>
  </div>
</div>

<p></p>

<div class="outer-container">
  <div>
    <span class="title">{{
      "modules.data-management.serien-daten.bdp.series-bdp-history-title"
        | transloco
    }}</span>
  </div>
  <p></p>

  <app-fcsc-filter-serien-bdp
    (refreshVisibleTable)="setFilter($event)"
    [data]="seriesBdpHistoryData"
  >
  </app-fcsc-filter-serien-bdp>

  <div class="minimum-height"></div>

  <div class="row">
    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="seriesBdpHistoryData"
          [footerHeight]="56"
          (page)="setPage($event)"
          (sort)="sortCallback($event)"
          [sorts]="sortConfig"
          [messages]="{ emptyMessage: emptyMessage | transloco }"
        >
          <ngx-datatable-column
            name="{{ 'components.navbar.series-bdp' | transloco }}"
            prop="bdpName"
            [flexGrow]="8"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              let-rowIndex="rowIndex"
              ngx-datatable-cell-template
            >
              <span
                class="sdpaket"
                *ngIf="value"
                (click)="bdpHisoryDetails(row, value)"
                placement="bottom"
                ngbTooltip="{{
                  'modules.data-management.tooltip.series-bdp'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                tooltipClass="note-tooltip"
                >{{ value }}</span
              >
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'components.navbar.series-bdp' | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="User"
            prop="username"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.system'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.comment' | transloco }}"
            prop="comment"
            [flexGrow]="8"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span
                *ngIf="value"
                class="text-pre-wrap"
                placement="bottom"
                [ngbTooltip]="value"
                data-container="body"
                container="body"
                tooltipClass="note-tooltip"
                >{{ truncateNote(value) }}
              </span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.comment'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.productive-from' | transloco }}"
            prop="setInSerieAt"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{
                value | date : "dd.MM.yy, HH:mm:ss":'UTC'
              }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'global.productive-from' | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.productive-until' | transloco }}"
            prop="inSerieUntil"
            [flexGrow]="5"
            [resizeable]="false"
            [sortable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{
                value | date : "dd.MM.yy, HH:mm:ss":'UTC'
              }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'global.productive-until' | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            *appRequiredEntitlements="tableListEntitlement"
            name="{{ 'global.actions' | transloco }}"
            [flexGrow]="4"
            [sortable]="false"
            [resizeable]="false"
          >
            <ng-template
              let-rowIndex="rowIndex"
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span 
                title="{{
                  'modules.data-management.tooltip.series-bdp-download' | transloco
                }}"
                (click)="exportBdpFiles(row)"
              >
                <i class="bi bi-download"></i>
              </span>
              <span 
                *appRequiredEntitlements="imageRollbackEntitlement"
                title="{{
                  'modules.data-management.tooltip.rollback-row' | transloco
                }}"
                (click)="rollbackData(row)"
              >
                <i class="bi bi-arrow-up-circle-fill"></i>
              </span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              ngx-datatable-header-template
            >
              <span
                ngbTooltip="{{
                  'global.actions' | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer *ngIf="true">
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-offset="offset"
              let-curPage="curPage"
            >
              <div class="col-8">
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [count]="response?.totalElements || 0"
                  [page]="page.pageNumber"
                  [size]="page.size"
                  (change)="setPage($event)"
                >
                </datatable-pager>
              </div>

              <div class="col-4 page-result-section server-side">
                <span class="float-end">
                  <span class="result-text">{{
                    "components.footer.result-per-page" | transloco
                  }}</span>
                  <span
                    class="result-value"
                    [ngClass]="{ active: page.size === 10 }"
                    (click)="setPageSize(10)"
                    >10</span
                  >|
                  <span
                    class="result-value margin-override"
                    [ngClass]="{ active: page.size === 30 }"
                    (click)="setPageSize(30)"
                    >30</span
                  >|
                  <span
                    class="result-value"
                    [ngClass]="{ active: page.size === 100 }"
                    (click)="setPageSize(100)"
                    >100</span
                  >
                  <span>
                    {{ "components.footer.files" | transloco }}:
                    {{
                      response && (response?.totalElements || 0) > 0
                        ? (response?.number || 0) * page.size + 1
                        : 0
                    }}
                    -
                    {{
                      isNaN(
                        (response?.number || 0) * page.size +
                          (response?.numberOfElements || 0)
                      )
                        ? 0
                        : (response?.number || 0) * page.size +
                          (response?.numberOfElements || 0)
                    }}
                  </span>
                  <span>
                    {{ "components.footer.of" | transloco }}
                    {{
                      response?.totalElements ? response?.totalElements : 0
                    }}</span
                  >
                </span>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>

<app-fcsc-bdp-series-queue
  *ngIf="isQueueExisting()"
  (refreshTable)="refreshTable($event)"
  [steuerDatenQueue]="steuerDatenQueue"
  [rollbackData]="rollbackDataList"
></app-fcsc-bdp-series-queue>
