<div class="modal-body">
  <div class="row">
    <div class="col-5">
      <div class="custom-margin-b">
        <span class="sub-title">{{
          "modules.data-management.serien-daten.modal.rollback-actual-status-title"
            | transloco
        }}</span>
      </div>

      <form [formGroup]="currentStatusForm">
        <div class="row form-group">
          <div class="col-8">
            <div class="form-group">
              <label for="bdpName">{{
                "modules.data-management.serien-daten.modal.series-bdp-name"
                  | transloco
              }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="bdpName"
                formControlName="bdpName"
                readonly
              />
            </div>
          </div>
          <div class="w-100"></div>

          <div class="col-6">
            <div class="form-group mt-3">
              <label for="vpVersion">{{
                "modules.data-management.serien-daten.modal.series-vp-versions"
                  | transloco
              }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="vpVersion"
                formControlName="vpVersion"
                readonly
              />
            </div>
          </div>
          <div class="w-100"></div>

          <div class="col-4">
            <div class="form-group mt-3">
              <label for="seriesNumber">{{
                "modules.data-management.serien-daten.modal.series-number"
                  | transloco
              }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="seriesNumber"
                formControlName="seriesNumber"
                readonly
              />
            </div>
          </div>
          <div class="w-100"></div>

          <div class="col-4">
            <div class="form-group mt-3">
              <label for="username">User</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="username"
                formControlName="username"
                readonly
              />
            </div>
          </div>
          <div class="w-100"></div>
        </div>
      </form>
    </div>
    <div class="col-2">
      <div class="app-divider"></div>
    </div>

    <div class="col-5">
      <div class="custom-margin-b">
        <span class="sub-title">{{
          "modules.data-management.serien-daten.modal.rollback-after-status-title"
            | transloco
        }}</span>
      </div>

      <form [formGroup]="replacedBdpStatusForm">
        <div class="row form-group">
          <div class="col-8">
            <div class="form-group">
              <label for="bdpName">{{
                "modules.data-management.serien-daten.modal.series-bdp-name"
                  | transloco
              }}</label>
              <div class="input-group">
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control small-size"
                  id="bdpName"
                  formControlName="bdpName"
                  readonly
                />
                <span class="input-group-addon">
                  <span
                    *ngIf="
                      replacedBdpStatusForm.get('bdpName')?.value !==
                      currentStatusForm.get('bdpName')?.value
                    "
                    title="{{
                      'modules.data-management.serien-daten.modal.info-icon-tooltip'
                      | transloco
                    }}"
                  >
                    <i class="bi bi-info-circle"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="w-100"></div>

          <div class="col-6">
            <div class="form-group mt-3">
              <label for="vpVersion">{{
                "modules.data-management.serien-daten.modal.series-vp-versions"
                  | transloco
              }}</label>
              <div class="input-group">
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control small-size"
                  id="vpVersion"
                  formControlName="vpVersion"
                  readonly
                />
                <span class="input-group-addon">
                  <span
                    *ngIf="
                      replacedBdpStatusForm.get('vpVersion')?.value !==
                      currentStatusForm.get('vpVersion')?.value
                    "
                    title="{{
                      'modules.data-management.serien-daten.modal.info-icon-tooltip'
                        | transloco
                    }}"
                  >
                    <i class="bi bi-info-circle"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="w-100"></div>

          <div class="col-4">
            <div class="form-group mt-3">
              <label for="seriesNumber">{{
                "modules.data-management.serien-daten.modal.series-number"
                  | transloco
              }}</label>

              <div class="input-group">
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control small-size"
                  id="seriesNumber"
                  formControlName="seriesNumber"
                  readonly
                />
                <span class="input-group-addon">                  
                  <span
                    *ngIf="
                      replacedBdpStatusForm.get('seriesNumber')?.value !==
                      currentStatusForm.get('seriesNumber')?.value
                    "
                    title="{{
                      'modules.data-management.serien-daten.modal.info-icon-tooltip'
                        | transloco
                    }}"
                  >
                    <i class="bi bi-info-circle"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="w-100"></div>

          <div class="col-4">
            <div class="form-group mt-3">
              <label for="username">User</label>
              <div class="input-group">
                <input
                  type="text"
                  autocomplete="off"
                  class="form-control small-size"
                  id="username"
                  formControlName="username"
                  readonly
                />
                <span class="input-group-addon">
                  <span
                    *ngIf="
                      replacedBdpStatusForm.get('username')?.value !==
                      currentStatusForm.get('username')?.value
                    "
                    title="{{
                      'modules.data-management.serien-daten.modal.info-icon-tooltip'
                        | transloco
                    }}"
                  >
                    <i class="bi bi-info-circle"></i>
                  </span>
                </span>
              </div>
            </div>
          </div>
          <div class="w-100"></div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-end">
          <div class="displayed">
            <button
              type="button"
              class="btn btn btn-secondary"
              (click)="handleClose()"
            >
              {{ "global.cancel" | transloco }}
            </button>

            <button
              type="button"
              class="btn btn btn-primary"
              (click)="handleNext()"
            >
              {{ "global.next" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
