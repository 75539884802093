import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@env/paths';
import {
  FilteredSerialUploadList,
  SerialUploadListPayload,
  SerialUploadListResponse,
} from '@shared/models/serialdata/SerialUploadList';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class SerialUploadListService {
  private baseUrl = '';
  private steuerungBaseUrl = '';

  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.baseUrl = `${envService.apiUrl}${apiPaths.fcscBerechnung}/api`;
    this.steuerungBaseUrl = `${envService.apiUrl}${apiPaths.fcscSteuerung}/api`;
  }

  getSerialUploadList(
    parameters?: FilteredSerialUploadList
  ): Observable<HttpResponse<SerialUploadListResponse>> {
    const flag = false;
    const url = `${this.steuerungBaseUrl}/upload-info`;

    let paramsVar = new HttpParams();

    if (parameters) {
      const paramsObj = {};
      Object.keys(parameters).map((key) => {
        paramsObj[key] = parameters[key];
      });
      paramsVar = new HttpParams({ fromObject: paramsObj });
    }

    return this.httpClient.get<SerialUploadListResponse>(url, {
      responseType: 'json',
      observe: 'response',
      params: paramsVar,
    });
  }

  putSerialUploadList(
    payload: SerialUploadListPayload
  ): Observable<HttpResponse<SerialUploadListPayload>> {
    const url = `${this.steuerungBaseUrl}/upload-info`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    return this.httpClient.put<SerialUploadListPayload>(url, payload, {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
    });
  }
}
