import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { paths } from '@env/paths';
import { TranslocoService } from '@ngneat/transloco';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
})
export class NotFoundPageComponent implements OnInit {
  public previousUrl!: string;

  constructor(
    public router: Router,
    private translocoService: TranslocoService
  ) {
    router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
  }

  ngOnInit() {
    const lang = localStorage.getItem('language') || 'en-US';
    this.translocoService.setActiveLang(lang);
  }

  public redirectToHome(): void {
    this.router.navigate([
      `${paths.fcsc.experttool.root}/${paths.fcsc.experttool.flashware}`,
    ]);
  }
}
