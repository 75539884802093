import { Component, Input, OnInit } from '@angular/core';
import { StatusSdpQueue } from '@core/enums/status-sdp-queue.enum';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { SeriesSteuerDatenQueue } from '@shared/models/filetype/SeriesSteuerDaten';
import { ModalResult } from '@shared/models/ModalResult';

@Component({
  selector: 'app-fcsc-bds-series-queue-modal',
  templateUrl: './fcsc-bds-series-queue-modal.component.html',
  styleUrls: ['./fcsc-bds-series-queue-modal.component.scss'],
})
export class FcscBdsSeriesQueueModalComponent implements OnInit {
  @Input() steuerDatenQueue!: SeriesSteuerDatenQueue[];
  @Input() currentSdp?: SeriesSteuerDatenQueue = undefined;
  @Input() refreshTable;
  sortConfig = [{ prop: 'status', dir: 'asc' }];

  constructor(
    public activeModal: NgbActiveModal,
    private translocoService: TranslocoService,
    private steuerdatenService: SteuerdatenService,
    private notificationService: ErrorHandlerService
  ) {}

  ngOnInit() {}

  removeFromQueue(steuerdatenpaket: SeriesSteuerDatenQueue) {
    this.steuerdatenService
      .deleteSdpFromReleaseQueue(steuerdatenpaket.id)
      .subscribe(
        () => {
          this.notificationService.showSuccess(
            '',
            this.translocoService.translate(
              'modules.data-management.delete-sdp-from-queue',
              { name: steuerdatenpaket.sdpNames }
            )
          );
          this.refreshTable.emit(true);
          const queues = this.steuerDatenQueue;
          const index = queues.findIndex(
            (queue) => queue.id === steuerdatenpaket.id
          );
          queues.splice(index, 1);
          this.steuerDatenQueue = [...queues];
        },
        (error) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.data-management.delete-sdp-from-queue-error'
          );
        }
      );
  }

  public truncateValue(value: string[]): string {
    const isExpanded = value.length > 1;
    return isExpanded
      ? value.join().substring(0, value[0].length) + '...'
      : value.join();
  }

  public getStatusText(value?: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(StatusSdpQueue[value as keyof typeof StatusSdpQueue])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  public getBarStatusText() {
    if (this.currentSdp && this.currentSdp.sdpNames.length > 1) {
      return this.translocoService.translate(
        this.currentSdp.isRollback === true
          ? 'modules.data-management.bdp-rollback-active'
          : 'modules.data-management.sdp-multi-switch-active'
      );
    } else {
      return this.translocoService.translate(
        this.currentSdp && this.currentSdp.isRollback === true
          ? 'modules.data-management.bdp-rollback-active'
          : 'modules.data-management.sdp-switch-active',
        { name: this.currentSdp?.sdpNames }
      );
    }
  }

  public isQueueExisting() {
    return this.steuerDatenQueue && this.steuerDatenQueue.length > 0;
  }

  public close(): void {
    this.activeModal.close(ModalResult.ACTIVATION_CANCEL);
  }
}
