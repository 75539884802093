import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ReasonInserieActivaton } from '@core/enums/reason-inserie-activaton.enum';
import { AppService } from '@core/services/app.service';
import { CalculationLogFilesService } from '@core/services/calculation-log-file.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { StorageService } from '@core/services/storage.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { entitlements } from '@env/entitlements';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import {
  FilteredSeriesBdp,
  SeriesBdp,
  SeriesBdpResponse,
} from '@shared/models/filetype/SeriesBdp';
import { SeriesSteuerDatenQueue } from '@shared/models/filetype/SeriesSteuerDaten';
import { ModalResult } from '@shared/models/ModalResult';
import { Page } from '@shared/models/Page';
import { GeneralPageInfo, SortInfo } from '@shared/models/SortInfo';
import { Spinner } from '@shared/models/spinner';
import { UserSetting } from '@shared/models/UserSetting';
import * as moment from 'moment';
import { ngxCsv } from 'ngx-csv';
import { Subscription, interval } from 'rxjs';

import { FcscSerienBdpHistoryDetailsModalComponent } from './components/fcsc-serien-bdp-history-details-modal/fcsc-serien-bdp-history-details-modal.component';
import { FcscSerienBdpRollbackModalComponent } from './components/fcsc-serien-bdp-rollback-modal/fcsc-serien-bdp-rollback-modal.component';

@Component({
  selector: 'app-fcsc-serien-bdp',
  templateUrl: './fcsc-serien-bdp.component.html',
  styleUrls: ['./fcsc-serien-bdp.component.scss'],
})
export class FcscSerienBdpComponent implements OnInit {
  seriesBdpData!: SeriesBdp;
  sortConfig!: Array<{}>;
  paginationDataSetting!: any;
  params: GeneralPageInfo | undefined;
  seriesBdpHistoryData: SeriesBdp[] = [];
  response!: SeriesBdpResponse | null;
  emptyMessage!: string;
  page = new Page();
  selectedFilter!: FilteredSeriesBdp;
  isNaN = Number.isNaN;

  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;
  steuerDatenQueue: SeriesSteuerDatenQueue[] = [];
  private queueRefreshSubscription!: Subscription;
  rollbackDataList;

  public imageRollbackEntitlement = [
    entitlements.VUS.FCSC_SERIEN_IMAGE_ROLLBACK,
  ];
  public tableListEntitlement = [entitlements.VUS.FCSC_SERIEN_DATA];

  constructor(
    private steuerdatenService: SteuerdatenService,
    private translocoService: TranslocoService,
    private modalService: NgbModal,
    private notificationService: ErrorHandlerService,
    private appService: AppService,
    private calculationLogFilesService: CalculationLogFilesService,
    private downloadService: DownloadService,
    private userSettingService: UserSettingService
  ) {}

  ngOnInit(): void {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };

    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
    this.getSeriesProductiveBdp();
    this.refreshDataTable();
    this.setTranslation();

    this.getSeriesSteuerDatenQueue();

    // refresh queue in 10s interval
    this.queueRefreshSubscription = interval(10000).subscribe(() => {
      this.getSeriesSteuerDatenQueue();
    });
  }

  formatName(name: string) {
    const arr = name.split('_');
    return arr.slice(1, arr.length).join('_');
  }

  getReasonTranslatedDefaultValue() {
    return this.translocoService.translate(
      ReasonInserieActivaton[
        this.seriesBdpData &&
          (this.seriesBdpData.reason as keyof typeof ReasonInserieActivaton)
      ]
    );
  }

  private getSeriesProductiveBdp(): void {
    this.steuerdatenService.getSeriesProductiveBdp().subscribe(
      (seriesBdpData: SeriesBdp[]) => {
        const data: any = seriesBdpData[0];
        if (data !== undefined) {
          data.bdpName = this.formatName(data?.bdpName);
        }
        this.seriesBdpData = data;
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  bdpHisoryDetails(row: SeriesBdp, value: string): void {
    const modalRef = this.modalService.open(
      FcscSerienBdpHistoryDetailsModalComponent,
      {
        size: 'lg',
        windowClass: 'bdp-edit-modal',
        backdrop: 'static',
        keyboard: false,
      }
    );
    modalRef.componentInstance.bdpName = value;
  }
  public rollbackData(row: SeriesBdp): void {
    const modalRef = this.modalService.open(
      FcscSerienBdpRollbackModalComponent,
      {
        size: 'lg',
        windowClass: 'bdp-edit-modal',
        backdrop: 'static',
        keyboard: false,
      }
    );

    const selectedRow: SeriesBdp = row;

    modalRef.componentInstance.title = this.translocoService.translate(
      'modules.data-management.serien-daten.modal.rollback-title'
    );

    modalRef.componentInstance.subTitle = this.translocoService.translate(
      'modules.data-management.serien-daten.modal.bdp-serie-bdp-rollback-subtitle',
      { selected: selectedRow.bdpName }
    );

    modalRef.componentInstance.notificationMessage =
      this.translocoService.translate(
        'modules.data-management.serien-daten.modal.bdp-serie-bdp-rollback-notification-title',
        { selected: selectedRow.bdpName }
      );

    modalRef.componentInstance.bdpName = selectedRow.bdpName;
    modalRef.componentInstance.seriesBdpData = this.seriesBdpData;

    modalRef.result.then((result) => {
      if (result && result.ok === ModalResult.ACTIVATION_SUCCESSFUL) {
        this.postRollback(result, selectedRow.bdpName);
      }
    });
  }

  postRollback(result, name): void {
    const data = {
      name: name,
      note: result.form.controls.note.value,
      reason: result.form.controls.reason.value,
    };
    this.steuerdatenService
      .postSeriesBdpRollback('SERIEN_' + data.name, data.note, data.reason)
      .subscribe(
        (resp) => {
          this.rollbackDataList = data;
        },
        (error: HttpErrorResponse) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.data-management.serien-daten.modal.bdp-activation-error-message'
          );
        }
      );
  }

  public isQueueExisting() {
    return this.steuerDatenQueue && this.steuerDatenQueue.length > 0;
  }
  refreshTable($event) {}

  setTranslation(): void {
    this.translocoService
      .selectTranslate(
        'modules.data-management.serien-daten.data-table.empty-table-series'
      )
      .subscribe((resp) => {
        this.emptyMessage = resp;
      });
  }

  public truncateNote(value: string): string {
    const isExpanded = value.length > 250;
    return value.substring(0, 250) + (isExpanded ? '...' : '');
  }

  loadContent() {
    this.seriesBdpHistoryData = (this.response && this.response?.content) || [];
    this.seriesBdpHistoryData.forEach((data) => {
      data.bdpName = this.formatName(data.bdpName);
    });
  }

  async getSeriesBdpHistory(params?: FilteredSeriesBdp) {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.steuerdatenService.getSeriesBdpHistory(params).subscribe(
      (data: HttpResponse<SeriesBdpResponse>) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.response = data.body;
        this.loadContent();
      },
      (error: HttpErrorResponse) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'global.bdp-status.service-down'
        );
      }
    );
  }

  public exportBdpFiles(file: SeriesBdp) {
    const selectedRowName = 'SERIEN_'+file.bdpName;
    this.steuerdatenService
      .getPresignedUrlForBdpZipFile(file.bdpName)
      .subscribe(
        (response: any) => {
          const url = response?.presignedUrl.toString();
          this.calculationLogFilesService
            .getExportFileFromPresignedUrl(url)
            .subscribe(
              (res: Blob) => {
                this.downloadService.createAndDownloadFile(
                  res,
                  `${selectedRowName}`,
                  '.zip'
                );
              },
              (error) => {
                this.notificationService.handleServerErrorNotification(
                  error,
                  'modules.admin-section.config-modal.insert-edit-api-error-message'
                );
              }
            );
        },
        (error) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.admin-section.config-modal.insert-edit-api-error-message'
          );
        }
      );
  }

  private getSeriesSteuerDatenQueue(): void {
    this.steuerdatenService.getBdpReleaseQueue().subscribe(
      (queue: SeriesSteuerDatenQueue[]) => {
        if (queue.length !== this.steuerDatenQueue.length) {
          this.refreshTable(true);
        }
        this.steuerDatenQueue = queue;
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  /*  pagination functionality */
  private async refreshDataTable(filter?) {
    await this.configPageInfoAfterInit();
    await this.getSeriesBdpHistory(filter ? filter : this.params);
  }

  private async retrieveDataOnPageChange(page) {
    const filter = this.selectedFilter || {};
    const sortConf = [this.page.orderBy, this.page.orderDir].toString();

    if (page) {
      filter.page = (+this.page.pageNumber - 1).toString();
      filter.size = this.page.size.toString();
      filter.sort = sortConf.toString();
      this.selectedFilter = filter;
    } else {
      filter.page = '0';
      filter.size = this.page.size.toString();
      filter.sort = sortConf.toString();
      this.selectedFilter = filter;
    }

    this.appService.resetDatatableScroll();
    await this.getSeriesBdpHistory(filter);

    const value = {
      size: filter.size,
      sort: filter.sort,
    };
    this.saveUserSetting(value);
  }

  public async setFilter(event: FilteredSeriesBdp) {
    if (!event) {
      this.seriesBdpHistoryData = [];
    }
    const filter = this.selectedFilter || {};
    await this.configPageFilterInfoAfterInit(filter);
    filter.bdpName = event && event.bdpName ? event.bdpName : '';
    filter.comment = event && event.comment ? event.comment : '';
    filter.username = event && event.username ? event.username : '';
    filter.setInSerieAt = event && event.setInSerieAt ? event.setInSerieAt : '';
    filter.inSerieUntil = event && event.inSerieUntil ? event.inSerieUntil : '';
    this.selectedFilter = { ...this.selectedFilter, ...filter };
    if (
      filter.setInSerieAt &&
      filter.inSerieUntil &&
      (moment(filter.setInSerieAt).isAfter(filter.inSerieUntil) ||
        moment(filter.inSerieUntil).isBefore(filter.setInSerieAt))
    ) {
      this.notificationService.handleServerErrorNotification(
        null,
        'modules.expert-tool.log-history.filter.from-to-date-validation'
      );
      return true;
    } else {
      this.selectedFilter = { ...this.selectedFilter, ...filter };
      await this.getSeriesBdpHistory(filter);

      const value = {
        size: filter.size,
        sort: filter.sort,
      };
      this.saveUserSetting(value);
      return;
    }
  }

  public setPageSize(size) {
    this.page.pageNumber = 1;
    this.page.size = size;
    this.retrieveDataOnPageChange(null);
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.page;
    this.retrieveDataOnPageChange(this.page);
  }

  public sortCallback(sortInfo: SortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.retrieveDataOnPageChange(this.page);
  }

  // Saves user data on user interaction on sort, filter and page change.
  private saveUserSetting(data) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.serien.serienBdpTable,
      data
    );
  }

  // Gets the user data or loads default ones.
  private async configPageInfoAfterInit() {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.paginationDataSetting =
      (await this.userSettingService.fetchUserSetting(
        userSettingKeys.serien.serienBdpTable
      )) || {};

    const { sort, size } = this.paginationDataSetting;
    // Sort
    const sortData = sort || 'setInSerieAt,desc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    this.sortConfig = [{ prop: orderBy, dir: orderDir }];

    // Order
    this.page.orderDir = orderDir;
    this.page.orderBy = orderBy;

    // Page
    this.page.pageNumber = 1;

    // Size
    this.page.size = this.page.size = size || 30;

    const sortConfArr = [this.page.orderBy, this.page.orderDir].toString();

    this.params = {
      page: '0',
      size: size || '30',
      sort: sortConfArr,
    };
  }

  private async configPageFilterInfoAfterInit(filter: any) {
    await this.configPageInfoAfterInit();
    const { sort, size } = this.paginationDataSetting;
    const sortData = sort || 'setInSerieAt,desc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    const sortConfigFilter = [orderBy, orderDir];
    filter.page = '0';
    filter.size = size || '30';
    filter.sort = sortConfigFilter.toString();
  }
}
