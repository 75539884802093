import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalResult } from '@shared/models/ModalResult';

@Component({
  selector: 'app-fcsc-result-dependencies',
  templateUrl: './fcsc-result-dependencies.component.html',
  styleUrls: ['./fcsc-result-dependencies.component.scss'],
})
export class FcscResultDependenciesComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  ignoreDependencies(): void {
    this.activeModal.close(ModalResult.DEPENDENCIES_IGNORE);
  }

  editDependencies(): void {
    this.activeModal.close(ModalResult.DEPENDENCIES_EDIT);
  }

  loadDataAndIdentifyFlashware() {
    this.activeModal.close(ModalResult.DEPENDENCIES_LOAD);
  }
}
