import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AdminService } from '@core/services/admin.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { FileImportService } from '@core/services/files/import/file-import.service';
import { TranslocoService } from '@ngneat/transloco';
import { AdminVehicleCodeRequest } from '@shared/models/filetype/AdminSeriesConfig';
import { FileData } from '@shared/models/ImportFile';
import { NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-fcsc-admin-code-list',
  templateUrl: './fcsc-admin-code-list.component.html',
  styleUrls: ['./fcsc-admin-code-list.component.scss'],
})
export class FcscAdminCodeListComponent implements OnInit {
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  public files: NgxFileDropEntry[] = [];
  public filesData: FileData[] = [];
  formLoaded!: boolean;
  form!: UntypedFormGroup;
  showDiv = {
    next: false,
    current: false,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private notificationService: ErrorHandlerService,
    private translocoService: TranslocoService,
    public adminService: AdminService,
    public downloadService: DownloadService,
    private fileImportService: FileImportService
  ) {}

  ngOnInit() {
    this.loadingTitle = 'modules.admin-section.codes.spinner-title';
    this.loadingSubtitle = 'modules.admin-section.codes.spinner-subtitle';

    this.showDiv.current = true;
    this.showDiv.next = false;
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      adminFileCode: new UntypedFormControl('DialogCodeinfo.csv'),
    });
    this.formLoaded = true;
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.showDiv.current = false;
    this.showDiv.next = true;
    this.files = files;
    let noFilesIncluded = true;
    if (files.length > 1) {
      this.showError('modules.admin-section.config-modal.validate-one-file');
      this.back();
    } else {
      for (const droppedFile of files) {
        const isFileAllowed = this.isFileAllowed(droppedFile.fileEntry.name);
        if (droppedFile.fileEntry.isFile && isFileAllowed) {
          const fileEntry = droppedFile.fileEntry as any;
          fileEntry.file((file: File) => {
            const modifiedDate = file.lastModified.toString();

            this.filesData.push({
              name: file.name,
              valid: true,
              modified: modifiedDate,
              file: file,
              relativePath: droppedFile.relativePath,
            });
            this.changeDetectorRef.markForCheck();
            this.changeDetectorRef.detectChanges();
            noFilesIncluded = false;
          });
        }
      }
    }
  }

  isFileAllowed(fileName: string): boolean {
    let isFileAllowed = true;
    const allowedFiles = [];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  private showError(error: string): void {
    this.notificationService.showError(
      '',
      this.translocoService.translate(error)
    );
  }

  private resetFilesData(): void {
    this.files = [];
    this.filesData = [];
  }

  public exportFile() {
    const filename = this.form.get('adminFileCode')?.value;
    this.adminService.getVehicleCodesFile().subscribe(
      (response: any) => {
        this.downloadService.downloadFile(response && response.body, filename);
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  private uploadNewFileCode(payload: AdminVehicleCodeRequest) {
    this.adminService.putVehicleCodes(payload).subscribe(
      () => {
        this.isLoading = false;
        this.back();
        this.notificationService.showSuccess(
          '',
          this.translocoService.translate(
            'modules.admin-section.codes.upload-success'
          )
        );
      },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.back();
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  //  buttons
  public async saveChanges(): Promise<void> {
    const result = {
      form: this.form,
      filesData: this.filesData[0],
    };
    const fileDataText =
      result.filesData &&
      (await this.fileImportService.getFileDataAsText(result.filesData));

    this.isLoading = true;
    const payload: AdminVehicleCodeRequest = {
      vehicleCodeContent: encodeURIComponent(fileDataText),
    };

    this.uploadNewFileCode(payload);
  }

  public back(): void {
    this.showDiv.current = true;
    this.showDiv.next = false;
    this.resetFilesData();
  }
}
