import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {StatusColor} from "@core/enums/status-color.enum";
import {TestautomationTestDetail} from "@shared/models/testautomation/TestautomationTestDetail";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TestautomationTestSuccess} from "@shared/models/testautomation/TestautomationTest";
import {DatatableComponent} from "@swimlane/ngx-datatable";
import {Page} from "@shared/models/Page";

@Component({
  selector: 'app-test-detail',
  templateUrl: './test-detail.component.html',
  styleUrls: ['./test-detail.component.scss']
})
export class TestDetailComponent implements OnInit {
  @ViewChild('dataTable', {static: true}) table: DatatableComponent;
  page = new Page();

  showOnlyFailed: boolean = false;
  protected readonly StatusColor = StatusColor;
  @Input() test: TestautomationTestSuccess;
  @Input() details: TestautomationTestDetail[];

  filteredDetails: TestautomationTestDetail[];
  dataTableLimit: number = 10;

  constructor(
    public activeModal: NgbActiveModal
  ) {
    this.updateFilterDetails();
  }

  ngOnInit(): void {
    if (this.test == null) {
      let date: Date = new Date(2024, 0, 1, 0, 0, 0, 0);
      this.test = new TestautomationTestSuccess(date.toString(), 0, 0, 0, 'UNKNOWN');
    }
    this.filteredDetails = this.details;
    this.showOnlyFailed = false;
  }

  updateFilterDetails(): void {
    this.filteredDetails = this.showOnlyFailed
      ? this.details.filter(detail => detail.status == 'FAILED')
      : this.details;
  }

  toggleFilter(): void {
    this.showOnlyFailed = !this.showOnlyFailed;
    this.updateFilterDetails();
  }

  public setPageSize(size) {
    this.dataTableLimit = size;
  }

  getColor(value) {
    const returnValue = value === 'PASSED' ? StatusColor.GREEN: StatusColor.RED;
    return returnValue;
  }

  //TODO: finish later
  // firstElement(): number {
  //   return (this.page.pageNumber - 1) * this.dataTableLimit + 1;
  // }
  // lastElement(): number {
  //   return Math.min(this.firstElement + this.dataTableLimit - 1, this.details.length);
  // }
}
