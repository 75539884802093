import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileName } from '@core/enums/file-name.enum';
import { System } from '@core/enums/system.enum';
import { AppService } from '@core/services/app.service';
import { I18n } from '@core/services/custom-datepicker-i18n.service';
import { SecurityService } from '@core/services/security.service';
import { SharedSeriesService } from '@core/services/shared-series.service';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgOption } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { SerialUploadList } from '@shared/models/serialdata/SerialUploadList';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-fcsc-filter-upload-list',
  templateUrl: './fcsc-filter-upload-list.component.html',
  styleUrls: ['./fcsc-filter-upload-list.component.scss'],
})
export class FcscFilterUploadListComponent implements OnInit {
  form!: UntypedFormGroup;
  seriesList!: NgOption[];
  formLoaded!: boolean;
  @ViewChild('searchMinDateStatus') searchMinDateStatus!: ElementRef;
  @ViewChild('searchUploadDate') searchUploadDate!: ElementRef;
  @ViewChild('searchInSeriesSinceSDP') searchInSeriesSinceSDP!: ElementRef;
  @ViewChild('searchHistDate') searchHistDate!: ElementRef;
  translatedAll!: any;
  @Output() refreshVisibleTable = new EventEmitter<any>();
  @Input() data!: SerialUploadList[];
  @ViewChild('search') search!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private appService: AppService,
    private translocoService: TranslocoService,
    private _i18n: I18n,
    public config: NgbDatepickerConfig,
    public sharedSeriesService: SharedSeriesService,
    public securityService: SecurityService
  ) {}

  ngOnInit(): void {
    this.initialize();
    this.handleTranslationKeys();
  }

  filterRows() {
    const form = this.form;
    const params = {};
    for (const key of Object.keys(form.controls)) {
      if (
        form.get(key) &&
        form.get(key)?.value &&
        form.get(key)?.value !== '' &&
        !Array.isArray(form.get(key)?.value)
      ) {
        let val = form.get(key)?.value;

        params[key] =
          val &&
          typeof val !== 'object' &&
          val?.toLowerCase() === this.translatedAll.toLowerCase()
            ? ''
            : val;
        if (
          (key === 'histDate' && params[key]) ||
          (key === 'inSeriesSinceSDP' && params[key]) ||
          (key === 'uploadDate' && params[key])
        ) {
          params[key] =
            params[key].year.toString() +
            params[key].month.toString().padStart(2, '0') +
            params[key].day.toString().padStart(2, '0');
        }
      }
    }

    this.refreshVisibleTable.emit(params);
  }

  resetFilteredRows() {
    this.initialize();
    this.refreshVisibleTable.emit();
  }

  removeFocus() {
    this.search.nativeElement.blur();
  }

  removeHistDateFocus() {
    this.searchHistDate.nativeElement.blur();
  }

  removeMinDateStatusFocus() {
    this.searchMinDateStatus.nativeElement.blur();
  }

  removeUploadDateFocus() {
    this.searchUploadDate.nativeElement.blur();
  }

  removeInSeriesSinceSDPFocus() {
    this.searchInSeriesSinceSDP.nativeElement.blur();
  }

  private initialize() {
    setTimeout(() => {
      this.appService.languageSubject.next(localStorage.getItem('language'));
    }, 50);

    if (!this.seriesList?.length) {
      this.sharedSeriesService.getAdminSeriesList();
    }
  }

  private handleTranslationKeys() {
    this.appService.languageSubject.subscribe((lang: any) => {
      this._i18n.language = lang;
      this.appService.translateForAll(
        'modules.data-management.filter.br-placeholder'
      );
    });

    this.appService.seriesSubject.subscribe((series: any) => {
      if (this.translatedAll) {
        this.setSeriesList(this.appService.addAll(series, this.translatedAll));
      } else {
        this.seriesList = series;
      }
    });

    this.appService
      .translateKey('modules.data-management.filter.br-placeholder')
      .subscribe((translation?) => {
        if (this.seriesList?.length) {
          this.setSeriesList(
            this.appService.addAll(this.seriesList, translation)
          );
        }
      });

    this.appService.translationSubject.subscribe((translation) => {
      this.translatedAll = translation;
      this.createFilterForm(translation);
    });
  }

  private setSeriesList(list) {
    this.seriesList = list;
  }

  private createFilterForm(translation?) {
    this.form = this.formBuilder.group({
      baureihe: new UntypedFormControl(translation),
      name: new UntypedFormControl(''),
      histDate: new UntypedFormControl(''),
      uploadDate: new UntypedFormControl(''),
      inSeriesSinceSDP: new UntypedFormControl(''),
      username: new UntypedFormControl(''),
      requirement: new UntypedFormControl(''),
      comment: new UntypedFormControl(''),
    });
    this.formLoaded = true;
  }
}
