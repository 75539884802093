<div class="chip-input-container">
  <div class="chips-container" (click)="focusInput()">
    <div 
      *ngFor="let chip of chips; let i = index" 
      class="chip" 
      [ngClass]="{
        'valid': isValid(chip),
        'invalid': !isValid(chip)
      }">
      {{ chip }}
      <span class="edit-icon" *ngIf="!editingChipIndex || editingChipIndex !== i" (click)="startEditing(i, chip)">✎</span>
      <input 
        *ngIf="editingChipIndex === i" 
        [(ngModel)]="editedChipText"
        (blur)="finishEditing(i)"
        (keydown.enter)="finishEditing(i)"
        (keydown.escape)="cancelEditing()"
        class="chip-edit-input"
      />
      <span class="close-icon" (click)="removeChip(i)">×</span>
    </div>
    <input 
      #input
      *ngIf="!editingChipIndex" 
      class="chip-add-input" 
      [(ngModel)]="inputText"
      (keydown.enter)="addChip()"
      (keydown.space)="addChip()"
      (keydown.,)="addChip()"
      (keydown.backspace)="onBackspace($event)"
      (paste)="handlePaste($event)"
      placeholder="+ Type here..."
    />
  </div>
</div>
