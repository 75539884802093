
import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '@core/services/app.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { UserManualService } from '@core/services/user-manual.service';
import { entitlements } from '@env/entitlements';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Spinner } from '@shared/models/spinner';
import { Application } from 'src/app/shared/models/Application';

import { SecurityService } from '../services/security.service';
import { EntitlementsModalComponent } from './entitlements-modal/entitlements-modal.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit{
  @Input() application!: Application;
  public kibanaEntitlement = [entitlements.VUS.FCSC_OPEN_SEARCH_DASHBOARD];
  public username!: string;
  public entitlementList: string[] = [];
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;

  constructor(
    private securityService: SecurityService,
    private modalService: NgbModal,
    private userManualService: UserManualService,
    private downloadService: DownloadService,
    private notificationService: ErrorHandlerService,
    private appService: AppService
  ) {}

  ngOnInit() {
    this.spinnerObject = {
      loadingTitle: 'components.header.spinner.fetch-title',
      loadingSubtitle: 'components.header.spinner.fetch-subtitle',
      isLoading: false,
    };

    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });

    if (this.securityService.isLoggedIn()) {
      this.username = this.securityService.getCurrentUser().getUsername();
    }
  }

  public logout(): void {
    this.securityService.logout();
  }

  public getUserManual() {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.userManualService.getPresignedUrlForUserManualFiles().subscribe(
      (response: any) => {
        this.spinnerObject.isLoading = true;
        this.appService.spinnerSubject.next(this.spinnerObject);
        const url = response?.presignedUrl.toString();
        this.userManualService.getExportFileFromPresignedUrl(url).subscribe(
          (res: Blob) => {
            this.spinnerObject.isLoading = false;
            this.appService.spinnerSubject.next(this.spinnerObject);
            this.downloadService.downloadFile(res, 'user-manual.pdf');
          },
          (error) => {
            this.spinnerObject.isLoading = false;
            this.appService.spinnerSubject.next(this.spinnerObject);
            this.notificationService.handleServerErrorNotification(
              error,
              'components.header.usermanual-error-message'
            );
          }
        );
      },
      (error) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'components.header.usermanual-error-message'
        );
      }
    );
  }

  public viewEntitlements(): void {
    this.entitlementList = this.securityService
      .getEntitlements()
      .filter((item) => item !== entitlements.VUS.FCSC_PUBLIC) as string[];
    const modalRef = this.modalService.open(EntitlementsModalComponent, {
      size: 'md',
      windowClass: 'entitlement-modal',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.componentInstance.entitlements = this.entitlementList;
  }

  navigateToKibana() {
    window.open(
      'https://elk.vus.corpinter.net/_dashboards/goto/afa421687d0bfa0be6ceb28f2b5e4b63?security_tenant=global',
      '_blank'
    );
  }
}
