import { CalculateDatapool } from './CalculateDatapool';
import { CalculateFinlog } from './CalculateFinlog';
import { CalculateFlashData } from './CalculateFlashData';
import { CalculateVedocRequest } from './CalculateVedocRequest';
import { CalculateVehicleData } from './CalculateVehicleData';

export class CalculatePayload {
  vehicleData: CalculateVehicleData;
  vedocRequest: CalculateVedocRequest;
  flashData: CalculateFlashData;
  dataPool: CalculateDatapool;
  finLog: CalculateFinlog;

  constructor(
    vehicleData: CalculateVehicleData,
    vedocRequest: CalculateVedocRequest,
    dataPool: CalculateDatapool,
    finLog: CalculateFinlog
  ) {
    this.vehicleData = vehicleData;
    this.vedocRequest = vedocRequest;
    this.dataPool = dataPool;
    this.finLog = finLog;
  }
}
