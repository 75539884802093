<div *ngIf="isEdit" class="modal-header">
  <span class="modal-title">{{
    "modules.admin-section.config-modal.edit-serie-title" | transloco
  }}</span>
</div>

<div *ngIf="!isEdit" class="modal-header">
  <span class="modal-title">{{
    "modules.admin-section.config-modal.create-new-serie-title" | transloco
  }}</span>
</div>

<div *ngIf="isEdit" class="modal-sub-text">
  {{ "modules.admin-section.config-modal.edit-serie-subtitle" | transloco }}
</div>

<div *ngIf="!isEdit" class="modal-sub-text">
  {{
    "modules.admin-section.config-modal.create-new-serie-subtitle" | transloco
  }}
</div>

<div class="modal-body" *ngIf="formLoaded">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <div class="row">
            <div class="col-6">
              <label for="baureihe-input">{{
                "modules.data-management.title-sereis" | transloco
              }}</label>

              <input
                formControlName="selectedSeries"
                [class.is-invalid]="isInputInvalid('selectedSeries')"
                type="text"
                autocomplete="off"
                class="form-control"
                id="baureihe-input"
              />
            </div>
            <div class="col-6" *ngIf="showShadowModelSeries">
              <label for="shadow-model-series-input">{{
                "modules.data-management.table-header.shadow-model-series" | transloco
              }}</label>

              <input
                formControlName="selectedShadowModelSeries"
                type="text"
                autocomplete="off"
                class="form-control"
                id="shadow-model-series-input"
              />
            </div>
          </div>
          <div class="row">
            <div class="form-group">
              <label>
                <input
                  type="checkbox"
                  formControlName="skipable"
                />
                <span class="smokeTestCheckMark"></span>
                <span>{{
                  "modules.admin-section.config-modal.smoketest-note"
                      | transloco
                }}
                </span>
              </label>
            </div>
          </div>
        </div>

        <div [ngStyle]="{ visibility: showDiv.current ? 'visible' : 'hidden' }">
          <br />
          <span *ngIf="isEdit" class="modal-text-body space">
            {{ smokeTestFileName }}
            <span 
              [ngClass]="{'no-drop-cursor': !data.hasSmoketestFinLog}"
              title="{{
                'modules.expert-tool.log-history.tooltip.download-icon' | transloco
              }}"
            >
              <span 
                [ngClass]="{'disabled': !data.hasSmoketestFinLog}"
                (click)="exportFile(data)"
              >
                <i class="bi bi-download"></i>
              </span>
            </span>
          </span>
        </div>

        <div
          *appRequiredEntitlements="smoketestFileEntitlement"
          [ngStyle]="{ visibility: showDiv.current ? 'visible' : 'hidden' }"
        >
          <p *ngIf="isEdit" class="modal-text-body">
            {{
              "modules.admin-section.config-modal.edit-serie-smoketest"
                | transloco
            }}
          </p>
        </div>

        <div
          *appRequiredEntitlements="smoketestFileEntitlement"
          [ngStyle]="{ visibility: showDiv.current ? 'visible' : 'hidden' }"
        >
          <p *ngIf="!isEdit" class="modal-text-body">
            {{
              "modules.admin-section.config-modal.create-new-serie-smoketest"
                | transloco
            }}
          </p>
        </div>

        <div
          *appRequiredEntitlements="smoketestFileEntitlement"
          class="form-group center"
          [ngClass]="{'disabled': form.get('skipable')?.value === true}"
        >
          <div class="disable-box"></div>
          <div *ngIf="showDiv.current">
            <ngx-file-drop [directory]="false" (onFileDrop)="dropped($event)" >
              <ng-template
                ngx-file-drop-content-tmp
                let-openFileSelector="openFileSelector"
              >
                <div *ngIf="showDiv.current" (click)="openFileSelector()">
                  <ul class="list-group droparea">
                    <li class="list-group-item">
                      <span>
                        <i class="bi bi-arrows-angle-expand"></i>
                      </span>
                    </li>
                    <li class="list-group-item">Drag&Drop</li>
                    <li class="list-group-item item-text">
                      &nbsp;{{
                        "modules.data-management.label-or" | transloco
                      }}&nbsp;
                    </li>
                    <li class="list-group-item">
                      <span>
                        <i class="bi bi-search"></i>
                      </span>
                    </li>
                    <li class="list-group-item">
                      {{ "modules.data-management.label-browse" | transloco }}
                    </li>
                  </ul>
                </div>
              </ng-template>
            </ngx-file-drop>
          </div>

          <div class="scroll-container">
            <div class="upload-area" *ngIf="showDiv.next">
              <ul
                class="list-group list-group-horizontal col-12"
                *ngFor="let item of filesData; let i = index"
              >
                <li class="list-group-item col-6">{{ item.name }}</li>
                <li class="list-group-item col-4">
                  {{ item.modified | date : "dd.MM.yy HH:mm:ss":'UTC' }}
                </li>
                <li class="list-group-item col-2">
                  <span *ngIf="item.valid === true">
                    <i class="bi bi-check"></i>
                  </span>
                </li>
              </ul>
            </div>
            <div class="scroll-space"></div>
          </div>
        </div>

        <div class="col-12 form-group add-space">
          <label for="comment">{{ "global.comment" | transloco }}</label>
          <textarea
            class="form-control"
            id="comment"
            formControlName="comment"
            rows="3"
          ></textarea>
        </div>
      </div>
      <div *ngIf="form.invalid" class="custom-pos-relative">
        <app-upload-duration
          [isError]="true"
          [translationKey]="
            'modules.admin-section.config-modal.validate-serie-regex'
          "
        ></app-upload-duration>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-start">
          <div [ngStyle]="{ visibility: showDiv.next ? 'visible' : 'hidden' }">
            <button
              type="button"
              class="btn btn-secondary back"
              (click)="back()"
            >
              {{ "global.back" | transloco }}
            </button>
          </div>
        </div>

        <div class="button-area float-end">
          <div class="displayed">
            <button type="button" class="btn btn-secondary" (click)="cancel()">
              {{ "global.cancel" | transloco }}
            </button>
            <button
              *ngIf="!isEdit"
              [disabled]="
                form.invalid || form.get('selectedSeries')?.value.length === 0 
              "
              type="button"
              class="btn btn-primary"
              (click)="saveChanges()"
            >
              {{ "modules.admin-section.config-modal.btn-create" | transloco }}
            </button>

            <button
              *ngIf="isEdit"
              type="button"
              class="btn btn-primary"
              (click)="saveChanges()"
            >
              {{ "modules.admin-section.config-modal.btn-edit" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
