import { Component, Input, OnInit } from '@angular/core';
import { TestautomationTest, TestautomationTestSuccess } from "@shared/models/testautomation/TestautomationTest";
import { StatusColor } from "@core/enums/status-color.enum";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import {
  TestDetailComponent
} from "@fcsc/fcsc-admin-section/fcsc-admin-testautomation/components/test-detail/test-detail.component";
import { Observable, Subject } from "rxjs";
import { TestautomationTestDetail } from "@shared/models/testautomation/TestautomationTestDetail";
import { TestautomationService } from "@core/services/testautomation.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-test-history',
  templateUrl: './test-history.component.html',
  styleUrls: ['./test-history.component.scss']
})
export class TestHistoryComponent implements OnInit {

  @Input() testRuns: TestautomationTest[] | TestautomationTestSuccess[];
  protected readonly StatusColor = StatusColor;
  private notifier = new Subject();
  date: Date = new Date(2024, 0, 1, 0, 0, 0, 0);
  testautomationTest1 = new TestautomationTestSuccess(this.date.toString(), 0, 0, 0, 'UNKNOWN');
  sortConfig = [{ prop: 'timeStamp', dir: 'desc' }];

  constructor(
    private modalService: NgbModal,
    private testautomationService: TestautomationService,
  ) {
  }

  ngOnInit(): void {
    console.log(this.testRuns)
    let mocktestruns: TestautomationTestSuccess[] = [];
    mocktestruns.push(this.testautomationTest1)
    if (this.testRuns == null) {
      this.testRuns = mocktestruns;
    }
  }

  public formatTime(row, ms: number): string {
    if (row.status === "cancelled" || row.status === "failed") {
      return '-';
    }
    else if (row.status === "success") {
      const hours = Math.floor(ms / 3600000);
      const minutes = Math.floor((ms % 3600000) / 60000);
      const seconds = Math.floor((ms % 60000) / 1000);

      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    else {
      const now: any = new Date().getTime();
      const ms = now - new Date(row.timeStamp).getTime();;
      const hours = Math.floor(ms / 3600000);
      const minutes = Math.floor((ms % 3600000) / 60000);
      const seconds = Math.floor((ms % 60000) / 1000);

      const formattedHours = String(hours).padStart(2, '0');
      const formattedMinutes = String(minutes).padStart(2, '0');
      const formattedSeconds = String(seconds).padStart(2, '0');

      return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
  }


  detail(test: TestautomationTestSuccess): void {
    this.getDetailsByTestTimestamp(
      test
    ).subscribe((details) =>
      this.openDetailModal(test, details)
    );
  }

  private openDetailModal(test: TestautomationTestSuccess, testDetails: TestautomationTestDetail[]) {
    const modalRef = this.modalService.open(TestDetailComponent, {
      size: 'lg',
      windowClass: 'details-modal',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.test = test;
    modalRef.componentInstance.details = testDetails;
  }

  private getDetailsByTestTimestamp(test: TestautomationTestSuccess): Observable<TestautomationTestDetail[]> {
    return this.testautomationService.getTestDetails(test.username + '_' + this.reformatTimestamp(test.timeStamp)).pipe(takeUntil(this.notifier));
  }

  private reformatTimestamp(timeStamp: string): string {
    return timeStamp.replace(/:/g, ".");
  }

  extractFilename(contentDisposition: string): string | null {
    const filenameRegex = /filename="(.+)"/;
    const matches = filenameRegex.exec(contentDisposition);
    return matches ? matches[1] : null;
  }

  isStatusCompleted(row) {
    return row.status === "success" ? false : true;
  }

  download(test: TestautomationTestSuccess) {
    this.testautomationService.downloadTestResult(test.username + '_' + this.reformatTimestamp(test.timeStamp))
      .subscribe((response) => {
        const contentDisposition = response.headers.get('Content-Disposition') || '';
        const filename = this.extractFilename(contentDisposition);
        const blob = new Blob([response.body!], { type: response.body!.type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename ? `${filename}.csv` : `${test.username}_${test.timeStamp}.csv`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
  }
}
