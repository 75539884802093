import {Component, Input, OnInit} from '@angular/core';
import {ConfirmatonModalComponent} from "@shared/components/confirmaton-modal/confirmaton-modal.component";
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslocoService} from "@ngneat/transloco";
import {TestautomationService} from "@core/services/testautomation.service";
import {Observable, Observer} from "rxjs";

@Component({
  selector: 'app-test-cancel',
  templateUrl: './test-cancel.component.html',
  styleUrls: ['./test-cancel.component.scss']
})
export class TestCancelComponent implements OnInit {
  @Input() testIsRunning: boolean;
  @Input() activeTestId: number;

  loadingTitle :string;
  loadingSubtitle : string;
  isLoading: boolean;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private translocoService: TranslocoService,
    private testautomationService: TestautomationService
  ) {
  }

  ngOnInit(): void {
    this.loadingTitle = 'modules.admin-section.testautomation.test-cancel.spinner-title';
    this.loadingSubtitle = 'modules.admin-section.testautomation.test-cancel.spinner-subtitle';
  }

  openConfirmationModal() {
    const modalRef = this.modalService.open(ConfirmatonModalComponent, {
      size: 'lg',
      windowClass: 'confirmation-modal',
      backdrop: 'static',
      keyboard: false,
    });
    this.activeModal.close([]);
    const message = (modalRef.componentInstance.title =
      this.translocoService.translate(
        'modules.admin-section.testautomation.test-cancel.confirm-title'
      ));
    modalRef.componentInstance.subText = this.translocoService.translate(
      'modules.admin-section.testautomation.test-cancel.confirm-text'
    );
    modalRef.componentInstance.buttonText = this.translocoService.translate(
      'modules.admin-section.testautomation.test-cancel.confirm-confirm'
    );

    modalRef.componentInstance.buttonSecondaryText =
      this.translocoService.translate(
        'modules.admin-section.testautomation.test-cancel.confirm-cancel'
      );

    modalRef.result.then((result) => {
      if (result && result.ok) {
        this.isLoading = true;
        this.testautomationService.cancelActiveTest(this.activeTestId).subscribe(
          response =>{
            this.checkUntilTestStopped().subscribe(
              (response: number) => {
                console.log(this.translocoService.translate(
                  'modules.admin-section.testautomation.test-cancel.cancel-success'
                ));
                this.isLoading = false;
                window.location.reload();
              },
              (error: any) => {
                console.error(error);
              },
              () => {
                console.log('Observable abgeschlossen');
              }
            );
          },
          error => {
            console.error(this.translocoService.translate(
              'modules.admin-section.testautomation.test-cancel.cancel-fail', error
            ));
          }
        );
      }
    });
  }
  checkUntilTestStopped(): Observable<number> {
    return new Observable<number>((observer: Observer<number>) => {
      const makeBackendCall = () => {
        this.testautomationService.getActiveTestRunId().subscribe(
          (response: number) => {
            if (response !== null) {
              setTimeout(() => {
                makeBackendCall();
              }, 3000);
            } else {
              observer.next(response);
              observer.complete();
            }
          },
          (error: any) => {
            observer.error(error);
          }
        );
      };
      makeBackendCall();
    });
  }
}
