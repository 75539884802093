<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<div class="outer-container" *appRequiredEntitlements="tableListEntitlement">
  <div>
    <span class="title">{{
      "modules.admin-section.series-config-title" | transloco
    }}</span>
  </div>
  <p></p>
  <div class="row">
    <div class="col-12 round-button-container">
      <span 
        title="{{
          'modules.expert-tool.insert-new-line' | transloco
        }}"
        (click)="addNewSerieConfig()"
        class="main-icon"
      >
        <i class="bi bi-plus"></i>
      </span>
    </div>

    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="adminSeriesConfig"
          [sorts]="sortConfig"
          [footerHeight]="56"
          [messages]="{ emptyMessage: emptyMessage }"
        >
          <ngx-datatable-column
            name="{{ 'modules.admin-section.saved-series' | transloco }}"
            prop="brName"
            [flexGrow]="6"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              let-rowIndex="rowIndex"
              ngx-datatable-cell-template
            >
              <ng-template #list>
                <div class="d-flex flex-column">
                  <span>{{ 'global.shadow-model-series' | transloco }}</span>
                  <span>{{ createSeriesMap(row.shadowModelList) }}</span>
                </div>
              </ng-template>
              <span *ngIf="value">{{ value }}</span>
              <span *ngIf="row.shadowModelList.length" 
                class="shadowModelSeries"
                [ngbTooltip]="list"
              >
                SB
              </span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.admin-section.saved-series'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="User"
            prop="username"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.system'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'modules.admin-section.creation-date' | transloco }}"
            prop="createdAt"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{
                value | date: 'dd.MM.yy HH:mm:ss':'UTC'}}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.admin-section.creation-date'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.comment' | transloco }}"
            prop="comment"
            [flexGrow]="8"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span
                *ngIf="value"
                class="text-pre-wrap"
                placement="bottom"
                [ngbTooltip]="value"
                data-container="body"
                container="body"
                tooltipClass="note-tooltip"
                >{{ truncateNote(value) }}
              </span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.comment'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
             [cellClass]="'text-right'"
             name= "{{ 'modules.expert-tool.sums-relevance' | transloco }}"
             prop="sumsRelevanceStatus"
             [flexGrow]="5"
             [resizeable]="false"
           >
             <ng-template
               let-value="value"
               let-row="row"
               ngx-datatable-cell-template
             >
               <span *ngIf="value">{{ value }}</span>
             </ng-template>

             <ng-template
               let-value="value"
               let-column="column"
               let-rowIndex="rowIndex"
               let-sort="sortFn"
               ngx-datatable-header-template
             >
               <span
                 class="header-sort"
                 ngbTooltip="{{
                   'modules.expert-tool.sums-relevance'
                     | transloco: { value: value }
                 }}"
                 data-container="body"
                 container="body"
                 placement="bottom"
                 tooltipClass="note-tooltip"
                 (click)="sort()"
                 >{{ column.name }}</span
               >
             </ng-template>
           </ngx-datatable-column>

           <ngx-datatable-column
              [cellClass]="'text-center'"
              name= "{{ 'modules.expert-tool.smoketest-status' | transloco }}"
              prop="skipable"
              [flexGrow]="4"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
              <span
                *ngIf="value"
              >
                <i class="bi bi-check"></i>
              </span>
              </ng-template>
            </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.actions' | transloco }}"
            [flexGrow]="4"
            [sortable]="false"
            [resizeable]="false"
          >
            <ng-template
              let-rowIndex="rowIndex"
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span 
                title="{{
                  'modules.admin-section.tooltip.edit-series' | transloco
                }}"
                (click)="editSerieConfig(row, rowIndex)"
              >
                <i class="bi bi-pencil-fill"></i>
              </span>

              
              <span 
                title="{{'modules.admin-section.tooltip.delete-series'| transloco}}"
                (click)="deleteData(row)"
              >
                <i class="bi bi-trash"></i>
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer *ngIf="true">
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-offset="offset"
              let-curPage="curPage"
            >
              <div class="col-12 page-result-section server-side">
                <span class="float-end">
                  <span>
                    {{ 'modules.admin-section.config-modal.footer-title' | transloco }} :
                  </span>
                  <span>
                    {{
                      adminSeriesConfig?.length ? adminSeriesConfig?.length : 0
                    }}</span>
                </span>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
