import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReasonBdpRollback } from '@core/enums/reason-bdp-rollback.enum';
import { ReasonInserieActivaton } from '@core/enums/reason-inserie-activaton.enum';
import { AppService } from '@core/services/app.service';

@Component({
  selector: 'app-fcsc-rollback-part4',
  templateUrl: './fcsc-rollback-part4.component.html',
  styleUrls: ['./fcsc-rollback-part4.component.scss'],
})
export class FcscRollbackPart4Component implements OnInit {
  serieBdpRollbackForm!: UntypedFormGroup;

  wrongBdpReason!: string;
  otherBdpReason!: string;

  @Input() queueTitle!: string;
  @Input() showQueueDiv!: boolean;
  @Input() notificationMessage!: string;

  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private appService: AppService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.wrongBdpReason = ReasonBdpRollback.FAULTY_BDP;
    this.otherBdpReason = ReasonBdpRollback.OTHER;
    this.createRollbackInSerieBdpForm();
  }

  private createRollbackInSerieBdpForm() {
    this.serieBdpRollbackForm = this.formBuilder.group({
      note: new UntypedFormControl(''),
      reason: new UntypedFormControl(
        this.appService.getValueConstant(
          ReasonInserieActivaton,
          ReasonInserieActivaton.FAULTY_BDP
        )
      ),
    });
  }

  handleBack() {
    this.back.emit();
  }

  handleSubmit() {
    this.submit.emit(this.serieBdpRollbackForm);
  }

  handleClose() {
    this.close.emit();
  }
}
