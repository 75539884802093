import { Component, Input, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent implements OnInit, OnDestroy {
  @Input() text!: string;
  @Input() loadingTitle!: string;
  @Input() loadingSubtitle!: string;
  @Input() overlay!: boolean;

  constructor() {}

  ngOnInit() {
    document.body.style.overflow = this.overlay ? 'hidden' : 'auto';
  }
  ngOnDestroy() {
    document.body.style.overflow = 'auto';
  }

  getBackgroundLayerClass() {
    return this.overlay ? 'background-layer' : 'nested';
  }
}
