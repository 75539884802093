<div class="modal-header">
  <span class="modal-title">{{ title }}</span>
</div>

<div class="modal-sub-text">{{ subText }}</div>

<div class="modal-body">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-12 form-group">
        <label for="field">{{fieldText}}</label>
        <textarea
          class="form-control"
          id="field"
          formControlName="field"
          rows="12"
        ></textarea>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-end">
          <div class="displayed">
            <button type="button" class="btn btn-secondary" (click)="cancel()">
              {{ 'global.cancel' | transloco }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="save()"
            >
            {{ 'modules.expert-tool.btn-apply-changes' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
