import { Component, OnInit } from '@angular/core';
import { AppService } from '@core/services/app.service';
import { TranslocoService } from '@ngneat/transloco';
import { Language } from '@shared/models/language';

@Component({
  selector: 'app-language-switch',
  templateUrl: './language-switch.component.html',
  styleUrls: ['./language-switch.component.scss'],
})
export class LanguageSwitchComponent implements OnInit {
  public languages!: Language[];
  public selectedLanguage!: Language;
  public locale!: string;

  constructor(
    private translocoService: TranslocoService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.setBrowserDefaultLang();
  }

  // change locale/language at runtime
  updateLocale(locale: string) {
    if (this.languages.some((l) => l.code === locale)) {
      this.locale = locale;
    }
    const lang = locale.substring(0, 2);
    this.appService.languageSubject.next(lang);
    localStorage.setItem('language', lang);
    this.translocoService.setActiveLang(lang);
  }

  getUsersLocale(defaultValue: string): string {
    if (
      typeof window === 'undefined' ||
      typeof window.navigator === 'undefined'
    ) {
      return defaultValue;
    }
    const wn = window.navigator as any;
    let lang = wn.languages ? wn.languages[0] : defaultValue;
    lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
    return lang;
  }

  setBrowserDefaultLang(): void {
    this.languages = [
      {
        name: 'English',
        code: 'en',
        image: './assets/icons/languages/en.png',
      },
      {
        name: 'Deutsch',
        code: 'de',
        image: './assets/icons/languages/de.png',
      },
    ];
    const defaultLanguage: string =
      localStorage.getItem('language') || this.getUsersLocale('en-US');
    const defaultLanguageInitial = defaultLanguage.split('-')[0];
    const selectedLanguage = this.languages.find(
      (language) => language.code === defaultLanguageInitial
    );
    this.selectedLanguage = selectedLanguage
      ? selectedLanguage
      : this.languages[0];

    // generate a regex from the locales we support
    const supportedRegex = new RegExp(
      '^' + this.languages.map((l) => l.code.substring(0, 2)).join('|^')
    );
    // check if the user's preferred language is supported and if so, use it.
    if (defaultLanguage.match(supportedRegex)) {
      this.updateLocale(defaultLanguage);
    }

    if (
      defaultLanguage.split('-')[0] !== 'en' &&
      defaultLanguage.split('-')[0] !== 'de'
    ) {
      this.updateLocale('en-US');
    }
  }
}
