import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fcsc-upload-vorab-alert',
  templateUrl: './fcsc-upload-vorab-alert.component.html',
  styleUrls: ['./fcsc-upload-vorab-alert.component.scss'],
})
export class FcscUploadVorabAlertComponent implements OnInit {
  @Input() message!: string;
  title = 'Alert';

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
