<div class="filter" *ngIf="formLoaded">
  <form [formGroup]="form" (keyup.enter)="filterRows()">
    <div>
      <div class="row row-cols-auto">
        <div class="col">
          <div class="form-group">
            <label for="bdpName">{{ 'components.navbar.series-bdp' | transloco }}</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="bdpName"
              formControlName="bdpName"
              placeholder="{{
                'modules.data-management.filter.input-placeholder'
                  | transloco
              }}"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="username">User</label>
            <input
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="username"
              formControlName="username"
              placeholder="{{
                'modules.data-management.filter.input-placeholder'
                  | transloco
              }}"
              (keyup.enter)="filterRows()"
            />
          </div>
        </div>

        <div class="col">
            <div class="form-group">
              <label for="comment">{{ 'global.comment' | transloco }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="comment"
                formControlName="comment"
                placeholder="{{
                  'modules.data-management.filter.input-placeholder'
                    | transloco
                }}"
                (keyup.enter)="filterRows()"
              />
            </div>
          </div>

        <div class="col">
          <div class="form-group">
            <label for="setInSerieAt">{{ 'global.productive-from' | transloco }}</label>

            <div class="input-group">
              <input
                #searchProductiveFrom
                class="form-control dp"
                placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                id="setInSerieAt"
                name="dp"
                formControlName="setInSerieAt"
                ngbDatepicker
                #d="ngbDatepicker"
                readonly
                (click)="removeProductiveFromFocus()"
              />
              <div class="input-group-append">
                <i class="dp" (click)="d.toggle()" class="bi bi-calendar3"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="inSerieUntil">{{ 'global.productive-until' | transloco }}</label>

            <div class="input-group">
              <input
                #searchProductiveUntil
                class="form-control dp"
                placeholder="{{ 'global.datepicker-placeholder' | transloco }}"
                id="inSerieUntil"
                name="dp"
                formControlName="inSerieUntil"
                ngbDatepicker
                #dto="ngbDatepicker"
                readonly
                (click)="removeProductiveUntilFocus()"
              />
              <div class="input-group-append">
                <i class="dp" (click)="dto.toggle()" class="bi bi-calendar3"></i>
              </div>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="button-area">
            <button
              type="button"
              class="btn btn-primary"
              (click)="filterRows()"
            >
              {{ "modules.data-management.filter.btn-search" | transloco }}
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              (click)="resetFilteredRows()"
            >
              {{ "modules.data-management.filter.btn-reset" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
