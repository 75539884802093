import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AdminService } from '@core/services/admin.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import {
  ValidateSerieInput,
  ValidationService,
} from '@core/services/validation.service';
import { entitlements } from '@env/entitlements';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import {
  AdminSeriesConfig,
  AdminSeriesSmoktestFinlog,
} from '@shared/models/filetype/AdminSeriesConfig';
import { FileData } from '@shared/models/ImportFile';
import { ModalResult } from '@shared/models/ModalResult';
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop';

@Component({
  selector: 'app-fcsc-series-operation',
  templateUrl: './fcsc-series-operation.component.html',
  styleUrls: ['./fcsc-series-operation.component.scss'],
})
export class FcscSeriesOperationComponent implements OnInit {
  @Input() message!: string;
  @Output() valueChange = new EventEmitter<AdminSeriesConfig>();
  @Input() data!: AdminSeriesConfig;
  @Input() isEditOperation: boolean;
  showShadowModelSeries: boolean;
  @Input() dataList: AdminSeriesConfig[] = [];
  public files: NgxFileDropEntry[] = [];
  public filesData: FileData[] = [];
  smokeTestFileName!: string;
  adminSerieFinlog!: AdminSeriesSmoktestFinlog;
  showDiv = {
    next: false,
    current: false,
  };
  isEdit!: boolean;
  formLoaded!: boolean;
  form!: UntypedFormGroup;
  public smoketestFileEntitlement = [
    entitlements.VUS.FCSC_VORAB_IMAGE_SMOKETEST_CONFIGURATION,
    entitlements.VUS.FCSC_FLASHWARE_CALCULATION_CONFIGURATION,
  ];

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private notificationService: ErrorHandlerService,
    private translocoService: TranslocoService,
    public adminService: AdminService,
    public downloadService: DownloadService,
    public validationService: ValidationService
  ) {}

  ngOnInit() {
    this.isEdit = this.data !== undefined;
    this.showDiv.current = true;
    this.showDiv.next = false;
    this.createForm();
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.showDiv.current = false;
    this.showDiv.next = true;
    this.files = files;
    let noFilesIncluded = true;
    if (files.length > 1) {
      this.showError('modules.admin-section.config-modal.validate-one-file');
      this.back();
    } else {
      for (const droppedFile of files) {
        const isFileAllowed = this.isFileAllowed(droppedFile.fileEntry.name);
        if (droppedFile.fileEntry.isFile && isFileAllowed) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            const modifiedDate = file.lastModified.toString();

            this.filesData.push({
              name: file.name,
              valid: true,
              modified: modifiedDate,
              file: file,
              relativePath: droppedFile.relativePath,
            });
            this.changeDetectorRef.markForCheck();
            this.changeDetectorRef.detectChanges();
            noFilesIncluded = false;
          });
        }
      }
    }
  }

  isFileAllowed(fileName: string): boolean {
    let isFileAllowed = true;
    const allowedFiles = [];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
      // if (isFileAllowed === false) {
      //   this.showError('modules.admin-section.config-modal.validate-only-xml');
      //   this.back();
      // }
    }
    return isFileAllowed;
  }

  isInputInvalid(controlName: string): boolean | undefined {
    return this.validationService.checkInputValidity(this.form, controlName);
  }

  public exportFile(data?: AdminSeriesConfig) {
    data = this.data;
    this.adminService.getSerieSmoketestFinLog(data).subscribe(
      (response: any) => {
        // this.smokeTestFileName = `${this.data.brName}_smoketest.xml`;
        const filename = response.headers
          ? response.headers &&
            response.headers
              .get('content-disposition')
              .split(';')[1]
              .split('=')[1]
              .replace(/\"/g, '')
          : '';
        this.downloadService.downloadFile(response && response.body, filename);
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  // modal buttons
  saveChanges(): void {
    if (this.validateFile() && this.isSerieExists() && this.checkfinLog()) {
      const result = {
        ok: ModalResult.IMPORT_SUCCESSFUL,
        form: this.form,
        filesData: this.filesData[0],
      };
      this.activeModal.close(result);
    } else {
      return;
    }
  }

  cancel(): void {
    this.activeModal.close(ModalResult.IMPORT_CANCEL);
  }

  back(): void {
    this.showDiv.current = true;
    this.showDiv.next = false;
    this.resetFilesData();
  }

  private getFileName(): any {
    this.adminService.getSerieSmoketestFinLog(this.data).subscribe(
      (response: any) => {
        this.smokeTestFileName =
          response.headers
            .get('content-disposition')
            .split(';')[1]
            .split('=')[1]
            .replace(/\"/g, '') || '';
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  createSeriesMap(list) {
    return list.map(item => item.value).join(', ');
  }

  private createForm() {
    this.showShadowModelSeries = this.isEditOperation;
    this.form = this.formBuilder.group({
      comment: new UntypedFormControl(this.isEdit ? this.data.comment : ''),

      selectedSeries: new UntypedFormControl(
        this.isEdit
          ? { value: this.data.brName, disabled: true }
          : { value: '', disabled: false },
        [ValidateSerieInput]
      ),

      selectedShadowModelSeries: new UntypedFormControl(
        this.isEdit
          ? { value: this.createSeriesMap(this.data.shadowModelList), disabled: true }
          : { value: '', disabled: true },
      ),

      skipable: new UntypedFormControl(
        this.isEdit
          ? this.data.skipable
          : false
      ),
    });
    if (this.isEdit && this.data.hasSmoketestFinLog === true) {
      // this.smokeTestFileName = `${this.data.brName}_smoketest`;
      this.smokeTestFileName = this.getFileName();
    } else {
      this.smokeTestFileName = this.translocoService.translate(
        'modules.admin-section.config-modal.no-finlog-file'
      );
    }
    this.formLoaded = true;
  }

  private isSerieExists(): boolean {
    let flag = true;
    const serie = this.form.get('selectedSeries')?.value;
    if (serie) {
      const foundSerie = this.dataList.find(
        (dt) => dt?.brName?.toLowerCase() === serie.toLowerCase()
      );
      if (foundSerie && !this.isEdit) {
        this.showError(
          'modules.admin-section.config-modal.validate-serie-exists'
        );
        flag = false;
      }
    }
    return flag;
  }

  private checkFileName(filename: string): boolean {
    let flag = true;
    const fileArray = filename.split('_');
    const serie = this.form.get('selectedSeries')?.value;
    if (!serie) {
      this.showError(
        'modules.admin-section.config-modal.validate-serie-mandatory'
      );
      flag = false;
    } else {
      if (fileArray.length) {
        // if (fileArray[0] !== serie) {
        //   this.showError(
        //     'modules.admin-section.config-modal.validate-file-regex'
        //   );
        //   this.back();
        //   flag = false;
        // }
        // if (fileArray[1] !== 'smoketest.xml') {
        //   this.showError(
        //     'modules.admin-section.config-modal.validate-file-regex'
        //   );
        //   this.back();
        //   flag = false;
        // }
      }
    }
    return flag;
  }

  private checkfinLog(): boolean {
    let skipableCheck = this.form.get('skipable')?.value === true;
    let isFilesEmpty = this.filesData.length === 0;
    // console.log(this.form.get('skipable')?.value)
    // console.log(isFilesEmpty)

    if ((skipableCheck && isFilesEmpty) || (!skipableCheck && !isFilesEmpty)) 
      return true; 
    else{
      this.showError('global.smoke-test-finlog-not-found'); // Error message for invalid condition
      return false; 
    }
  }

  private validateFile(): boolean {
    let flag = true;
    const file = this.filesData[0];
    const fileName = file?.name;
    if (fileName) {
      flag = this.checkFileName(fileName);
    }
    return flag;
  }

  private showError(error: string): void {
    this.notificationService.showError(
      '',
      this.translocoService.translate(error)
    );
  }

  private resetFilesData(): void {
    this.files = [];
    this.filesData = [];
  }
}
