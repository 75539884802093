<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<div class="outer-container">
  <div class="row">
    <div class="col-12 round-button-container">
      <span 
        title="{{
          'modules.data-management.test-data.vp5.tooltip.add-record' | transloco
        }}"
        (click)="addNewItem()"
        class="main-icon"
      >
        <i class="bi bi-plus"></i>
      </span>
    </div>
    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="vp5TestData"
          [sorts]="sortConfig"
        >
          <ngx-datatable-column
            name="{{
              'modules.data-management.test-data.vp5.name' | transloco
            }}"
            prop="name"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.test-data.vp5.name'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="{{
              'modules.data-management.test-data.vp5.vp5-version' | transloco
            }}"
            prop="version"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.test-data.vp5.vp5-version'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.uploaddate' | transloco }}"
            prop="createdAt"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value | date : "dd.MM.yy HH:mm:ss":'UTC' }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.upload-date'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.comment' | transloco }}"
            prop="comment"
            [flexGrow]="7"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span
                *ngIf="value"
                class="text-pre-wrap"
                placement="bottom"
                [ngbTooltip]="value"
                data-container="body"
                container="body"
                tooltipClass="note-tooltip"
                >{{ truncateNote(value) }}</span
              >
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.comment'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{
              'modules.data-management.test-data.vp5.shadow-operation-status'
                | transloco
            }}"
            prop="status"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.test-data.vp5.shadow-operation-status'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>

            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <div *ngIf="value">
                {{ getShadowOperationStatusValue(value) }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{
              'modules.data-management.test-data.vp5.shadow-operation-date'
                | transloco
            }}"
            prop="shadowModeStartedAt"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value | date : "dd.MM.yy HH:mm:ss":'UTC' }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.test-data.vp5.shadow-operation-date'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
          name="{{
            'modules.data-management.test-data.vp5.shadow-operation-finished-date'
              | transloco
          }}"
          prop="shadowModeFinishedAt"
          [flexGrow]="3"
          [resizeable]="false"
        >
          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <span *ngIf="value">{{ value | date : "dd.MM.yy HH:mm:ss":'UTC' }}</span>
          </ng-template>

          <ng-template
            let-value="value"
            let-column="column"
            let-rowIndex="rowIndex"
            let-sort="sortFn"
            ngx-datatable-header-template
          >
            <span
              class="header-sort"
              ngbTooltip="{{
                'modules.data-management.test-data.vp5.shadow-operation-finished-date'
                  | transloco : { value: value }
              }}"
              data-container="body"
              container="body"
              placement="bottom"
              tooltipClass="note-tooltip"
              (click)="sort()"
              >{{ column.name }}</span
            >
          </ng-template>
        </ngx-datatable-column>

          <ngx-datatable-column
            [cellClass]="'text-left'"
            name="{{ 'global.actions' | transloco }}"
            [flexGrow]="5"
            [sortable]="false"
            [resizeable]="false"
          >
            <ng-template
              let-rowIndex="rowIndex"
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              
              <span 
                *ngIf="row.status === activeStatus"
                title="{{
                  'modules.data-management.test-data.vp5.tooltip.deactivate-action' | transloco
                }}"
                (click)="deactivateTestBdp(row.name)"
              >
                <i class="bi bi-stop-fill"></i>
              </span>

              <span 
                *ngIf="row.status !== activeStatus"
                title="{{
                  'modules.data-management.test-data.vp5.tooltip.activate-action' | transloco
                }}"
                (click)="activateTestBdp(row.name)"
              >
                <i class="bi bi-play-fill"></i>
              </span>

              <span 
                title="{{
                  'modules.data-management.test-data.vp5.tooltip.download-data' | transloco
                }}"
                (click)="exportFiles(row)"
              >
                <i class="bi bi-download"></i>
              </span>
              
              <span 
                [ngClass]="{'no-drop-cursor': !row.containerUrl}"
                title="{{
                  !row.containerUrl
                    ? ('modules.data-management.test-data.vp5.tooltip.no-container-url'
                      | transloco)
                    : ('modules.data-management.test-data.vp5.tooltip.container-url-copy'
                      | transloco)
                }}"
              >
                <span
                  [ngClass]="{'disabled': !row.containerUrl}"
                  (click)="copyContinerUrl(row?.containerUrl, !row.containerUrl)"
                >
                  <i class="bi bi-front"></i>
                </span>
              </span>

              <span 
                [ngClass]="{'no-drop-cursor': row.status === activeStatus}"
                title="{{
                  row.status === activeStatus
                  ? ('modules.data-management.test-data.vp5.tooltip.active-delete'
                    | transloco)
                  : ('modules.data-management.test-data.vp5.tooltip.inactive-delete'
                    | transloco)
                }}"
              >
                <span
                  [ngClass]="{'disabled': row.status === activeStatus}" 
                  (click)="deleteFile(row, row.status === activeStatus)"
                >
                  <i class="bi bi-trash"></i>
                </span>
              </span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              ngx-datatable-header-template
            >
              <span
                ngbTooltip="{{
                  'global.actions' | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
