import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { StatusVp5 } from '@core/enums/status-vp5.enum';
import { AppService } from '@core/services/app.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { TestDataService } from '@core/services/test-data.service';
import { FcscUploadVorabAlertComponent } from '@fcsc/dialogs/fcsc-upload-vorab-alert/fcsc-upload-vorab-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import {
  CreateVp5TestDataPayload,
  Vp5TestData,
} from '@shared/models/filetype/Vp5TestData';
import { ModalResult } from '@shared/models/ModalResult';
import { Spinner } from '@shared/models/spinner';
import { ClipboardService } from 'ngx-clipboard';

import { FcscCreateVp5TestDataComponent } from './components/fcsc-create-vp5-test-data/fcsc-create-vp5-test-data.component';

@Component({
  selector: 'app-fcsc-vp5-test-data',
  templateUrl: './fcsc-vp5-test-data.component.html',
  styleUrls: ['./fcsc-vp5-test-data.component.scss'],
})
export class FcscVp5TestDataComponent implements OnInit {
  sortConfig = [{ prop: 'createdAt', dir: 'desc' }];
  vp5TestData: Vp5TestData[] | null;
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;
  activeStatus!: string;

  constructor(
    private testDataService: TestDataService,
    private modalService: NgbModal,
    private notificationService: ErrorHandlerService,
    private translocoService: TranslocoService,
    private appService: AppService,
    public clipboardService: ClipboardService,
    private downloadService: DownloadService
  ) {
    this.activeStatus = this.appService.getValueConstant(
      StatusVp5,
      StatusVp5.ACTIVE
    );
  }

  ngOnInit(): void {
    this.handleInitialSpinner();
    this.fetchVp5TestData();
  }

  addNewItem() {
    const modalRef = this.modalService.open(FcscCreateVp5TestDataComponent, {
      size: 'lg',
      windowClass: 'uploader-modal',
      backdrop: 'static',
      keyboard: false,
    });

    modalRef.result.then((result) => {
      if (result && result.ok === ModalResult.CREATE_BDP_TEST) {
        this.addNewVp5TestBdpItemEp(result.recordPayload);
      }
    });
  }

  activateTestBdp(name: string): void {
    this.testDataService.putVp5ActivateTestBdp(name).subscribe(
      () => {
        this.fetchVp5TestData();
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.expert-tool.result.error-message'
        );
      }
    );
  }

  deactivateTestBdp(name: string): void {
    this.testDataService.putVp5DeactivateTestBdp(name).subscribe(
      () => {
        this.fetchVp5TestData();
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.expert-tool.result.error-message'
        );
      }
    );
  }

  // TODO:  implementation later
  exportFiles(row: Vp5TestData) {
    const modalRef = this.modalService.open(FcscUploadVorabAlertComponent);
    modalRef.componentInstance.message = this.translocoService.translate(
      'global.alert-message'
    );
  }

  deleteFile(row: Vp5TestData, propogate: boolean) {
    if (propogate) {
      return;
    }
    this.testDataService.deleteVp5File(row.name).subscribe(
      () => {
        this.notificationService.showSuccess(
          '',
          this.translocoService.translate(
            'modules.data-management.test-data.vp5.messages.delete-success'
          )
        );
        this.fetchVp5TestData();
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.expert-tool.result.error-message'
        );
      }
    );
  }

  copyContinerUrl(text: string, propogate: boolean): void {
    if (propogate) {
      return;
    }

    this.clipboardService.copy(text);
    this.notificationService.showSuccess(
      '',
      this.translocoService.translate(
        'modules.data-management.test-data.vp5.messages.container-url-copy-success'
      )
    );
  }

  truncateNote(value: string): string {
    const isExpanded = value.length > 250;
    return value.substring(0, 250) + (isExpanded ? '...' : '');
  }

  getShadowOperationStatusValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(StatusVp5[value as keyof typeof StatusVp5])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  private fetchVp5TestData(): void {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.testDataService.getVp5TestData().subscribe(
      (data: HttpResponse<Vp5TestData[]>) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.vp5TestData = data.body;
      },
      (error: HttpErrorResponse) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.expert-tool.result.error-message'
        );
      }
    );
  }

  private addNewVp5TestBdpItemEp(payload: CreateVp5TestDataPayload) {
    this.testDataService.postVp5TestBdp(payload).subscribe(
      () => {
        this.fetchVp5TestData();
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.data-management.serien-daten.modal.bdp-activation-error-message'
        );
      }
    );
  }

  private handleInitialSpinner() {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };
    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
  }
}
