<div *ngIf="!isDetailSdpOpen">
  <div class="modal-header">
    <div class="col-md-6">
      <span *ngIf="showDiv.current" class="modal-title"> {{ titleCurrent }}</span>

      <span *ngIf="showDiv.next" class="modal-title"> {{ titleNext }}</span>
      <span *ngIf="showDiv.last" class="modal-title"> {{ titleLast }}</span>
    </div>
    <div
      *ngIf="showDiv.current || (!bdpContentId && showDiv.next)"
      (click)="close()"
      class="col-md-6 text-right"
    >
      <span
        class="close-icon"
        icon="times"
      >
        <i class="bi bi-x"></i>
      </span>
    </div>
  </div>

  <div *ngIf="showDiv.next" class="modal-sub-text">
    {{
      "modules.data-management.serien-daten.modal.bdp-selection-sub-title"
        | transloco
    }}
  </div>

  <div *ngIf="showDiv.last" class="modal-sub-text">{{ subTitleLast }}</div>

  <div class="modal-body">
    <div *ngIf="showDiv.current">
      <div>
        <span class="sub-title">{{
          "modules.data-management.serien-daten.modal.bdp-sub-title" | transloco
        }}</span>
      </div>
      <p></p>
      <form [formGroup]="bdpInSerieForm">
        <div class="row row-cols-auto">
          <div class="col-4">
            <div class="form-group">
              <label for="name">{{
                "modules.data-management.serien-daten.modal.name-lb" | transloco
              }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="name"
                formControlName="name"
                readonly
              />
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label for="inSeriesSince">{{
                "modules.data-management.serien-daten.modal.inSeriesSince-lb"
                  | transloco
              }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="inSeriesSince"
                formControlName="inSeriesSince"
                readonly
              />
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label for="reason">{{
                "modules.data-management.serien-daten.modal.changeReason-lb"
                  | transloco
              }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="reason"
                formControlName="reason"
                readonly
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-7">
            <div class="form-group add-space">
              <label for="note">{{
                "modules.data-management.serien-daten.modal.bdp-activation-comment"
                  | transloco
              }}</label>
              <textarea
                class="form-control"
                id="note"
                formControlName="note"
                rows="3"
                readonly
              ></textarea>
            </div>
          </div>

          <div class="col-5">
            <button
              type="button"
              class="btn btn-secondary back justify"
              (click)="viewInSerieSdpData()"
            >
              {{
                "modules.data-management.serien-daten.modal.view-inserie-data-btn"
                  | transloco
              }}
            </button>
            <p></p>

            <button
              *appRequiredEntitlements="serieBdpDeploytEntitlement"
              type="button"
              class="btn btn-secondary back"
              (click)="isDirectFlag = false; activateNewInSerieBdpSelection()"
            >
              {{
                "modules.data-management.serien-daten.modal.show-inserie-data-btn"
                  | transloco
              }}
            </button>
          </div>
        </div>
      </form>
      <p></p>

      <div>
        <span class="sub-title">{{
          "modules.data-management.serien-daten.modal.bdp-serie-history-modal-title"
            | transloco
        }}</span>
      </div>

      <p></p>

      <div class="row">
        <div class="col-12">
          <div class="vus-container">
            <ngx-datatable
              #dataTable
              class="material datatable-scrollable"
              [columnMode]="'flex'"
              [headerHeight]="56"
              rowHeight="auto"
              [scrollbarH]="false"
              [scrollbarV]="false"
              [rows]="seriesHistory"
              [sorts]="sortConfig"
            >
              <ngx-datatable-column
                name="{{ 'global.sd-packet' | transloco }}"
                prop="name"
                [flexGrow]="7"
                [resizeable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  let-rowIndex="rowIndex"
                  ngx-datatable-cell-template
                >
                  <span
                    class="sdpaket"
                    *ngIf="value"
                    (click)="sdPaketsDetails(row, value)"
                    placement="bottom"
                    ngbTooltip="{{
                      'modules.data-management.tooltip.sd-packet'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    tooltipClass="note-tooltip"
                    >{{ value }}</span
                  >
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort datatable-header-cell-label"
                    ngbTooltip="{{
                      'modules.data-management.table-header.sd-packet'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                    (click)="sort()"
                    >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'global.uploaddate' | transloco }}"
                prop="createdAt"
                [flexGrow]="5"
                [resizeable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                >
                  <span *ngIf="value">{{ value | date : "dd.MM.yy HH:mm:ss":'UTC' }}</span>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort"
                    ngbTooltip="{{
                      'modules.data-management.table-header.upload-date'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                    (click)="sort()"
                    >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [cellClass]="'text-right'"
                name="User"
                prop="username"
                [flexGrow]="5"
                [resizeable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                >
                  <span *ngIf="value">{{ value }}</span>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort"
                    ngbTooltip="{{
                      'modules.data-management.table-header.system'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                    (click)="sort()"
                    >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'modules.data-management.table-header.status' | transloco }}"
                prop="status"
                [flexGrow]="6"
                [resizeable]="false"
              >
                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort"
                    ngbTooltip="{{
                      'modules.data-management.table-header.status'
                        | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                    (click)="sort()"
                    >{{ column.name }}</span
                  >
                </ng-template>

                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                >
                  <div class="status-box" *ngIf="value">
                    <div>
                      <span
                        [ngStyle]="{'color': getColor(value)}"
                      >
                        <i class="bi bi-circle-fill"></i>
                      </span>
                    </div>
                    <div class="status-text">
                      {{ getValue(value) }}
                    </div>
                  </div>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'global.productive-from' | transloco }}"
                prop="inSeriesSince"
                [flexGrow]="5"
                [resizeable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                >
                  <span *ngIf="value">{{
                    value | date : "dd.MM.yy HH:mm:ss":'UTC'
                  }}</span>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort"
                    ngbTooltip="{{
                      'global.productive-from' | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                    (click)="sort()"
                    >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'global.productive-until' | transloco }}"
                prop="inSerieUntil"
                [flexGrow]="5"
                [resizeable]="false"
                [sortable]="false"
              >
                <ng-template
                  let-value="value"
                  let-row="row"
                  ngx-datatable-cell-template
                >
                  <span *ngIf="value">{{
                    value | date : "dd.MM.yy HH:mm:ss":'UTC'
                  }}</span>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  let-sort="sortFn"
                  ngx-datatable-header-template
                >
                  <span
                    class="header-sort"
                    ngbTooltip="{{
                      'global.productive-until' | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                    (click)="sort()"
                    >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                [cellClass]="'text-center'"
                name="{{ 'global.actions' | transloco }}"
                [flexGrow]="4"
                [sortable]="false"
                [resizeable]="false"
              >
                <ng-template
                  let-rowIndex="rowIndex"
                  let-row="row"
                  let-value="value"
                  ngx-datatable-cell-template
                >
                  <span 
                    title="{{
                      'modules.data-management.tooltip.download' | transloco
                    }}"
                    (click)="exportSdpFiles(row)"
                  >
                    <i class="bi bi-download"></i>
                  </span>
                  <span 
                    title="{{
                      'modules.data-management.tooltip.rollback-row' | transloco
                    }}"
                    (click)="handleDirectNavigation(row)"
                  >
                    <i class="bi bi-arrow-up-circle-fill"></i>
                  </span>
                </ng-template>

                <ng-template
                  let-value="value"
                  let-column="column"
                  let-rowIndex="rowIndex"
                  ngx-datatable-header-template
                >
                  <span
                    ngbTooltip="{{
                      'global.actions' | transloco : { value: value }
                    }}"
                    data-container="body"
                    container="body"
                    placement="bottom"
                    tooltipClass="note-tooltip"
                    >{{ column.name }}</span
                  >
                </ng-template>
              </ngx-datatable-column>
            </ngx-datatable>
          </div>
        </div>
      </div>

      <!-- end history table -->
    </div>

    <!-- 2nd div html  -->
    <div *ngIf="showDiv.next">
      <app-fcsc-bdp-inserie-select
        [baureihe]="baureihe"
        (selectedPacket)="packetSelectedEvent($event)"
      >
      </app-fcsc-bdp-inserie-select>
    </div>

    <!-- 3rd div html  -->

    <div *ngIf="showDiv.last">
      <form [formGroup]="serieActivationForm">
        <p></p>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="form-group center">
                {{
                  "modules.data-management.serien-daten.modal.bdp-activation-reason-title"
                    | transloco
                }}
              </div>

              <p></p>
              <div class="custom-rad">
                <div class="col-9">
                  <div class="form-group custom-radio">
                    <label
                      class="radio-inline checkradio-container custom-radio-lb"
                    >
                      <input
                        type="radio"
                        value="DATA_UPDATE"
                        formControlName="reason"
                      />
                      <span class="radiomark"></span>
                      <span class="custom-radio-span">
                        {{ updateReason | transloco }}
                      </span>
                    </label>

                    <label
                      class="radio-inline checkradio-container custom-radio-lb"
                    >
                      <input
                        type="radio"
                        value="FAULTY_BDP"
                        formControlName="reason"
                      />
                      <span class="radiomark"></span>
                      <span class="custom-radio-span">
                        {{ wrongBdpReason | transloco }}
                      </span>
                    </label>

                    <label
                      class="radio-inline checkradio-container custom-radio-lb"
                    >
                      <input
                        type="radio"
                        value="OTHER"
                        formControlName="reason"
                      />
                      <span class="radiomark"></span>
                      <span class="custom-radio-span">
                        {{ otherBdpReason | transloco }}</span
                      >
                    </label>
                  </div>
                </div>
              </div>

              <p></p>
              <div class="col-12 form-group">
                <label for="note">{{
                  "modules.data-management.serien-daten.modal.bdp-activation-comment"
                    | transloco
                }}</label>
                <textarea
                  class="form-control"
                  id="note"
                  formControlName="note"
                  rows="3"
                ></textarea>
              </div>

              <div class="mt-3" *ngIf="seriesHistory && seriesHistory[0]?.name">
                <app-upload-duration
                  [estimatedTime]="10"
                  [translationKey]="notificationTitle"
                ></app-upload-duration>
              </div>

              <div class="mt-3">
                <app-upload-duration
                  [estimatedTime]="10"
                  [translationKey]="
                    'modules.data-management.sdp-multi-activation-notification'
                  "
                ></app-upload-duration>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <!-- modal footer  -->

  <div *ngIf="showDiv.next" class="modal-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="button-area float-start">
            <button
              *ngIf="bdpContentId"
              [ngStyle]="{ display: showDiv.next ? 'display' : 'none' }"
              type="button"
              class="btn btn-secondary back"
              (click)="backToFirstView()"
            >
              {{ "global.back" | transloco }}
            </button>
          </div>
          <div class="button-area float-end">
            <div class="displayed">
              <button
                type="button"
                class="btn btn-secondary back"
                (click)="close()"
              >
                {{ "global.cancel" | transloco }}
              </button>

              <button
                [ngStyle]="{ display: showDiv.next ? 'display' : 'none' }"
                [disabled]="!selectedPacket"
                type="button"
                class="btn btn btn-primary"
                (click)="selectBdp()"
              >
                {{
                  "modules.data-management.serien-daten.modal.choose-packet-btn"
                    | transloco
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="showDiv.last" class="modal-footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="button-area float-start">
            <button
              [ngStyle]="{ display: showDiv.last ? 'display' : 'none' }"
              type="button"
              class="btn btn-secondary back"
              (click)="backToSecondView()"
            >
              {{ "global.back" | transloco }}
            </button>
          </div>
          <div class="button-area float-end">
            <div class="displayed">
              <button
                type="button"
                class="btn btn-secondary back"
                (click)="close()"
              >
                {{ "global.cancel" | transloco }}
              </button>

              <button
                [ngStyle]="{ display: showDiv.last ? 'display' : 'none' }"
                type="button"
                class="btn btn btn-primary"
                (click)="activateInSerie()"
              >
                {{
                  "modules.data-management.serien-daten.modal.bdp-activation-btn"
                    | transloco
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
