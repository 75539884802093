export const FlashFileExtension = [
  { name: '.CFF' },
  { name: '.SMR-F' },
  { name: 'Default' },
];

export const FlashFileSpecialExtension = [{ name: '.CFF' }, { name: '.SMR-F' }];

export const TypeExtension = [{ name: '.CFF' }, { name: '.SMR-F' }];

export enum ExtConstants {
  CFF = 'CFF',
  SMRF = 'SMR-F',
  DEFAULT = 'Default',
}
