import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { CurrentUser } from '@shared/models/CurrentUser';
import { Observable, interval, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SecurityService {
  private jwtApiUrl = environment.i3Access.userinfoUrl;
  private authGroup = environment.i3Access.authorizationGroup;
  private currentUser!: CurrentUser;
  private authError!: string;

  constructor(private http: HttpClient) {}

  public initialize() {
    this.refreshAccessToken();
    return this.loadCurrentUser();
  }

  private loadCurrentUser() {
    return new Promise((resolve, reject) => {
      this.getLoggedInUser().subscribe(
        (currentUser: CurrentUser) => {
          this.currentUser = currentUser;

          if (currentUser.hasAuthorizationGroup(this.authGroup)) {
            this.authError = '';
            resolve(true);
          } else {
            this.authError = 'MISSING_AUTHORIZATION_GROUP';
            resolve(false);
          }
        },
        (error) => {
          this.authError = error.error.message;
          resolve(false);
        }
      );
    });
  }

  public getCurrentUser(): CurrentUser {
    return this.currentUser;
  }

  private refreshAccessToken() {
    interval(1000).subscribe(() => {
      const expIn = this.getExpireIn();
      const now = new Date();
      if (expIn !== null && expIn <= now.getTime() / 1000) {
        // do a reload every 3 secs to not flood the server
        if (now.getSeconds() % 3 === 0) {
          this.loadCurrentUser().then(() => {});
        }
      }
    });
  }

  public getLoggedInUser(): Observable<any> {
    return this.getJwt()
      .pipe(map((data: any) => this.mapToCurrentUser(data)))
      .pipe(
        tap((currentUser: CurrentUser) => {
          localStorage.setItem('accessToken', currentUser.accessToken);
          this.setExpireIn(currentUser.expIn);
        })
      );
  }

  public getEntitlements(): string[] {
    if (this.currentUser && this.currentUser.entitlements) {
      return this.currentUser.entitlements;
    } else {
      return [];
    }
  }

  public isLoggedIn(): boolean {
    return this.currentUser !== undefined && this.currentUser !== null;
  }

  public hasAuthError(): boolean {
    return this.authError !== '';
  }

  public getAuthError(): string {
    return this.authError;
  }

  public logout() {
    window.location.href = environment.i3Access.logoutUrl;
  }

  private getJwt(): Observable<any> {
    return this.http.get(this.jwtApiUrl);
  }

  private mapToCurrentUser(data: any): CurrentUser {
    // current time + exp_in (119) - 15 seconds. Date in Future,  when 2 min passes then token gets refreshed
    // every second, the current time get compared with the future time we set before

    const expireAt = new Date().getTime() / 1000 + (data.exp_in || 0) - 15;

    return new CurrentUser(
      data.sub || '',
      Array.isArray(data.authorization_group)
        ? data.authorization_group
        : [data.authorization_group] || [],
      data.entitlement_group || [],
      data.jwtToken || '',
      data.accessToken || '',
      parseInt(expireAt.toString(), 10)
    );
  }

  private setExpireIn(expIn: number) {
    localStorage.setItem('accessTokenExpIn', expIn.toString());
  }

  private getExpireIn(): number {
    const data = localStorage.getItem('accessTokenExpIn');
    return parseInt(data ? data : '0', 10);
  }
}
