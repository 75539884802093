import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { serialUploadListHeadersTitles } from '@shared/constants/csvConfigs';

@Injectable({
  providedIn: 'root',
})
export class CsvExportService {
  constructor(private translocoService: TranslocoService) {}

  getOptions(): object {
    const options: any = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      removeEmptyValues: true,
      headers: this.getHeaders(serialUploadListHeadersTitles),
    };
    return options;
  }

  private getHeaders(list: string[]): string[] {
    const translatedList = [];
    list.forEach((element) => {
      translatedList.push(this.translocoService.translate(element));
    });
    return translatedList;
  }
}
