import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReasonInserieActivaton } from '@core/enums/reason-inserie-activaton.enum';
import { Status } from '@core/enums/status-color.enum';
import { AppService } from '@core/services/app.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { getStatusColor } from '@core/utils/getStatusColor/getStatusColor';
import { entitlements } from '@env/entitlements';
import { FcscSdpDetailsComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-vorab/components/fcsc-sdp-details/fcsc-sdp-details.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { SeriesSteuerDatenMap } from '@shared/models/filetype/SeriesSteuerDaten';
import { SeriesSteuerDatenHistory } from '@shared/models/filetype/SeriesSteuerDatenHistory';
import { ModalResult } from '@shared/models/ModalResult';

@Component({
  selector: 'app-fcsc-edit-series-modal',
  templateUrl: './fcsc-edit-series-modal.component.html',
  styleUrls: ['./fcsc-edit-series-modal.component.scss'],
})
export class FcscEditSeriesModalComponent implements OnInit {
  radioList!: any;
  @Input() message!: string;
  @Input() titleCurrent!: string;
  @Input() titleNext!: string;
  @Input() titleLast!: string;
  @Input() subTitleLast!: string;
  @Input() notificationMessage!: string;
  @Input() baureihe!: string;
  @Input() bdpContentId!: number;
  @Output() emitService = new EventEmitter();
  updateReason!: string;
  wrongBdpReason!: string;
  otherBdpReason!: string;
  bdpInSerieForm!: UntypedFormGroup;
  serieActivationForm!: UntypedFormGroup;
  selectedPacket!: any;
  notificationTitle = '';
  isDetailSdpOpen = false;
  isDirectFlag = false;

  sortConfig = [{ prop: 'inSeriesSince', dir: 'desc' }];
  seriesHistory!: SeriesSteuerDatenHistory[];
  seriesEditHistory!: SeriesSteuerDatenHistory[];

  public serieBdpDeploytEntitlement = [
    entitlements.VUS.FCSC_SERIEN_IMAGE_DEPLOY,
  ];

  showDiv = {
    current: false,
    next: false,
    last: false,
  };

  constructor(
    private dataPipe: DatePipe,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private notificationService: ErrorHandlerService,
    private translocoService: TranslocoService,
    private appService: AppService,
    private steuerdatenService: SteuerdatenService,
    private modalService: NgbModal,
    private downloadService: DownloadService
  ) {}

  ngOnInit() {
    this.updateReason = ReasonInserieActivaton.DATA_UPDATE;
    this.wrongBdpReason = ReasonInserieActivaton.FAULTY_BDP;
    this.otherBdpReason = ReasonInserieActivaton.OTHER;

    this.setTranslation();

    if (!this.bdpContentId) {
      this.activateNewInSerieBdpSelection();
    } else {
      this.getSeriesEditDetails();
      this.getSeriesHistory();
      this.createBdpInSerieForm();

      this.showDiv.current = true;
      this.showDiv.next = false;
      this.showDiv.last = false;
    }
  }

  private createBdpInSerieForm() {
    this.bdpInSerieForm = this.formBuilder.group({
      name: new UntypedFormControl(
        this.seriesEditHistory && this.seriesEditHistory[0].name
      ),
      inSeriesSince: new UntypedFormControl(
        this.seriesEditHistory &&
          this.dataPipe?.transform(
            this.seriesEditHistory[0].inSeriesSince,
            'dd.MM.yy, HH:mm:ss'
          )
      ),
      reason: new UntypedFormControl(this.getReasonTranslatedDefaultValue()),
      note: new UntypedFormControl(
        this.seriesEditHistory && this.seriesEditHistory[0].note
      ),
    });
  }

  private createSerieActivationForm() {
    this.serieActivationForm = this.formBuilder.group({
      note: new UntypedFormControl(''),
      reason: new UntypedFormControl(
        this.appService.getValueConstant(
          ReasonInserieActivaton,
          ReasonInserieActivaton.DATA_UPDATE
        )
      ),
      name: new UntypedFormControl(''),
    });

    this.notificationTitle = this.translocoService.translate(
      'modules.data-management.serien-daten.modal.bdp-activation-notification-title',
      {
        selected:
          this.seriesEditHistory && this.seriesEditHistory[0].name
            ? this.seriesEditHistory[0].name
            : '',
      }
    );
  }

  public activateNewInSerieBdpSelection() {
    this.showDiv.current = false;
    this.showDiv.next = true;
    this.createSerieActivationForm();
  }

  activateNewInSerieBdpSelectionHistory() {
    this.showDiv.current = false;
    this.showDiv.next = false;
    this.showDiv.last = true;
    this.createSerieActivationForm();
  }

  handleDirectNavigation(row) {
    this.isDirectFlag = true;
    const packetSelected = { name: row.name };
    if (packetSelected) {
      const event = [packetSelected];
      this.activateNewInSerieBdpSelectionHistory();
      this.packetSelectedEvent(event);
    }
  }

  public viewInSerieSdpData() {
    const modalRef = this.modalService.open(FcscSdpDetailsComponent, {
      size: 'lg',
      windowClass: 'bdp-edit-modal override',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.title = this.translocoService.translate(
      'modules.data-management.sdp-details-title',
      { name: this.bdpInSerieForm.get('name')?.value }
    );

    modalRef.componentInstance.sdpName = this.bdpInSerieForm.get('name')?.value;
    modalRef.componentInstance.showCrossClose = false;
    this.isDetailSdpOpen = true;

    modalRef.result.then((result) => {
      if (result && result.back === ModalResult.BACK_VIEW) {
        this.isDetailSdpOpen = false;
      }
    });
  }

  packetSelectedEvent(event) {
    this.selectedPacket = event[0] ?? null;
    this.serieActivationForm.controls.name.setValue(this.selectedPacket?.name);
    this.notificationMessage =
      this.selectedPacket && this.selectedPacket.name
        ? this.selectedPacket.name
        : '';
    this.emitService.emit(this.selectedPacket);
  }

  getSeriesEditDetails() {
    this.steuerdatenService.getSeriesEditDetails(this.baureihe).subscribe(
      (data: SeriesSteuerDatenHistory[]) => {
        this.seriesEditHistory = data;
        this.createBdpInSerieForm();
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  private getSeriesHistory(): void {
    this.steuerdatenService.getSeriesHistory(this.baureihe).subscribe(
      (seriesHistoryData: SeriesSteuerDatenHistory[]) => {
        this.seriesHistory = seriesHistoryData;
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  sdPaketsDetails(row: SeriesSteuerDatenMap, value: string): void {
    const modalRef = this.modalService.open(FcscSdpDetailsComponent, {
      size: 'lg',
      windowClass: 'bdp-edit-modal override',
      backdrop: 'static',
      keyboard: false,
    });
    const selectedRow: SeriesSteuerDatenMap = row;
    modalRef.componentInstance.title = this.translocoService.translate(
      'modules.data-management.sdp-details-title',
      { name: selectedRow.name }
    );

    modalRef.componentInstance.sdpName = value;
    modalRef.componentInstance.showCrossClose = false;
    this.isDetailSdpOpen = true;

    modalRef.result.then((result) => {
      if (result && result.back === ModalResult.BACK_VIEW) {
        this.isDetailSdpOpen = false;
      }
    });
  }

  public exportSdpFiles(sdp: SeriesSteuerDatenMap): void {
    const selectedRowName = sdp.name;

    this.steuerdatenService
      .getExportFilesAsZipDownload(sdp.steuerDatenPaketId)
      .subscribe(
        (response) => {
          this.downloadService.createAndDownloadFile(
            response,
            `${selectedRowName}`,
            '.zip'
          );
        },
        (error: HttpErrorResponse) => {
          this.notificationService.handleServerErrorNotification(error, '');
        }
      );
  }

  // This is called when translation is triggered.
  setTranslation(): void {
    this.appService.languageSubject.subscribe(() => {
      setTimeout(() => {
        this.setReasonInitialValue();
      }, 100);
    });
  }

  setReasonInitialValue(): void {
    this.bdpInSerieForm
      .get('reason')
      ?.setValue(this.getReasonTranslatedDefaultValue());
  }

  getReasonTranslatedDefaultValue(): void {
    return this.translocoService.translate(
      ReasonInserieActivaton[
        this.seriesEditHistory &&
          (this.seriesEditHistory[0]
            ?.reason as keyof typeof ReasonInserieActivaton)
      ]
    );
  }

  public getColor(value: string): string {
    const enumValue = Status[value as keyof typeof Status];
    return getStatusColor(enumValue);
  }

  public getValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(Status[value as keyof typeof Status])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  // modal buttons
  public selectBdp(): void {
    this.showDiv.current = false;
    this.showDiv.next = false;
    this.showDiv.last = true;
  }

  public activateInSerie(): void {
    const result = {
      ok: ModalResult.ACTIVATION_SUCCESSFUL,
      form: this.serieActivationForm,
    };
    this.activeModal.close(result);
  }

  public close(): void {
    this.activeModal.close(ModalResult.ACTIVATION_CANCEL);
  }

  public backToFirstView(): void {
    this.showDiv.current = true;
    this.showDiv.next = false;
    this.selectedPacket = null;
  }

  public backToSecondView(): void {
    if (this.isDirectFlag) {
      this.showDiv.last = false;
      this.backToFirstView();
      return;
    }
    this.showDiv.current = false;
    this.showDiv.last = false;
    this.showDiv.next = true;
    this.selectedPacket = null;
  }
}
