<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>
<div class="modal-header">
  <span class="modal-title col-md-6">{{title}}</span>
  <div *ngIf="showCrossClose" (click)="activeModal.dismiss()" class="col-md-6 text-right">
    <span
      class="close-icon"
      icon="times"
    >
      <i class="bi bi-x"></i>
    </span>
  </div>
</div>
<div class="modal-body">
  <div class="row">

    <div class="col pull-left mb-2">
      {{ steuerdatenFiles?.length }}
      {{
        "modules.data-management.table-header.files"
          | transloco
      }}
    </div>

    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable #dataTable class="material datatable-scrollable" [columnMode]="'flex'" [headerHeight]="56"
          rowHeight="auto" [rows]="steuerdatenFiles">

          <ngx-datatable-column name="{{ 'global.file' | transloco }}" prop="name" [flexGrow]="5" [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <span *ngIf="value">{{value}}</span>
            </ng-template>
            <ng-template let-value="value" let-column="column" let-rowIndex="rowIndex" let-sort="sortFn" ngx-datatable-header-template>
              <span class="header-sort datatable-header-cell-label" ngbTooltip ="{{ 'modules.data-management.table-header.files' | transloco: { value: value } }}" 
              data-container="body"
              container="body"
              placement="bottom"
             tooltipClass="note-tooltip" (click)="sort()">{{column.name}}</span>
           </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="{{ 'global.uploaddate' | transloco }}" prop="createdAt" [flexGrow]="4" [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <span *ngIf="value">{{value | date: 'dd.MM.yy HH:mm:ss':'UTC' }}</span>
            </ng-template>
            <ng-template let-value="value" let-column="column" let-rowIndex="rowIndex" let-sort="sortFn" ngx-datatable-header-template>
              <span class="header-sort datatable-header-cell-label" ngbTooltip ="{{ 'modules.data-management.table-header.upload-date' | transloco: { value: value } }}" 
              data-container="body"
              container="body"
              placement="bottom"
             tooltipClass="note-tooltip" (click)="sort()">{{column.name}}</span>
           </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="{{ 'global.modification-date' | transloco }}" prop="lastModified" [flexGrow]="4" [resizeable]="false">
            <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
              <span *ngIf="value">{{value | date: 'dd.MM.yy HH:mm:ss':'UTC' }}</span>
            </ng-template>
            <ng-template let-value="value" let-column="column" let-rowIndex="rowIndex" let-sort="sortFn" ngx-datatable-header-template>
              <span class="header-sort datatable-header-cell-label" ngbTooltip ="{{ 'global.modification-date' | transloco: { value: value } }}" 
              data-container="body"
              container="body"
              placement="bottom"
             tooltipClass="note-tooltip" (click)="sort()">{{column.name}}</span>
           </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="{{ 'global.actions' | transloco }}" [flexGrow]="2" [canAutoResize]="true" [sortable]="false" [resizeable]="false">
            <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template let-rowIndex="rowIndex">
              <div class="float-right align-icons icon-box">
                <div *appRequiredEntitlements= "fileExportEntitlement" class="option-button">
                    <span 
                      title="{{
                        'modules.data-management.tooltip.download-control-data' | transloco
                      }}"
                      (click)="exportFile(row)"
                      class="option-svg"
                    >
                      <i class="bi bi-download"></i>
                    </span>
                </div>

              </div>
            </ng-template>

            <ng-template let-value="value" let-column="column" let-rowIndex="rowIndex" ngx-datatable-header-template>
              <span  ngbTooltip ="{{ 'global.actions' | transloco: { value: value } }}" 
              data-container="body"
              container="body"
              placement="bottom"
             tooltipClass="note-tooltip">{{column.name}}</span>
           </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>

<!-- modal footer  -->
<div *ngIf="!showCrossClose">
<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area">
          <div class="displayed">
            <button
              type="button"
              class="btn btn-secondary back"
              (click)="backToFirstView()"
            >
              {{ "global.back" | transloco }}
            </button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
