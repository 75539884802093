import { Injectable } from '@angular/core';

export enum Environment {
    PROD = 'PROD',
    INT = 'INT',
    DEV = 'DEV',
    LOCAL = 'LOCAL',
    CYPRESS = 'CYPRESS',
  }

  @Injectable({ providedIn: 'root' })
  export class EnvService {
    private _env!: Environment;
    private _apiUrl!: string;

    get env(): Environment {
      return this._env;
    }

    get apiUrl(): string {
      return this._apiUrl;
    }

    constructor() {}

    init(): Promise<void> {
      return new Promise(resolve => {
        this.setEnvVariables();
        resolve();
      });
    }

    private setEnvVariables(): void {
      const hostname = window && window.location && window.location.hostname;
      const port = window && window.location && window.location.port;

      if (/^fcs-c/.test(hostname)) {
        // this domain is used inside of the docker compose environment
        this._env = Environment.CYPRESS;
        this._apiUrl = '/';
      } else if (/^.*localhost.*/.test(hostname)) {
        if ("8080" === (port as string)) {
          this._env = Environment.CYPRESS;
          this._apiUrl = '/';
        } else {
          this._env = Environment.LOCAL;
          this._apiUrl = 'https://api.fcsc-dev.vus.corpinter.net/';
        }
      } else if (/^fcsc-dev.vus.corpinter.net/.test(hostname)) {
        this._env = Environment.DEV;
        this._apiUrl = 'https://api.fcsc-dev.vus.corpinter.net/';
      } else if (/^fcsc-int.vus.corpinter.net/.test(hostname)) {
        this._env = Environment.INT;
        this._apiUrl = 'https://api.fcsc-int.vus.corpinter.net/';
      } else if (/^fcsc.vus.corpinter.net/.test(hostname)) {
        this._env = Environment.PROD;
        this._apiUrl = 'https://api.fcsc.vus.corpinter.net/';
      } else {
        console.warn(`Cannot find environment for host name ${hostname}`);
      }
    }
  }
