import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { I18n } from '@core/services/custom-datepicker-i18n.service';
import { SecurityService } from '@core/services/security.service';
import { TranslocoService } from '@ngneat/transloco';
import { VehicleCode } from '@shared/models/experttool/VedocData';

@Component({
  selector: 'app-fcsc-fw-vehicle-codes-filter',
  templateUrl: './fcsc-fw-vehicle-codes-filter.component.html',
  styleUrls: ['./fcsc-fw-vehicle-codes-filter.component.scss'],
})
export class FcscFwVehicleCodesFilterComponent implements OnInit {
  form!: UntypedFormGroup;
  formLoaded!: boolean;
  translatedAll!: any;
  @Output() refreshVisibleTable = new EventEmitter<any>();
  @Input() data!: VehicleCode[];
  @ViewChild('search') search!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private appService: AppService,
    private translocoService: TranslocoService,
    private _i18n: I18n,
    public securityService: SecurityService
  ) {}

  ngOnInit(): void {
    this.createFilterForm();
  }

  private createFilterForm() {
    this.form = this.formBuilder.group({
      codeOrDescription: new UntypedFormControl(''),
    });
    this.formLoaded = true;
  }

  public filterRows() {
    const form = this.form;
    const params = {};
    for (const key of Object.keys(form.controls)) {
      if (
        form.get(key) &&
        form.get(key)?.value &&
        form.get(key)?.value !== '' &&
        !Array.isArray(form.get(key)?.value)
      ) {
        const val = form.get(key)?.value;

        params[key] = val;
        console.log(val);
      }
    }

    this.refreshVisibleTable.emit(params);
  }

  public resetFilteredRows() {
    this.form.get('codeOrDescription')?.setValue('');
    this.refreshVisibleTable.emit('');
  }

  public removeFocus() {
    this.search.nativeElement.blur();
  }
}
