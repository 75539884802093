import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BdpDocumentationContent } from '@shared/models/filetype/SeriesBdpRollback';

@Component({
  selector: 'app-fcsc-rollback-part2',
  templateUrl: './fcsc-rollback-part2.component.html',
  styleUrls: ['./fcsc-rollback-part2.component.scss'],
})
export class FcscRollbackPart2Component implements OnInit {
  sortConfig = [{ prop: 'baureihe', dir: 'asc' }];
  emptyMessage!: string;

  @Input() seriesBdpDocumentationContent: BdpDocumentationContent[] = [];
  @Input() seriesReplacedBdpDocumentationContent: BdpDocumentationContent[] =
    [];

  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  checkVisibility(row: BdpDocumentationContent): string {
    const currentName = row?.sdpName;
    const content = this.seriesBdpDocumentationContent;
    const found = content.find((con) => con?.sdpName === currentName);
    return found ? 'option-svg-info-hidden' : 'option-svg-info';
  }

  handleBack() {
    this.back.emit();
  }

  handleNext() {
    this.next.emit();
  }

  handleClose() {
    this.close.emit();
  }
}
