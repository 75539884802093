import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  saveData(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  getData(key: string): any {
    const data = sessionStorage.getItem(key);
    if (data?.length) return JSON.parse(data);
    else {
      return;
    }
  }

  clearData() {
    sessionStorage.clear();
  }
}
