import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { I18n } from '@core/services/custom-datepicker-i18n.service';
import { SecurityService } from '@core/services/security.service';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { SeriesBdp } from '@shared/models/filetype/SeriesBdp';

@Component({
  selector: 'app-fcsc-filter-serien-bdp',
  templateUrl: './fcsc-filter-serien-bdp.component.html',
  styleUrls: ['./fcsc-filter-serien-bdp.component.scss'],
})
export class FcscFilterSerienBdpComponent implements OnInit {
  form!: UntypedFormGroup;
  formLoaded!: boolean;
  translatedAll!: any;
  @Output() refreshVisibleTable = new EventEmitter<any>();
  @Input() data!: SeriesBdp[];
  @ViewChild('searchProductiveFrom') searchProductiveFrom!: ElementRef;
  @ViewChild('searchProductiveUntil') searchProductiveUntil!: ElementRef;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private appService: AppService,
    private translocoService: TranslocoService,
    private _i18n: I18n,
    public config: NgbDatepickerConfig,
    public securityService: SecurityService
  ) {}

  ngOnInit(): void {
    this.initialize();
    this.handleTranslationKeys();
  }

  private initialize() {
    setTimeout(() => {
      this.appService.languageSubject.next(localStorage.getItem('language'));
    }, 50);
  }

  private handleTranslationKeys() {
    this.appService.languageSubject.subscribe((lang: any) => {
      this._i18n.language = lang;
      this.appService.translateForAll(
        'modules.data-management.filter.br-placeholder'
      );
    });

    this.appService.translationSubject.subscribe((translation) => {
      this.translatedAll = translation;
      this.createFilterForm(translation);
    });
  }

  private createFilterForm(translation?) {
    this.form = this.formBuilder.group({
      bdpName: new UntypedFormControl(''),
      username: new UntypedFormControl(''),
      comment: new UntypedFormControl(''),
      setInSerieAt: new UntypedFormControl(''),
      inSerieUntil: new UntypedFormControl(''),
    });
    this.formLoaded = true;
  }

  public filterRows() {
    const form = this.form;
    const params = {};
    for (const key of Object.keys(form.controls)) {
      if (
        form.get(key) &&
        form.get(key)?.value &&
        form.get(key)?.value !== '' &&
        !Array.isArray(form.get(key)?.value)
      ) {
        const val = form.get(key)?.value;

        params[key] =
          val &&
          typeof val !== 'object' &&
          val?.toLowerCase() === this.translatedAll.toLowerCase()
            ? ''
            : val;

        if (
          (key === 'setInSerieAt' && params[key]) ||
          (key === 'inSerieUntil' && params[key])
        ) {
          params[key] =
            params[key].year.toString() +
            params[key].month.toString().padStart(2, '0') +
            params[key].day.toString().padStart(2, '0');
        }
      }
    }

    this.refreshVisibleTable.emit(params);
  }

  public resetFilteredRows() {
    this.initialize();
    this.refreshVisibleTable.emit();
  }

  public removeProductiveFromFocus() {
    this.searchProductiveFrom.nativeElement.blur();
  }

  public removeProductiveUntilFocus() {
    this.searchProductiveUntil.nativeElement.blur();
  }
}
