<div class="margin-custom">
  <div class="col pull-left">
    <div class="row row-cols-auto mt-5 position-relative">
      <div class="loading-bar-container">
        <app-loading-bar
          [text]="
            'modules.expert-tool.modal-bdp-activation-loadingbar-title'
              | transloco
          "
          *ngIf="loadingStatus && loadingStatus !== 'ACTIVE'"
        ></app-loading-bar>
      </div>
    </div>
  </div>

  <div class="col pull-right">
    <form [formGroup]="directLoadForm">
      <div class="row row-cols-auto justify-content-right">
        <div class="col">
          <div class="form-group">
            <div (click)="handleDirectLoadCheck()">
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  formControlName="directLoad"
                  (change)="handleDirectLoadCheck()"
                />
                <span class="checkmark"></span>
                <span class="lb-alignment">{{
                  "modules.expert-tool.vp-direct-load" | transloco
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-cols-auto justify-content-right">
        <div class="col">
          <button
            *appRequiredEntitlements="logDownloadEntitlement"
            type="button"
            class="btn btn-secondary justify"
            (click)="openLogFilesModal()"
          >
            {{ "modules.expert-tool.result.log.btn-log-download" | transloco }}
          </button>

          <button
            [disabled]="
              isEcuSelected ||
              !selectedSdpName ||
              waitForResponse ||
              selectedExtentionName === translatedExtentionPlaceholder ||
              (waitForActivationResponse && isSelectedBdpStatusChanged)
            "
            class="btn btn-primary justify"
            (click)="updateFinLog(true, true)"
          >
            <span *ngIf="!isCalculated">{{
              "modules.expert-tool.btn-identify-flashware" | transloco
            }}</span>
            <span *ngIf="isCalculated">{{
              "modules.expert-tool.btn-reidentify-flashware" | transloco
            }}</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
