import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { BdpDocumentation } from '@shared/models/filetype/SeriesBdpRollback';

@Component({
  selector: 'app-fcsc-rollback-part1',
  templateUrl: './fcsc-rollback-part1.component.html',
  styleUrls: ['./fcsc-rollback-part1.component.scss'],
})
export class FcscRollbackPart1Component implements OnInit, OnChanges {
  currentStatusForm!: UntypedFormGroup;
  replacedBdpStatusForm!: UntypedFormGroup;
  @Output() apiCall: EventEmitter<any> = new EventEmitter();

  @Input() seriesBdpDocumentation!: BdpDocumentation;
  @Input() seriesReplacedBdpDocumentation!: BdpDocumentation;

  @Output() close: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();

  ngOnInit(): void {
    this.createStatusForm();
  }

  ngOnChanges() {
    if (this.seriesBdpDocumentation && this.seriesReplacedBdpDocumentation) {
      this.setCurrentStatusForm();
      this.setReplacedStatusForm();
    }
  }

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  private createStatusForm() {
    this.currentStatusForm = this.formBuilder.group({
      bdpName: new UntypedFormControl(''),
      vpVersion: new UntypedFormControl(''),
      seriesNumber: new UntypedFormControl(''),
      username: new UntypedFormControl(''),
    });
    this.replacedBdpStatusForm = this.formBuilder.group({
      bdpName: new UntypedFormControl(''),
      vpVersion: new UntypedFormControl(''),
      seriesNumber: new UntypedFormControl(''),
      username: new UntypedFormControl(''),
    });

    this.setCurrentStatusForm();
    this.setReplacedStatusForm();
  }

  setCurrentStatusForm() {
    const filteredList = this.seriesBdpDocumentation?.content?.filter(
      (data) => data.sdpName
    );
    this.currentStatusForm
      ?.get('bdpName')
      ?.setValue(this.seriesBdpDocumentation?.name?.split('_')[1]);
    this.currentStatusForm
      ?.get('vpVersion')
      ?.setValue(this.seriesBdpDocumentation?.vpVersion);
    this.currentStatusForm
      ?.get('username')
      ?.setValue(this.seriesBdpDocumentation?.username);
    this.currentStatusForm?.get('seriesNumber')?.setValue(filteredList?.length);
  }

  setReplacedStatusForm() {
    const filteredList = this.seriesReplacedBdpDocumentation?.content?.filter(
      (data) => data.sdpName
    );
    this.replacedBdpStatusForm
      ?.get('bdpName')
      ?.setValue(this.seriesReplacedBdpDocumentation?.name?.split('_')[1]);
    this.replacedBdpStatusForm
      ?.get('vpVersion')
      ?.setValue(this.seriesReplacedBdpDocumentation?.vpVersion);
    this.replacedBdpStatusForm
      ?.get('username')
      ?.setValue(this.seriesReplacedBdpDocumentation?.username);
    this.replacedBdpStatusForm
      ?.get('seriesNumber')
      ?.setValue(filteredList?.length);
  }

  handleClose() {
    this.close.emit();
  }

  handleNext() {
    this.next.emit();
  }
}
