import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { CalculationService } from '@core/services/calculation.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { StorageService } from '@core/services/storage.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOption } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import { TypeExtension } from '@shared/lists/flashFileExtension';
import { DeterminedResultEdit } from '@shared/models/determinedResultEdit';
import {
  RcddbLookupBr,
} from '@shared/models/filetype/RcddbLookupBr';
import { ModalResult } from '@shared/models/ModalResult';

@Component({
  selector: 'app-fcsc-determined-flashware-insert',
  templateUrl: './fcsc-determined-flashware-insert.component.html',
  styleUrls: ['./fcsc-determined-flashware-insert.component.scss'],
})
export class FcscDeterminedFlashwareInsertComponent implements OnInit {
  @Input() showDiv = {
    current: true,
    next: false,
  };

  typeItems!: NgOption[];
  isLookupSelected!: boolean;
  @Input() data!: DeterminedResultEdit | null;
  isEdit!: boolean;
  @Output() sendDataToFirstView = new EventEmitter<string>();
  form!: UntypedFormGroup;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private steuerdatenService: SteuerdatenService,
    private storageService: StorageService,
    private appService: AppService,
    private notificationService: ErrorHandlerService,
    private translateService: TranslocoService,
    private userSettingService: UserSettingService,
    private calculationService: CalculationService
  ) {}

  ngOnInit(): void {
    this.typeItems = TypeExtension;
    this.createForm();
  }

  private createForm() {
    this.isEdit = this.data !== null;
    this.form = this.formBuilder.group({
      SG_FITTINGFLASHSW: new UntypedFormControl(
        this.isEdit ? this.data?.SG_FITTINGFLASHSW : ''
      ),
      SG_FITTINGFLASHSW_PRIO: new UntypedFormControl(
        this.isEdit
          ? {
              value: this.data?.SG_FITTINGFLASHSW_PRIO,
              disabled: this.isLookupSelected,
            }
          : { value: '', disabled: this.isLookupSelected }
      ),
      SG_FITTINGFLASHSW_SIZE: new UntypedFormControl(
        this.isEdit
          ? {
              value: this.data?.SG_FITTINGFLASHSW_SIZE,
              disabled: this.isLookupSelected,
            }
          : { value: '', disabled: this.isLookupSelected }
      ),
      flashweg: new UntypedFormControl(
        this.isEdit
          ? { value: this.data?.flashweg, disabled: this.isLookupSelected }
          : { value: '', disabled: this.isLookupSelected }
      ),
      type: new UntypedFormControl(
        this.isEdit
          ? {
              value: this.appService.getFlashType(),
              disabled: this.isLookupSelected,
            }
          : {
              value: this.appService.getFlashType(),
              disabled: this.isLookupSelected,
            }
      ),
      latebound: new UntypedFormControl(
        this.isEdit
          ? { value: this.data?.latebound, disabled: this.isLookupSelected }
          : { value: '', disabled: this.isLookupSelected }
      ),
      selected: new UntypedFormControl(this.isEdit ? this.data?.selected : true),
      selectedLookup: new UntypedFormControl(false),
    });
    if (!this.data?.SG_FITTINGFLASHSW) {
      this.form.get('selectedLookup')?.disable();
    }

    this.form.get('SG_FITTINGFLASHSW')?.valueChanges.subscribe((value) => {
      if (!value) {
        this.form.get('selectedLookup')?.disable();
      } else {
        this.form.get('selectedLookup')?.enable();
      }
    });
  }

  handleFields(isSelected) {
    if (isSelected) {
      this.form.get('SG_FITTINGFLASHSW_PRIO')?.disable();
      this.form.get('SG_FITTINGFLASHSW_SIZE')?.disable();
      this.form.get('latebound')?.disable();
      this.form.get('flashweg')?.disable();
      this.form.get('type')?.disable();
    } else {
      this.form.get('SG_FITTINGFLASHSW_PRIO')?.enable();
      this.form.get('SG_FITTINGFLASHSW_SIZE')?.enable();
      this.form.get('latebound')?.enable();
      this.form.get('flashweg')?.enable();
      this.form.get('type')?.enable();
    }
  }

  handleLookupChange() {
    const isSelected = this.form.get('selectedLookup')?.value;
    this.handleFields(isSelected);
    if (isSelected) {
      this.getRcddbLookup();
    }
  }

  async getRcddbLookup() {
    const flashForm =
      this.storageService.getData(sessionStorageKeys.flashForm) || {};
    const sdpName = flashForm.sdpName;
    const xentryRelease = flashForm.xentryReleases;
    let sgFittingFlashValue = this.form.get('SG_FITTINGFLASHSW')?.value;
    sgFittingFlashValue = sgFittingFlashValue?.split('_')[0].replace(/A/g, '');
    this.steuerdatenService
      .getRcddbLookup(sdpName, [sgFittingFlashValue], xentryRelease)
      .subscribe(
        (data: RcddbLookupBr[]) => {
          let foundData = data.find(
            (item) =>
              (item.snr && item.snr.split('_')[0]) === sgFittingFlashValue
          );
          if (foundData) {
            if (foundData?.zgs && foundData?.zgs.length !== 0){
              foundData.snr = foundData.snr?.split('_')[0].toString() + '_' + foundData.zgs
            }
            this.form.get('SG_FITTINGFLASHSW')?.setValue(foundData.snr?.toString())
            this.form
              .get('SG_FITTINGFLASHSW_PRIO')
              ?.setValue(foundData.priority?.toString());
            this.form
              .get('SG_FITTINGFLASHSW_SIZE')
              ?.setValue(foundData.totalSize?.toString());
            this.form.get('flashweg')?.setValue(foundData.weg?.toString());
            this.form.get('latebound')?.setValue(foundData.sourceFileName);
          } else {
            this.form.get('SG_FITTINGFLASHSW_PRIO')?.setValue('');
            this.form.get('SG_FITTINGFLASHSW_SIZE')?.setValue('');
            this.form.get('flashweg')?.setValue('');
            this.form.get('latebound')?.setValue('');
          }
        },
        (error: HttpErrorResponse) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.admin-section.config-modal.insert-edit-api-error-message'
          );
        }
      );
  }

  async sendDataToFirstViewFunc() {
    const flashForm =
      this.storageService.getData(sessionStorageKeys.flashForm) || {};
    const sdpName = flashForm.sdpName;
    const xentryRelease = flashForm.xentryReleases;
    let sgFittingFlashValue = this.form.get('SG_FITTINGFLASHSW')?.value;
    sgFittingFlashValue = sgFittingFlashValue?.split('_')[0].replace(/A/g, '');
    this.steuerdatenService
      .getRcddbLookup(sdpName, [sgFittingFlashValue], xentryRelease)
      .subscribe(
        (data: RcddbLookupBr[]) => {
          if (data) {
            const rcddbLookup = data[0];
            const fittingFlash = this.form.get('SG_FITTINGFLASHSW')?.value;
            let fittingValue = fittingFlash?.replace(/A/g, '');
           
            const valueObject = {
              SG_FITTINGFLASHSW: fittingValue,
              zgs : rcddbLookup ? rcddbLookup.zgs : undefined,
              SG_FITTINGFLASHSW_PRIO: this.form.get('SG_FITTINGFLASHSW_PRIO')?.value,
              SG_FITTINGFLASHSW_SIZE: this.form.get('SG_FITTINGFLASHSW_SIZE')?.value,
              SG_NEWFLASHSW: fittingValue,
              flashweg: this.form.get('flashweg')?.value,
              type: this.form.get('type')?.value ,
              latebound: this.form.get('latebound')?.value,
              selected: this.form.get('selected')?.value,
              selectedLookup: this.form.get('selectedLookup')?.value,
              xentryUpdate: rcddbLookup ? rcddbLookup.xentryUpdate : '',
            };
            if (this.isEdit) {
              valueObject['id'] = this.data?.id;
            }
            this.sendDataToFirstView.emit(valueObject as any);
          }
        },
        (error: HttpErrorResponse) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.admin-section.config-modal.insert-edit-api-error-message'
          );
        }
      );
  }

  public backToFirstView(): void {
    this.showDiv.current = true;
    this.showDiv.next = false;
  }

  close(): void {
    this.activeModal.close(ModalResult.ACTIVATION_CANCEL);
  }
}
