<div class="modal-header">
  <span class="modal-title">{{ "modules.expert-tool.result.modal.multiimport-flash-title" | transloco }}</span>
</div>

<div class="modal-sub-text">
  {{ "modules.expert-tool.result.modal.multiimport-flash-subtitle-1" | transloco }}
</div>

<div class="modal-body">
  <div class="import-modal">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12 form-group">
          <label for="field">{{
              "modules.expert-tool.result.labels.flashware" | transloco
            }}</label>
          <div class="chips">
            <app-chip (chipsChanged)="onChipsChanged($event)" (chipsUpdated)="onUpdateChips($event)"></app-chip>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-start">
          <div class="displayed">
            <button
              *ngIf="showImport.next"
              [ngStyle]="{ display: showImport.next ? 'display' : 'none' }"
              type="button"
              class="btn btn-secondary back"
              (click)="backToFirstView()"
            >
              {{ "global.back" | transloco }}
            </button>
          </div>
        </div>
        <div class="button-area float-end">
          <div class="displayed">
            <button
              *ngIf="showImport.next"
              [ngStyle]="{ display: showImport.next ? 'display' : 'none' }"
              type="button"
              class="btn btn-primary"
              (click)="save()"
              [disabled]="!buttonEnabled"
            >
              {{ 'modules.expert-tool.btn-apply-changes' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
