<nav class="header-container">
  <div class="header-left">
    <div>
      <span class="vpo">Verbaubarkeitsprüfung-Online (VPO)</span>
    </div>
  </div>
  <div class="header-right">
    <app-language-switch></app-language-switch>
    <div class="user-info">
      <span 
        class="custom"
        *appRequiredEntitlements="kibanaEntitlement"
        title="{{
          'components.header.kibana' | transloco
        }}"
        (click)="navigateToKibana()"
      >
        <i class="bi bi-file-earmark-bar-graph-fill"></i>
      </span>
      <span 
        class="custom"
        title="{{
          'components.header.usermanual-download' | transloco
        }}"
        (click)="getUserManual()"
      >
        <i class="bi bi-question-circle-fill"></i>
      </span>
      <span
        class="custom"
        title="{{
          'Version - Info'
        }}"
        #popover="ngbPopover"
        [ngbPopover]="popContent"
      >
        <i class="bi bi-info-square-fill"></i>
      </span>

      <span
        class="custom"
        title="{{
          'components.header.entitlements-view' | transloco
        }}"
        (click)="viewEntitlements()"
      >
        <i class="bi bi-person-fill"></i>
      </span>

      <span
        (click)="viewEntitlements()"
        class="profile"
        placement="bottom"
        [ngbTooltip]="'components.header.entitlements-view' | transloco"
        data-container="body"
        container="body"
        tooltipClass="note-tooltip"
        >{{ username }}</span
      >
    </div>
    <div class="signout">
      <span 
        (click)="logout()"
      >
        <i class="bi bi-box-arrow-right"></i>
      </span>
    </div>
  </div>
</nav>
<ng-template #popContent>
  <app-version [popover]="popover"></app-version>
</ng-template>
<app-breadcrumb
  class="breadcrumb-pos"
  [application]="application"
  *ngIf="application && application.menus.length > 0"
></app-breadcrumb>
