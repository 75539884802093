<div
  class="editable"
  [attr.data-primary-key]="row[primaryKey]"
  [attr.data-secondary-key]="row[secondaryKey]"
>
  <div
    *ngIf="disabled"
    [ngbTooltip]="toolTip"
    placement="bottom"
    container="body"
    data-container="body"
    tooltipClass="note-tooltip"
    class="disable-tooltip"
  ></div>

  <input
    [ngClass]="setStyling()"
    class="datatable-body-cell-input"
    type="text"
    readonly
    (dblclick)="enableEditing()"
    *ngIf="!editing[row[primaryKey]]"
    [disabled]="disabled || deleted"
    [value]="visiblePropertyValue"
    [ngbTooltip]="toolTip"
    placement="bottom"
    container="body"
    data-container="body"
    tooltipClass="note-tooltip"
    (keydown)="handleKeydown($event)"
  />

  <input
    #activeInput
    class="datatable-body-cell-input-active"
    type="text"
    (blur)="updateValue($event)"
    *ngIf="!longText && editing[row[primaryKey]]"
    [value]="cellPropertyValue"
    (keydown)="handleKeydown($event)"
  />

  <textarea
    #activeInput
    appendTo="body"
    rows="2"
    class="datatable-body-cell-input-active"
    (blur)="updateValue($event)"
    *ngIf="longText && editing[row[primaryKey]]"
    [value]="cellPropertyValue"
    (keydown)="handleKeydown($event)"
  ></textarea>
</div>
