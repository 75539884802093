<div class="modal-header">
    <span class="modal-title">{{title}}</span>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-12">
            {{message}}
        </div>
    </div>
</div>
<div class="modal-footer">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="float-right">
              <button type="button" class="btn btn-secondary" (click)="activeModal.dismiss()">{{ 'global.close' | transloco }}</button>
            </div>
          </div>
        </div>
      </div>
</div>