import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateFormat } from '@core/enums/date-format.enum';
import { FileName } from '@core/enums/file-name.enum';
import { System } from '@core/enums/system.enum';
import { AppService } from '@core/services/app.service';
import { CalculationLogFilesService } from '@core/services/calculation-log-file.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { LogHistoryService } from '@core/services/log-history.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { FcscUploadVorabAlertComponent } from '@fcsc/dialogs/fcsc-upload-vorab-alert/fcsc-upload-vorab-alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import {
  FilteredLogHistory,
  LogHistory,
  LogHistoryResponse,
} from '@shared/models/experttool/LogHistory';
import { Page } from '@shared/models/Page';
import { CheckBoxSelect } from '@shared/models/Select';
import { GeneralPageInfo, SortInfo } from '@shared/models/SortInfo';
import { Spinner } from '@shared/models/spinner';
import { SelectionType } from '@swimlane/ngx-datatable';
import moment from 'moment';

import { FcscFilterLogHistoryComponent } from './components/fcsc-filter-log-history/fcsc-filter-log-history.component';

@Component({
  selector: 'app-fcsc-fw-log-history',
  templateUrl: './fcsc-fw-log-history.component.html',
  styleUrls: ['./fcsc-fw-log-history.component.scss'],
})
export class FcscFwLogHistoryComponent implements OnInit {
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;
  page = new Page();
  sortConfig!: Array<{}>;
  paginationDataSetting!: any;
  params: GeneralPageInfo | undefined;
  logHistoryData: LogHistory[] = [];
  selected: LogHistory[] = [];
  finalSelectedMap = {};
  s3KeyList = [''];
  dataTableSelection = SelectionType.checkbox;
  emptyMessage = 'modules.expert-tool.empty-table-result-modal';
  selectedFilter!: FilteredLogHistory;
  response!: LogHistoryResponse | null;
  isNaN = Number.isNaN;
  form!: UntypedFormGroup;
  @ViewChild('filterComponent')
  filterComponent!: FcscFilterLogHistoryComponent;
  s3KeyNotExisting: string[] = [];
  baureihe!: string;

  constructor(
    public downloadService: DownloadService,
    public notificationService: ErrorHandlerService,
    public translocoService: TranslocoService,
    public appService: AppService,
    public logHistoryService: LogHistoryService,
    public modalService: NgbModal,
    public calculationLogFilesService: CalculationLogFilesService,
    private userSettingService: UserSettingService
  ) {}

  ngOnInit(): void {
    this.handleSpinner();
    this.refreshDataTable();
  }

  handleSpinner() {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };

    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
  }

  public getSystemValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(System[value as keyof typeof System])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  public getFileNameValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(FileName[value as keyof typeof FileName])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  /*  endpoint integration */

  loadContent() {
    this.logHistoryData = (this.response && this.response?.content) || [];
    const filteredList = this.logHistoryData.filter(
      (lhd) => lhd.alreadySelected
    );
    const s3Keys = this.logHistoryData.map((val) => val.s3Key);
    this.selected = [...filteredList];
    const s3NotExisting: string[] = [];

    this.s3KeyList.forEach((s3Key) => {
      if (!s3Keys.includes(s3Key)) {
        s3NotExisting.push(s3Key);
      }
    });
    this.s3KeyNotExisting = s3NotExisting;
    const filteredListCopy = JSON.parse(JSON.stringify(filteredList));

    this.finalSelectedMap[this.page.pageNumber] = filteredListCopy.map(
      (val) => val.s3Key
    );
  }

  async retrieveLogHistoryData(s3Key: string[], params?: FilteredLogHistory) {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    const s3KeyCheck = s3Key ? s3Key : [];
    this.logHistoryService.postLogHistory(s3KeyCheck, params).subscribe(
      (data: HttpResponse<LogHistoryResponse>) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.response = data.body;
        this.loadContent();
      },
      (error: HttpErrorResponse) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.data-management.serien-daten.modal.bdp-activation-error-message'
        );
      }
    );
  }

  onSelect(event: CheckBoxSelect): void {
    this.selected = [...event.selected];
    const selectedS3Keys = this.selected.map((item) => item.s3Key);
    this.finalSelectedMap[this.page.pageNumber] = selectedS3Keys;
    this.retrieveDataOnPageChange(this.page);
  }

  private resultS3Keys() {
    const keys = Object.keys(this.finalSelectedMap);
    let finalselectedS3Keys: any = [];
    keys.forEach((key) => {
      finalselectedS3Keys = finalselectedS3Keys.concat(
        this.finalSelectedMap[key]
      );
    });
    const s3Keys = [...new Set(finalselectedS3Keys)].concat(
      this.s3KeyNotExisting
    );

    const result: any[] = s3Keys.filter(
      (item, pos) => s3Keys.indexOf(item) === pos
    );
    this.s3KeyList = result.filter((el) => el !== '');
    return result;
  }

  /*  buttons */

  public exportFile(file: LogHistory) {
    this.logHistoryService.getPresignedUrlForSingleFile(file).subscribe(
      (response: any) => {
        const url = response?.presignedUrl.toString();
        this.calculationLogFilesService
          .getExportFileFromPresignedUrl(url)
          .subscribe(
            (res: Blob) => {
              this.downloadService.downloadFile(
                res,
                `${file.vin}${this.getFileNameValue(file.fileName)}`
              );
            },
            (error) => {
              this.notificationService.handleServerErrorNotification(
                error,
                'modules.admin-section.config-modal.insert-edit-api-error-message'
              );
            }
          );
      },
      (error) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  public downloadZipData() {
    if (this.selected.length) {
      this.logHistoryService
        .putPresignedUrlForZipFiles(this.s3KeyList)
        .subscribe(
          (response: any) => {
            const url = response?.presignedUrl.toString();
            this.calculationLogFilesService
              .getExportFileFromPresignedUrl(url)
              .subscribe(
                (res: Blob) => {
                  this.downloadService.createAndDownloadFile(
                    res,
                    `FCSC_Logs`,
                    '.zip'
                  );
                },
                (error) => {
                  this.notificationService.handleServerErrorNotification(
                    error,
                    'modules.admin-section.config-modal.insert-edit-api-error-message'
                  );
                }
              );
          },
          (error) => {
            this.notificationService.handleServerErrorNotification(
              error,
              'modules.admin-section.config-modal.insert-edit-api-error-message'
            );
          }
        );
    }
  }

  /*  pagination functionality */

  private async refreshDataTable(filter?, pageNotRefresh?: boolean) {
    await this.configPageInfoAfterInit();
    await this.retrieveLogHistoryData(
      this.resultS3Keys(),
      filter ? filter : this.params
    );
  }

  private async retrieveDataOnPageChange(page) {
    const filter = this.selectedFilter || {};
    const sortConf = [this.page.orderBy, this.page.orderDir].toString();

    if (page) {
      filter.page = (+this.page.pageNumber - 1).toString();
      filter.size = this.page.size.toString();
      filter.sort = sortConf.toString();
      this.selectedFilter = filter;
    } else {
      filter.page = '0';
      filter.size = this.page.size.toString();
      filter.sort = sortConf.toString();
      this.selectedFilter = filter;
    }

    this.appService.resetDatatableScroll();
    await this.retrieveLogHistoryData(this.resultS3Keys(), filter);

    const value = {
      size: filter.size,
      sort: filter.sort,
    };
    this.saveUserSetting(value);
  }

  public async setFilter(event: FilteredLogHistory) {
    if (!event) {
      this.logHistoryData = [];
    }

    const filter: any = {};
    await this.configPageFilterInfoAfterInit(filter);
    filter.vin = event && event.vin ? event.vin : '';
    filter.baureihe = event && event.baureihe ? event.baureihe : '';
    filter.request = event && event.request ? event.request : '';
    filter.fileName = event && event.fileName ? event.fileName : '';
    filter.system = event && event.system ? event.system : '';
    filter.createdAtFrom =
      event && event.createdAtFrom ? event.createdAtFrom : '';
    filter.createdAtUntil =
      event && event.createdAtUntil ? event.createdAtUntil : '';

    if (
      filter.createdAtFrom &&
      filter.createdAtUntil &&
      (moment(filter.createdAtFrom).isAfter(filter.createdAtUntil) ||
        moment(filter.createdAtUntil).isBefore(filter.createdAtFrom))
    ) {
      this.notificationService.handleServerErrorNotification(
        null,
        'modules.expert-tool.log-history.filter.from-to-date-validation'
      );
      return true;
    } else {
      this.selectedFilter = { ...this.selectedFilter, ...filter };
      await this.retrieveLogHistoryData(this.resultS3Keys(), filter);

      const value = {
        size: filter.size,
        sort: filter.sort,
      };
      this.saveUserSetting(value);
      return;
    }
  }

  public setPageSize(size) {
    this.page.pageNumber = 1;
    this.page.size = size;
    this.retrieveDataOnPageChange(null);
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.page;
    this.retrieveDataOnPageChange(this.page);
  }

  public sortCallback(sortInfo: SortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.retrieveDataOnPageChange(this.page);
  }

  // Saves user data on user interaction on sort, filter and page change.
  private saveUserSetting(data) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.experttool.logHistoryTable,
      data
    );
  }

  // Gets the user data or loads default ones.
  private async configPageInfoAfterInit() {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.paginationDataSetting =
      (await this.userSettingService.fetchUserSetting(
        userSettingKeys.experttool.logHistoryTable
      )) || {};

    const { sort, size } = this.paginationDataSetting;
    // Sort
    const sortData = sort || 'createdAt,desc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    this.sortConfig = [{ prop: orderBy, dir: orderDir }];

    // Order
    this.page.orderDir = orderDir;
    this.page.orderBy = orderBy;

    // Page
    this.page.pageNumber = 1;

    // Size
    this.page.size = this.page.size = size || 30;

    const sortConfArr = [this.page.orderBy, this.page.orderDir].toString();

    this.params = {
      page: '0',
      size: size || '30',
      sort: sortConfArr,
    };
  }

  private async configPageFilterInfoAfterInit(filter: any) {
    await this.configPageInfoAfterInit();
    const { sort, size } = this.paginationDataSetting;
    const sortData = sort || 'createdAt,desc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    const sortConfigFilter = [orderBy, orderDir];
    filter.page = '0';
    filter.size = size || '30';
    filter.sort = sortConfigFilter.toString();
  }
}
