export const ExceludedResponseAttributeKeys = [
  'id',
  'selected',
  'disabled',
  'FW_MBS',
  'SG_Current_SW',
  'FW_KETTE',
  'Flashbedarf',
  'SG_DiogName',
  'SG_REFNR',
  'VP_Methode',
  'SG_FITTINGFLASHSW_SIZE',
  'SG_FITTINGFLASHSW_PRIO',
  'SG_FITTINGFLASHSW_SIZE',
  'SG_FITTING_FWINFO',
  'ECU_Refer',
  'SG_NEWFLASHSW',
  'SG_FITTINGFLASHSW',
  'FW_AddonKand',
  'SG_NEWFLASHSW_PRIO',
  'SG_NEWFLASHSW_FLASHWEG',
];

export const ExceludedVedocAttrib = ['VIN'];

export const ExceludedResponseCalculateFW = [
  'Flashbedarf',
  'ECU_Refer',
  'SG_FITTINGFLASHSW',
  'FW_KETTE',
];

export const ExceludedResponseDownloadKeys = [
  'disabled',
  'attributes',
  'KOMMENTAR_AKTTAB',
];

export const ExceludedFinlogPopulation = [
  'SG_DiogName',
  'SG_REFNR',
  'SG_Current_SW',
  'Vp_Rate_Modules',
  'id',
];

export const ExceludedDynamicKeys = ['FW_MBS'];
