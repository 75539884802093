import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@core/services/env.service';
import { apiPaths } from '@env/paths';
import { IActuatorInfo } from '@shared/models/ActuatorInfo';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { sanitizeUrl } from 'src/app/core/utils/sanitizeUrl/sanitizeUrl';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  private actuatorInfoApi = '';
  private datamanagerBaseUrl = '';
  private steuerungBaseUrl = '';

  constructor(private http: HttpClient, public envService: EnvService) {
    this.datamanagerBaseUrl = `${envService.apiUrl}${apiPaths.fcscDatamanager}`;
    this.steuerungBaseUrl = `${envService.apiUrl}${apiPaths.fcscSteuerung}`;
    this.actuatorInfoApi = '/actuator/info';
  }

  public getDataManagerVersion(): Observable<string> {
    return this.getVersion(`${this.datamanagerBaseUrl}${this.actuatorInfoApi}`);
  }

  public getSteuerungVersion(): Observable<string> {
    return this.getVersion(`${this.steuerungBaseUrl}${this.actuatorInfoApi}`);
  }

  public getUiVersion(): Observable<string> {
    return this.getVersion('actuator/info');
  }

  private getVersion(url: string): Observable<string> {
    return this.http
      .get<IActuatorInfo>(sanitizeUrl(url))
      .pipe(
        map(
          (actuatorInfoResponse) =>
            actuatorInfoResponse.version || actuatorInfoResponse.build.version
        )
      );
  }
}
