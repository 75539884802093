import { Status, StatusColor } from '../../enums/status-color.enum';

export function getStatusColor(status: string) {
  switch (status) {
    case Status.SMOKE_TEST_FAILED:
      return StatusColor.RED;
    case Status.SMOKE_TEST_FAILED_TO_START:
      return StatusColor.RED;
    case Status.SMOKE_TEST_PASSED:
      return StatusColor.YELLOW;
    case Status.IN_SERIES:
      return StatusColor.GREEN;
    case Status.INITIAL:
      return StatusColor.DEFAULT;
    case Status.SMOKE_TEST_FINLOG_NOT_FOUND:
      return StatusColor.RED;
      case Status.ABORTED:
        return StatusColor.RED;
    default:
      return StatusColor.DEFAULT;
  }
}
