import { Injectable } from '@angular/core';
import { MediaType } from '@shared/constants/MediaType';
import { saveAs as importedSaveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor() {}

  public downloadFile(blob: Blob, filename: string) {
    importedSaveAs(blob, filename);
  }

  public createAndDownloadFile(
    textOrResponse: any,
    fileName: any,
    extention: string
  ) {
    const fileBlob = new Blob([textOrResponse as Blob], {
      type: 'application/octet-stream',
    });

    this.downloadFile(fileBlob, fileName + extention);
  }
}
