<div class="modal-header">
  <span class="modal-title col-md-6">{{
    "components.header.modal.entitlements" | transloco
  }}</span>
  <div (click)="activeModal.dismiss()" class="col-md-6">
    <span
      class="close-icon"
      icon="times"
    >
      <i class="bi bi-x"></i>
    </span>
  </div>
</div>

<div class="modal-header">
  <span class="col">{{
    "components.header.modal.entitlements-sub-title" | transloco
  }}</span>

  <div class="col round-button-container">
    <span
      title="{{
        'components.header.modal.entitlements-btn-placeholder' | transloco
      }}"
      (click)="copyEntitlements(entitlementsList)"
      class="main-icon"
    >
      <i class="bi bi-copy"></i>
    </span>
  </div>
</div>

<ngx-datatable
        #dataTable
        class="material datatable-scrollable"
        [columnMode]="'flex'"
        [headerHeight]="56"
        rowHeight="auto"
        [scrollbarH]="false"
        [scrollbarV]="false"
        [rows]="userEntitlements"
      >
        <ngx-datatable-column
            [cellClass]="'text-right'"
            name="Entitlements"
            [sortable]="false"
            [flexGrow]="3"
            [resizeable]="false"
            prop="entitlementName"
          >
          </ngx-datatable-column>
        </ngx-datatable>
