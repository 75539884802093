import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {apiPaths} from "@env/paths";
import { SnsTopic } from '@shared/models/sns-notification/sns-topic';
import { SnsTopicSubscriber } from '@shared/models/sns-notification/sns-topic-subscriber';
import { Observable } from 'rxjs';
import {EnvService} from "@core/services/env.service";

@Injectable({
  providedIn: 'root',
})
export class SnsNotificationService {
  private topicRoute = 'api/topic';
  private subscriberRoute = 'subscriber';
  private subscriptionManagerBaseUrl= '';

  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.subscriptionManagerBaseUrl = `${envService.apiUrl}${apiPaths.fcscSubscriptionManager}`;
  }


  get(): Observable<SnsTopic[]> {
    return this.httpClient.get<SnsTopic[]>(
      `${this.subscriptionManagerBaseUrl}/${this.topicRoute}`
    );
  }

  getSubscribersByTopicName(
    topicId: string
  ): Observable<SnsTopicSubscriber[]> {
    return this.httpClient.get<SnsTopicSubscriber[]>(
      `${this.subscriptionManagerBaseUrl}/${this.topicRoute}/${topicId}/${this.subscriberRoute}`
    );
  }

  deleteSubscriber(topicName: string, subscriberId: string) {
    return this.httpClient.delete(
      `${this.subscriptionManagerBaseUrl}/${this.topicRoute}/${topicName}/${this.subscriberRoute}/${subscriberId}`
    );
  }

  saveSubscriber(
    topicName: string,
    subscriberEmail: string
  ): Observable<SnsTopicSubscriber> {
    return this.httpClient.post<SnsTopicSubscriber>(
      `${this.subscriptionManagerBaseUrl}/${this.topicRoute}/${topicName}/${this.subscriberRoute}`,
      { email: subscriberEmail }
    );
  }
}
