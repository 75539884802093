<p class="mt-4"></p>

<div id="calculate-result" class="outer-container">
  <div>
    <span class="title">{{
      "modules.expert-tool.result.title" | transloco
    }}</span>
    <span
      class="caret-spacer"
      (click)="
        isCalculateSectionCollapsed = !isCalculateSectionCollapsed;
        populateData()
      "
    >
      <span *ngIf="isCalculateSectionCollapsed"><i class="bi bi-caret-down-fill"></i></span>
      <span *ngIf="!isCalculateSectionCollapsed"><i class="bi bi-caret-up-fill"></i></span>
    </span>
  </div>
  <div [ngbCollapse]="!isCalculateSectionCollapsed">
    <div class="row row-cols-auto">
      <div class="col">
        <div class="form-group">
          <label class="vp-status" for="status">{{
            "modules.expert-tool.result.vp-status" | transloco
          }}</label>
          <div [ngClass]="vpStatusClass">
            {{ vpStatus }}
            <span class="vp-status-span" *ngIf="vpStatusText">
              -
              {{ vpStatusText | transloco }}</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row control-units-table">
      <div class="col-12">
        <div>
          <div class="result">
            <span class="sub-title">{{
              "modules.expert-tool.result.flashware" | transloco
            }}</span>
          </div>
          <div class="row">
            <div class="round-button-container">
              <span 
                *ngIf="hasFSFManipulatorRights"
                title="{{
                  'modules.expert-tool.insert-new-line' | transloco
                }}"
                (click)="openFlashwareResponseModal()"
              >
                <i class="bi bi-plus-circle-fill"></i>
              </span>
            </div>
          </div>

          <ngx-datatable
            #dataTable
            class="material datatable-scrollable"
            [columnMode]="'flex'"
            [headerHeight]="56"
            rowHeight="auto"
            [rows]="rows"
            [scrollbarH]="false"
            [sorts]="sortConfig"
            [selected]="selected"
            (page)="onPageChange($event)"
            [messages]="{ emptyMessage: emptyMessage }"
            [selectionType]="dataTableSelection"
            [selectAllRowsOnPage]="false"
            (select)="onSelect($event)"
            (sort)="onSortCallback($event)"
            [footerHeight]="56"
            [limit]="pageSize"
          >
            <ngx-datatable-column
              [cellClass]="'text-center'"
              [headerClass]="'checkbox-sort'"
              name=""
              prop="selected"
              [flexGrow]="0"
              [sortable]="true"
              [canAutoResize]="false"
              [draggable]="false"
              [resizeable]="false"
              [width]="50"
              *ngIf="hasFSFManipulatorRights"
            >
              <ng-template
                ngx-datatable-header-template
                let-value="value"
                let-allRowsSelected="allRowsSelected"
                let-selectFn="selectFn"
              >
                <label class="checkbox-container">
                  <input
                    type="checkbox"
                    checked="checked"
                    [checked]="allRowsSelected"
                    (change)="selectFn(!allRowsSelected)"
                  />
                  <span class="checkmark"></span>
                </label>
              </ng-template>
              <ng-template
                ngx-datatable-cell-template
                let-value="value"
                let-isSelected="isSelected"
                let-onCheckboxChangeFn="onCheckboxChangeFn"
              >
                <label class="checkbox-container">
                  <input
                    type="checkbox"
                    [checked]="isSelected"
                    (change)="onCheckboxChangeFn($event)"
                  />
                  <span class="checkmark"></span>
                </label>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'modules.expert-tool.diog-name' | transloco }}"
              prop="SG_DiogName"
              [flexGrow]="4.5"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
              >
                <inline-editing-cell
                  (updateDatatable)="updateDatatable($event)"
                  [primaryKey]="'id'"
                  [secondaryKey]="'SG_DiogName'"
                  [row]="row"
                  [cellPropertyName]="'SG_DiogName'"
                  [cellPropertyValue]="value"
                  [data]="rows"
                  [disabled]="!hasFSFManipulatorRights"
                  [orignalData]="records"
                >
                </inline-editing-cell>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.expert-tool.table-header.diog-name'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [cellClass]="'text-right'"
              name="{{ 'modules.expert-tool.hwsnr' | transloco }}"
              prop="SG_REFNR"
              [flexGrow]="3.5"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
              >
                <inline-editing-cell
                  (updateDatatable)="updateDatatable($event)"
                  [row]="row"
                  [primaryKey]="'id'"
                  [cellPropertyName]="'SG_REFNR'"
                  [cellPropertyValue]="value"
                  [data]="rows"
                  [orignalData]="records"
                  [disabled]="!hasFSFManipulatorRights"
                  [validationSingleHighlightKey]="'SG_REFNR'"
                >
                </inline-editing-cell>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.expert-tool.table-header.hwsnr'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{
                'modules.expert-tool.result.datatable.ecu-ref' | transloco
              }}"
              prop="ECU_Refer"
              [flexGrow]="4"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
              >
                <inline-editing-cell
                  (updateDatatable)="updateDatatable($event)"
                  [primaryKey]="'id'"
                  [row]="row"
                  [cellPropertyName]="'ECU_Refer'"
                  [cellPropertyValue]="value"
                  [data]="rows"
                  [orignalData]="records"
                  [disabled]="!hasFSFManipulatorRights"
                  [longText]="true"
                >
                </inline-editing-cell>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.expert-tool.result.labels.ecu-ref'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'modules.expert-tool.fw-on-cu' | transloco }}"
              prop="FW_MBS"
              [flexGrow]="9"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
              >
                <inline-editing-cell
                  (updateDatatable)="updateDatatable($event)"
                  [primaryKey]="'id'"
                  [row]="row"
                  [cellPropertyName]="'FW_MBS'"
                  [cellPropertyValue]="value"
                  [data]="rows"
                  [orignalData]="records"
                  [longText]="true"
                  [disabled]="!hasFSFManipulatorRights"
                  [validationHighlightKey]="'FW_MBS'"
                >
                </inline-editing-cell>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.expert-tool.table-header.fw-on-cu'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{
                'modules.expert-tool.result.datatable.identify' | transloco
              }}"
              prop="SG_FITTINGFLASHSW"
              [flexGrow]="9"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
              >
                <inline-editing-cell
                  (updateDatatable)="updateDatatable($event)"
                  [primaryKey]="'id'"
                  [row]="row"
                  [cellPropertyName]="'SG_FITTINGFLASHSW'"
                  [cellPropertyValue]="value"
                  [data]="rows"
                  [disabled]="!hasFSFManipulatorRights"
                  [orignalData]="records"
                  [longText]="true"
                >
                </inline-editing-cell>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.expert-tool.result.labels.identify'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{
                'modules.expert-tool.result.datatable.etkette' | transloco
              }}"
              prop="FW_KETTE"
              [flexGrow]="10"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
              >
                <inline-editing-cell
                  (updateDatatable)="updateDatatable($event)"
                  [primaryKey]="'id'"
                  [row]="row"
                  [cellPropertyName]="'FW_KETTE'"
                  [cellPropertyValue]="value"
                  [data]="rows"
                  [disabled]="!hasFSFManipulatorRights"
                  [orignalData]="records"
                  [longText]="true"
                >
                </inline-editing-cell>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.expert-tool.result.labels.etkette'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{
                'modules.expert-tool.result.datatable.flash-requirement'
                  | transloco
              }}"
              prop="Flashbedarf"
              [flexGrow]="5"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
              >
                <span *ngIf="value">{{ value | transloco }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.expert-tool.result.datatable.flash-requirement'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'modules.expert-tool.result.datatable.vp' | transloco }}"
              prop="VP_Methode"
              [flexGrow]="3"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
                let-rowIndex="rowIndex"
              >
                <span *ngIf="value">{{ value }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.expert-tool.result.labels.vp'
                      | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [cellClass]="'text-center'"
              name="{{ 'global.actions' | transloco }}"
              [flexGrow]="4"
              [sortable]="false"
              [resizeable]="false"
              *ngIf="hasFSFManipulatorRights"

            >
              <ng-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <span 
                  title="{{
                    'modules.expert-tool.tooltip.edit-row' | transloco
                  }}"
                  (click)="openFlashwareResponseModal(row, rowIndex)"
                >
                  <i class="bi bi-pencil-fill"></i>
                </span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                ngx-datatable-header-template
              >
                <span
                  ngbTooltip="{{
                    'global.actions' | transloco: { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-footer *ngIf="true">
              <ng-template
                ngx-datatable-footer-template
                let-rowCount="rowCount"
                let-pageSize="pageSize"
                let-selectedCount="selectedCount"
                let-offset="offset"
                let-curPage="curPage"
              >
                <div class="col-7">
                  <datatable-pager
                    [pagerLeftArrowIcon]="'datatable-icon-left'"
                    [pagerRightArrowIcon]="'datatable-icon-right'"
                    [pagerPreviousIcon]="'datatable-icon-prev'"
                    [pagerNextIcon]="'datatable-icon-skip'"
                    [page]="curPage"
                    [size]="pageSize"
                    [count]="rowCount"
                    (change)="dataTable.onFooterPage($event)"
                  >
                  </datatable-pager>
                </div>

                <div class="col-5 page-result-section client-side">
                  <span class="float-end">
                    <span class="result-text">{{
                      "components.footer.result-per-page" | transloco
                    }}</span>
                    <span
                      class="result-value"
                      (click)="setPageSize(10)"
                      [ngClass]="{
                        active:
                          pageSize === 10 && selectedSize !== allPageObject.value
                      }"
                    >
                      10 </span
                    >|
                    <span
                      class="result-value"
                      (click)="setPageSize(30)"
                      [ngClass]="{
                        active:
                          pageSize === 30 && selectedSize !== allPageObject.value
                      }"
                    >
                      30 </span
                    >|
                    <span
                      class="result-value"
                      (click)="setPageSize(100)"
                      [ngClass]="{
                        active:
                          pageSize === 100 &&
                          selectedSize !== allPageObject.value
                      }"
                    >
                      100 </span
                    >|
                    <span
                      class="result-value margin-override"
                      (click)="setPageSize()"
                      [ngClass]="{
                        active: selectedSize === allPageObject.value
                      }"
                      >{{ allPageObject.label }}</span
                    >
                    <span>
                      {{ "components.footer.files" | transloco }}:
                      {{
                        rowCount === 0 ? rowCount : (curPage - 1) * pageSize + 1
                      }}
                      -
                      {{
                        curPage * pageSize > rowCount
                          ? rowCount
                          : curPage * pageSize
                      }}
                    </span>
                    <span>
                      {{ "components.footer.of" | transloco }}
                      {{ rowCount }}</span
                    >
                  </span>
                </div>
              </ng-template>
            </ngx-datatable-footer>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="checkRecordsLength()" class="mt-3">
    <app-upload-duration
      [isError]="true"
      [translationKey]="'global.validation.table-cell-10'"
    ></app-upload-duration>
  </div>
</div>
<app-fcsc-result-finlog
  [calculationData]="calculationData"
  [rows]="selected"
  [initialFwRes]="initialFwRes"
  [vedocData]="vedocData"
></app-fcsc-result-finlog>
