import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@env/paths';

import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class UserManualService {
  private fcscUserManual = '';

  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.fcscUserManual = `${envService.apiUrl}${apiPaths.fcscUserManual}`;
  }

  public getExportFileFromPresignedUrl(url: string) {
    return this.httpClient.get(url, {
      responseType: 'blob',
    });
  }

  public getPresignedUrlForUserManualFiles() {
    const currentLanguageKey = localStorage.getItem('language');
    const url = `${this.fcscUserManual}/${currentLanguageKey}/user-manual.pdf`;
    return this.httpClient.get(url, {
      responseType: 'json',
    });
  }
}
