export const i18nValues = {
  en: {
    weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
    months: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
  de: {
    weekdays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
    months: [
      'Jan',
      'Feb',
      'März',
      'Apr',
      'Mai',
      'Jun',
      'Jul',
      'Aug',
      'Sept',
      'Okt',
      'Nov',
      'Dez',
    ],
  },
};
