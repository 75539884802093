<div *ngIf="currentSdp && currentSdp.status !== 'IN_QUEUE'">
  <div class="fixed-width">
    <div class="row row-cols-auto mt-5">
      <div class="loading-bar-container">
        <app-loading-bar [text]="getBarStatusText()"></app-loading-bar>
      </div>

      <button
        type="button"
        class="btn btn-secondary"
        (click)="openQueueModal()"
      >
        {{ "modules.data-management.modal-open-series-queue" | transloco }}
      </button>
    </div>
  </div>

  <div class="mt-4">
    <form [formGroup]="form">
      <div class="fixed-width">
        <div class="row row-cols-auto">
          <div
            *ngIf="currentSdp && currentSdp.sdpNames.length === 1"
            class="col-5"
          >
            <div class="form-group">
              <label for="baureihe">{{
                "modules.data-management.table-header.series" | transloco
              }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="baureiheInQueue"
                formControlName="baureihe"
                readonly
              />
            </div>
          </div>

          <div
            *ngIf="currentSdp && currentSdp.sdpNames.length > 1"
            class="col-5"
          >
            <div class="form-group">
              <label for="baureihe">{{
                "modules.data-management.table-header.series" | transloco
              }}</label>

              <textarea
                class="form-control small-size"
                id="baureiheInQueueText"
                formControlName="baureihe"
                rows="3"
                readonly
              ></textarea>
            </div>
          </div>

          <div
            *ngIf="currentSdp && currentSdp.sdpNames.length === 1"
            class="col-5"
          >
            <div class="form-group">
              <label for="sdp">{{
                "modules.data-management.table-header.sd-packet" | transloco
              }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="sdpInQueue"
                formControlName="sdp"
                readonly
              />
            </div>
          </div>

          <div
            *ngIf="currentSdp && currentSdp.sdpNames.length > 1"
            class="col-5"
          >
            <div class="form-group">
              <label for="sdp">{{
                "modules.data-management.table-header.sd-packet" | transloco
              }}</label>

              <textarea
                class="form-control small-size"
                id="sdpText"
                formControlName="sdp"
                rows="3"
                readonly
              ></textarea>
            </div>
          </div>
        </div>
        <p></p>
        <div class="row row-cols-auto">
          <div class="col-4">
            <div class="form-group">
              <label for="status">{{ "global.status" | transloco }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="statusInQueue"
                formControlName="status"
                readonly
              />
            </div>
          </div>

          <div class="col-4">
            <div class="form-group">
              <label for="uploaded_at">{{
                "modules.data-management.table-header.start-time" | transloco
              }}</label>

              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="createdAtInQueue"
                formControlName="createdAt"
                readonly
              />
            </div>
          </div>

          <div class="col-2 user">
            <div class="form-group">
              <label for="username">{{
                "modules.data-management.table-header.user" | transloco
              }}</label>
              <input
                type="text"
                autocomplete="off"
                class="form-control small-size"
                id="usernameInQueue"
                formControlName="username"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
      <p></p>
      <div class="row">
        <div class="col-12 mt-1" *ngIf="CheckFailedSdpAcivation()">
          <app-upload-duration
            [isError]="true"
            [translationKey]="
              'modules.data-management.serien-daten.spd-queue.status-error-message'
            "
          ></app-upload-duration>
        </div>
      </div>
    </form>
  </div>
</div>
