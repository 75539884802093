<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<div class="col-5">
  <div class="outer-container sub-section">
    <div>
      <span class="modal-title">{{
        "modules.admin-section.codes.title" | transloco
      }}</span>
    </div>

    <div>
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <div class="col-6">
                <label for="adminFileCode">{{
                  "modules.admin-section.codes.sub-title" | transloco
                }}</label>

                <input
                  formControlName="adminFileCode"
                  type="text"
                  autocomplete="off"
                  class="form-control"
                  id="adminFileCode"
                  [readonly]="true"
                />

                <span class="custom-icon">
                  <span
                    class="custom"
                    title="{{
                      'modules.admin-section.codes.download-icon'
                        | transloco
                    }}"
                    (click)="exportFile()"  
                  >
                    <i class="bi bi-download"></i>
                  </span>
                </span>
              </div>
            </div>

            <div
              [ngStyle]="{ visibility: showDiv.current ? 'visible' : 'hidden' }"
            >
              <p class="text-body-custom">
                {{
                  "modules.admin-section.codes.description"
                    | transloco
                }}
              </p>
            </div>

            <div class="form-group center">
              <div class="disable-box"></div>
              <div *ngIf="showDiv.current">
                <ngx-file-drop
                  [directory]="false"
                  (onFileDrop)="dropped($event)"
                >
                  <ng-template
                    ngx-file-drop-content-tmp
                    let-openFileSelector="openFileSelector"
                  >
                    <div *ngIf="showDiv.current" (click)="openFileSelector()">
                      <ul class="list-group droparea">
                        <li class="list-group-item">
                          <span>
                            <i class="bi bi-arrows-angle-expand"></i>
                          </span>
                        </li>
                        <li class="list-group-item">Drag&Drop</li>
                        <li class="list-group-item item-text">
                          &nbsp;{{
                            "modules.data-management.label-or" | transloco
                          }}&nbsp;
                        </li>
                        <li class="list-group-item">
                          <span>
                            <i class="bi bi-search"></i>
                          </span>
                        </li>
                        <li class="list-group-item">
                          {{
                            "modules.data-management.label-browse" | transloco
                          }}
                        </li>
                      </ul>
                    </div>
                  </ng-template>
                </ngx-file-drop>
              </div>

              <div class="scroll-container">
                <div class="upload-area" *ngIf="showDiv.next">
                  <ul
                    class="list-group list-group-horizontal col-12"
                    *ngFor="let item of filesData; let i = index"
                  >
                    <li class="list-group-item col-6">{{ item.name }}</li>
                    <li class="list-group-item col-4">
                      {{ item.modified | date: "dd.MM.yy HH:mm:ss":'UTC' }}
                    </li>
                    <li class="list-group-item col-2">
                      <span *ngIf="item.valid === true">
                        <i class="bi bi-check"></i>
                      </span>
                    </li>
                  </ul>
                </div>
                <div class="scroll-space"></div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div>
      <div class="row">
        <div class="col-12">
          <div *ngIf="showDiv.next" class="button-area float-start">
            <div class="displayed">
              <button
                type="button"
                class="btn btn-secondary back"
                (click)="back()"
              >
                {{ "global.back" | transloco }}
              </button>
            </div>
          </div>

          <div class="button-area float-end">
            <div class="displayed">
              <button
                type="button"
                class="btn btn-primary"
                (click)="saveChanges()"
                [disabled]="showDiv.current"
              >
                {{
                  "modules.admin-section.codes.btn-title" | transloco
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
