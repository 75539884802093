import {
  Component, DoCheck,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { ValidationService } from '@core/services/validation.service';
import { TranslocoService } from '@ngneat/transloco';
import { inlineStyles } from '@shared/constants/inlineEdiingStyles';

@Component({
  selector: 'inline-editing-cell',
  templateUrl: './inline-editing-cell.component.html',
  styleUrls: ['./inline-editing-cell.component.scss'],
})
export class InlineEditingCellComponent implements OnInit, DoCheck{
  @ViewChild('activeInput') activeInput!: ElementRef;
  // define action to update data in parent-component
  @Output() updateDatatable = new EventEmitter<any[]>();
  // get cellpropertyname
  @Input() cellPropertyName!: string;
  // primaryKey
  @Input() primaryKey!: string | number;
  @Input() secondaryKey!: string | number;
  // method show/hide icon/function
  @Input() cellPropertyValue!: string;
  // data-array from parent-component
  @Input() data!: any[];
  // maxLenght of formfield
  @Input() maxLenght!: number;
  // is longText to truncate value
  @Input() longText = false;
  // set disabled-flag
  @Input() disabled = false;
  // set deleted-flag for styling
  @Input() deleted = false;

  @Input() row!: any;

  @Input() orignalData!: any[];

  // handle validation in table cell
  @Input() validationHighlightKey!: string;

  // handle validation in table cell for single entry
  @Input() validationSingleHighlightKey!: string;

  editing: any = {};
  visiblePropertyValue!: string;
  toolTip = '';

  constructor(
    private translocoService: TranslocoService,
    private notificationService: ErrorHandlerService,
    private validationService: ValidationService
  ) {}

  ngOnInit() {
    this.editing = {};
    this.setVisiblePropertyValue();
  }
  ngDoCheck() {
    this.setVisiblePropertyValue();
  }

  enableEditing() {
    const key: string | undefined = this.row[this.primaryKey]?.toString();
    if (key) {
      this.editing[key] = true;
    }

    // Timeout needs to be there -> element isn't in dom yet
    setTimeout(() => {
      this.activeInput.nativeElement.focus();
    });
  }

  updateValue(event: Event) {
    const val = (event.target as HTMLInputElement)?.value;

    if (val && this.secondaryKey) {
      const foundData = this.data.find((dt) => dt[this.secondaryKey] === val);
      if (foundData) {
        (event.target as HTMLInputElement).value = this.row[this.secondaryKey];
      }
    } else if (!val) {
      (event.target as HTMLInputElement).value = this.row[this.secondaryKey];
      if (this.secondaryKey) {
        this.notificationService.showError(
          '',
          this.translocoService.translate(
            'modules.expert-tool.diog-name-empty-valdation'
          )
        );
      }
    }

    this.editing[this.row[this.primaryKey]?.toString() as string] = false;

    if (this.hasValueChanged((event.target as HTMLInputElement)?.value)) {
      const val = (event.target as HTMLInputElement)?.value.trim();
      this.findDataByPrimaryKey()[this.cellPropertyName] =
        val === 'undefined' ? '' : val;

      this.cellPropertyValue =
        this.findDataByPrimaryKey()[this.cellPropertyName];
      this.setVisiblePropertyValue();

      this.updateDatatable.emit([
        this.data,
        this.cellPropertyName,
        this.cellPropertyValue,
      ]);
    }
  }

  setStyling(): string {
    if (
      this.checkRecordsLength() !== true &&
      this.findOrignalDataByPrimaryKey() &&
      this.findOrignalDataByPrimaryKey()[this.cellPropertyName] !==
        this.findDataByPrimaryKey()[this.cellPropertyName]
    ) {
      return `${inlineStyles.original} ${inlineStyles.baseEdited} ${inlineStyles.edited}`;
    }
    if (this.checkRecordsLength() === true) {
      return `${inlineStyles.original} ${inlineStyles.baseEdited} ${inlineStyles.validated}`;
    }
    return inlineStyles.original;
  }

  handleKeydown(event) {
    if (event.keyCode === 13) {
      this.updateValue(event);
      event.preventDefault();
      return;
    }
  }

  private truncateValue(): void {
    this.visiblePropertyValue =
      this.cellPropertyValue && this.cellPropertyValue.length > 46
        ? this.cellPropertyValue.substring(0, 44) + '...'
        : this.cellPropertyValue;
  }

  private setVisiblePropertyValue(): void {
    this.cellPropertyValue = this.cellPropertyValue || '';

    if (this.longText) {
      this.truncateValue();
      this.toolTip = this.cellPropertyValue;
    } else {
      this.visiblePropertyValue = this.cellPropertyValue;
    }
  }

  private findDataByPrimaryKey() {
    return this.data.find(
      (item) => item[this.primaryKey] === this.row[this.primaryKey]
    );
  }

  private findOrignalDataByPrimaryKey() {
    return this.orignalData?.find(
      (item) => item[this.primaryKey] === this.row[this.primaryKey]
    );
  }

  private hasValueChanged(value: string) {
    return this.findDataByPrimaryKey()[this.cellPropertyName] !== value;
  }

  private checkRecordsLength(): boolean | null {
    const entriesDataArr: any = [];
    entriesDataArr.push(this.findDataByPrimaryKey());
    if (this.validationHighlightKey) {
      const newRec = entriesDataArr?.map((item) => {
        const newItem =
          item &&
          item[this.validationHighlightKey] &&
          item[this.validationHighlightKey]
            .split(/(\s+)/)
            .filter((e) => e.trim().length > 0);
        return newItem;
      });
      return this.validationService.validateDataTableRecord(newRec);
    } else if (this.validationSingleHighlightKey) {
      const newRec = entriesDataArr?.map((item) => {
        return item[this.validationSingleHighlightKey]
          .split(/(\s+)/)
          .filter((e) => e.trim().length > 0);
      });
      return this.validationService.validateDataTableSingleRecord(newRec);
    }
    return null;
  }
}
