export class Menu {
  constructor(
    public title: string,
    public path: string,
    public defaultPage: boolean = false,
    public entitlementKey?: string[],
    public subMenu?: Menu[],
    public redirectTo?: string
  ) {}
}
