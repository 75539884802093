<div class="modal-header">
  <span class="modal-title">{{
    "modules.data-management.test-data.vp5.create.title" | transloco
  }}</span>
</div>

<div class="modal-sub-text">
  {{ "modules.data-management.test-data.vp5.create.sub-title" | transloco }}
</div>

<div class="modal-body" *ngIf="formLoaded">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-6">
        <label for="name">{{
          "modules.data-management.test-data.vp5.create.name" | transloco
        }}</label>
        <input
          appTrim
          readonly
          autocomplete="off"
          class="form-control"
          id="name"
          formControlName="name"
        />
      </div>

      <div class="col-3">
        <div class="form-group">
          <label for="version">{{
            "modules.data-management.test-data.vp5.vp5-version" | transloco
          }}</label>
          <ng-select
            [searchable]="true"
            [clearable]="false"
            notFoundText="{{ 'global.dropdown-not-found-text' | transloco }}"
            [hideSelected]="true"
            [items]="vp5TagsItems"
            [searchFn]="onSearchFn"
            bindLabel="name"
            bindValue="name"
            formControlName="version"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              {{ item.name }}
            </ng-template>
            <ng-template
              ng-label-tmp
              ng-option-selected
              let-item="item"
              let-index="index"
            >
              {{ item.name }}
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 form-group">
        <label for="comment">{{ "global.comment" | transloco }}</label>
        <textarea
          class="form-control"
          id="comment"
          formControlName="comment"
          maxlength="100"
          rows="3"
        ></textarea>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-end">
          <div class="displayed">
            <button type="button" class="btn btn-secondary" (click)="cancel()">
              {{ "global.cancel" | transloco }}
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="save()"
              [disabled]="this.checkSelection()"
            >
              {{
                "modules.data-management.test-data.vp5.create.btn-create"
                  | transloco
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
