<div class="col-5">
  <div class="outer-container">
    <div class="row">
      <div class="col-12">
        <div class="vus-container">
          <ngx-datatable #notificationTable
                         class="material"
                         [columnMode]="'flex'"
                         [rows]="topics"
                         [draggable]="false"
                         [headerHeight]="50"
                         [rowHeight]="'auto'"
                         [loadingIndicator]="isLoading"
                         [sorts]="sortConfig">
            <ngx-datatable-column name="{{'modules.admin-section.sns-notification.name' | transloco}}" prop="name" [flexGrow]="2" [resizeable]="false">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{ value }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'modules.admin-section.sns-notification.description' | transloco}}" prop="description" [flexGrow]="5"
                                  [resizeable]="false">
              <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                <span>{{ value }}</span>
              </ng-template>
            </ngx-datatable-column>
            <ngx-datatable-column name="{{'modules.admin-section.sns-notification.actions' | transloco}}" [flexGrow]="1" [resizeable]="false">
              <ng-template let-row="row" let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
                <div class="action-column">
                  <span 
                    title="{{
                      'modules.admin-section.sns-notification.tool-tip' | transloco
                    }}"
                    (click)="openDetails(row.id, rowIndex)"
                  >
                    <i class="bi bi-pencil-fill"></i>
                  </span>
                </div>
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>
</div>
