<div class="modal-header">
  <span class="modal-title">
    {{ "modules.expert-tool.result.modal.dep-text" | transloco }}</span
  >
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      {{ "modules.expert-tool.result.modal.dep-subtext" | transloco }}
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-end">
          <div class="displayed">
            <button
              type="button"
              class="btn btn-secondary"
              (click)="ignoreDependencies()"
            >
              {{
                "modules.expert-tool.result.modal.dep-ignore-btn" | transloco
              }}
            </button>

            <button
              type="button"
              class="btn btn-secondary"
              (click)="editDependencies()"
            >
              {{ "modules.expert-tool.result.modal.dep-load-btn" | transloco }}
            </button>

            <button
              type="button"
              class="btn btn-primary"
              (click)="loadDataAndIdentifyFlashware()"
            >
              {{ "modules.expert-tool.result.modal.dep-edit-btn" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
