<div class="version-container">
    <div class="row">
        <div class="version-close" (click)="popover.close()">
            <span
                class="version-icon"
                icon="times"
                >
                <i class="bi bi-x"></i>
            </span>
        </div>

        <div class="version-header">Version-Info</div>
        <div class="col-12" [hidden]="uiVersion.length === 0">
            <label>UI&nbsp;</label><div class="version-values">{{uiVersion}}</div>
        </div>
        <div class="col-12" [hidden]="dataManagerVersion.length === 0">
            <label>Datamanager&nbsp;</label><div class="version-values">{{dataManagerVersion}}</div>
        </div>
        <div class="col-12" [hidden]="steuerungVersion.length === 0">
            <label>Steuerung&nbsp;</label><div class="version-values">{{steuerungVersion}}</div>
        </div>
        <div class="col-12" [hidden]="vpVersion.length === 0">
            <label>VP-Version&nbsp;</label><div class="version-values">{{vpVersion}}</div>
        </div>
    </div>
</div>
