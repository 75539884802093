import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@env/paths';
import { MediaType } from '@shared/constants/MediaType';
import {
  FilteredLogHistory,
  LogHistory,
  LogHistoryResponse,
} from '@shared/models/experttool/LogHistory';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class LogHistoryService {
  private baseUrl = '';
  private s3BaseUrl = '';

  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.baseUrl = `${envService.apiUrl}${apiPaths.fcscBerechnung}/api`;
    this.s3BaseUrl = `${envService.apiUrl}${apiPaths.fcscS3Service}`;
  }

  postLogHistory(
    s3Key: string[],
    parameters?: FilteredLogHistory
  ): Observable<HttpResponse<LogHistoryResponse>> {
    let paramsVar = new HttpParams();
    const url = `${this.baseUrl}/v1/logs`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    if (parameters) {
      const paramsObj = {};
      Object.keys(parameters).map((key) => {
        paramsObj[key] = parameters[key];
      });
      paramsVar = new HttpParams({ fromObject: paramsObj });
    }
    return this.httpClient.post<LogHistoryResponse>(url, s3Key, {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
      params: paramsVar,
    });
  }

  public getPresignedUrlForSingleFile(file: LogHistory) {
    const url = `${this.s3BaseUrl}/files/${file.s3Key}`;

    return this.httpClient.get(url, {
      responseType: 'json',
    });
  }

  public putPresignedUrlForZipFiles(s3Key: string[]) {
    const httpHeaders = new HttpHeaders({
      Accept: MediaType.zipMediaType,
      'Content-Type': MediaType.zipMediaType,
    });

    const url = `${this.s3BaseUrl}/files`;

    return this.httpClient.put(url, s3Key, {
      headers: httpHeaders,
      responseType: 'json',
    });
  }
}
