<div class="modal-header">
  <span *ngIf="!isEdit" class="modal-title">{{
    "modules.expert-tool.result.modal.determined-flash-title" | transloco
  }}</span>
  <span *ngIf="isEdit" class="modal-title">{{
    "modules.expert-tool.result.modal.determined-flash-edit-title" | transloco
  }}</span>
</div>

<div *ngIf="!isEdit" class="modal-sub-text">
  {{ "modules.expert-tool.result.modal.determined-flash-subtitle" | transloco }}
</div>

<div *ngIf="isEdit" class="modal-sub-text">
  {{
    "modules.expert-tool.result.modal.determined-flash-edit-subtitle"
      | transloco
  }}
</div>

<div class="modal-body">
  <form [formGroup]="form" #insertForm>
    <div class="row form-group">
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label for="SG_FITTINGFLASHSW">{{
              "modules.expert-tool.result.labels.flashware" | transloco
            }}</label>
            <input
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="SG_FITTINGFLASHSW"
              formControlName="SG_FITTINGFLASHSW"
            />
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="SG_FITTINGFLASHSW_PRIO">{{
              "modules.expert-tool.result.labels.prio" | transloco
            }}</label>
            <input
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="SG_FITTINGFLASHSW_PRIO"
              formControlName="SG_FITTINGFLASHSW_PRIO"
            />
          </div>
        </div>

        <div class="col-3">
          <div class="form-group">
            <label for="SG_FITTINGFLASHSW_SIZE">{{
              "modules.expert-tool.result.labels.size" | transloco
            }}</label>
            <input
              appTrim
              type="text"
              autocomplete="off"
              class="form-control small-size"
              id="SG_FITTINGFLASHSW_SIZE"
              formControlName="SG_FITTINGFLASHSW_SIZE"
            />
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label for="type">{{
              "modules.expert-tool.result.labels.type" | transloco
            }}</label>
            <ng-select
              [searchable]="false"
              [hideSelected]="true"
              [items]="typeItems"
              bindLabel="name"
              bindValue="name"
              formControlName="type"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                {{ item.name }}
              </ng-template>
              <ng-template
                ng-label-tmp
                ng-option-selected
                let-item="item"
                let-index="index"
              >
                {{ item.name }}
              </ng-template>
            </ng-select>
          </div>
        </div>
      </div>

      <div class="row add-space">
        <div class="col-3">
          <label for="latebound">{{
            "modules.expert-tool.result.labels.latebound" | transloco
          }}</label>
          <input
            appTrim
            type="text"
            autocomplete="off"
            class="form-control small-size"
            id="latebound"
            formControlName="latebound"
          />
        </div>

        <div class="col-3">
          <label for="flashweg">{{
            "modules.expert-tool.result.labels.flash-way" | transloco
          }}</label>
          <input
            appTrim
            type="text"
            autocomplete="off"
            class="form-control small-size"
            id="flashweg"
            formControlName="flashweg"
          />
        </div>
      </div>
      <div class="row add-space last">
        <div class="col-3">
          <div class="form-group">
            <label class="checkbox-container">
              <input type="checkbox" formControlName="selected" />
              <span class="checkmark"></span>
              <span class="lb-alignment">{{
                "modules.expert-tool.result.modal.determined-flash-checkbox"
                  | transloco
              }}</span>
            </label>
          </div>
        </div>

        <div class="col">
          <div class="form-group">
            <label class="checkbox-container">
              <input
                type="checkbox"
                formControlName="selectedLookup"
                (change)="handleLookupChange()"
              />
              <span class="checkmark"></span>
              <span class="lb-alignment">{{
                "modules.expert-tool.result.modal.flash-lookup-checkbox"
                  | transloco
              }}</span>
            </label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-start">
          <div class="displayed">
            <button
              *ngIf="showDiv.next"
              [ngStyle]="{ display: showDiv.next ? 'display' : 'none' }"
              type="button"
              class="btn btn-secondary back"
              (click)="backToFirstView()"
            >
              {{ "global.back" | transloco }}
            </button>
          </div>
        </div>

        <div class="button-area float-end">
          <div class="displayed">

            <button
            type="button"
            class="btn btn-secondary back"
            (click)="close()"
          >
            {{ "global.cancel" | transloco }}
          </button>

            <button
              *ngIf="showDiv.next"
              [ngStyle]="{ display: showDiv.next ? 'display' : 'none' }"
              type="button"
              class="btn btn-primary"
              (click)="sendDataToFirstViewFunc()"
            >
              {{ "modules.expert-tool.btn-apply-changes" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
