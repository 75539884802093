<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"></app-loading-spinner>
<div *ngIf="true" class="col-5 body">
  <p class="title">{{ "modules.admin-section.testautomation.test-cancel.test-running"  | transloco }} </p>
  <div class="cancel-running">
    <div class="bar">
      <app-loading-bar>
      </app-loading-bar>
    </div>
    <button class=" btn btn-secondary button" (click)="openConfirmationModal()">{{ "global.cancel"  | transloco }}
    </button>
  </div>
</div>
