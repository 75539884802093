import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DateFormat } from '@core/enums/date-format.enum';
import { Status } from '@core/enums/status-color.enum';
import { AppService } from '@core/services/app.service';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SecurityService } from '@core/services/security.service';
import { SharedSeriesService } from '@core/services/shared-series.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { StorageService } from '@core/services/storage.service';
import { getStatusColor } from '@core/utils/getStatusColor/getStatusColor';
import { entitlements } from '@env/entitlements';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { DataPool } from '@shared/models/filetype/Datapool';
import {
  SeriesSteuerDaten,
  SeriesSteuerDatenMap,
  SeriesSteuerDatenQueue,
} from '@shared/models/filetype/SeriesSteuerDaten';
import { ModalResult } from '@shared/models/ModalResult';
import { Spinner } from '@shared/models/spinner';
import moment from 'moment';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { Subscription, interval } from 'rxjs';

import { FcscSdpDetailsComponent } from '../fcsc-upload-vorab/components/fcsc-sdp-details/fcsc-sdp-details.component';
import { FcscEditSeriesModalComponent } from './components/fcsc-edit-series-modal/fcsc-edit-series-modal.component';
import { FcscMultiSeriesActivationMainModalComponent } from './components/fcsc-serien-bdp/components/mulli-series/fcsc-multi-series-activation-main-modal/fcsc-multi-series-activation-main-modal.component';

@Component({
  selector: 'app-fcsc-serien-images',
  templateUrl: './fcsc-serien-images.component.html',
  styleUrls: ['./fcsc-serien-images.component.scss'],
})
export class FcscSerienImagesComponent implements OnInit, OnDestroy {
  sortConfig = [{ prop: 'createdAt', dir: 'desc' }];
  steuerDaten: SeriesSteuerDatenMap[] = [];
  steuerDatenQueue: SeriesSteuerDatenQueue[] = [];
  text!: string;
  seriesList!: any;
  count: number;
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;

  public tableListEntitlement = [entitlements.VUS.FCSC_SERIEN_DATA];
  public zipExportEntitlement = [entitlements.VUS.FCSC_SERIEN_DATA_EXPORT];

  private queueRefreshSubscription!: Subscription;

  constructor(
    private steuerdatenService: SteuerdatenService,
    private modalService: NgbModal,
    private notificationService: ErrorHandlerService,
    private translocoService: TranslocoService,
    public sharedSeriesService: SharedSeriesService,
    public securityService: SecurityService,
    private appService: AppService,
    private downloadService: DownloadService,
    private datePipe: DatePipe,
    private storageService: StorageService
  ) {}

  ngOnInit(): void {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };

    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
    this.sharedSeriesService.getAdminSeriesList();
    setTimeout(() => {
      this.getSeriesSteuerDaten();
    }, 200);
    this.getSeriesSteuerDatenQueue();

    // refresh queue in 10s interval
    this.queueRefreshSubscription = interval(10000).subscribe(() => {
      this.getSeriesSteuerDatenQueue();
    });

  }

  ngOnDestroy() {
    this.queueRefreshSubscription.unsubscribe();
  }

  private getSeriesSteuerDaten(): void {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.steuerdatenService.getSeriesSteuerDaten().subscribe(
      (data: SeriesSteuerDaten[]) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.steuerDaten = data;
        this.steuerDaten = data
          ? this.enrichMissingBaureihen(this.steuerDaten)
          : this.enrichMissingBaureihen([]);
        this.count = this.steuerDaten.length;
      },
      (error) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.steuerDaten = this.enrichMissingBaureihen([]);
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }
  private enrichMissingBaureihen(rawSteuerDaten: SeriesSteuerDatenMap[]) {
    const seriesEntitlements = entitlements.VUS.FCSC_VORAB_IMAGE_BUILD_DEPLOY;
    if (
      this.securityService
        .getEntitlements()
        .some(() =>
          this.securityService.getEntitlements().includes(seriesEntitlements)
        )
    ) {
      this.seriesList = this.sharedSeriesService.getAdminSeriesList();
      return this.seriesList?.map((baureiheRaw) => {
        const baureiheIndex = rawSteuerDaten.findIndex(
          (sd) => baureiheRaw.brName.toUpperCase() === sd.baureihe.toUpperCase()
        );

        // Baureihe is missing in the raw steuer daten list
        if (baureiheIndex === -1) {
          return {
            bdpContentId: undefined,
            steuerDatenPaketId: undefined,
            baureihe: baureiheRaw.brName,
            createdAt: undefined,
            inSeriesSince: undefined,
            dataPool: undefined,
            name: undefined,
            note: undefined,
            status: undefined,
            username: undefined,
          };
        } else {
          return rawSteuerDaten[baureiheIndex];
        }
      });
    }
  }

  private getSeriesSteuerDatenQueue(): void {
    this.steuerdatenService.getBdpReleaseQueue().subscribe(
      (queue: SeriesSteuerDatenQueue[]) => {
        if (queue.length !== this.steuerDatenQueue.length) {
          this.refreshTable(true);
        }
        this.steuerDatenQueue = queue;
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  public editData(row: SeriesSteuerDatenMap): void {
    const modalRef = this.modalService.open(FcscEditSeriesModalComponent, {
      size: 'lg',
      windowClass: 'details-modal override',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.emitService.subscribe((emmitedValue) => {
      modalRef.componentInstance.notificationMessage =
        this.translocoService.translate(
          'modules.data-management.serien-daten.modal.bdp-activation-notification-title',
          {
            selected:
              emmitedValue && emmitedValue.name ? emmitedValue.name : '',
          }
        );

      modalRef.componentInstance.subTitleLast = this.translocoService.translate(
        'modules.data-management.serien-daten.modal.bdp-activation-sub-title',
        {
          selected: emmitedValue && emmitedValue.name ? emmitedValue.name : '',
        }
      );
    });
    const selectedRow: SeriesSteuerDatenMap = row;
    modalRef.componentInstance.titleCurrent = this.translocoService.translate(
      'modules.data-management.serien-daten.modal.bdp-title',
      { selected: selectedRow.baureihe }
    );

    modalRef.componentInstance.titleNext = this.translocoService.translate(
      'modules.data-management.serien-daten.modal.bdp-selection-title',
      { selected: selectedRow.baureihe }
    );

    modalRef.componentInstance.titleLast = this.translocoService.translate(
      'modules.data-management.serien-daten.modal.bdp-activation-title',
      { selected: selectedRow.baureihe }
    );

    modalRef.componentInstance.baureihe = selectedRow.baureihe;
    modalRef.componentInstance.bdpContentId = selectedRow.bdpContentId;

    modalRef.result.then((result) => {
      if (result && result.ok === ModalResult.ACTIVATION_SUCCESSFUL) {
        this.postQueue(
          result.form.controls.name?.value.split(','),
          result.form.controls.note.value,
          result.form.controls.reason.value
        );
      }
    });
  }

  public postQueue(sdpNames: string[], note: string, reason: string) {
    this.steuerdatenService
      .postBdpReleaseQueue(sdpNames, note, reason)
      .subscribe(
        () => {},
        (error: HttpErrorResponse) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.data-management.serien-daten.modal.bdp-activation-error-message'
          );
        }
      );
  }

  public isQueueExisting() {
    return this.steuerDatenQueue && this.steuerDatenQueue.length > 0;
  }

  public truncateNote(value: string): string {
    const isExpanded = value.length > 250;
    return value.substring(0, 250) + (isExpanded ? '...' : '');
  }

  sdPaketsDetails(row: SeriesSteuerDatenMap, value: string): void {
    const modalRef = this.modalService.open(FcscSdpDetailsComponent, {
      size: 'lg',
      windowClass: 'details-modal override',
      backdrop: 'static',
      keyboard: false,
    });
    const selectedRow: SeriesSteuerDatenMap = row;
    modalRef.componentInstance.title = this.translocoService.translate(
      'modules.data-management.sdp-details-title',
      { name: selectedRow.name }
    );
    modalRef.componentInstance.showCrossClose = true;
    modalRef.componentInstance.sdpName = value;
  }

  public exportSdpFiles(sdp: SeriesSteuerDatenMap): void {
    const selectedRowName = sdp.name;
    this.steuerdatenService
      .getExportFilesAsZipDownload(sdp.steuerDatenPaketId)
      .subscribe(
        (response) => {
          this.downloadService.createAndDownloadFile(
            response,
            `${selectedRowName}`,
            '.zip'
          );
        },
        (error: HttpErrorResponse) => {
          this.notificationService.handleServerErrorNotification(error, '');
        }
      );
  }

  public getColor(value: string): string {
    const enumValue = Status[value as keyof typeof Status];
    return getStatusColor(enumValue);
  }

  public getValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(Status[value as keyof typeof Status])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  public refreshTable(event): void {
    if (event) {
      this.getSeriesSteuerDaten();
    }
  }

  public openActivateMultipleSeries() {
    const modalRef = this.modalService.open(
      FcscMultiSeriesActivationMainModalComponent,
      {
        size: 'lg',
        windowClass: 'details-modal override',
        backdrop: 'static',
        keyboard: false,
      }
    );

    modalRef.result.then((result) => {
      if (result && result.ok === ModalResult.ACTIVATION_SUCCESSFUL) {
        this.postQueue(
          result.form.controls.name?.value.split(','),
          result.form.controls.note.value,
          result.form.controls.reason.value
        );
      }
    });
  }

  getHeaders(list: string[]): string[] {
    const translatedList = [];
    list.forEach((element) => {
      translatedList.push(this.translocoService.translate(element));
    });
    return translatedList;
  }

  public downloadCSV(): ngxCsv {
    const now = moment();
    const formula = 'series_data_' + now.format(DateFormat.CSV_EXPORT_FORMAT);
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
      removeEmptyValues: true,
      headers: this.getHeaders([
        'global.series',
        'global.sd-packet',
        'global.uploaddate',
        'modules.data-management.serien-daten.data-table.inSeriesSince',
        'System / User',
        'global.comment',
      ]),
    };
    const exportIncludeList = [
      'baureihe',
      'name',
      'createdAt',
      'inSeriesSince',
      'username',
      'note',
    ];

    const exportList: any = [];
    const steuerDaten = JSON.parse(JSON.stringify(this.steuerDaten));
    steuerDaten.forEach((data) => {
      (data.inSeriesSince as any) = data.inSeriesSince
        ? this.datePipe.transform(data.inSeriesSince, 'dd.MM.yy, HH:mm:ss')
        : '';
      (data.createdAt as any) = data.createdAt
        ? this.datePipe.transform(data.createdAt, 'dd.MM.yy')
        : '';
      const exportObj: any = {};
      exportIncludeList.map((key: any) => {
        exportObj[key] = data[key];
      });
      exportList.push(exportObj);
    });
    return new ngxCsv(exportList, formula, options);
  }
}
