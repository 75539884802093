import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppService } from '@core/services/app.service';
import { TranslocoService } from '@ngneat/transloco';
import { Application } from '@shared/models/Application';
import { Breadcrumb } from '@shared/models/Breadcrumb';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit, AfterViewInit {
  @Input() application!: Application;
  navItems: Breadcrumb[] = [];
  constructor(
    private router: Router,
    private appService: AppService,
    private translateService: TranslocoService
  ) {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.appService.application = this.application;
    setTimeout(() => {
      this.navItems = this.appService.getMenuItems();
    }, 100);
    this.router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe(() => {
        setTimeout(() => {
          this.navItems = this.appService.getMenuItems();
        }, 100);
      });

    this.appService.languageSubject.subscribe(() => {
      setTimeout(() => {
        this.navItems = this.appService.getMenuItems();
      }, 100);
    });
  }

  onItemSelect(navItem: string, index: number, isHome: boolean) {
    this.navItems = this.navItems.map((item, elementIndex) => {
      item.isSelected = elementIndex === index ? true : false;
      return item;
    });
    this.router.navigate([navItem]);
  }
}
