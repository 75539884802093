import {Component, OnDestroy, OnInit} from '@angular/core';
import {TestautomationService} from "@core/services/testautomation.service";
import {TestautomationTest, TestautomationTestSuccess} from "@shared/models/testautomation/TestautomationTest";
import {map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {HttpResponse} from "@angular/common/http";
import { SecurityService } from '@core/services/security.service';

@Component({
  selector: 'app-fcsc-admin-testautomation',
  templateUrl: './fcsc-admin-testautomation.component.html',
  styleUrls: ['./fcsc-admin-testautomation.component.scss']
})
export class FcscAdminTestautomationComponent implements OnInit, OnDestroy {
  isLoading = false;
  testRuns: TestautomationTest[] = [];
  loadingTitle: string;
  loadingSubtitle: string;
  private notifier = new Subject();
  recentTestRun: TestautomationTestSuccess;
  activeTestId: number;
  testIsRunning: boolean;

  constructor(
    private testautomationService: TestautomationService,
    private securityService: SecurityService,
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.loadingTitle = 'modules.admin-section.testautomation.spinner-title';
    this.loadingSubtitle = 'modules.admin-section.testautomation.spinner-subtitle';
    await this.getTests();
  }

  private getTests(): void {
    this.isLoading = true;
    this.testautomationService.getTestRuns()
      .subscribe(
        (response: TestautomationTest[]) => {
          let testresponse : any[] = [];
          response['success'].forEach(element => {
              element.status = "success";
              testresponse.push(element);
          });
          response['cancelled'].forEach(element => {
              testresponse.push(element);
          });
          response['inprogress'].forEach(element => {
              testresponse.push(element);
          });
          console.log(response['inprogress'])
          if (response['inprogress'].length > 0) {
              this.checkUntilTestStopped(response['inprogress'][0].id);
          }
          this.testRuns = testresponse;
          this.getRecentTestRun(response["success"]);
          this.getActiveTestRunId();
        },
        (error: any) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }

  checkUntilTestStopped(id): void {
    console.log(id)
    let counter = 0;
    const username = this.securityService.getCurrentUser().getUsername();
        const makeBackendCall = () => {
            counter++;
            console.log(counter);
            this.testautomationService.postActiveTestRunId(id, username).subscribe(
                (response: number) => {
                    console.log(response)
                    if (response !== null) {
                        setTimeout(() => {
                            makeBackendCall();
                        }, 3000);
                    }
                },
                (error: any) => {
                  console.error(error);
                }
            );
        };
        makeBackendCall();
}

  private getRecentTestRun(testRuns:TestautomationTestSuccess[]): void {
    if (testRuns.length > 0) {
      this.recentTestRun = testRuns.reduce((prev, current) => {
        let prevDate = new Date(prev.timeStamp);
        let currentDate = new Date(current.timeStamp);
        return currentDate > prevDate ? current : prev;
      });
    }
  }

  private getActiveTestRunId(){
    this.isLoading = true;
    this.testautomationService.getActiveTestRunId()
      .pipe(
        takeUntil(this.notifier)
      )
      .subscribe(
        (resp : number) =>{
          this.activeTestId = resp;
          this.checkForActiveTest(resp);
          this.isLoading = false;
        },
        (error: any) => {
          console.error(error);
          this.isLoading = false;
        }
      )
  }

  private checkForActiveTest(testId: number){
    testId === null ? this.testIsRunning = false : this.testIsRunning = true;
  }


  testStartedEvent(event) {
    this.testIsRunning = event;
    this.getTests();
  }

  ngOnDestroy() {
    this.notifier.complete();
  }
}
