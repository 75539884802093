import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SnsTopic } from '@shared/models/sns-notification/sns-topic';
import { SnsTopicSubscriber } from '@shared/models/sns-notification/sns-topic-subscriber';
import {SnsNotificationService} from "@core/services/sns-notification/sns-notification.service";
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationDetailComponent } from './components/notification-detail/notification-detail.component';
import { Spinner } from '@shared/models/spinner';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-fcsc-admin-notification-management',
  templateUrl: './fcsc-admin-notification-management.component.html',
  styleUrls: ['./fcsc-admin-notification-management.component.scss']
})
export class FcscAdminNotificationManagementComponent implements OnInit, OnDestroy {
  topics: SnsTopic[];
  isLoading = false;

  private ROOT_URL: string;
  private notifier = new Subject();
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;

  constructor(
    private modalService: NgbModal,
    private snsNotificationService: SnsNotificationService,
    private appService: AppService,
    public notificationService: ErrorHandlerService,
  ) {}

  ngOnInit() {
    this.handleSpinner();
    this.loadingTitle = 'modules.admin-section.sns-notification.spinner-title';
    this.loadingSubtitle =
      'modules.admin-section.sns-notification.spinner-subtitle';
    this.getTopics();
  }

  handleSpinner() {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };

    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
  }

  detail(event: { id: string; index: number }) {
   const snsTopic = this.topics.find((obj) => {
      return obj.id  === event.id
    })
    if (snsTopic){
    this.getSubscribersByTopicName(
      (snsTopic?.name || '')
    ).subscribe((subscribers: SnsTopicSubscriber[]) =>
      this.openDetailModal((snsTopic), subscribers)
    );
  }
  }

  private openDetailModal(topic: SnsTopic, subscribers: SnsTopicSubscriber[]) {
    const modalRef = this.modalService.open(NotificationDetailComponent, {
      size: 'lg',
      windowClass: 'details-modal',
      backdrop: 'static',
      keyboard: false
    });

    modalRef.componentInstance.topic = topic;
    modalRef.componentInstance.subscribers = subscribers;
    modalRef.componentInstance.ROOT_URL = this.ROOT_URL;
  }

  private getTopics() {
    this.isLoading = true;
    this.snsNotificationService
      .get()
      .pipe(takeUntil(this.notifier))
      .subscribe((notifications: SnsTopic[]) => {
        this.topics = this.reArchitecturedSetOfTopics(notifications)
        this.isLoading = false;
      },
      (error: HttpErrorResponse) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        ); 
      });
  }

  private getSubscribersByTopicName(
    topicName: string
  ): Observable<SnsTopicSubscriber[]> {
    return this.snsNotificationService
      .getSubscribersByTopicName(topicName)
      .pipe(takeUntil(this.notifier));
  }

  private reArchitecturedSetOfTopics(listOfTopic) {
    if (listOfTopic && listOfTopic.length > 0) {
      let listOfData = listOfTopic
      listOfData.forEach(element => {
        element.description = decodeURIComponent(atob(element.description).split('').map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
      });
      return listOfData;
    }
  }
  
  ngOnDestroy() {
    this.notifier.complete();
  }
}
