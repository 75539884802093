<h5>
    <div [ngClass]="getBackgroundLayerClass()">
        <div class="spinner-box">
            <div class="spinner-border spinner-border-sm spinner " role="status">
                <span class="sr-only"></span>
            </div>
            <h1>{{loadingTitle | transloco}}</h1>
            <p>{{loadingSubtitle | transloco}}</p>
        </div>
    </div>
</h5>
