import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { entitlements } from '@env/entitlements';
import { Subject } from 'rxjs';
import { SecurityService } from 'src/app/core/services/security.service';

@Directive({
  selector: '[appRequiredEntitlements]',
})
export class EntitlementDirective implements OnDestroy {
  entitlements!: string[];
  stop = new Subject();
  isVisible = false;

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private securityService: SecurityService
  ) {}

  @Input() set appRequiredEntitlements(requiredEntitlements: string[]) {
    this.entitlements = this.securityService.getEntitlements();
    if (!this.entitlements) {
      this.viewContainerRef.clear();
    }
    this.entitlements.push(entitlements.VUS.FCSC_PUBLIC);
    if (requiredEntitlements.some((item) => this.entitlements.includes(item))) {
      if (!this.isVisible) {
        this.isVisible = true;
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    } else {
      this.isVisible = false;
      this.viewContainerRef.clear();
    }
  }

  ngOnDestroy() {
    this.stop.next(entitlements);
  }
}
