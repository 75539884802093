export enum ModalResult {
  IMPORT_SUCCESSFUL,
  IMPORT_FAILED,
  IMPORT_FILE,
  IMPORT_CANCEL,
  ACTIVATION_SUCCESSFUL,
  ACTIVATION_CANCEL,
  INSERT_SUCCESSFUL,
  INSERT_CANCEL,
  DELETE_FAILED,
  DELETE_OK,
  DELETE_CANCEL,
  WARNING_OK,
  WARNING_CANCEL,
  DEPENDENCIES_IGNORE,
  DEPENDENCIES_EDIT,
  DEPENDENCIES_LOAD,
  BACK_VIEW,
  CREATE_BDP_TEST_CANCEL,
  CREATE_BDP_TEST,
}
