import { CalculateKeyValGeneric } from './CalculateKeyValGeneric';

export class CalculateFinlog {
  metadata: CalculateKeyValGeneric[];
  ecus: CalculateKeyValGeneric[];

  constructor(
    finlogMetadata: CalculateKeyValGeneric[],
    ecus: CalculateKeyValGeneric[]
  ) {
    this.metadata = finlogMetadata;
    this.ecus = ecus;
  }
}
