<div class="container">
  <div class="not-found">
    <div class="not-found__404">
      <img src=" ./assets/404/not-found.svg" alt="Not Found SVG" />
    </div>

    <div class="not-found__content">
      <span>{{ 'components.not-found.link-title' | transloco }} <a [href]="previousUrl">{{previousUrl}}</a></span>

      <h1 class="not-found__title">{{ 'components.not-found.title' | transloco }}</h1>

      <p class="not-found__text">
        {{ 'components.not-found.subtite-1' | transloco }}<br>
        {{ 'components.not-found.subtite-2' | transloco }}
      </p>

      <button type="button" class="btn btn-primary" id="reloadPage" (click)="redirectToHome()">{{ 'components.not-found.btn-back-to-home' | transloco }}</button>
    </div>
  </div>
</div>