export enum ErrorCodesFromServer {
  M1 = 'errorcodes.model.br-not-found',
  M2 = 'errorcodes.model.br-invalid',
  M3 = 'errorcodes.model.dp',
  M4 = 'errorcodes.model.v-not-found',
  M5 = 'errorcodes.model.v-invalid',
  M6 = 'errorcodes.model.bm-notfound',
  M7 = 'errorcodes.model.stdp',
  M8 = 'errorcodes.model.bdp',

  S1 = 'errorcodes.steuerung.bdp-content-is-empty',
  S2 = 'errorcodes.steuerung.bdp-history-creation-failed',
  S3 = 'errorcodes.steuerung.bdp-documentation-not-found',
  S7 = 'errorcodes.steuerung.smoketest-failed',
  S8 = 'errorcodes.steuerung.already-in-queue',
  S9 = 'errorcodes.steuerung.serien-bdp-history-not-found',
  S10 = 'errorcodes.steuerung.bdp-release-error',
  S11 = 'errorcodes.steuerung.steuerdatenpaket-detail-not-found',
  S12 = 'errorcodes.steuerung.bdp-release-not-found',
  S13 = 'errorcodes.steuerung.failed-to-detect-current-bdp',
  S14 = 'errorcodes.steuerung.baureihe-not-found',
  S15 = 'errorcodes.steuerung.smoketest-finlog-not-found-for-baureihe',
  S16 = 'errorcodes.steuerung.baureihe-already-exists',
  // S17 = 'errorcodes.steuerung.bdp-history-not-found',
  S30 = 'errorcodes.steuerung.shadow-mode-already-active',
  S17 = 'errorcodes.steuerung.data_manger_api_failed',
  'FV-204' = 'errorcodes.steuerung.vedoc-empty',
  'FV-403' = 'errorcodes.steuerung.vedoc-403',
  'FV-404' = 'errorcodes.steuerung.vedoc-404',
  S19 = 'errorcodes.steuerung.bdp-is-not-from-vorab-data-pool',
  S20 = 'errorcodes.steuerung.bdp-does-not-exist-in-ecr',
  S21 = 'errorcodes.steuerung.parse-csv-failed',
  S22 = 'errorcodes.steuerung.sdp-does-not-exist-in-series',
  D1 = 'errorcodes.datamanager.steuerdaten-paket-not-found',
  D3 = 'errorcodes.datamanager.invalid-input',
  D5 = 'errorcodes.datamanager.file-record-not-found',
  D6 = 'errorcodes.datamanager.gzip-unpack-failed',
  D7 = 'errorcodes.datamanager.steuerdaten-paket-name-is-not-unique',
  D8 = 'errorcodes.datamanager.steuerdaten-paket-was-in-series',
  C1 = 'errorcodes.fwcalcualtion.vp5-request-failed',
  C2 = 'errorcodes.fwcalcualtion.vedoc-enrichment-failed',

  Y1 = 'errorcodes.crypto.invalid-message-format-unmarshall',
  Y2 = 'errorcodes.crypto.invalid-message-format-finlog',
  Y3 = 'errorcodes.crypto.invalid-message-format-finlog-inbound',
  Y4 = 'errorcodes.crypto.invalid-message-format-mappings',
  Y5 = 'errorcodes.crypto.invalid-message-format-empty-payload',
  Y6 = 'errorcodes.crypto.encryption-failed-empty-payload',
  Y7 = 'errorcodes.crypto.encryption-failed-invalid-public-key',
  Y8 = 'errorcodes.crypto.encryption-failed-invalid-xml',
  Y9 = 'errorcodes.crypto.encryption-failed-private-key',
  Y10 = 'errorcodes.crypto.encryption-failed-aes',
  Y11 = 'errorcodes.crypto.decryption-failed-private-key',
  Y12 = 'errorcodes.crypto.decryption-failed-mappings',
  Y13 = 'errorcodes.crypto.decryption-failed-decrypt-message',
  Y14 = 'errorcodes.crypto.decryption-failed-decrypt-content',
  Y15 = 'errorcodes.crypto.decryption-failed-xml-content',
  Y16 = 'errorcodes.crypto.session-key-problem',
  Y17 = 'errorcodes.crypto.session-key-problem-empty-session',
  Y18 = 'errorcodes.crypto.cypher-problem',
  Y19 = 'errorcodes.crypto.cypher-problem-algorithm',
  Y20 = 'errorcodes.crypto.keystore-problem',
  Y21 = 'errorcodes.crypto.keystore-problem-file',
  Y22 = 'errorcodes.crypto.invalid-base64-encoding',
}
