import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { StatusSdpQueue } from '@core/enums/status-sdp-queue.enum';
import { TranslocoService } from '@ngneat/transloco';
import { SeriesSteuerDatenQueue } from '@shared/models/filetype/SeriesSteuerDaten';

@Component({
  selector: 'app-fcsc-rollback-part3',
  templateUrl: './fcsc-rollback-part3.component.html',
  styleUrls: ['./fcsc-rollback-part3.component.scss'],
})
export class FcscRollbackPart3Component implements OnInit {
  sortConfigQueue = [{ prop: 'baureihen', dir: 'desc' }];
  emailNotificationForm!: UntypedFormGroup;

  @Input() steuerDatenQueue: SeriesSteuerDatenQueue[] = [];
  @Input() showQueueDiv!: boolean;

  @Output() back: EventEmitter<any> = new EventEmitter();
  @Output() next: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor(
    private translocoService: TranslocoService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.createEmailNotificationForm();
  }

  public getStatusText(value?: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(StatusSdpQueue[value as keyof typeof StatusSdpQueue])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  private createEmailNotificationForm() {
    this.emailNotificationForm = this.formBuilder.group({
      selectedNotify: new UntypedFormControl(false),
    });
  }

  public truncateValue(value: string[]): string {
    const isExpanded = value.length > 1;
    return isExpanded
      ? value.join().substring(0, value[0].length) + '...'
      : value.join();
  }

  handleBack() {
    this.back.emit();
  }

  handleNext() {
    this.next.emit();
  }

  handleClose() {
    this.close.emit();
  }
}
