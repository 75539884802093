import { Component, Input, OnInit } from '@angular/core';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SecurityService } from '@core/services/security.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { CurrentUserEnt } from '@shared/models/CurrentUser';
import { ClipboardService } from 'ngx-clipboard';

@Component({
  selector: 'app-entitlements-modal',
  templateUrl: './entitlements-modal.component.html',
  styleUrls: ['./entitlements-modal.component.scss'],
})
export class EntitlementsModalComponent implements OnInit {
  @Input() entitlements: string[] = [];

  public entitlementsList: CurrentUserEnt[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public securityService: SecurityService,
    public clipboardService: ClipboardService,
    public notificationService: ErrorHandlerService,
    public translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.createEntitlementsRows();
  }

  private createEntitlementsRows(): void {
    let entitlementsList: CurrentUserEnt[] = [];
    entitlementsList = this.entitlements?.map((entitl, index) => {
      return new CurrentUserEnt(entitl, index);
    });
    this.entitlementsList = entitlementsList;
  }

  copyEntitlements(text: CurrentUserEnt[]): void {
    const entitlements = text.map((elem) => elem.entitlementName);
    let entitlementText = '';
    entitlements.forEach((entitlement) => {
      entitlementText = entitlementText + entitlement + '\n';
    });
    this.clipboardService.copy(entitlementText);
    this.notificationService.showSuccess(
      '',
      this.translocoService.translate(
        'components.header.modal.entitlements-copy-success'
      )
    );
  }
}
