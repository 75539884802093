<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<div class="modal-header">
  <span class="modal-title">
    {{ "modules.expert-tool.codes-modal.title" | transloco }}
  </span>
</div>

<div class="modal-sub-text">
  {{ "modules.expert-tool.codes-modal.sub-title" | transloco }}
</div>

<div class="modal-body">
  <app-fcsc-fw-vehicle-codes-filter
    #filterComponent
    (refreshVisibleTable)="setFilter($event)"
    [data]="vehicleCodeData"
  >
  </app-fcsc-fw-vehicle-codes-filter>
  <p></p>
  <form [formGroup]="form" >
  <div class="col">
    <div class="form-group">
      <label class="checkbox-container">
        <input type="checkbox"
        formControlName="selectedOriginalCodes"
        (change)="handleCodesCheckChange()" />
        <span class="checkmark"></span>
        <span class="lb-alignment">{{
          "modules.expert-tool.codes-modal.check-box" | transloco
        }}</span>
      </label>
    </div>
  </div>
</form>
  <p></p>
  <div class="row">
    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="vehicleCodeData"
          [selected]="selected"
          [selectionType]="dataTableSelection"
          [selectAllRowsOnPage]="false"
          (select)="onSelect($event)"
          [footerHeight]="56"
          (page)="setPage($event)"
          (sort)="sortCallback($event)"
          [sorts]="sortConfig"
          [messages]="{ emptyMessage: emptyMessage | transloco }"
        >
          <ngx-datatable-column
            [cellClass]="'text-center'"
            name=""
            prop="selected"
            [flexGrow]="0"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            [width]="24"
          >
            <ng-template
              ngx-datatable-cell-template
              let-value="value"
              let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn"
            >
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  [checked]="isSelected"
                  (change)="onCheckboxChangeFn($event)"
                />
                <span class="checkmark"></span>
              </label>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name=  "{{ 'modules.expert-tool.codes-modal.datatable.code' | transloco }}"
            prop="code"
            [flexGrow]=".5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              let-rowIndex="rowIndex"
              ngx-datatable-cell-template
            >
              <span
                *ngIf="value"
                class="text-pre-wrap"
                placement="bottom"
                [ngbTooltip]="value"
                data-container="body"
                container="body"
                tooltipClass="note-tooltip"
                >{{ value }}</span
              >
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'modules.expert-tool.codes-modal.datatable.code'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name= "{{ 'modules.expert-tool.codes-modal.datatable.description' | transloco }}"
            prop="description"
            [flexGrow]="4"
            [resizeable]="false"
            [sortable]="true"
          >
            <ng-template
              let-value="value"
              let-row="row"
              let-rowIndex="rowIndex"
              ngx-datatable-cell-template
            >
              <span
                *ngIf="value"
                class="text-pre-wrap"
                placement="bottom"
                [ngbTooltip]="value"
                data-container="body"
                container="body"
                tooltipClass="note-tooltip"
                >{{ value }}</span
              >
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'modules.expert-tool.codes-modal.datatable.description'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer *ngIf="true">
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-offset="offset"
              let-curPage="curPage"
            >
              <div class="col-8">
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [count]="response?.totalElements || 0"
                  [page]="page.pageNumber"
                  [size]="page.size"
                  (change)="setPage($event)"
                >
                </datatable-pager>
              </div>

              <div class="col-4 page-result-section server-side">
                <span class="float-end">
                  <span class="result-text">{{
                    "components.footer.result-per-page" | transloco
                  }}</span>
                  <span
                    class="result-value"
                    [ngClass]="{ active: page.size === 10 }"
                    (click)="setPageSize(10)"
                    >10</span
                  >|
                  <span
                    class="result-value margin-override"
                    [ngClass]="{ active: page.size === 30 }"
                    (click)="setPageSize(30)"
                    >30</span
                  >|
                  <span
                    class="result-value"
                    [ngClass]="{ active: page.size === 100 }"
                    (click)="setPageSize(100)"
                    >100</span
                  >
                  <span>
                    {{ "components.footer.files" | transloco }}:
                    {{
                      response && (response.totalElements || 0) > 0
                        ? (response?.number || 0) * page.size + 1
                        : 0
                    }}
                    -
                    {{
                      isNaN(
                        (response?.number || 0) * page.size +
                          (response?.numberOfElements || 0)
                      )
                        ? 0
                        : (response?.number || 0) * page.size +
                          (response?.numberOfElements || 0)
                    }}
                  </span>
                  <span>
                    {{ "components.footer.of" | transloco }}
                    {{
                      response?.totalElements ? response?.totalElements : 0
                    }}</span
                  >
                </span>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>

<!-- footer  -->

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-end">
          <div class="displayed">
            <button
              type="button"
              class="btn btn-secondary back"
              (click)="cancelAction()"
            >
              {{ "global.cancel" | transloco }}
            </button>

            <button
              type="button"
              class="btn btn btn-primary"
              (click)="applyChangesAction()"
              [disabled]="!vehicleCodeData.length"
            >
              {{
                "modules.expert-tool.codes-modal.submit-btn"
                  | transloco
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
