<div class="filter" *ngIf="formLoaded">
    <form [formGroup]="form" (keyup.enter)="filterRows()">
      <div>
        <div class="row row-cols-auto">
     
          <div class="col-3">
            <div class="form-group">
              <label for="codeOrDescription">{{
                "modules.expert-tool.codes-modal.input-label" | transloco
              }}</label>
              <input
              #search
                type="text"
                autocomplete="off"
                class="form-control"
                id="codeOrDescription"
                formControlName="codeOrDescription"
                placeholder="{{
                  'modules.expert-tool.codes-modal.input-placeholder' | transloco
                }}"
              />
            </div>
          </div>
  
          <div class="col">
            <div class="button-area">
              <button
                type="button"
                class="btn btn-primary"
                (click)="filterRows()"
              >
                {{ "modules.data-management.filter.btn-search" | transloco }}
              </button>
  
              <button
                type="button"
                class="btn btn-secondary"
                (click)="resetFilteredRows()"
              >
                {{ "modules.data-management.filter.btn-reset" | transloco }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  