import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SecurityService } from '@core/services/security.service';
import { Observable } from 'rxjs';

@Injectable()
export class GlobalHttpInterceptor implements HttpInterceptor {
  constructor(private securityService: SecurityService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.url.indexOf('api/userinfo') === -1 &&
      request.url.indexOf('s3.amazonaws.com/') === -1
    ) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${
            this.securityService.getCurrentUser().accessToken
          }`,
        },
      });
    }
    return next.handle(request);
  }
}
