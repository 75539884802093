<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<form autocomplete="off" [formGroup]="form">
  <div class="custom-uploader">
    <file-upload simple formControlName="files" (change)="readFinlogFile()">
      <ng-template let-file="file" #placeholder> </ng-template>

      <ng-template let-control="control" let-file="file" #button>
        {{ "modules.expert-tool.btn-read-finlog" | transloco }}
      </ng-template>
    </file-upload>
  </div>
</form>
<br />
