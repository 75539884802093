<div class="modal-header">
  <span class="modal-title">{{
    "modules.data-management.modal-open-series-queue-title" | transloco
  }}</span>
  <div (click)="close()">
    <span
      class="close-icon"
      icon="times"
    >
      <i class="bi bi-x"></i>
    </span>
  </div>
</div>

<div class="modal-body bdp-modal">
  <div *ngIf="isQueueExisting()">
    <div class="loading-bar-container mt-3">
      <app-loading-bar [text]="getBarStatusText()"></app-loading-bar>
    </div>

    <ngx-datatable
      #dataTable
      class="material datatable-scrollable mt-4"
      [columnMode]="'flex'"
      [headerHeight]="56"
      rowHeight="auto"
      [scrollbarH]="false"
      [scrollbarV]="false"
      [rows]="steuerDatenQueue"
      [sorts]="sortConfig"
    >
      <ngx-datatable-column
        name="{{
          'modules.data-management.table-header.sd-packet' | transloco
        }}"
        prop="sdpNames"
        [flexGrow]="3"
        [resizeable]="false"
      >
        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span
            *ngIf="value"
            class="text-pre-wrap"
            placement="bottom"
            [ngbTooltip]="value"
            data-container="body"
            container="body"
            tooltipClass="note-tooltip"
            >{{ truncateValue(value) }}</span
          >
        </ng-template>

        <ng-template
          let-value="value"
          let-column="column"
          let-rowIndex="rowIndex"
          let-sort="sortFn"
          ngx-datatable-header-template
        >
          <span
            class="header-sort"
            ngbTooltip="{{
              'modules.data-management.table-header.sd-packet' | transloco
            }}"
            data-container="body"
            container="body"
            placement="bottom"
            tooltipClass="note-tooltip"
            (click)="sort()"
            >{{ column.name }}</span
          >
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'modules.data-management.table-header.series' | transloco }}"
        prop="baureihen"
        [flexGrow]="2"
        [resizeable]="false"
      >
        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span
            *ngIf="value"
            class="text-pre-wrap"
            placement="bottom"
            [ngbTooltip]="value"
            data-container="body"
            container="body"
            tooltipClass="note-tooltip"
            >{{ truncateValue(value) }}</span
          >
        </ng-template>

        <ng-template
          let-value="value"
          let-column="column"
          let-rowIndex="rowIndex"
          let-sort="sortFn"
          ngx-datatable-header-template
        >
          <span
            class="header-sort"
            ngbTooltip="{{
              'modules.data-management.table-header.series' | transloco
            }}"
            data-container="body"
            container="body"
            placement="bottom"
            tooltipClass="note-tooltip"
            (click)="sort()"
            >{{ column.name }}</span
          >
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{
          'modules.data-management.table-header.start-time' | transloco
        }}"
        prop="createdAt"
        [flexGrow]="3"
        [resizeable]="false"
      >
        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="value">{{ value | date : "HH:mm:ss":'UTC' }}</span>
        </ng-template>

        <ng-template
          let-value="value"
          let-column="column"
          let-rowIndex="rowIndex"
          let-sort="sortFn"
          ngx-datatable-header-template
        >
          <span
            class="header-sort"
            ngbTooltip="{{
              'modules.data-management.table-header.start-time' | transloco
            }}"
            data-container="body"
            container="body"
            placement="bottom"
            tooltipClass="note-tooltip"
            (click)="sort()"
            >{{ column.name }}</span
          >
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'modules.data-management.table-header.user' | transloco }}"
        prop="username"
        [flexGrow]="3"
        [resizeable]="false"
      >
        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="value">{{ value }}</span>
        </ng-template>

        <ng-template
          let-value="value"
          let-column="column"
          let-rowIndex="rowIndex"
          let-sort="sortFn"
          ngx-datatable-header-template
        >
          <span
            class="header-sort"
            ngbTooltip="{{
              'modules.data-management.table-header.user' | transloco
            }}"
            data-container="body"
            container="body"
            placement="bottom"
            tooltipClass="note-tooltip"
            (click)="sort()"
            >{{ column.name }}</span
          >
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        name="{{ 'global.status' | transloco }}"
        prop="status"
        [flexGrow]="3"
        [resizeable]="false"
      >
        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span *ngIf="value"> {{ getStatusText(value) }}</span>
        </ng-template>

        <ng-template
          let-value="value"
          let-column="column"
          let-rowIndex="rowIndex"
          let-sort="sortFn"
          ngx-datatable-header-template
        >
          <span
            class="header-sort"
            ngbTooltip="{{ 'global.status' | transloco }}"
            data-container="body"
            container="body"
            placement="bottom"
            tooltipClass="note-tooltip"
            (click)="sort()"
            >{{ column.name }}</span
          >
        </ng-template>
      </ngx-datatable-column>

      <ngx-datatable-column
        [cellClass]="'text-center'"
        name=""
        [flexGrow]="1"
        [sortable]="false"
        [resizeable]="false"
      >
        <ng-template
          let-rowIndex="rowIndex"
          let-row="row"
          let-value="value"
          ngx-datatable-cell-template
        >
          <span 
            title="{{
              'modules.data-management.tooltip.cancel-sdp-serie-queue'
                | transloco
            }}"
            (click)="removeFromQueue(row)"
          >
            <i class="bi bi-trash-fill"></i>
          </span>
        </ng-template>

        <ng-template
          let-value="value"
          let-column="column"
          let-rowIndex="rowIndex"
          ngx-datatable-header-template
        >
          <span
            ngbTooltip="{{ 'global.actions' | transloco : { value: value } }}"
            data-container="body"
            container="body"
            placement="bottom"
            tooltipClass="note-tooltip"
            >{{ column.name }}</span
          >
        </ng-template>
      </ngx-datatable-column>
    </ngx-datatable>
  </div>

  <div *ngIf="!isQueueExisting()">
    <span>{{ "modules.data-management.sdp-queue-empty" | transloco }}</span>
  </div>

  <app-upload-duration
    *ngIf="isQueueExisting()"
    [dataList]="steuerDatenQueue"
    [estimatedTime]="10"
    [translationKey]="
      'modules.data-management.sdp-activation-queue-notification'
    "
  ></app-upload-duration>
</div>
