import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '@core/services/admin.service';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { NgOption } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { AdminSeriesConfig } from '@shared/models/filetype/AdminSeriesConfig';
import { BehaviorSubject, Subject } from 'rxjs';
import { SteuerdatenService } from './steuerdaten.service';

@Injectable({
  providedIn: 'root',
})
export class SharedSeriesService {
  adminSeriesConfig: AdminSeriesConfig[] = [];
  adminBaureihen!: NgOption[];

  constructor(
    public router: Router,
    public translateService: TranslocoService,
    public adminService: AdminService,
    public appService: AppService,
    public notificationService: ErrorHandlerService,
    private steuerdatenService : SteuerdatenService
  ) {}

  getAdminSeriesList(): any {
    this.adminService.getSeriesConfig().subscribe(
      (adminSeriesConfig: AdminSeriesConfig[]) => {
        this.adminBaureihen = adminSeriesConfig
          .map(({ brName: brName }) => ({ brName }))
          .sort((br1, br2) => {
            const brName1 = br1.brName;
            const brName2 = br2.brName;
            if (brName1 > brName2) {
              return 1;
            }
            if (brName1 < brName2) {
              return -1;
            }
            return 0;
          });
        this.appService.seriesSubject.next(this.adminBaureihen);
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
    return this.adminBaureihen;
  }

  public getSeries() {
    return new Promise((resolve, reject) => {
      this.adminService.getSeriesConfig().subscribe(
        (adminSeriesConfig: AdminSeriesConfig[]) => {
          resolve(adminSeriesConfig);
        },
        (error: HttpErrorResponse) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.admin-section.config-modal.insert-edit-api-error-message'
          );
          resolve([])
        }
      );
    })
  }

  public getExtractedSerie(vedocData): string {
    const filteredSerie = `C${
      this.steuerdatenService.extractSerieFromFinVin(
        vedocData && vedocData.fin
      ) ||
      this.steuerdatenService.extractSerieFromFinVin(
        vedocData && vedocData.vin
      )
    }`;
    return filteredSerie;
  }
}
