import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@env/paths';
import {
  FilteredVehicleCode,
  VedocData,
  VehicleCodeResponse,
} from '@shared/models/experttool/VedocData';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class VehicleDataService {
  private baseUrl = '';
  private steuerungBaseUrl = '';

  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.baseUrl = `${envService.apiUrl}${apiPaths.fcscVedocGateway}/api`;
    this.steuerungBaseUrl = `${envService.apiUrl}${apiPaths.fcscSteuerung}/api`;
  }

  getVehicleDataFromVedoc(vin: string): Observable<VedocData> {
    return this.httpClient.get<VedocData>(this.baseUrl + '/vedoc/' + vin);
  }

  postVehicleCodes(
    code: string[],
    parameters?: FilteredVehicleCode
  ): Observable<HttpResponse<VehicleCodeResponse>> {
    let paramsVar = new HttpParams();
    const url = `${this.steuerungBaseUrl}/vehicle-code`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    if (parameters) {
      const paramsObj = {};
      Object.keys(parameters).map((key) => {
        paramsObj[key] = parameters[key];
      });
      paramsVar = new HttpParams({ fromObject: paramsObj });
    }
    return this.httpClient.post<VehicleCodeResponse>(url, code, {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
      params: paramsVar,
    });
  }
}
