import { Injectable } from '@angular/core';
import { NgbDateStruct, NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import { i18nValues } from '@shared/lists/datepickeri18n';

import { AppService } from './app.service';

@Injectable()
export class I18n {
  language = localStorage.getItem('language') || 'en';
}

@Injectable()
export class CustomDatepickerI18nService extends NgbDatepickerI18n {
  constructor(private _i18n: I18n, public appService: AppService) {
    super();
  }

  getWeekdayLabel(weekday: number): string {
    return i18nValues[this._i18n.language].weekdays[weekday - 1];
  }

  getMonthShortName(month: number): string {
    return i18nValues[this._i18n.language].months[month - 1];
  }

  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

}
