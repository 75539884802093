export class CurrentUser {
  constructor(
    public username: string = '',
    public authorizationGroup: string[],
    public entitlements: string[] = [],
    public jwtToken: string = '',
    public accessToken: string = '',
    public expIn: number = 0
  ) {}

  public hasAuthorizationGroup(authorizationGroup: string): boolean {
    if (this.authorizationGroup) {
      return (
        this.authorizationGroup.filter(
          (authGroup) => authGroup === authorizationGroup
        ).length > 0
      );
    }
    return false;
  }

  public getAuthorizationGroup() {
    return this.authorizationGroup;
  }

  public getUsername(): string {
    return this.username;
  }

  public getEntitlements(): string[] {
    return this.entitlements;
  }
}

export class CurrentUserEnt {
  constructor(public entitlementName: string, public id: number) {}
}
