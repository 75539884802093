import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(
    private translateService: TranslocoService,
    private notificationService: ErrorHandlerService
  ) {}

  public validateKeyValuePairs(data, separator, subSeparator): boolean {
    // return true; // to disable validation key-value pair
    let validateFlag = true;
    const splitArr = data && data.split(separator);
    if (splitArr && splitArr.length) {
      splitArr.forEach((element) => {
        if (element) {
          const subSplit = element.split(subSeparator);
          const lastCharacter = element.charAt(element.length - 1);
          if (!subSplit[1] && lastCharacter !== subSeparator) {
            validateFlag = false;
          }

          if (!subSplit[1] && !subSplit[0] && lastCharacter === subSeparator) {
            validateFlag = false;
          }

          if (!subSplit[0] && lastCharacter === subSeparator) {
            validateFlag = false;
          }
          if (!subSplit[0] && lastCharacter !== subSeparator) {
            validateFlag = false;
          }
        }
      });
    }

    if (!validateFlag) {
      this.showErrorNotification('global.validation.key-value-pair');
    }
    return validateFlag;
  }

  validateDataTableRecord(property: any): boolean {
    return this.mapMergedArray(property, false)?.some((val) =>
      val === false ? true : false
    );
  }

  validateDataTableSingleRecord(property: any): boolean {
    return property
      ? this.mapMergedArray(property, true)?.some((val) =>
          val === false ? true : false
        )
      : false;
  }

  checkInputValidity(
    form: UntypedFormGroup,
    controlName: string
  ): boolean | undefined {
    return (
      (form.get(controlName) ||
        form.get(controlName)?.touched ||
        form.get(controlName)?.dirty) &&
      form.get(controlName)?.invalid
    );
  }

  private mapMergedArray(property: any, useUnMerged: boolean) {
    const mergedArray: any = useUnMerged
      ? property
      : [].concat.apply([], property);
    const regex: RegExp = new RegExp('^[0-9]+$');
    return mergedArray?.map((item) => {
      const validateString: string = useUnMerged ? item.join(' ') : item;
      return (
        (regex.test(validateString) && validateString?.length === 10) ||
        validateString?.length === 0
      );
    });
  }

  private showErrorNotification(key) {
    this.notificationService.showError(
      '',
      this.translateService.translate(key)
    );
  }
}

// Reactive form custom validations

export function ValidateFwmbsInput(
  control: AbstractControl
): { [key: string]: any } | null {
  const regex: RegExp = new RegExp('^[0-9]+$');
  const splittedControl =
    control.value &&
    control.value?.split(/(\s+)/).filter((item) => item.trim().length > 0);
  if (splittedControl) {
    const resultArray = splittedControl?.map((item) => {
      return regex.test(item) && item.length === 10;
    });
    return resultArray?.some((val) => val === false) ? { Invalid: true } : null;
  }
  return null;
}

export function ValidateVinInput(
  control: AbstractControl
): { [key: string]: any } | null {
  const regex: RegExp = new RegExp('[0-9A-HJ-NPR-Z]{17}');
  const result: boolean =
    regex.test(control.value) && control.value.length === 17;
  return !result && control.value.length !== 0 ? { Invalid: true } : null;
}

export function ValidateSerieInput(
  control: AbstractControl
): { [key: string]: any } | null {
  const regex: RegExp = new RegExp(/^C\d\d\d$/g);
  const result: boolean = regex.test(control.value);
  return !result && control.value.length !== 0 ? { Invalid: true } : null;
}
