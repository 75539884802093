<div class="modal-header">
  <span class="modal-title"> {{ title }}</span>
</div>

<div class="modal-sub-text">
  {{ subTitle }}
</div>

<div id="stepper-rollback" class="bs-stepper">
  <div class="bs-stepper-header">
    <div class="step" data-target="#target-part1">
      <button class="step-trigger" [disabled]="true">
        <span class="bs-stepper-circle">1</span>
      </button>
    </div>
    <div class="line"></div>
    <div class="step" data-target="#target-part2">
      <button class="step-trigger" [disabled]="true">
        <span class="bs-stepper-circle">2</span>
      </button>
    </div>
    <div class="line"></div>
    <div class="step" data-target="#target-part3">
      <button class="step-trigger" [disabled]="true">
        <span class="bs-stepper-circle">3</span>
      </button>
    </div>

    <div class="line"></div>
    <div class="step" data-target="#target-part4">
      <button class="step-trigger" [disabled]="true">
        <span class="bs-stepper-circle">4</span>
      </button>
    </div>
  </div>

  <div class="bs-stepper-content">
    <!-- First Step -->
    <div id="target-part1" class="content">
      <div *ngIf="currentStep === 0">
        <app-fcsc-rollback-part1
          [seriesReplacedBdpDocumentation]="seriesReplacedBdpDocumentation"
          [seriesBdpDocumentation]="seriesBdpDocumentation"
          (close)="close()"
          (next)="next()"
        >
        </app-fcsc-rollback-part1>
      </div>
    </div>

    <!-- Second Step -->
    <div id="target-part2" class="content">
      <div *ngIf="currentStep === 1">
        <app-fcsc-rollback-part2
          [seriesReplacedBdpDocumentationContent]="
            seriesReplacedBdpDocumentationContent
          "
          [seriesBdpDocumentationContent]="seriesBdpDocumentationContent"
          (close)="close()"
          (back)="back()"
          (next)="next()"
        ></app-fcsc-rollback-part2>
      </div>
    </div>

    <!-- Third Step -->
    <div id="target-part3" class="content">
      <div *ngIf="currentStep === 2">
        <app-fcsc-rollback-part3
          [steuerDatenQueue]="steuerDatenQueue"
          [showQueueDiv]="showQueueDiv"
          (close)="close()"
          (back)="back()"
          (next)="next()"
        ></app-fcsc-rollback-part3>
      </div>
    </div>

    <!-- Fourth Step -->
    <div id="target-part4" class="content">
      <div *ngIf="currentStep === 3">
        <app-fcsc-rollback-part4
          [queueTitle]="queueTitle"
          [showQueueDiv]="showQueueDiv"
          [notificationMessage]="notificationMessage"
          (close)="close()"
          (back)="back()"
          (submit)="submit($event)"
        ></app-fcsc-rollback-part4>
      </div>
    </div>
  </div>
</div>
