<div class="nav-main" id="sidebar-wrapper">
  <div class="sidebar-heading"><img *ngIf="application" [src]="application.logo" /></div>
  <div class="list-group list-group-flush">
      <ng-template #recursiveList let-ctx>
        <ng-container *ngFor="let item of ctx.menu" >
          <div class="list-item" *appRequiredEntitlements="item.entitlementKey">
              <a [routerLink]="item.path"
                 routerLinkActive="menu-active"
                 [routerLinkActiveOptions]="{exact: !ctx.root}"
                 (click)="navigateToLink(item.redirectTo, item.path)"
                 [ngClass]="ctx.root ? 'menu-item' : 'submenu-item'">{{item.title}}</a>
              <ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: { menu : item.subMenu, root: false } }">
              </ng-container>
          </div>
        </ng-container>
      </ng-template>
      <ng-container
          *ngTemplateOutlet="recursiveList; context:{ $implicit: { menu : application?.menus, root: true} }">
      </ng-container>
  </div>
</div>
