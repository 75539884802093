import { CalculateApplication } from './CalculateApplication';
import { CalculateLocale } from './CalculateLocale';
import { CalculateTask } from './CalculateTask';

export class CalculateClient {
  platform: string;
  dateTime: string;
  locale: CalculateLocale;
  application: CalculateApplication;
  task: CalculateTask;

  constructor(
    platform: string,
    dateTime: string,
    locale: CalculateLocale,
    application: CalculateApplication,
    task: CalculateTask
  ) {
    this.platform = platform;
    this.dateTime = dateTime;
    this.locale = locale;
    this.application = application;
    this.task = task;
  }
}
