import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fcsc-experttool',
  templateUrl: './fcsc-experttool.component.html',
  styleUrls: ['./fcsc-experttool.component.scss'],
})
export class FcscExperttoolComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
