import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@env/paths';
import {
  CreateVp5TestDataPayload,
  Vp5TestData,
} from '@shared/models/filetype/Vp5TestData';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class TestDataService {
  private steuerungBaseUrl = '';
  private vp5TagsBaseUrl = '';

  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.steuerungBaseUrl = `${envService.apiUrl}${apiPaths.fcscSteuerung}/api`;
    this.vp5TagsBaseUrl = `${envService.apiUrl}${apiPaths.fcscVp5Tags}`;
  }

  getVp5TestData(): Observable<HttpResponse<Vp5TestData[]>> {
    const url = `${this.steuerungBaseUrl}/vp5-testing`;

    return this.httpClient.get<Vp5TestData[]>(url, {
      responseType: 'json',
      observe: 'response',
    });
  }

  deleteVp5File(name: string) {
    const url = `${this.steuerungBaseUrl}/vp5-testing/${name}`;
    return this.httpClient.delete(url);
  }

  getVp5Tags(): Observable<HttpResponse<string[]>> {
    const url = `${this.vp5TagsBaseUrl}`;

    return this.httpClient.get<string[]>(url, {
      responseType: 'json',
      observe: 'response',
    });
  }

  postVp5TestBdp(
    payload: CreateVp5TestDataPayload
  ): Observable<HttpResponse<Vp5TestData>> {
    const url = `${this.steuerungBaseUrl}/vp5-testing`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    return this.httpClient.post<Vp5TestData>(url, payload, {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
    });
  }

  putVp5ActivateTestBdp(name: string): Observable<HttpResponse<Vp5TestData>> {
    const url = `${this.steuerungBaseUrl}/vp5-testing/${name}/shadow-mode/activate`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    return this.httpClient.put<Vp5TestData>(url, '', {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
    });
  }

  putVp5DeactivateTestBdp(name: string): Observable<HttpResponse<Vp5TestData>> {
    const url = `${this.steuerungBaseUrl}/vp5-testing/${name}/shadow-mode/deactivate`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    return this.httpClient.put<Vp5TestData>(url, '', {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
    });
  }
}
