import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, UntypedFormBuilder, Validators,} from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SnsTopic} from '@shared/models/sns-notification/sns-topic';
import {SnsTopicStatus} from '@shared/models/sns-notification/sns-topic-status';
import {SnsTopicSubscriber} from '@shared/models/sns-notification/sns-topic-subscriber';
import {ErrorHandlerService} from "@core/services/errorHandler/error-handler.service";
import {SnsNotificationService} from "@core/services/sns-notification/sns-notification.service";
import {ConfirmatonModalComponent} from "@shared/components/confirmaton-modal/confirmaton-modal.component";
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-notification-detail',
  templateUrl: './notification-detail.component.html',
  styleUrls: ['./notification-detail.component.scss'],
})
export class NotificationDetailComponent implements OnInit {
  @Input()
  topic: SnsTopic;

  @Input()
  subscribers: SnsTopicSubscriber[];

  sortConfig = [{ prop: 'email', dir: 'desc' }];
  topicStatus = SnsTopicStatus;
  form: FormGroup;
  showMailInfo = false;

  constructor(
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private snsNotificationService: SnsNotificationService,
    private fb: UntypedFormBuilder,
    private notificationService: ErrorHandlerService,
    private translateService : TranslocoService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'
          ),
        ],
      ],
    });
    this.subscribers.forEach((subscriber: any) => {
      if(subscriber.status == "PENDINGCONFIRMATION") {
        this.showMailInfo = true;
      }
    });
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  deleteEntry(subscriber: SnsTopicSubscriber) {
    const modalRef = this.modalService.open(ConfirmatonModalComponent);
    const subscriberId: string = subscriber.id?.split(':').pop() ?? '';
    modalRef.componentInstance.closingOption = false;
    modalRef.componentInstance.title = this.translateService.translate(`modules.admin-section.sns-notification.modal.delete-confirm-modal-title`)
    modalRef.componentInstance.subText = this.translateService.translate(`modules.admin-section.sns-notification.modal.delete-confirm-modal-message`, {subscriberEmail:subscriber.email});
    modalRef.componentInstance.buttonText = this.translateService.translate(
      'modules.admin-section.sns-notification.modal.delete-button-text'
    );

    modalRef.componentInstance.buttonSecondaryText =
      this.translateService.translate(
        'modules.admin-section.sns-notification.modal.cancel-button-text'
      );
    modalRef.result.then((result) => {
      if (result && result.ok) {
      this.snsNotificationService
        .deleteSubscriber(this.topic.name, subscriberId)
        .subscribe(() => {
          this.subscribers = [...this.subscribers].filter(
            tempSubscribers => tempSubscribers.id !== subscriber.id
          );
          modalRef.dismiss();
          this.notificationService.showSuccess(
            '',
            `${subscriber.email} wurde aus ${this.topic.name} entfernt`
          );
        });
      }
    });
  }

  save() {
    if (this.form.valid) {
      const email = this.form.get('email')?.value;
      this.snsNotificationService
        .saveSubscriber(this.topic.name, email)
        .subscribe((subscriber: SnsTopicSubscriber) => {
          this.subscribers = [...this.subscribers, subscriber];
          this.form.get('email')?.setValue('');
          this.form.get('email')?.markAsUntouched();
          this.form.get('email')?.markAsPristine();
          this.showMailInfo = true;
          this.notificationService.showSuccess(
            '',
            `${email} wurde eine Anfrage für ${this.topic.name} gesendet`
          );
        });
    } else {
      this.form.get('email')?.markAsTouched();
    }
  }
}
