import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ErrorCodesFromServer } from '@core/enums/error-codes-server.enum';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private toastr: ToastrService,
    private translateService: TranslocoService
  ) {}

  showError(errorType: string, errorMessage: string) {
    this.clearAll();
    this.toastr.error(errorMessage, errorType, { timeOut: 2000 });
  }

  showSuccess(errorType: string, errorMessage: string) {
    this.clearAll();
    this.toastr.success(errorMessage, errorType, { timeOut: 1500 });
  }

  showWarning(errorType: string, warningMessage: string) {
    this.toastr.warning(warningMessage, errorType);
  }

  clearAll() {
    this.toastr.clear();
  }

  handleServerErrorNotification(
    err: HttpErrorResponse | null,
    clientMessageKey: string,
    paramsExternal?: any
  ) {
    this.clearAll();
    const error = err?.error;
    const code = error?.message_CODE;
    const params = error?.params;
    const statusCode = err?.status;
    const errorMessage = error?.message;
    const status = err?.status
    let result = '';
    if (error && code) {
      const errorMessageKey = ErrorCodesFromServer[code];
      if (errorMessageKey) {
        const message = this.translateService.translate(errorMessageKey);

        if (message) {
          if (params && params.length) {
            result = params.reduce(
              (f, s, i) => `${f}`.replace(`{${i}}`, s),
              message
            );
          } else {
            result = message;
          }
          result = code ? ` ${code} - ${result}` : result;
        }
      }
    }

    if (result !== ''){
      this.showError('', result); 
    } else if (statusCode !== undefined && errorMessage !== undefined)  {
      let errorToDisplay = ` ${statusCode} - ${errorMessage}`
      this.showError('', errorToDisplay);
      
    } else if (clientMessageKey !== '') {
      let finalParams = {};
      const errorParams = status !== undefined ? {
        errorCode: status,
      } : {
        errorCode: result,
      };
      if (paramsExternal !== undefined) {
        finalParams = {
          ...errorParams,
          ...paramsExternal,
        };
        this.showError('',this.translateService.translate(clientMessageKey, finalParams)) 
      } else {
        if (status !== undefined) {
          const errorText = this.getErrorMessageFromErrorStatus(status)
          this.showError('', errorText);
        }
      }
    }else if (status){
      const errorText = this.getErrorMessageFromErrorStatus(status)
      this.showError('',errorText);
    }
  }

  getErrorMessageFromErrorStatus(errStatus: number): string {

    const errorStatus: string = `Error ${errStatus} :`
    let errorMessage: string = ''
    switch (errStatus) {
      case 400:
        errorMessage = `errorcodes.model.400-error`
        break
      case 401:
        errorMessage = `errorcodes.model.401-error`
        break
      case 403:
      case 0:
        errorMessage = `errorcodes.model.403-error`
        break
      case 404:
        errorMessage = `errorcodes.model.404-error`
        break
      case 405:
        errorMessage = `errorcodes.model.405-error`
        break
      case 500:
        errorMessage = `errorcodes.model.500-error`
        break
      case 502:
        errorMessage = `errorcodes.model.502-error`
        break
      default:
        errorMessage = this.translateService.translate('modules.admin-section.config-modal.insert-edit-api-error-message', {
          errorCode : errStatus
        })
        return errorMessage;
    }
    return errorStatus + this.translateService.translate(errorMessage)
  }
}