import { Breadcrumb } from './Breadcrumb';
import { Menu } from './Menu';
export class Application {
  constructor(
    public title: string,
    public description: string,
    public logo: string,
    public menus: Menu[],
    public selected: boolean = false
  ) {}
}
