export const environment = {
  production: false,
  i3Access: {
    userinfoUrl: '/api/userinfo',
    loginUrl: '/login',
    logoutUrl: '/api/logout',
    authorizationGroup: 'VUS.UserAuth',
  },
  baseUrl: 'http://localhost:4200/',
  backupUrl: 'http://localhost:4200/',
  apiUrl: 'http://localhost:4200',
};
