import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss']
})

export class ChipComponent {
  chips: string[] = [];
  inputText: string = '';
  editedChipText: string = '';
  editingChipIndex: number | null = null;
  @ViewChild('input') inputElement: ElementRef;
  @Output() chipsChanged = new EventEmitter<boolean>();
  @Output() chipsUpdated = new EventEmitter<any>();

  isValid(chip): boolean {
    return chip.length === 10 && /^[0-9]+$/.test(chip);
  }

  addChip() {
    const trimmedText = this.inputText.trim();
    if (trimmedText && !this.chips.includes(trimmedText) && trimmedText !== ',') {
      this.chips.push(trimmedText);
    }
    setTimeout( () => {
      this.inputText = '';
      this.inputElement.nativeElement.value = '';
    });
    this.emitChipsStatus();
  }

  handlePaste(event) {
    const pastedText = event.clipboardData.getData('text/plain')
    let splitByComma = pastedText.replace(/\n/g, ' ').split(',');
    splitByComma.forEach(element => {
      if(element.length) {
        element.split(' ').forEach(value => {
          if(value !== '') {
            const trimmedText = value.trim();
            if (trimmedText && !this.chips.includes(trimmedText) && trimmedText !== ',') {
              this.chips.push(trimmedText);
            }
          }
        })
      }
    });
    setTimeout( () => {
      this.inputText = '';
      this.inputElement.nativeElement.value = '';
    });
    this.emitChipsStatus();
  }

  removeChip(index: number) {
    this.chips.splice(index, 1);
    this.emitChipsStatus();
  }

  startEditing(index: number, chip: string) {
    this.editingChipIndex = index;
    this.editedChipText = chip;
    setTimeout(() => {
      const input = document.querySelector('.chip-edit-input') as HTMLInputElement;
      input?.focus();
    }, 0);
  }

  finishEditing(index: number) {
    if (this.editedChipText.trim()) {
      this.chips[index] = this.editedChipText.trim();
    }
    this.editingChipIndex = null;
    this.emitChipsStatus();
  }

  cancelEditing() {
    this.editingChipIndex = null;
    this.emitChipsStatus();
  }

  onBackspace(event) {
    if (!this.inputText && !this.editingChipIndex) {
      this.chips.pop();
    }
    this.emitChipsStatus();
  }

  focusInput() {
    if (!this.editingChipIndex) {
      const input = this.inputElement.nativeElement;
      input.focus();
    }
    this.emitChipsStatus();
  }

  private emitChipsStatus() {
    const allGreen = this.chips.every(chip => this.isValid(chip));
    this.chipsChanged.emit(allGreen);
    if(allGreen) {
      this.chipsUpdated.emit(this.chips);
    }
  }
}
