import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { StorageService } from '@core/services/storage.service';
import { ValidationService } from '@core/services/validation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOption } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { ExceludedResponseAttributeKeys } from '@shared/lists/ExperttoolExceludedKeys';
import { TypeExtension } from '@shared/lists/flashFileExtension';
import { DeterminedResultEdit } from '@shared/models/determinedResultEdit';
import {
  RcddbLookupBr,
} from '@shared/models/filetype/RcddbLookupBr';
import { FlashwareResponse } from '@shared/models/flashwareResponse';
import { FwbsResultEdit } from '@shared/models/fwmbsResultEdit';
import { ModalResult } from '@shared/models/ModalResult';
import { CheckBoxSelect } from '@shared/models/Select';
import { SelectionType } from '@swimlane/ngx-datatable';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-fcsc-calculate-modal',
  templateUrl: './fcsc-calculate-modal.component.html',
  styleUrls: ['./fcsc-calculate-modal.component.scss'],
})
export class FcscCalculateModalComponent implements OnInit {
  @Input() title!: string;
  @Input() subText!: string;
  @Input() data!: FlashwareResponse;
  @Input() fieldText!: string;
  @Input() buttonText!: string;
  @Input() index!: number;
  @Input() dataList!: FlashwareResponse[];
  form!: UntypedFormGroup;
  okValue = '';
  attributesArray = ExceludedResponseAttributeKeys;
  dataTableSelection = SelectionType.checkbox;
  fwmbsRows: FwbsResultEdit[] = [];
  orignalFwmbsRows!: FwbsResultEdit[];
  determinedRows: DeterminedResultEdit[] = [];
  orignalDeterminedRows: DeterminedResultEdit[] = [];
  selected: DeterminedResultEdit[] = [];
  dataFromChild!: DeterminedResultEdit | null;
  showDiv = {
    current: true,
    next: false,
  };
  showImport = {
    current: true,
    next: false,
  };
  typeItems!: NgOption[];
  rcddbLookup!: RcddbLookupBr[];
  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private translocoService: TranslocoService,
    private storageService: StorageService,
    private steuerdatenService: SteuerdatenService,
    private appService: AppService,
    private notificationService: ErrorHandlerService,
    private translateService: TranslocoService,
    private validationService: ValidationService
  ) {}

  ngOnInit(): void {
    this.typeItems = TypeExtension;
    this.createForm();
    this.createFwmbsRows();
    this.createDeterminedRows();
  }

  handleClick(index) {
    if (this.determinedRows.length === index + 1) {
      const element: any = document.querySelector(
        '.result-table .ngx-datatable .datatable-body'
      );
      element.scrollTop = element.offsetHeight;
    }
  }

  private createForm() {
    const isEdit = this.data !== undefined;
    let attributeStr = '';
    if (this.data) {
      if ('attributes' in this.data) {
        attributeStr = this.data.attributes as string;
      } else {
        const keysData = Object.keys(this.data);
        keysData.forEach((element) => {
          if (this.attributesArray.indexOf(element) === -1) {
            if (
              (this.data[element] && this.data[element] !== undefined) ||
              this.data[element] !== null
            ) {
              attributeStr =
                attributeStr +
                (attributeStr ? '\n' : '') +
                `${element}=${this.data[element]}`;
            }
          }
        });
      }
    }

    this.form = this.formBuilder.group({
      SG_DiogName: new UntypedFormControl(isEdit ? this.data.SG_DiogName : ''),
      attributes: new UntypedFormControl(isEdit ? attributeStr : ''),
      FW_MBS: new UntypedFormControl(isEdit ? this.data.FW_MBS : ''),
      SG_Current_SW: new UntypedFormControl(isEdit ? this.data.SG_Current_SW : ''),
      SG_REFNR: new UntypedFormControl(isEdit ? this.data.SG_REFNR : '', [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$'),
      ]),
      ECU_Refer: new UntypedFormControl(isEdit ? this.data.ECU_Refer : ''),
      SG_FITTINGFLASHSW: new UntypedFormControl(
        isEdit ? this.data.SG_FITTINGFLASHSW : ''
      ),
      FW_KETTE: new UntypedFormControl(isEdit ? this.data.FW_KETTE : ''),
      VP_Methode: new UntypedFormControl(isEdit ? this.data.VP_Methode : ''),
      Flashbedarf: new UntypedFormControl(
        isEdit
          ? this.data.Flashbedarf
            ? this.translocoService.translate(this.data.Flashbedarf)
            : ''
          : this.translocoService.translate(
              'modules.expert-tool.result.flash-requirement-opt.no-result'
            )
      ),
    });
    this.showDiv.current = true;
    this.showDiv.next = false;
  }

  private createFwmbsRows(): void {
    if (this.data) {
      const fwmbs = this.data && this.data.FW_MBS?.split(' ');
      let fwmbsList: FwbsResultEdit[] = [];
      fwmbsList = fwmbs?.map((fwmbs, index) => {
        return {
          id: index,
          FW_MBS: fwmbs,
          SG_DiogName: this.data.SG_DiogName,
        };
      });

      fwmbsList?.some((element) => {
        if (element?.FW_MBS === '') {
          fwmbsList.splice(0, fwmbsList.length);
        }
      });

      this.fwmbsRows = fwmbsList;

      try {
        this.orignalFwmbsRows = JSON.parse(JSON.stringify(this.fwmbsRows));
      } catch (err) {}
      this.setFwmbsDisability();
      this.showDiv.current = true;
      this.showDiv.next = false;
    }
  }

  private setFwmbsDisability(): void {
    if (this.fwmbsRows) {
      this.fwmbsRows?.map((record) => (record.disabled = true));
      this.orignalFwmbsRows.map((record) => (record.disabled = true));
    }
  }

  checkFwmbsRecordsLength() {
    const newFwmbsRec = this.fwmbsRows?.map((el) => {
      return el?.FW_MBS?.split(/(\s+)/).filter((e) => e.trim().length > 0);
    });
    return this.validationService.validateDataTableRecord(newFwmbsRec);
  }

  private checkFwmbsTableEdit(rows: FwbsResultEdit[]): void {
    rows.forEach((row) => {
      this.orignalFwmbsRows.forEach((orignalRow) => {
        if (row.id === orignalRow.id) {
          row.disabled = JSON.stringify(orignalRow) === JSON.stringify(row);
        }
      });
    });
  }

  private createDeterminedRows(): void {
    if (this.data) {
      const prio = this.data.SG_FITTINGFLASHSW_PRIO
        ? this.data.SG_FITTINGFLASHSW_PRIO?.split('|')
        : [];
      const sizes = this.data.SG_FITTINGFLASHSW_SIZE
        ? this.data.SG_FITTINGFLASHSW_SIZE?.split('|')
        : [];
      const flashType = this.data.SG_FITTING_FWINFO
        ? this.data.SG_FITTING_FWINFO?.split('|').map((dt) => dt.split(',')[1])
        : [];
      const sgNewFlash = this.data.SG_NEWFLASHSW
        ? this.data.SG_NEWFLASHSW?.split('|')
        : [];
      const sgNewPrio = this.data.SG_NEWFLASHSW_PRIO
        ? this.data.SG_NEWFLASHSW_PRIO?.split('|')
        : [];
      const sgNewFlashWeg = this.data.SG_NEWFLASHSW_FLASHWEG
        ? this.data.SG_NEWFLASHSW_FLASHWEG?.split('|')
        : [];
      const sgFittingFlash = this.data.SG_FITTINGFLASHSW
        ? this.data.SG_FITTINGFLASHSW?.split(' ')
        : [];
      const flashWeg = this.data.SG_FITTING_FWINFO
        ? this.data.SG_FITTING_FWINFO?.split('|').map((dt) => dt.split(',')[2])
        : [];

      const lateBound = this.data.SG_FITTING_FWINFO
        ? this.data.SG_FITTING_FWINFO?.split('|').map((dt) => dt.split(',')[4])
        : [];

      const xentryUpdate = this.data.SG_FITTING_FWINFO
        ? this.data.SG_FITTING_FWINFO?.split('|').map((dt) => dt.split(',')[0])
        : [];

      let determinedFwList: DeterminedResultEdit[] = [];

      determinedFwList = sgFittingFlash.map((item, index) => {
        const newFlash = sgNewFlash.find((flash) => flash === item);
        const obj = {
          id: index,
          SG_DiogName: this.data.SG_DiogName,
          SG_FITTINGFLASHSW_PRIO: prio[index],
          SG_FITTINGFLASHSW_SIZE: sizes[index],
          type: flashType[index],
          flashweg: flashWeg[index],
          latebound: lateBound[index],
          xentryUpdate: xentryUpdate[index],
          SG_FITTINGFLASHSW: sgFittingFlash[index],
          SG_NEWFLASHSW_PRIO: sgNewPrio[index],
          SG_NEWFLASHSW_FLASHWEG: sgNewFlashWeg[index],
          selected: sgNewFlash.indexOf(item) > -1,
        };
        if (newFlash) {
          obj['SG_NEWFLASHSW'] = newFlash;
        }

        return obj;
      });
      this.selected = [];
      determinedFwList.forEach((element) => {
        if (element.selected) {
          this.selected.push(element);
        }
      });

      this.determinedRows = determinedFwList;
      this.orignalDeterminedRows = JSON.parse(
        JSON.stringify(this.determinedRows)
      );
      this.setDeterminedTableDisability();

      this.showDiv.current = true;
      this.showDiv.next = false;
    }
  }

  private setDeterminedTableDisability(): void {
    this.determinedRows.map((record) => (record.disabled = true));
    this.orignalDeterminedRows.map((record) => (record.disabled = true));
  }

  private checkDeterminedTableEdit(rows: DeterminedResultEdit[]): void {
    rows.forEach((row) => {
      this.orignalDeterminedRows.forEach((orignalRow) => {
        if (row.id === orignalRow.id) {
          row.disabled = JSON.stringify(orignalRow) === JSON.stringify(row);
        }
      });
    });
  }

  private reloadTable(): void {
    const copy = this.determinedRows;
    this.determinedRows = [];
    setTimeout(() => {
      this.determinedRows = copy;
    }, 50);
  }

  private reloadFwmbsTable(): void {
    const rows = this.fwmbsRows;
    this.fwmbsRows = [];
    setTimeout(() => {
      rows.map((row) => (row.FW_MBS = row.FW_MBS.replace(/A/g, '')));
      this.fwmbsRows = rows;
      this.getRcddbLookup();
    }, 50);
  }

  private verifyFlash(): void {
    if (this.determinedRows) {
      this.determinedRows.forEach((row) => {
        if (!row.selected) {
          delete row.SG_NEWFLASHSW;
        }
      });
    }
  }

  private async getRcddbLookup() {
    const flashForm =
      this.storageService.getData(sessionStorageKeys.flashForm) || {};
    const sdpName = flashForm.sdpName;
    const xentryRelease = flashForm.xentryReleases;
    const fwmbsValues =
      this.fwmbsRows &&
      this.fwmbsRows.map((row) => row.FW_MBS.replace(/A/g, '')).join(',');

    this.steuerdatenService.getRcddbLookup(
      sdpName,
      [fwmbsValues],
      xentryRelease
    ).subscribe(
      (resp: any) => {
        const rcddbLookupData: RcddbLookupBr[] = resp as RcddbLookupBr[];
        if (rcddbLookupData) {
          this.rcddbLookup = rcddbLookupData;
          this.setDeterminedRows(rcddbLookupData);
        }
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'global.bdp-status.service-down'
        );
      }
    );
  }

  private setDeterminedRows(
    data: RcddbLookupBr[]
  ) {
    if (data) {
      this.determinedRows = [];
      this.orignalDeterminedRows = [];
      this.selected = [];
      this.fwmbsRows.forEach((row, index) => {
        const determinedRow: DeterminedResultEdit = {
          id: row.id,
          SG_DiogName: row.SG_DiogName,
          type: this.appService.getFlashType(),
        };
        const fwmbs = row.FW_MBS.replace(/A/g, '');
        const selectedData =
          data && data.find((dt) => (dt.snr && dt.snr.split('_')[0]) === fwmbs);
        if (selectedData) {
          if (selectedData?.zgs && selectedData?.zgs.length !== 0){
            selectedData.snr = selectedData.snr?.split('_')[0].toString() + '_' + selectedData.zgs?.toString()
          }
          determinedRow.SG_FITTINGFLASHSW =  selectedData.snr?.toString()
          determinedRow.SG_FITTINGFLASHSW_PRIO =
            selectedData.priority?.toString();
          determinedRow.SG_FITTINGFLASHSW_SIZE =
            selectedData.totalSize?.toString();
          determinedRow.flashweg = selectedData.weg?.toString();
          determinedRow.latebound = selectedData.sourceFileName;
          determinedRow.xentryUpdate = selectedData.xentryUpdate;
        }
        this.determinedRows.push(determinedRow);
      });
    }
    this.orignalDeterminedRows = JSON.parse(
      JSON.stringify(this.determinedRows)
    );
  }

  updateFwmbsDatatable(event: FwbsResultEdit[][]): void {
    this.checkFwmbsTableEdit(event[0]);
  }

  onSelect(event: CheckBoxSelect): void {
    if (event && event.selected && event.selected.length) {
      const oldSelected: DeterminedResultEdit[] = [...this.selected];
      this.selected = event.selected || [...this.determinedRows];
      const diff: DeterminedResultEdit[] = [];
      if (oldSelected.length > this.selected.length) {
        oldSelected.forEach((element) => {
          this.selected.forEach((row) => {
            if (JSON.stringify(element) !== JSON.stringify(row)) {
              diff.push(element);
            }
          });
        });
      } else {
        this.selected.forEach((element) => {
          oldSelected.forEach((row) => {
            if (JSON.stringify(element) !== JSON.stringify(row)) {
              diff.push(element);
            }
          });
        });
      }
      const combArray: DeterminedResultEdit[] = this.selected.concat(diff);
      this.determinedRows.forEach((element) => {
        let found = false;
        combArray.forEach((row) => {
          if (JSON.stringify(element) === JSON.stringify(row)) {
            found = true;
            row.SG_NEWFLASHSW = row.SG_FITTINGFLASHSW;
            row.SG_NEWFLASHSW_PRIO = row.SG_FITTINGFLASHSW_PRIO;
            row.SG_NEWFLASHSW_FLASHWEG = row.flashweg;
          }
        });
        element.selected = found;
      });
    }
    if (event && event.selected && event.selected.length === 0) {
      this.determinedRows.forEach((element) => {
        element.selected = false;
      });
      this.selected = [];
    }
  }

  updateDeterminedDatatable(event: DeterminedResultEdit[][]): void {
    this.checkDeterminedTableEdit(event[0]);
  }

  deleteFlashRecord(row: DeterminedResultEdit) {
    const index =
      this.orignalDeterminedRows.findIndex((record) => record.id === row.id) &&
      this.determinedRows.findIndex((record) => record.id === row.id);
    const selectedIndex = this.selected.findIndex(
      (record) => record.id === row.id
    );

    if (index > -1) {
      this.determinedRows.splice(index, 1);
      this.orignalDeterminedRows.splice(index, 1);
    }

    if (selectedIndex > -1) {
      this.selected.splice(selectedIndex, 1);
    }
    this.reloadTable();
  }

  copyFwmbsRows(): void {
    this.reloadFwmbsTable();
  }

  save() {
    this.verifyFlash();
    const SG_FITTING_FWINFO_ARR =
      this.data && this.data.SG_FITTING_FWINFO?.split('|');
    const SG_FITTING_FWINFO =
      this.determinedRows &&
      this.determinedRows.map((row, index) => {
        const orignal =
          SG_FITTING_FWINFO_ARR && SG_FITTING_FWINFO_ARR[index]
            ? SG_FITTING_FWINFO_ARR[index]
            : '';
        const newType = row.type;
        const newLatebound = row.latebound;
        const newFlashweg = row.flashweg;
        const newXentryUpdate = row.xentryUpdate;
        const origanlArr = orignal.split(',');
        if (origanlArr && origanlArr.length) {
          origanlArr[0] = newXentryUpdate ? newXentryUpdate : '';
          origanlArr[1] = newType ? newType : '';
          origanlArr[4] = newLatebound ? newLatebound : '';
          origanlArr[2] = newFlashweg ? newFlashweg : '';
          origanlArr[3] = origanlArr[3] ? origanlArr[3] : '0';
        }
        return origanlArr.join();
      });
    const attributes = this.form.get('attributes')?.value;
    const attrSplitArr = attributes ? attributes.split('\n') : [];
    const record: FlashwareResponse = {
      SG_DiogName: this.form.get('SG_DiogName')?.value,
      SG_Current_SW: this.form.get('SG_Current_SW')?.value,
      SG_REFNR: this.form.get('SG_REFNR')?.value,
      ECU_Refer: this.form.get('ECU_Refer')?.value,
      FW_MBS:
        this.fwmbsRows && this.fwmbsRows?.map((row) => row.FW_MBS).join(' '),
      SG_FITTINGFLASHSW:
        this.determinedRows &&
        this.determinedRows.map((row) => row.SG_FITTINGFLASHSW).join(' '),
      SG_FITTING_FWINFO: this.determinedRows && SG_FITTING_FWINFO.join('|'),
      SG_FITTINGFLASHSW_PRIO:
        this.determinedRows &&
        this.determinedRows.map((row) => row.SG_FITTINGFLASHSW_PRIO).join('|'),
      SG_FITTINGFLASHSW_SIZE:
        this.determinedRows &&
        this.determinedRows.map((row) => row.SG_FITTINGFLASHSW_SIZE).join('|'),
      SG_NEWFLASHSW:
        this.determinedRows &&
        this.determinedRows
          .filter((row) => row.SG_NEWFLASHSW)
          .map((row) => row.SG_NEWFLASHSW)
          .join('|'),

      SG_NEWFLASHSW_PRIO:
        this.selected &&
        this.selected
          .filter((row) => row.SG_FITTINGFLASHSW_PRIO)
          .map((row) => row.SG_FITTINGFLASHSW_PRIO)
          .join('|'),

      SG_NEWFLASHSW_FLASHWEG:
        this.selected &&
        this.selected
          .filter((row) => row.flashweg)
          .map((row) => row.flashweg)
          .join('|'),

      attributes: this.form.get('attributes')?.value || '',
      FW_KETTE: this.form.get('FW_KETTE')?.value,
      VP_Methode: this.form.get('VP_Methode')?.value,
      Flashbedarf: this.form.get('Flashbedarf')?.value,
      id:
        this.data && this.data.id > -1
          ? this.data.id
          : this.dataList.length + 1,
      disabled: true,
      selected: true,
    };
    attrSplitArr.forEach((element) => {
      const key = element.split('=')[0];
      const value = element.split('=')[1];
      if (record[key]) {
        this.form.get(key)?.setValue(value);
      }
      record[key] = value;
    });
    const finalRecord = {
      ok: ModalResult.INSERT_SUCCESSFUL,
      index: this.index,
      checkFlag:
        this.data &&
        this.data.SG_DiogName !== this.form.get('SG_DiogName')?.value,
      record,
    };

    const isValid = this.validationService.validateKeyValuePairs(
      attributes,
      '\n',
      '='
    );
    if (isValid) {
      this.activeModal.close(finalRecord);
    }
  }

  cancel() {
    this.activeModal.close(null);
  }

  openAddNewRecordView(): void {
    this.dataFromChild = null;
    this.showDiv.current = false;
    this.showDiv.next = true;
    this.showImport.current = false;
    this.showImport.next = false;
  }

  openMultiImportRecordView(): void {
    this.showImport.current = false;
    this.showImport.next = true;
    this.showDiv.current = false;
    this.showDiv.next = false;
  }

  openEditRecordView(data: DeterminedResultEdit, index?: number): void {
    this.dataFromChild = data;
    this.showDiv.current = false;
    this.showDiv.next = true;
    this.showImport.current = false;
    this.showImport.next = false;
  }

  backToFirstView(): void {
    this.showDiv.current = true;
    this.showDiv.next = false;
    this.showImport.current = true;
    this.showDiv.next = false;
  }

  private resetSelected() {
    this.selected = this.determinedRows.filter((row) => row.selected);
  }

  eventFromSecondView(data) {
    this.dataFromChild = data;
    // Case: Multi flashware auto Import
    if (Array.isArray(data)) {
     this.mapMultiFlashwareData(data);
    } else {
      // Case: Normal insert by input fields
      this.mapSingleFlashwareData(data);
    }

    this.backToFirstView();
  }

  eventFromMultiImport(data) {
    this.backToFirstView();
  }

  isInputInvalid(controlName: string): boolean | undefined {
    return this.validationService.checkInputValidity(this.form, controlName);
  }

  private mapMultiFlashwareData(data) {
   const newRecords: DeterminedResultEdit[]= data.map((dt, index) => {
    if (dt.zgs && dt.zgs?.length !== 0){
      dt.snr = dt.snr?.split('_')[0] + '_' + dt.zgs
    }
      return {
        SG_DiogName: dt.dioName,
        id: index,
        SG_FITTINGFLASHSW: dt.snr,
        SG_NEWFLASHSW: dt.snr,
        SG_FITTINGFLASHSW_PRIO: dt.priority? dt.priority: undefined,
        SG_FITTINGFLASHSW_SIZE: dt.totalSize? dt.totalSize: undefined,
        flashweg:  dt.weg? dt.weg: undefined,
        type: dt.flashFormat? dt.flashFormat: undefined,
        latebound: dt.sourceFileName? dt.sourceFileName: undefined,
        xentryUpdate: dt.xentryUpdate ? dt.xentryUpdate: undefined,
        selected: true,
      } as DeterminedResultEdit;
    });
    const filteredData = newRecords.filter(dt => !this.determinedRows.find(row => row.SG_FITTINGFLASHSW?.split('_')[0] === dt.SG_FITTINGFLASHSW?.split('_')[0]));
    this.determinedRows = filteredData.concat( ...this.determinedRows);

    filteredData.forEach((row) => {
      this.selected.push((row));
    });

    this.orignalDeterminedRows = JSON.parse(
      JSON.stringify(this.determinedRows)
    ); 
  }


  private mapSingleFlashwareData(data) {
    if (this.dataFromChild) {
      const diogName = this.data
        ? this.data.SG_DiogName
        : this.form.get('SG_DiogName')?.value;

      const fittingFlashExist = this.determinedRows.find(
        (row) => row.SG_FITTINGFLASHSW?.split('_')[0] === data.SG_FITTINGFLASHSW?.split('_')[0]
      );
      const foundDeterminedRow = this.determinedRows.find(
        (row) => row.id === data.id
      );
      if (foundDeterminedRow) {

        const keys = Object.keys(data);
        keys.forEach((key) => {
          if (foundDeterminedRow) {
            foundDeterminedRow[key] = data[key];
            
          }
        });
        this.orignalDeterminedRows = JSON.parse(
          JSON.stringify(this.determinedRows)
        );
        this.resetSelected();
      } else {
        this.dataFromChild.id = this.orignalDeterminedRows.length || 0;
        this.dataFromChild.SG_DiogName = diogName;
        this.orignalDeterminedRows.push(this.dataFromChild);
        if (this.dataFromChild.zgs && this.dataFromChild.zgs.length !== 0){
          this.dataFromChild.SG_FITTINGFLASHSW = this.dataFromChild.SG_FITTINGFLASHSW?.split('_')[0] + '_' + this.dataFromChild.zgs
        }
        const newRecord: DeterminedResultEdit = {
          SG_DiogName: diogName, 
          id: this.dataFromChild.id,
          SG_FITTINGFLASHSW: this.dataFromChild.SG_FITTINGFLASHSW,
          zgs : this.dataFromChild.zgs,
          SG_FITTINGFLASHSW_PRIO: this.dataFromChild.SG_FITTINGFLASHSW_PRIO,
          SG_FITTINGFLASHSW_SIZE: this.dataFromChild.SG_FITTINGFLASHSW_SIZE,
          flashweg: this.dataFromChild.flashweg,
          type: this.dataFromChild.type,
          latebound: this.dataFromChild.latebound,
          xentryUpdate: this.dataFromChild.xentryUpdate,
        };
        if (this.dataFromChild.selected) {
          newRecord['SG_NEWFLASHSW'] = this.dataFromChild.SG_FITTINGFLASHSW;
          newRecord.selected = true;
          this.selected.push(newRecord);

          if (fittingFlashExist) {
            this.notificationService.showError(
              '',
              this.translocoService.translate(
                'modules.expert-tool.result.modal.determined-flash-validation'
              )
            );
            return;
          }
        }
        this.determinedRows.push(newRecord);
      }
    }
  }
}
