import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { AppService } from '@core/services/app.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-upload-duration',
  templateUrl: './upload-duration.component.html',
  styleUrls: ['./upload-duration.component.scss'],
})
export class UploadDurationComponent implements OnInit, OnChanges {
  @Input() dataList: any;
  @Input() estimatedTime!: number;
  @Input() translationKey!: string;
  @Input() isError!: boolean;
  public notificationMessage!: string;

  constructor(
    private translocoService: TranslocoService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.handleI18nSwitch();
  }

  ngOnChanges(): void {
    this.handleI18nSwitch();
  }

  handleI18nSwitch(): void {
    setTimeout(() => {
      this.loadMessage();
    }, 100);
    this.appService.languageSubject.subscribe(() => {
      setTimeout(() => {
        this.loadMessage();
      }, 100);
    });
  }

  loadMessage(): string {
    if (this.estimatedTime) {
      this.notificationMessage = this.getTranslationMessage(
        this.estimatedTime,
        this.dataList?.length * this.estimatedTime
      );
    } else {
      this.notificationMessage = this.getTranslationMessage(
        undefined,
        undefined,
        this.isError
      );
    }
    return this.notificationMessage;
  }

  getTranslationMessage(
    estimated?: number,
    multiplier?: number,
    isError?: boolean
  ): string {
    return this.translocoService.translate(this.translationKey, {
      estimated,
      multiplier,
      isError,
    });
  }
}
