import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { VehicleDataService } from '@core/services/vehicle-data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import {
  FilteredVehicleCode,
  VehicleCode,
  VehicleCodeResponse,
} from '@shared/models/experttool/VedocData';
import { ModalResult } from '@shared/models/ModalResult';
import { Page } from '@shared/models/Page';
import { CheckBoxSelect } from '@shared/models/Select';
import { GeneralPageInfo, SortInfo } from '@shared/models/SortInfo';
import { SelectionType } from '@swimlane/ngx-datatable';

import { FcscFwVehicleCodesFilterComponent } from './fcsc-fw-vehicle-codes-filter/fcsc-fw-vehicle-codes-filter.component';

@Component({
  selector: 'app-fcsc-fw-vehicle-codes-modal',
  templateUrl: './fcsc-fw-vehicle-codes-modal.component.html',
  styleUrls: ['./fcsc-fw-vehicle-codes-modal.component.scss'],
})
export class FcscFwVehicleCodesModalComponent implements OnInit {
  loadingTitle = 'global.loading-spinner-fetch-title';
  loadingSubtitle = 'global.loading-spinner-fetch-subtitle';
  isLoading = false;

  @Input() codeList!: string[];
  @Input() filteredData: FilteredVehicleCode[] = [];
  @Input() fin!: string;
  page = new Page();
  sortConfig!: Array<{}>;
  paginationDataSetting!: any;
  params: GeneralPageInfo | undefined;
  paramsCheckBox: GeneralPageInfo | undefined;
  vehicleCodeData: VehicleCode[] = [];
  selected: VehicleCode[] = [];
  finalSelectedMap = {};
  dataTableSelection = SelectionType.checkbox;
  emptyMessage = 'modules.expert-tool.empty-table-result-modal';
  selectedFilter!: FilteredVehicleCode;
  selectedFilterCheckBox!: FilteredVehicleCode;
  response!: VehicleCodeResponse | null;
  isNaN = Number.isNaN;
  form!: UntypedFormGroup;
  @ViewChild('filterComponent')
  filterComponent!: FcscFwVehicleCodesFilterComponent;
  codeNotExisting: string[] = [];
  baureihe!: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public activeModal: NgbActiveModal,
    private vehicleDataService: VehicleDataService,
    private translocoService: TranslocoService,
    private notificationService: ErrorHandlerService,
    private steuerdatenService: SteuerdatenService,
    private appService: AppService,
    private userSettingService: UserSettingService
  ) {}

  ngOnInit(): void {
    this.baureihe = `C${this.steuerdatenService.extractSerieFromFinVin(
      this.fin
    )}`;
    this.createForm();
    this.refreshDataTable();
  }

  loadDescription() {
    const currentLanguageKey =
      localStorage.getItem('language') === 'en' ? 'english' : 'deutsch';
    this.response?.content.forEach((ct) => {
      ct.description = ct[currentLanguageKey];
    });

    this.vehicleCodeData = (this.response && this.response?.content) || [];
    const filteredList = this.vehicleCodeData.filter(
      (vcd) => vcd.alreadySelected
    );
    const codes = this.vehicleCodeData.map((cd) => cd.code);
    this.selected = [...filteredList];
    const codeNotExisting: string[] = [];
    this.codeList.forEach((code) => {
      if (!codes.includes(code)) {
        codeNotExisting.push(code);
      }
    });
    this.codeNotExisting = codeNotExisting;
    const filteredListCopy = JSON.parse(JSON.stringify(filteredList));
    this.finalSelectedMap[this.page.pageNumber] = filteredListCopy.map(
      (cd) => cd.code
    );
  }

  retrieveVehicleCodeData(code: string[], params?: FilteredVehicleCode) {
    this.isLoading = true;
    let codeCheck = code ? code : [];
    this.vehicleDataService.postVehicleCodes(codeCheck, params).subscribe(
      (data: HttpResponse<VehicleCodeResponse>) => {
        this.isLoading = false;
        this.response = data.body;
        this.loadDescription();
      },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.data-management.serien-daten.modal.bdp-activation-error-message'
        );
      }
    );
  }

  onSelect(event: CheckBoxSelect): void {
    this.selected = [...event.selected];
    const selectedCodes = this.selected.map((item) => item.code);
    this.finalSelectedMap[this.page.pageNumber] = selectedCodes;
    this.getDataOnPageChange(this.page);
  }

  private resultCodes(): string[] {
    const selectedCodeseKeys = this.selected
      .map((item) => item.code)
      .concat(this.codeNotExisting);
    this.finalSelectedMap[this.page.pageNumber] = selectedCodeseKeys;

    const result: any[] = selectedCodeseKeys.filter(
      (item, pos) => selectedCodeseKeys.indexOf(item) === pos
    );
    this.codeList = result.filter((el) => el !== '');
    return result;
  }

  /*  checkbox functionality */

  private createForm() {
    this.form = this.formBuilder.group({
      selectedOriginalCodes: new UntypedFormControl(false),
    });
  }

  handleCodesCheckChange() {
    this.refreshDataTable();
  }

  // buttons

  applyChangesAction(): void {
    const result = {
      ok: ModalResult.INSERT_SUCCESSFUL,
      selected: this.resultCodes(),
    };
    this.activeModal.close(result);
  }

  cancelAction(): void {
    this.activeModal.close(ModalResult.INSERT_CANCEL);
  }

  /*  pagination functionality */

  private async refreshDataTable() {
    await this.configPageInfoAfterInit();
    const isSelected = this.form.get('selectedOriginalCodes')?.value;
    if (isSelected) {
      await this.configPageInfoAfterInit();
      this.filterComponent.form?.get('codeOrDescription')?.setValue('');
      this.retrieveVehicleCodeData(this.codeList, this.paramsCheckBox);
    } else {
      if (this.filterComponent && this.filterComponent.formLoaded === true) {
        this.filterComponent.form?.get('codeOrDescription')?.setValue('');
      }
      this.retrieveVehicleCodeData(this.codeList, this.params);
    }
  }

  private getDataOnPageChange(page, list?) {
    const isSelected = this.form.get('selectedOriginalCodes')?.value;
    const filter = this.selectedFilter || {};
    const filterCheckBox = this.selectedFilterCheckBox || {};
    const selectedFilter = isSelected ? filterCheckBox : filter;
    const sortConf = [this.page.orderBy, this.page.orderDir].toString();
    selectedFilter.size = this.page.size.toString();
    selectedFilter.sort = sortConf.toString();
    selectedFilter.language = localStorage.getItem('language')?.toUpperCase();
    selectedFilter.baureihe = this.baureihe;
    if (!isSelected) {
      delete selectedFilter.showOnlySelectedCodes;
    } else {
      selectedFilter.showOnlySelectedCodes = true;
    }

    if (isSelected === true) {
      if (page) {
        selectedFilter.page = (+this.page.pageNumber - 1).toString();
      } else {
        selectedFilter.page = '0';
      }
      this.selectedFilterCheckBox = selectedFilter;
    } else if (isSelected === false) {
      if (page) {
        filter.page = (+this.page.pageNumber - 1).toString();
      } else {
        filter.page = '0';
      }
      this.selectedFilter = selectedFilter;
    }
    this.appService.resetDatatableScroll();
    this.retrieveVehicleCodeData(this.resultCodes(), selectedFilter);

    const value = {
      size: filter.size,
      sort: filter.sort,
    };
    this.saveUserSetting(value);
  }

  public async setFilter(event: FilteredVehicleCode) {
    if (!event) {
      this.vehicleCodeData = [];
    }

    const isSelected = this.form.get('selectedOriginalCodes')?.value;
    const filterCodeDescription =
      event && event.codeOrDescription ? event.codeOrDescription : '';
    const filterCheckBox = this.selectedFilterCheckBox || {};
    const filter = this.selectedFilter || {};
    const selectedFilter = isSelected ? filterCheckBox : filter;
    await this.configPageFilterInfoAfterInit(selectedFilter);
    selectedFilter.codeOrDescription = filterCodeDescription;

    if (isSelected) {
      this.selectedFilter = { ...this.selectedFilter, ...filterCheckBox };
      selectedFilter.showOnlySelectedCodes = true;
      this.selectedFilterCheckBox = {
        ...this.selectedFilterCheckBox,
        ...filterCheckBox,
      };
    } else if (!isSelected) {
      delete selectedFilter.showOnlySelectedCodes;
      this.selectedFilter = { ...this.selectedFilter, ...filter };
    }
    this.retrieveVehicleCodeData(this.resultCodes(), selectedFilter);

    const value = {
      size: filter.size,
      sort: filter.sort,
    };
    this.saveUserSetting(value);
  }

  public setPageSize(size) {
    this.page.pageNumber = 1;
    this.page.size = size;
    this.getDataOnPageChange(null);
  }

  public setPage(pageInfo) {
    this.page.pageNumber = pageInfo.page;
    this.getDataOnPageChange(this.page, this.resultCodes);
  }

  public sortCallback(sortInfo: SortInfo) {
    const prop = sortInfo.sorts[0].prop;
    this.page.orderDir = sortInfo.sorts[0].dir;
    if (prop === 'description') {
      const currentLanguageKey =
        localStorage.getItem('language') === 'en' ? 'english' : 'deutsch';
      this.page.orderBy = currentLanguageKey;
    } else {
      this.page.orderBy = sortInfo.sorts[0].prop;
    }

    this.getDataOnPageChange(this.page, this.resultCodes());
  }

  // Saves user data on user interaction on sort, filter and page change.
  private saveUserSetting(data) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.experttool.vehicleCodesTable,
      data
    );
  }

  // Gets the user data or loads default ones.
  private async configPageInfoAfterInit() {
    this.paginationDataSetting =
      (await this.userSettingService.fetchUserSetting(
        userSettingKeys.experttool.vehicleCodesTable
      )) || {};

    const { sort, size } = this.paginationDataSetting;
    // Sort
    const sortData = sort || 'code,asc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    this.sortConfig = [{ prop: orderBy, dir: orderDir }];

    // Order
    this.page.orderDir = orderDir;
    this.page.orderBy = orderBy;

    // Page
    this.page.pageNumber = 1;

    // Size
    this.page.size = this.page.size = size || 30;

    const sortConfArr = [this.page.orderBy, this.page.orderDir].toString();

    this.params = {
      page: '0',
      size: size || '30',
      sort: sortConfArr,
      language: localStorage.getItem('language')?.toUpperCase(),
      baureihe: this.baureihe,
    };

    this.paramsCheckBox = {
      page: '0',
      size: size || '30',
      sort: sortConfArr,
      showOnlySelectedCodes: true,
      language: localStorage.getItem('language')?.toUpperCase(),
      baureihe: this.baureihe,
    };

    const returnedData = this.paramsCheckBox || this.params;
    return returnedData;
  }

  private async configPageFilterInfoAfterInit(filter: any) {
    await this.configPageInfoAfterInit();
    const { sort, size } = this.paginationDataSetting;
    const sortData = sort || 'code,asc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    const sortConfigFilter = [orderBy, orderDir];
    filter.page = '0';
    filter.size = size || '30';
    filter.sort = sortConfigFilter.toString();
    filter.language = localStorage.getItem('language')?.toUpperCase();
    filter.baureihe = this.baureihe;
  }
}
