<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>
<div>
    <div class="d-flex" id="wrapper" *ngIf="application">
        <app-navbar [application]="application" *ngIf="showNavigation"></app-navbar>
        <div id="page-content-wrapper">
            <app-header [application]="application" *ngIf="showNavigation"></app-header>
            <div>
                <router-outlet></router-outlet>
            </div>
        </div>
    </div>
</div>
