<p
  [ngClass]="isError === true ? 'notification-error-red' : 'notification-error'"

>
  <span
    class="header-icon"
  >
    <i class="bi bi-exclamation-triangle-fill"></i>
  </span>
  <span class="space-span">{{ notificationMessage }}</span>
</p>
