import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, ViewChild } from '@angular/core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DateFormat } from '@core/enums/date-format.enum';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { FileImportService } from '@core/services/files/import/file-import.service';
import { SecurityService } from '@core/services/security.service';
import { SharedSeriesService } from '@core/services/shared-series.service';
import { entitlements } from '@env/entitlements';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOption } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { SteuerDaten } from '@shared/models/filetype/SteuerDaten';
import { DeltaUpload, FileData } from '@shared/models/ImportFile'; // DeltaUploadRequest,
import { ModalResult } from '@shared/models/ModalResult';
import * as moment from 'moment';
import {
  FileSystemDirectoryEntry,
  FileSystemFileEntry,
  NgxFileDropEntry,
} from 'ngx-file-drop';

@Component({
  selector: 'app-fcsc-upload-vorab-folder',
  templateUrl: './fcsc-upload-vorab-folder.component.html',
  styleUrls: ['./fcsc-upload-vorab-folder.component.scss'],
})
export class FcscUploadVorabFolderComponent implements OnInit {
  @Input() message!: string;
  @ViewChild('browseBtnRef') el: any;
  public files: NgxFileDropEntry[] = [];
  public filesData: FileData[] = [];
  public deltaUploadfilesData: DeltaUpload[] | null = [];

  @Output() valueChange = new EventEmitter<SteuerDaten>();
  public createBdpEntitlement = [
    entitlements.VUS.FCSC_VORAB_IMAGE_BUILD_DEPLOY,
  ];

  showDiv = {
    previous: false,
    current: false,
  };

  seriesList!: NgOption[];
  formLoaded!: boolean;
  translatedAll!: any;
  form!: UntypedFormGroup;
  disableUpload = false;
  disableDeltaUpload = false;
  searchText = '';
  isLoading = false;
  loadingTitle = 'global.loading-spinner-fetch-title';
  loadingSubtitle = 'global.loading-spinner-fetch-subtitle';

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private changeDetectorRef: ChangeDetectorRef,
    private fileImportService: FileImportService,
    private notificationService: ErrorHandlerService,
    private translocoService: TranslocoService,
    private appService: AppService,
    public sharedSeriesService: SharedSeriesService,
    public securityService: SecurityService
  ) {}

  ngOnInit() {
    this.showDiv.current = true;
    this.showDiv.previous = false;
    if (!this.seriesList?.length) {
      const seriesEntitlements = entitlements.VUS.FCSC_VORAB_IMAGE_BUILD_DEPLOY;
      if (
        this.securityService
          .getEntitlements()
          .some(() =>
            this.securityService.getEntitlements().includes(seriesEntitlements)
          )
      ) {
        this.sharedSeriesService.getAdminSeriesList();
      }
    }
    this.appService.seriesSubject.subscribe((series: any) => {
      if (this.translatedAll) {
        this.setList(this.appService.addAll(series, this.translatedAll));
      } else {
        this.seriesList = series;
      }
    });
    this.appService
      .translateKey('modules.data-management.select-option')
      .subscribe((translation) => {
        if (this.seriesList?.length) {
          this.setList(this.appService.addAll(this.seriesList, translation));
        }
        this.translatedAll = translation;
        this.createForm(translation);
      });
  }

  private setList(list) {
    this.seriesList = list;
  }

  private createForm(translation?) {
    this.form = this.formBuilder.group({
      note: new UntypedFormControl(''),
      selectedSeries: new UntypedFormControl(translation),
      name: new UntypedFormControl(''),
    });
    this.formLoaded = true;
    this.form.get('selectedSeries')?.valueChanges.subscribe((val) => {
      if (val === this.translatedAll) {
        this.form.get('name')?.setValue('');
      } else {
        this.generateDefaultSdpName(val);
      }
    });
  }

  onSearchFn = (word: string, item: { brName: string }): boolean => {
    const extractedValue =
      this.seriesList &&
      this.seriesList?.map((itm) => {
        return itm.brName;
      });

    const filteredExtractedValue = extractedValue.filter(
      (exv) => exv !== this.translatedAll
    );

    const filteredVal = filteredExtractedValue.some((vl) =>
      vl.toLocaleLowerCase().includes(word.toLocaleLowerCase())
    );

    if (!filteredVal && this.searchText !== word) {
      this.searchText = word;
      this.notificationService.showError(
        '',
        this.translocoService.translate(
          'modules.data-management.delta-upload.messages.serie-not-found'
        )
      );
    }

    return item.brName.toLocaleLowerCase().includes(word.toLocaleLowerCase());
  };

  generateDefaultSdpName(val: string) {
    const now = moment();
    const generatedTime = now.format(DateFormat.SDP_CREATION_TIME);
    const selectedSeries = val;
    const generatedName = `${generatedTime}_${selectedSeries}_man`;
    this.form.get('name')?.setValue(generatedName);
  }

  public dropped(files: NgxFileDropEntry[]) {
    this.showDiv.current = false;
    this.showDiv.previous = true;
    this.files = files;
    let noFilesIncluded = true;
    for (const droppedFile of files) {
      // Is it a file?
      const isFileAllowed = this.isFileAllowed(droppedFile.fileEntry.name);
      const isSerieAllowed = this.isSerieAllowed(droppedFile.fileEntry.name);
      if (droppedFile.fileEntry.isFile) {
        if (isSerieAllowed) {
          noFilesIncluded = false;
        }
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file

          const modifiedDate = file.lastModified.toString();

          this.filesData.push({
            name: file.name,
            valid: true,
            modified: modifiedDate,
            file,
            relativePath: droppedFile.relativePath,
            isFileAllowed,
            isSerieAllowed,
          });
          this.changeDetectorRef.markForCheck();
          this.changeDetectorRef.detectChanges();
        });
      }
    }

    if (noFilesIncluded) {
      this.disableUpload = true;
      this.disableDeltaUpload = true;
      this.notificationService.showError(
        '',
        this.translocoService.translate(
          'modules.data-management.delta-upload.messages.serie-incompatible'
        )
      );
    } else {
      this.disableUpload = false;
      this.disableDeltaUpload = false;
    }
  }

  public openFileBrowse(): void {
    this.el.nativeElement.click();
  }

  isFileAllowed(fileName: string): boolean {
    let isFileAllowed = false;
    const allowedFiles = ['.csv', '.MAP', '.lst'];
    const regex = /(?:\.([^.]+))?$/;
    const extension = regex.exec(fileName);
    if (undefined !== extension && null !== extension) {
      for (const ext of allowedFiles) {
        if (ext === extension[0]) {
          isFileAllowed = true;
        }
      }
    }
    return isFileAllowed;
  }

  private isSerieAllowed(fileName: string) {
    return this.form.controls.selectedSeries.value === fileName.split('_')[0];
  }

  private resetFilesData(): void {
    this.files = [];
    this.filesData = [];
    this.deltaUploadfilesData = [];
  }

  private validateName(): boolean {
    let isValid = true;
    const name = this.form.get('name')?.value;
    if (!name) {
      isValid = false;
    }
    const splitName = name.split('_');
    if (splitName.length) {
      const serie = splitName[splitName.length - 2];
      const suffix = splitName[splitName.length - 1];
      const selectedSerie = this.form.get('selectedSeries')?.value;
      if (selectedSerie !== serie || suffix !== 'man') {
        isValid = false;
      }
    } else {
      isValid = false;
    }
    return isValid;
  }

  retrieveDeltaUploadFiles() {
    this.isLoading = true;
    const selectedSerie: string = this.form.get('selectedSeries')?.value;
    const payload = this.filesData
      .filter((file) => file.isFileAllowed && file.isSerieAllowed)
      .map((item) => item.name);

    this.fileImportService
      .putDeltaUploadFiles(selectedSerie, payload)
      .subscribe(
        (response) => {
          this.deltaUploadfilesData = response.body;
          this.disableDeltaUpload = true;
          if (
            this.deltaUploadfilesData &&
            this.deltaUploadfilesData.length === 0
          ) {
            this.notificationService.showSuccess(
              '',
              this.translocoService.translate(
                'modules.data-management.delta-upload.messages.files-already-complete'
              )
            );
          } else if (
            this.deltaUploadfilesData &&
            this.deltaUploadfilesData.length > 0
          ) {
            this.disableDeltaUpload = true;
            this.notificationService.showSuccess(
              '',
              this.translocoService.translate(
                'modules.data-management.delta-upload.messages.completion-success'
              )
            );
          }
          this.isLoading = false;
        },
        (error: HttpErrorResponse) => {
          this.disableDeltaUpload = false;
          this.isLoading = false;
          this.notificationService.handleServerErrorNotification(
            error,
            this.translocoService.translate(
              'modules.admin-section.config-modal.insert-edit-api-error-message'
            )
          );
        }
      );
  }

  // modal buttons
  public upload(): void {
    const isValid = this.validateName();
    const selectedSerie = this.form.get('selectedSeries')?.value;
    if (isValid) {
      const result = {
        status: ModalResult.IMPORT_SUCCESSFUL,
        form: this.form,
        filesData: this.filesData.filter(
          (file) => file.isFileAllowed && file.isSerieAllowed
        ),
        deltaUploadFileIds: this.deltaUploadfilesData?.map((item) => item.id),
      };
      this.activeModal.close(result);
    } else {
      this.notificationService.showError(
        '',
        this.translocoService.translate(
          'modules.data-management.modal-sdp-name-error',
          { serie: selectedSerie }
        )
      );
    }
  }

  public cancel(): void {
    this.activeModal.close(ModalResult.IMPORT_CANCEL);
  }

  public back(): void {
    this.showDiv.current = true;
    this.showDiv.previous = false;
    this.form.controls.selectedSeries.setValue(this.translatedAll);
    this.form.controls.note.setValue('');
    this.resetFilesData();
  }

  public checkSelection(): boolean {
    return (
      this.form.controls.selectedSeries.value ===
        this.translocoService.translate(
          'modules.data-management.select-option'
        ) || this.form.controls.selectedSeries.value === ''
    );
  }

  countFiles(): string {
    const droppedCounter = this.filesData.filter(
      (file) => file.isFileAllowed && file.isSerieAllowed
    );

    const combinedCounter = this.deltaUploadfilesData
      ? droppedCounter.length + this.deltaUploadfilesData.length
      : droppedCounter.length;

    return this.translocoService.translate(
      'modules.data-management.label-files-counter',
      { number: combinedCounter }
    );
  }
}
