<div class="container">
  <div class="forbidden">
    <div class="forbidden__403">
      <img src=" ./assets/forbidden/forbidden.svg" alt="Forbidden SVG" />
    </div>

    <div class="forbidden__content">
      <h1 class="forbidden__title">
        {{ "components.forbidden.title" | transloco }}
      </h1>

      <p class="forbidden__text">
        {{ "components.forbidden.subtite-1" | transloco }}<br />
        {{ "components.forbidden.subtite-2" | transloco }}
        <a
          class="forbidden__link"
          href="https://pages.git.i.mercedes-benz.com/vus/home/flashwarecalculation/fcsc/"
          >https://pages.git.i.mercedes-benz.com/vus/home/flashwarecalculation/fcsc/</a
        >
      </p>
    </div>
  </div>
</div>

<div class="outer-container">
  <div class="minimum-height"></div>
</div>
