<div class="modal-header">
  <span class="modal-title col-md-6">{{
    "components.header.modal.entitlements" | transloco
  }}</span>
  <div (click)="activeModal.dismiss()" class="col-md-6 text-right">
    <span
      class="close-icon"
      icon="times"
    >
      <i class="bi bi-x"></i>
    </span>
  </div>
</div>

<div class="modal-header">
  <span class="col">{{
    "components.header.modal.entitlements-sub-title" | transloco
  }}</span>

  <div class="col round-button-container">

    <span 
      title="{{
        'components.header.modal.entitlements-btn-placeholder' | transloco
      }}"
      (click)="copyEntitlements(entitlementsList)"
      class="main-icon"
    >
      <i class="bi bi-copy"></i>
    </span>
  </div>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [rows]="entitlementsList"
        >
          <ngx-datatable-column
            name="{{
              'components.header.modal.entitlements-title' | transloco
            }}"
            prop="entitlementName"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'modules.data-management.table-header.files'
                    | transloco: { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
