import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { SecurityService } from '@core/services/security.service';
import {TestautomationService} from "@core/services/testautomation.service";
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {TranslocoService} from "@ngneat/transloco";
import { ConfirmatonModalComponent } from '@shared/components/confirmaton-modal/confirmaton-modal.component';
import {Observable, Observer, Subject, takeUntil} from "rxjs";

@Component({
  selector: 'app-test-start',
  templateUrl: './test-start.component.html',
  styleUrls: ['./test-start.component.scss']
})
export class TestStartComponent implements OnInit {
  @Input() testIsRunning: boolean;
  @Input() activeTestId: number;
  @Output() testStartedEvent = new EventEmitter<boolean>();
  isLoading = false;
  loadingTitle: string;
  loadingSubtitle: string;
  private notifier = new Subject();

  constructor(
    private testautomationService: TestautomationService,
    private translocoService: TranslocoService,
    private securityService: SecurityService,
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
  ) { }

 async ngOnInit(): Promise<void> {
    this.loadingTitle = this.testIsRunning ? 
        'modules.admin-section.testautomation.test-cancel.spinner-title':
        'modules.admin-section.testautomation.test-start.spinner-title';
      
    this.loadingSubtitle = this.testIsRunning ? 
        'modules.admin-section.testautomation.test-cancel.spinner-subtitle':
        'modules.admin-section.testautomation.test-start.spinner-subtitle'; 
  }

  startTest(){
    this.isLoading =true;
    this.loadingTitle = 'modules.admin-section.testautomation.test-start.spinner-title';
    this.loadingSubtitle = 'modules.admin-section.testautomation.test-start.spinner-subtitle'; 

    const username = this.securityService.getCurrentUser().getUsername();
    this.testautomationService.startTestRun().subscribe(
      response =>{
        console.log(this.translocoService.translate(
          'modules.admin-section.testautomation.test-start.start-success', response
        ));
        this.getActiveTestRunIdWithTimeout().subscribe(
          async (response: number) => {
            console.log(response);
            this.isLoading = false;
            await this.testautomationService.postActiveTestRunId(response, username).subscribe((resp) => {
              this.testStartedEvent.emit(true);
              this.checkUntilTestStopped(response, username).subscribe(
                  (response: number) => {
                      this.testStartedEvent.emit(false);
                  },
                  (error: any) => {
                      console.error(error);
                  }
              );
          });
          },
          (error: any) => {
            console.error(error);
          }
        );

      },
      error => {
        console.error(this.translocoService.translate(
          'modules.admin-section.testautomation.test-start.start-fail', error
        ));
      }
    );
  }

  checkUntilTestStopped(response, username): Observable<number> {
    return new Observable<number>((observer: Observer<number>) => {
        const makeBackendCall = () => {
            this.testautomationService.postActiveTestRunId(response, username).subscribe(
                (response: number) => {
                    if (response !== null) {
                        setTimeout(() => {
                            makeBackendCall();
                        }, 3000);
                    } else {
                        observer.next(response);
                        observer.complete();
                    }
                },
                (error: any) => {
                  console.error(error);
                }
            );
        };
        makeBackendCall();
    });
}


  getActiveTestRunIdWithTimeout(){
    return new Observable<number>((observer: Observer<number>) => {
      const makeBackendCall = () => {
        this.testautomationService.getActiveTestRunId().subscribe(
          (response: number) => {
            if (response !== null) {
              observer.next(response);
              observer.complete();
            } else {
              setTimeout(() => {
                makeBackendCall();
              }, 3000);
            }
          },
          (error: any) => {
            observer.error(error);
          }
        );
      };
      makeBackendCall();
    });

  }

  openConfirmationModal() {
    const modalRef = this.modalService.open(ConfirmatonModalComponent, {
        size: 'lg',
        windowClass: 'confirmation-modal',
        backdrop: 'static',
        keyboard: false,
    });
    this.activeModal.close([]);
    const message = (modalRef.componentInstance.title =
      this.translocoService.translate(
        'modules.admin-section.testautomation.test-cancel.confirm-title'
      ));
    modalRef.componentInstance.subText = this.translocoService.translate(
      'modules.admin-section.testautomation.test-cancel.confirm-text'
    );
    modalRef.componentInstance.buttonText = this.translocoService.translate(
      'modules.admin-section.testautomation.test-cancel.confirm-confirm'
    );

    modalRef.componentInstance.buttonSecondaryText =
      this.translocoService.translate(
        'modules.admin-section.testautomation.test-cancel.confirm-cancel'
      );
    modalRef.componentInstance.closingOption = false;
    modalRef.result.then(
        result => {
            this.loadingTitle = 'modules.admin-section.testautomation.test-cancel.spinner-title';
            this.loadingSubtitle = 'modules.admin-section.testautomation.test-cancel.spinner-subtitle';
            this.isLoading = true;
           
            this.getActiveTestRunId();
            this.testautomationService.cancelActiveTest(this.activeTestId).subscribe(
                response =>{
                    console.log(
                        'Test successfully aborted!', response
                    );
                    this.checkUntilTestStoppedGet().subscribe(
                      (response: number) => {
                          console.log(this.translocoService.translate(
                            'modules.admin-section.testautomation.test-cancel.cancel-success'
                          ));
                          this.isLoading = false;
                          this.testStartedEvent.emit(false);
                        },
                        (error: any) => {
                            console.error(error);
                        },
                        () => {
                            console.log('Observable completed');
                        }
                    );
                },
                error => {
                  console.error(this.translocoService.translate(
                    'modules.admin-section.testautomation.test-cancel.cancel-fail', error
                  ));
                }
            );
    });
}

private getActiveTestRunId(){
    this.isLoading = true;
    this.testautomationService.getActiveTestRunId()
        .pipe(
          takeUntil(this.notifier)
        )
        .subscribe(
            (resp : number) =>{
                this.activeTestId = resp;
                this.isLoading = false;
            },
            (error: any) => {
                console.error(error);
                this.isLoading = false;
            }
        )
}

checkUntilTestStoppedGet(): Observable<number> {
    return new Observable<number>((observer: Observer<number>) => {
        const makeBackendCall = () => {
            this.testautomationService.getActiveTestRunId().subscribe(
                (response: number) => {
                    if (response !== null) {
                        setTimeout(() => {
                            makeBackendCall();
                        }, 3000);
                    } else {
                        observer.next(response);
                        observer.complete();
                    }
                },
                (error: any) => {
                    observer.error(error);
                }
            );
        };
        makeBackendCall();
    });
}
}
