import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ValidationService } from '@core/services/validation.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-fcsc-fw-edit-modal',
  templateUrl: './fcsc-fw-edit-modal.component.html',
  styleUrls: ['./fcsc-fw-edit-modal.component.scss'],
})
export class FcscFwEditModalComponent implements OnInit {
  @Input() title!: string;
  @Input() subText!: string;
  @Input() data!: string;
  @Input() fieldText!: string;

  form!: UntypedFormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private validationService: ValidationService
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  private createForm() {
    this.form = this.formBuilder.group({
      field: new UntypedFormControl(this.data),
    });
  }

  cancel() {
    this.activeModal.close(null);
  }

  save() {
    const dataAttribute =
      this.fieldText !== 'Codes' &&
      this.form.get('field')?.value &&
      Array.isArray(this.form.get('field')?.value)
        ? this.form.get('field')?.value[0]
        : this.form.get('field')?.value;

    const dataCodes =
      this.fieldText === 'Codes' && this.form.get('field')?.value;

    let isValid = true;

    if (this.fieldText !== 'Codes') {
      isValid = this.validationService.validateKeyValuePairs(
        dataAttribute,
        '\n',
        '='
      );
    }

    if (isValid) {
      const result = {
        key: 'OK',
        data: this.fieldText !== 'Codes' ? dataAttribute : dataCodes,
      };
      this.activeModal.close(result);
    }
  }
}
