import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@env/paths';
import { UserSetting } from '@shared/models/UserSetting';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root',
})
export class UserSettingService {
  private steuerungBaseUrl = '';

  constructor(
    private httpClient: HttpClient,
    private envService: EnvService,
    private storageService: StorageService
  ) {
    this.steuerungBaseUrl = `${envService.apiUrl}${apiPaths.fcscSteuerung}/api`;
  }

  getUserSetting(key: string): Observable<HttpResponse<UserSetting>> {
    const url = `${this.steuerungBaseUrl}/user-settings/${key}`;
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });
    return this.httpClient.get<UserSetting>(url, {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
    });
  }

  putUserSetting(
    payload: UserSetting[]
  ): Observable<HttpResponse<UserSetting>> {
    const url = `${this.steuerungBaseUrl}/user-settings`; // user-settings-put
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    return this.httpClient.put<UserSetting>(url, payload, {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
    });
  }

  saveUserSetting(key: string, data) {
    const payload: UserSetting[] = [
      {
        key,
        value: JSON.stringify(data),
      },
    ];
    this.putUserSetting(payload).subscribe();
  }

  fetchUserSetting(key: string, localKey?: string) {
    return new Promise((resolve, reject) => {
      this.getUserSetting(key).subscribe(
        (data: any) => {
          const value = data.body.value;
          resolve(JSON.parse(value));
        },
        (error: HttpErrorResponse) => {
          if (error) {
            if (localKey) {
              resolve(this.storageService.getData(localKey));
            } else {
              resolve({});
            }
          }
        }
      );
    });
  }
}
