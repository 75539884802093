import { ExtConstants } from '@shared/lists/flashFileExtension';

/*
 * Initial/ default FlashFrom values
 */
export class FlashwareForm {
  constructor(
    public logLevel: string = '2',
    public fileExt: string = ExtConstants.DEFAULT,
    public bdpName: string = '',
    public sdpName: string = '',
    public xentryReleases: string = '2100.10.000',
    public abfragen: boolean = true
  ) {}
}
