<ng-container  *ngIf="navItems && navItems.length > 0" > 
  <ng-container   *ngFor="let nav of navItems; let index = index" > 
  <div class="vus-breadcrumb" *appRequiredEntitlements="nav.entitlementKey ? nav.entitlementKey : []"> 
  <span>
      <span 
        class="container-title icon home-icon" 
        *ngIf="!nav.title"
        [ngClass]="{'selected' : nav.isSelected}"
        (click)="onItemSelect(nav.path, index, true)"
      >
        <i class="bi bi-house-door-fill"></i>
      </span>
      <span 
        class="container-title icon chevron-icon"
        *ngIf="index > 0"
      >
        <i class="bi bi-chevron-right"></i>
      </span>
    <span  class="container-title text" [ngClass]="{'selected' : nav.isSelected}"
      (click)="onItemSelect(nav.path, index, false)" *ngIf="nav.title" >{{nav.title}}</span>
  </span>
</div>
</ng-container>
</ng-container>