import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DownloadService } from '@core/services/download.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { entitlements } from '@env/entitlements';
import { FcscUploadVorabAlertComponent } from '@fcsc/dialogs/fcsc-upload-vorab-alert/fcsc-upload-vorab-alert.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FileData } from '@shared/models/ImportFile';
import { ModalResult } from '@shared/models/ModalResult';
import { SteuerdatenPaketFile } from '@shared/models/SteuerdatenPaketFile';

@Component({
  selector: 'app-fcsc-sdp-details',
  templateUrl: './fcsc-sdp-details.component.html',
  styleUrls: ['./fcsc-sdp-details.component.scss'],
})
export class FcscSdpDetailsComponent implements OnInit {
  @Input() title!: string;
  @Input() sdpName!: string;
  @Input() showCrossClose!: boolean;
  steuerdatenFiles: SteuerdatenPaketFile[] = [];
  isLoading = false;
  loadingTitle = 'global.loading-spinner-fetch-title';
  loadingSubtitle = 'global.loading-spinner-fetch-subtitle';

  public fileExportEntitlement = [entitlements.VUS.FCSC_VORAB_DATA_EXPORT];

  constructor(
    public activeModal: NgbActiveModal,
    private steuerdatenService: SteuerdatenService,
    private downloadService: DownloadService,
    private modalService: NgbModal,
    private notificationService: ErrorHandlerService
  ) {}

  private getSteuerDatenPaketFiles(steuerdatenPaketName: string): void {
    this.isLoading = true;
    this.steuerdatenService
      .getSteuerDatenPaketFiles(steuerdatenPaketName)
      .subscribe(
        (data: SteuerdatenPaketFile[]) => {
          this.isLoading = false;
          this.steuerdatenFiles = data;
        },
        (error: HttpErrorResponse) => {
          this.isLoading = false;
          this.notificationService.handleServerErrorNotification(error, '');
        }
      );
  }

  public exportFile(file: SteuerdatenPaketFile) {
    this.steuerdatenService.getExportFile(file).subscribe(
      (response: Blob) => {
        this.downloadService.downloadFile(response, file.name);
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  public backToFirstView() {
    const result = {
      back: ModalResult.BACK_VIEW,
    };
    this.activeModal.close(result);
  }

  ngOnInit() {
    this.getSteuerDatenPaketFiles(this.sdpName);
  }
}
