export class CalculateKeyValGeneric {
  key: string;
  value:
    | string
    | string[]
    | number
    | CalculateKeyValGeneric[]
    | CalculateKeyValGeneric;

  constructor(
    key: string,
    value:
      | string
      | string[]
      | number
      | CalculateKeyValGeneric
      | CalculateKeyValGeneric[]
  ) {
    this.key = key;
    this.value = value;
  }
}
