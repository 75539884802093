import {Injectable} from "@angular/core";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {EnvService} from "@core/services/env.service";
import {apiPaths} from "@env/paths";
import { Observable } from 'rxjs';
import {TestautomationTest} from "@shared/models/testautomation/TestautomationTest";
import {TestautomationTestDetail} from "@shared/models/testautomation/TestautomationTestDetail";

@Injectable({
  providedIn: 'root',
})
export class TestautomationService {
  private testautomationBaseUrl = '';

  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.testautomationBaseUrl= `${envService.apiUrl}${apiPaths.fcscTestautomation}/api`;
  }

  getTestRuns():Observable<TestautomationTest[]> {
    const url = `${this.testautomationBaseUrl}/test-run`;
    return this.httpClient.get<TestautomationTest[]>(url);
  }

  getTestDetails(key:string):Observable<TestautomationTestDetail[]> {
    const url = `${this.testautomationBaseUrl}/test-run/${key}/test-case`;
    return this.httpClient.get<TestautomationTestDetail[]>(url);
  }

  downloadTestResult(key: string): Observable<HttpResponse<Blob>>{
    const url = `${this.testautomationBaseUrl}/test-run/file/${key}`;
    return this.httpClient.get(url, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  getActiveTestRunId():Observable<number>{
    const url = `${this.testautomationBaseUrl}/test-run/active`
    return this.httpClient.get<number>(url);
  }

  postActiveTestRunId(id, username):Observable<number>{
    const url = `${this.testautomationBaseUrl}/test-run/active`
    const startTime = new Date();
    return this.httpClient.post<number>(url,{
      id : id,
      username : username,
      timeStamp: startTime.toISOString()
    });
  }

  cancelActiveTest(id : number){
    const url = `${this.testautomationBaseUrl}/test-run/${id}/cancel`;
    return this.httpClient.post(url, {id: id});
  }

  startTestRun(){
    const url = `${this.testautomationBaseUrl}/test-run`;
    return this.httpClient.post(url, {});
  }
}
