import {Component, Input, OnChanges, OnDestroy, OnInit, ViewChild} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { ExperttoolDataModelingService } from '@core/services/experttool-data-modeling/experttool-data-modeling.service';
import { StorageService } from '@core/services/storage.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { ValidationService } from '@core/services/validation.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { expertToolConstants } from '@shared/constants/ExpertToolConstants';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import { ExceludedResponseDownloadKeys } from '@shared/lists/ExperttoolExceludedKeys';
import { ExpertToolCalculation } from '@shared/models/expertool-dynamic-models/ExpertToolCalculation';
import { CalculateKeyValGeneric } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateKeyValGeneric';
import { VedocData } from '@shared/models/experttool/VedocData';
import { FlashwareResponse } from '@shared/models/flashwareResponse';
import { FlashwareResponseInitial } from '@shared/models/flashwareResponseInitial';
import { ModalResult } from '@shared/models/ModalResult';
import { Page } from '@shared/models/Page';
import { CheckBoxSelect } from '@shared/models/Select';
import { GeneralPageInfo, SortInfo } from '@shared/models/SortInfo';
import { DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';
import { Subscription } from 'rxjs';

import { FcscCalculateModalComponent } from '../fcsc-calculate-modal/fcsc-calculate-modal.component';
import {SecurityService} from "@core/services/security.service";
import {entitlements} from "@env/entitlements";


@Component({
  selector: 'app-fcsc-calculate',
  templateUrl: './fcsc-calculate.component.html',
  styleUrls: ['./fcsc-calculate.component.scss'],
})
export class FcscCalculateComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('dataTable') dataTable!: DatatableComponent;
  @Input() vedocData!: VedocData;
  @Input() calculationData: ExpertToolCalculation | null;
  @Input() fromReader!: boolean;
  @Input() xentryReleasesData : string[];

  rows!: FlashwareResponse[];
  orignalRows!: FlashwareResponse[];
  sortConfig!: Array<{}>;
  params: GeneralPageInfo | undefined;
  page = new Page();
  paginationDataSetting!: any;
  selected: FlashwareResponse[] = [];
  dataTableSelection = SelectionType.checkbox;
  emptyMessage!: string;
  isCalculateSectionCollapsed = true;
  records!: FlashwareResponse[];
  searchKeyword = '';
  subscription!: Subscription;
  vpStatus!: number | null;
  CPWebinfo!: number | null;
  orignalVpStatus!: number | null;
  vpStatusText!: string | null;
  vpStatusClass!: string;
  initialFwRes: FlashwareResponseInitial = {};
  pageSize!: number;
  selectedSize!: number | string;
  allPageObject!: any;
  hasFSFManipulatorRights: boolean;

  constructor(
    public expertoolDataModelingService: ExperttoolDataModelingService,
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private translocoService: TranslocoService,
    private appService: AppService,
    private storageService: StorageService,
    private notificationService: ErrorHandlerService,
    private validationService: ValidationService,
    private userSettingService: UserSettingService,
    private securityService: SecurityService
  ) {}

  ngOnInit(): void {
    if (!this.fromReader) {
      this.configPageInfo();
    }
    const fsfEntitlement = entitlements.VUS.FCSC_FSF_MANIPULATION;
    this.hasFSFManipulatorRights = this.securityService.getEntitlements().some(() =>
      this.securityService.getEntitlements().includes(fsfEntitlement)
    );
    this.translateAllPaginationText();
  }

  ngOnDestroy() {
    this.storageService.removeItem(sessionStorageKeys.resultSelected);
  }

  ngOnChanges(): void {
    this.rows = [];
    this.orignalRows = [];
    if (!this.fromReader) {
      setTimeout(() => {
        this.populateData();
      }, 200);
    } else {
      this.configPageInfo();
    }
  }

  setVpStatus() {
    this.vpStatus =
      this.initialFwRes.VP_STATUS || this.initialFwRes.VP_STATUS === 0
        ? parseInt(this.initialFwRes.VP_STATUS, 10)
        : null;
    if (this.fromReader && this.xentryReleasesData) {
      const diagnosisRelease = this.expertoolDataModelingService.findDynamicValueFromKey(
        this.expertoolDataModelingService.getHeaderFromResponse(
          this.calculationData
        ) as CalculateKeyValGeneric[],
        expertToolConstants.keys.diagnosisRelease
      ) as string;
      if (diagnosisRelease.split('_').length > 1 && diagnosisRelease.includes('_')) {
        const convertedDiagnosisRelease = diagnosisRelease.split('_')[0] + '.' + diagnosisRelease.split('_')[1] + '.000'
        const xentryReleaseData = this.xentryReleasesData.find((obj) => obj === convertedDiagnosisRelease)
        if (xentryReleaseData == undefined && convertedDiagnosisRelease !== "") {
          this.vpStatus = -5
        }
      }
    }
    this.updateStatusInCalulateResponse();
  }

  setCPWebInfo() {
    this.CPWebinfo = this.initialFwRes.CPweb_Info
      ? parseInt(this.initialFwRes.CPweb_Info, 10)
      : null;
    this.updateStatusInCalulateResponse();
  }

  getEcusValue(value, key) {
    switch (key) {
      case expertToolConstants.keys.ecuRefer:
      case expertToolConstants.keys.fwKette:
        return this.expertoolDataModelingService.getJoinAndSplit(
          value,
          '\n ',
          '|'
        );
      case expertToolConstants.keys.sgFittingFlashsw:
        return this.expertoolDataModelingService.getJoinAndSplit(
          value,
          ' ',
          '|'
        );
      case expertToolConstants.keys.fwmbs:
        return this.expertoolDataModelingService.getJoinAndSplit(
          value,
          ' ',
          ','
        );
      default:
        return value;
    }
  }

  getEcusMapValueForDownload(value, key) {
    switch (key) {
      case expertToolConstants.keys.ecuRefer:
      case expertToolConstants.keys.fwKette:
        return this.expertoolDataModelingService.getJoinAndSplit(
          value,
          '|',
          '\n'
        );
      case expertToolConstants.keys.sgFittingFlashsw:
        return this.expertoolDataModelingService.getJoinAndSplit(
          value,
          '|',
          ' '
        );
      case expertToolConstants.keys.fwmbs:
        return this.expertoolDataModelingService.getJoinAndSplit(
          value,
          ',',
          ' '
        );

      case expertToolConstants.keys.sgCurrentSw:
       return this.expertoolDataModelingService.getJoinAndSplit(
          value,
          '|',
          ' '
        );

      case expertToolConstants.keys.flashbedarf:
        this.rows.map((ctu) => {
          value = this.translocoService.translate(value);
        });
        return value;
      default:
        return value;
    }
  }

  populateData() {
    this.populateStatusesFromResponse();
    const controlArray: FlashwareResponse[] = [];
    const ecus = this.calculationData?.payload.finLog.ecus;
    ecus?.forEach(
      (ecu: CalculateKeyValGeneric | CalculateKeyValGeneric[], index) => {
        const obj = {} as FlashwareResponse;
        (ecu as CalculateKeyValGeneric[])?.forEach((ec) => {
          obj.id = index;
          obj.selected = true;
          obj.Flashbedarf = this.translocoService.translate(
            this.expertoolDataModelingService.getResultFlashStatus(obj)
          );

          obj[ec.key] = this.getEcusValue(ec.value, ec.key);
          this.updateEachEcuInCalulateResponse(obj, false);
        });
        controlArray.push(obj);
      }
    );

    this.setVpStatus();
    this.setCPWebInfo();
    this.orignalVpStatus = this.vpStatus;

    this.populateVpStatusClass();
    this.populateVpStatusText();

    this.rows =
      this.storageService.getData(sessionStorageKeys.fwResponseData) ||
      JSON.parse(JSON.stringify(controlArray));
    this.storageService.saveData(sessionStorageKeys.fwResponseData, this.rows);
    this.selectedSize = this.allPageObject.value;
    if (!this.pageSize) {
      this.pageSize = this.rows.length;
    }

    this.records =
      this.storageService.getData(
        sessionStorageKeys.flashwareResponseRecords
      ) || JSON.parse(JSON.stringify(controlArray));
    this.storageService.saveData(
      sessionStorageKeys.flashwareResponseRecords,
      this.records
    );

    this.orignalRows =
      this.storageService.getData(sessionStorageKeys.fwResponseData) ||
      JSON.parse(JSON.stringify(controlArray));
    this.storageService.saveData(
      sessionStorageKeys.flashwareResponseRecords,
      this.orignalRows
    );

    this.setSelected(false);
    this.setDisability();
    if (!this.fromReader) {
      this.resetUserSetting();
    }
  }

  setPageSize(pageSize?, setDefualtPage?: boolean) {
    this.page.orderBy = this.dataTable.sorts
      ? this.dataTable.sorts[0].prop
      : 'SG_DiogName';
    this.page.orderDir = this.dataTable.sorts
      ? this.dataTable.sorts[0].dir
      : 'asc';
    if (!setDefualtPage) {
      this.dataTable.offset = 0;
    }

    if (!setDefualtPage) {
      this.dataTable.offset = 0;
    }
    if (pageSize) {
      this.pageSize = pageSize;
      this.selectedSize = pageSize;
    } else {
      this.pageSize = this.rows.length;
      this.selectedSize = 'all';
    }
    this.userSettingService.saveUserSetting(
      userSettingKeys.experttool.fwResultTable,
      this.loadPageParams()
    );
  }

  onSortCallback(sortInfo?: SortInfo) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.experttool.fwResultTable,
      this.loadPageParams()
    );
    this.setSelected(false);
  }

  onPageChange(event?) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.experttool.fwResultTable,
      this.loadPageParams()
    );
    this.appService.resetDatatableScroll();
  }

  private translateAllPaginationText() {
    this.translocoService
      .selectTranslate('components.footer.all')
      .subscribe((resp) => {
        this.allPageObject = { label: resp, value: 'all' };
      });
  }

  private setDisability(): void {
    this.rows.map((record) => (record.disabled = true));
    this.orignalRows.map((record) => (record.disabled = true));
  }

  checkRecordsLength() {
    const newHwsnrRec = this.rows?.map((el) => {
      return el?.SG_REFNR?.split(/(\s+)/).filter((e) => e.trim().length > 0);
    });
    const newFwmbsRec = this.rows?.map((el) => {
      return el?.FW_MBS
        ? el?.FW_MBS?.split(/(\s+)/).filter((e) => e.trim().length > 0)
        : '';
    });
    return (
      this.validationService.validateDataTableSingleRecord(newHwsnrRec) ||
      this.validationService.validateDataTableRecord(newFwmbsRec)
    );
  }

  populateVpStatusClass() {
    const vpStatus = this.vpStatus;
    this.vpStatusClass =
      vpStatus?.toString() && vpStatus >= 0
        ? 'success-vp-status'
        : 'error-vp-status';
  }

  populateVpStatusText() {
    const vpStatus = this.vpStatus;
    const cPWebInfo = this.CPWebinfo;

    if (!vpStatus) {
      this.vpStatusText = 'modules.expert-tool.result.vp-status-no';
    }

    if (cPWebInfo?.toString()) {
      if (vpStatus?.toString() && vpStatus === 0 && cPWebInfo === 4) {
        this.vpStatusText = `modules.expert-tool.result.vp-status-cases.0-cp-web-info-4`;
      } else if (vpStatus?.toString() && vpStatus === 1 && cPWebInfo === 1) {
        this.vpStatusText = `modules.expert-tool.result.vp-status-cases.1-cp-web-info-1`;
      } else if (vpStatus?.toString() && vpStatus === 1 && cPWebInfo === 0) {
        this.vpStatusText = `modules.expert-tool.result.vp-status-cases.1-cp-web-info-0`;
      } else if (vpStatus?.toString() && vpStatus === 1 && cPWebInfo === 4) {
        this.vpStatusText = `modules.expert-tool.result.vp-status-cases.1-cp-web-info-4`;
      } else {
        if (vpStatus?.toString() && vpStatus >= 0) {
          this.vpStatusText = `modules.expert-tool.result.vp-status-cases.positive-${vpStatus}`;
        }
      }
    } else {
      if (vpStatus?.toString() && vpStatus >= 0) {
        this.vpStatusText = `modules.expert-tool.result.vp-status-cases.positive-${vpStatus}`;
      }
    }

    if (vpStatus?.toString() && vpStatus < 0) {
      const negativeText =
        (this.initialFwRes.ERROR_MSG
          ? this.initialFwRes?.ERROR_MSG + ' '
          : '') +
        (this.initialFwRes.VP_STATUS_VP5
          ? '(' + this.initialFwRes.VP_STATUS_VP5 + ')'
          : '');
      this.vpStatusText = negativeText;
    }

    if (this.fromReader && vpStatus === -5){
      this.vpStatusText = `modules.expert-tool.result.vp-status-cases.negative-5`
    }
  }

  private checkSgNewFlashSelection() {
    const isEmpty = !Object.values(this.rows).some((val) => val.SG_NEWFLASHSW);
    if (this.orignalVpStatus !== 1) {
      if (isEmpty === false) {
        const newStatus = 1;
        this.initialFwRes.VP_STATUS = newStatus;
        this.setVpStatus();
        this.populateVpStatusClass();
        this.populateVpStatusText();
      } else if (isEmpty === true) {
        this.resetVpStatus();
      }
    }
  }

  private resetVpStatus() {
    this.vpStatus = this.orignalVpStatus;
    this.initialFwRes.VP_STATUS = this.vpStatus;
    this.populateVpStatusClass();
    this.populateVpStatusText();
    this.updateStatusInCalulateResponse();
  }

  updateDatatable(event: FlashwareResponse[][]): void {
    this.checkEdit(event[0]);
  }

  checkEdit(rows: FlashwareResponse[]): void {
    rows.forEach((row) => {
      this.orignalRows.forEach((orignalRow) => {
        if (row.id === orignalRow.id) {
          row.disabled = JSON.stringify(orignalRow) === JSON.stringify(row);
        }
      });
    });
    this.replaceOrginal();
  }

  setSelected(useStore: boolean = true): void {
    this.selected = [];
    const selectedRows =
      (useStore &&
        this.storageService.getData(sessionStorageKeys.resultSelected)) ||
      this.rows.filter((row) => row.selected);
    this.selected = [...selectedRows];
    this.storageService.saveData(
      sessionStorageKeys.resultSelected,
      this.selected
    );
  }

  onSelect(event: CheckBoxSelect): void {
    if (event && event.selected && event.selected.length) {
      const oldSelected: FlashwareResponse[] = [...this.selected];
      this.selected = event.selected || [...this.rows];
      const diff: FlashwareResponse[] = [];
      if (oldSelected.length > this.selected.length) {
        oldSelected.forEach((element) => {
          this.selected.forEach((row) => {
            if (JSON.stringify(element) !== JSON.stringify(row)) {
              diff.push(element);
            }
          });
        });
      } else {
        this.selected.forEach((element) => {
          oldSelected.forEach((row) => {
            if (JSON.stringify(element) !== JSON.stringify(row)) {
              diff.push(element);
            }
          });
        });
      }
      const combArray: FlashwareResponse[] = this.selected.concat(diff);
      this.rows.forEach((element) => {
        let found = false;
        combArray.forEach((row) => {
          if (JSON.stringify(element) === JSON.stringify(row)) {
            found = true;
          }
        });
        element.selected = found;
      });
      this.rows.sort((a, b) => (a.selected === b.selected ? 1 : -1));
      this.replaceOrginal();
      this.resetTable(true);
    }
    if (event && event.selected && event.selected.length === 0) {
      this.rows.forEach((element) => {
        element.selected = false;
      });
      this.replaceOrginal();
      this.selected = [];
      this.resetTable(true);
    }
    this.storageService.saveData(
      sessionStorageKeys.resultSelected,
      JSON.parse(JSON.stringify(this.selected))
    );
  }

  openFlashwareResponseModal(data?: FlashwareResponse, index?: number): void {
    const modalRef: NgbModalRef = this.modalService.open(
      FcscCalculateModalComponent,
      {
        size: 'lg',
        windowClass: 'cont-units-modal override',
        backdrop: 'static',
        keyboard: false,
      }
    );
    modalRef.componentInstance.title = data
      ? this.translocoService.translate('modules.expert-tool.manual-edit')
      : this.translocoService.translate('modules.expert-tool.insert-new-line');
    modalRef.componentInstance.subText = data
      ? this.translocoService.translate(
          'modules.expert-tool.result.modal.edit-subtitle'
        )
      : this.translocoService.translate(
          'modules.expert-tool.result.modal.add-subtitle'
        );
    modalRef.componentInstance.dataList = this.rows;
    modalRef.componentInstance.buttonText = data
      ? this.translocoService.translate('modules.expert-tool.btn-apply-changes')
      : this.translocoService.translate('modules.expert-tool.insert-new-line');
    modalRef.componentInstance.index = index;
    modalRef.componentInstance.data = data;

    modalRef.result.then((result) => {
      if (
        result &&
        result.ok === ModalResult.INSERT_SUCCESSFUL &&
        result.index !== null &&
        result.index !== undefined
      ) {
        if (result.record?.SG_DiogName) {
          const foundRec = this.rows.find(
            (record) => record.SG_DiogName === result.record.SG_DiogName
          );
          if (foundRec && result.checkFlag) {
            this.notificationService.showError(
              '',
              this.translocoService.translate(
                'modules.expert-tool.diog-name-valdation'
              )
            );
            return;
          }
        } else {
          this.notificationService.showError(
            '',
            this.translocoService.translate(
              'modules.expert-tool.diog-name-empty-valdation'
            )
          );
          return;
        }
        const idx: number = this.rows.findIndex(
          (rowObj: FlashwareResponse) => rowObj.id === result.record.id
        );
        const row: FlashwareResponse = this.rows[idx];
        result.record.id = row?.id;
        result.record.disabled = true;
        this.rows[idx] = result.record;
        const currentRow: FlashwareResponse = this.rows[idx];
        currentRow.FW_MBS = result.record.FW_MBS;
        currentRow.SG_Current_SW = result.record.SG_Current_SW;
        currentRow.SG_DiogName = result.record.SG_DiogName;
        currentRow.SG_REFNR = result.record.SG_REFNR;
        currentRow.ECU_Refer = result.record.ECU_Refer;
        currentRow.SG_FITTINGFLASHSW = result.record.SG_FITTINGFLASHSW;
        currentRow.FW_KETTE = result.record.FW_KETTE;
        currentRow.Vp_Rate_Modules = result.record.Vp_Rate_Modules;
        currentRow.SG_DOWNGRADE = result.record.SG_DOWNGRADE;
        currentRow.SG_NEWFLASHSW = result.record.SG_NEWFLASHSW;
        currentRow.ECU_Dependent = result.record.ECU_Dependent;
        currentRow.SG_FITTINGFLASHSW_PRIO =
          result.record.SG_FITTINGFLASHSW_PRIO;
        currentRow.SG_FITTINGFLASHSW_SIZE =
          result.record.SG_FITTINGFLASHSW_SIZE;
        currentRow.SG_NEWFLASHSW_FLASHWEG =
          result.record.SG_NEWFLASHSW_FLASHWEG;
        currentRow.SG_NEWFLASHSW_PRIO = result.record.SG_NEWFLASHSW_PRIO;
        currentRow.SG_FITTING_FWINFO = result.record.SG_FITTING_FWINFO;

        this.replaceOrginal(result.record);
        this.setSelected(false);
      } else {
        if (result && result.ok === ModalResult.INSERT_SUCCESSFUL) {
          if (result.record?.SG_DiogName) {
            const foundRec = this.rows.find(
              (record) => record.SG_DiogName === result.record.SG_DiogName
            );
            if (foundRec) {
              this.notificationService.showError(
                '',
                this.translocoService.translate(
                  'modules.expert-tool.diog-name-valdation'
                )
              );
              return;
            }
          } else {
            this.notificationService.showError(
              '',
              this.translocoService.translate(
                'modules.expert-tool.diog-name-empty-valdation'
              )
            );
            return;
          }

          result.record.id = this.orignalRows.length + 1;
          this.orignalRows.push(result.record);
          const newRecord: FlashwareResponse = {
            SG_DiogName: result.record.SG_DiogName,
            ECU_Dependent: result.record.ECU_Dependent,
            SG_REFNR: result.record.SG_REFNR,
            Vp_Rate_Modules: result.record.Vp_Rate_Modules,
            FW_MBS: result.record.FW_MBS,
            ECU_Refer: result.record.ECU_Refer,
            SG_Current_SW: result.record.SG_Current_SW,
            SG_FITTINGFLASHSW: result.record.SG_FITTINGFLASHSW,
            SG_FITTINGFLASHSW_PRIO: result.record.SG_FITTINGFLASHSW_PRIO,
            SG_FITTINGFLASHSW_SIZE: result.record.SG_FITTINGFLASHSW_SIZE,
            SG_NEWFLASHSW: result.record.SG_NEWFLASHSW,
            SG_NEWFLASHSW_FLASHWEG: result.record.SG_NEWFLASHSW_FLASHWEG,
            SG_NEWFLASHSW_PRIO: result.record.SG_NEWFLASHSW_PRIO,
            SG_FITTING_FWINFO: result.record.SG_FITTING_FWINFO,
            SG_DOWNGRADE: result.record.SG_DOWNGRADE,
            FW_KETTE: result.record.FW_KETTE,
            id: result.record.id,
            disabled: result.record.disabled,
            selected: result.record.selected,
          };
          this.records.push(newRecord);
          this.updateEachEcuInCalulateResponse(newRecord, true);
          this.storageService.saveData(
            sessionStorageKeys.flashwareResponseRecords,
            this.records
          );
          this.setSelected(false);
        }
      }
      this.rows = this.orignalRows;
      this.updateFinlogResDataStore();
      this.setSelected(false);

      this.resetTable();
    });
  }

  resetTable(flag?) {
    const rows = this.rows;
    this.rows = [];
    setTimeout(() => {
      this.rows = rows;
      if (!flag) {
        const { size } = this.paginationDataSetting || {};
        const initialSize =
          (size === 'all' ? null : size) ||
          (this.selectedSize === 'all' ? null : this.pageSize);
        this.setPageSize(initialSize);
      }
    }, 100);
  }

  resetUserSetting() {
    setTimeout(() => {
        const { size } = this.paginationDataSetting || {};
        const initialSize =
          (size === 'all' ? null : size) ||
          (this.selectedSize === 'all' ? null : this.pageSize);
        this.setPageSize(initialSize);
    }, 100);
  }

  replaceOrginal(ecuObj?): void {
    this.orignalRows.forEach((orow) => {
      this.rows.forEach((row) => {
        if (row.id === orow.id) {
          const keys = Object.keys(row);
          keys.forEach((key) => {
            orow[key] = row[key];
          });
        }
        this.updateEachEcuInCalulateResponse(row, false);
      });
    });

    if (ecuObj) {
      const orow = this.orignalRows.find(
        (orow) => orow.SG_DiogName === ecuObj.SG_DiogName
      );

      const row = this.rows.find(
        (orow) => orow.SG_DiogName === ecuObj.SG_DiogName
      );
      if (orow) {
        orow.Flashbedarf =
          this.expertoolDataModelingService.getResultFlashStatus(ecuObj);
        orow.attributes = ecuObj.attributes;
      }
      if (row) {
        row.Flashbedarf =
          this.expertoolDataModelingService.getResultFlashStatus(ecuObj);
        row.attributes = ecuObj.attributes;
      }
    }

    this.resetSelected();
    this.updateFinlogResDataStore();
  }

  resetSelected() {
    const selected: FlashwareResponse[] = [];
    this.rows.forEach((row) => {
      if (row.selected) {
        selected.push(row);
      }
    });
    this.storageService.saveData(
      sessionStorageKeys.resultSelected,
      JSON.parse(JSON.stringify(this.selected))
    );
  }

  private updateFinlogResDataStore() {
    let data = this.rows;
    this.checkSgNewFlashSelection();
    this.storageService.saveData(sessionStorageKeys.fwResponseData, data);
    this.updateCalculateResponseInStorage();
  }

  private updateStatusInCalulateResponse() {
    const metadata = this.calculationData?.payload.finLog.metadata;
    const header = metadata?.find(
      (meta) => meta.key === expertToolConstants.keys.header
    );
    const status = (header?.value as CalculateKeyValGeneric[])?.find(
      (meta) => meta.key === expertToolConstants.keys.vpStatus
    );

    const cpWebInfo = (header?.value as CalculateKeyValGeneric[])?.find(
      (meta) => meta.key === expertToolConstants.keys.cpWebInfo
    );
    if (status) {
      status.value = this.vpStatus as number;
    }

    if (cpWebInfo) {
      cpWebInfo.value = this.CPWebinfo as number;
    }
    this.updateCalculateResponseInStorage();
  }

  private updateCalculateResponseInStorage() {
    this.storageService.saveData(
      sessionStorageKeys.calculationData,
      this.calculationData
    );
  }

  private updateEachEcuInCalulateResponse(
    row: FlashwareResponse,
    add: boolean
  ) {
    if (add) {
      this.expertoolDataModelingService.addDataToPayloadEcus(
        row,
        this.calculationData?.payload?.finLog?.ecus,
        ExceludedResponseDownloadKeys,
        this.getEcusMapValueForDownload.bind(this)
      );
    } else {
      this.expertoolDataModelingService.mapDataToPayloadEcus(
        row,
        this.calculationData?.payload?.finLog?.ecus,
        ExceludedResponseDownloadKeys,
        this.getEcusMapValueForDownload.bind(this)
      );
    }
  }

  private populateStatusesFromResponse() {
    this.initialFwRes.VP_STATUS =
      this.expertoolDataModelingService.findDynamicValueFromKey(
        this.expertoolDataModelingService.getHeaderFromResponse(
          this.calculationData
        ) as CalculateKeyValGeneric[],
        expertToolConstants.keys.vpStatus
      ) as string;

    this.initialFwRes.CPweb_Info =
      this.expertoolDataModelingService.findDynamicValueFromKey(
        this.expertoolDataModelingService.getHeaderFromResponse(
          this.calculationData
        ) as CalculateKeyValGeneric[],
        expertToolConstants.keys.cpWebInfo
      ) as string;

    this.initialFwRes.VP_STATUS_VP5 =
      this.expertoolDataModelingService.findDynamicValueFromKey(
        this.expertoolDataModelingService.getHeaderFromResponse(
          this.calculationData
        ) as CalculateKeyValGeneric[],
        expertToolConstants.keys.vpStatusVp5
      ) as string;

    this.initialFwRes.ERROR_MSG =
      this.expertoolDataModelingService.findDynamicValueFromKey(
        this.expertoolDataModelingService.getHeaderFromResponse(
          this.calculationData
        ) as CalculateKeyValGeneric[],
        expertToolConstants.keys.errorMsg
      ) as string;
  }

  private async configPageInfo() {
    this.paginationDataSetting =
      (await this.userSettingService.fetchUserSetting(
        userSettingKeys.experttool.fwResultTable
      )) || {};

    if (this.fromReader) {
      this.populateData();
    }

    const { sort, size } = this.paginationDataSetting;

    // Sort
    const sortData = sort || 'SG_DiogName,asc';
    const sortArray = sortData?.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    this.sortConfig = [{ prop: orderBy, dir: orderDir }];

    // Size
    this.pageSize =
      (size === 'all' ? null : size) ||
      (this.selectedSize === 'all' ? null : this.pageSize);

    // Reset table
    this.resetTable();
  }

  private loadPageParams(): any {
    this.page.orderBy = this.dataTable.sorts
      ? this.dataTable.sorts[0].prop
      : 'SG_DiogName';
    this.page.orderDir = this.dataTable.sorts
      ? this.dataTable.sorts[0].dir
      : 'asc';
    this.params = {
      size: this.selectedSize,
      sort: `${this.page.orderBy},${this.page.orderDir}`,
    };

    return this.params;
  }
}