import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { TestDataService } from '@core/services/test-data.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOption } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { CustomNgOption } from '@shared/models/CustomNgOption';
import { CreateVp5TestDataPayload } from '@shared/models/filetype/Vp5TestData';
import { ModalResult } from '@shared/models/ModalResult';
import moment from 'moment';

@Component({
  selector: 'app-fcsc-create-vp5-test-data',
  templateUrl: './fcsc-create-vp5-test-data.component.html',
  styleUrls: ['./fcsc-create-vp5-test-data.component.scss'],
})
export class FcscCreateVp5TestDataComponent implements OnInit {
  vp5TagsItems: NgOption[] = [];
  formLoaded!: boolean;
  translatedAll!: any;
  form!: UntypedFormGroup;
  disableUpload = false;
  searchText = '';

  constructor(
    private testDataService: TestDataService,
    private modalService: NgbModal,
    private notificationService: ErrorHandlerService,
    private translocoService: TranslocoService,
    private appService: AppService,
    public activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.getTranslatedData();
  }

  onSearchFn = (word: string, item: CustomNgOption): boolean => {
    const extractedValue =
      this.vp5TagsItems &&
      this.vp5TagsItems?.map((itm) => {
        return itm.name;
      });

    const filteredExtractedValue = extractedValue.filter(
      (exv) => exv !== this.translatedAll
    );

    const filteredVal = filteredExtractedValue.some((vl) =>
      vl.toLocaleLowerCase().includes(word.toLocaleLowerCase())
    );

    if (!filteredVal && this.searchText !== word) {
      this.searchText = word;
      this.notificationService.showError(
        '',
        this.translocoService.translate(
          'modules.data-management.delta-upload.messages.serie-not-found'
        )
      );
    }

    return item.name.toLocaleLowerCase().includes(word.toLocaleLowerCase());
  };

  private createForm(translation?) {
    this.form = this.formBuilder.group({
      comment: new UntypedFormControl(''),
      version: new UntypedFormControl(translation),
      name: new UntypedFormControl(''),
    });
    this.formLoaded = true;
    this.generateDefaulTestBdpName();
  }

  private generateDefaulTestBdpName() {
    const generatedTime = moment().unix();
    const generatedName = `VP5_TEST_${generatedTime}`;
    this.form.get('name')?.setValue(generatedName);
  }

  private getTranslatedData() {
    if (!this.vp5TagsItems?.length) {
      this.getVp5Tags();
    }

    this.appService.seriesSubject.subscribe((items: any) => {
      if (this.translatedAll) {
        this.setList(this.appService.addAll(items, this.translatedAll));
      } else {
        this.vp5TagsItems = items;
      }
    });

    this.appService
      .translateKey('modules.data-management.select-option')
      .subscribe((translation) => {
        if (this.vp5TagsItems?.length) {
          this.setList(this.appService.addAll(this.vp5TagsItems, translation));
        }
        this.translatedAll = translation;
        this.createForm(translation);
      });
  }

  private setList(list) {
    this.vp5TagsItems = list;
  }

  getVp5Tags() {
    this.testDataService.getVp5Tags().subscribe(
      (data: HttpResponse<string[]>) => {
        if (data && data.body) {
          data.body.forEach((dt) => {
            this.vp5TagsItems = [...this.vp5TagsItems, { name: dt, value: dt }];
          });
        }
        this.appService.seriesSubject.next(this.vp5TagsItems);
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  checkSelection(): boolean {
    return (
      this.form.controls.version.value ===
        this.translocoService.translate(
          'modules.data-management.select-option'
        ) || this.form.controls.version.value === ''
    );
  }

  // modal buttons

  save() {
    const recordPayload: CreateVp5TestDataPayload = {
      name: this.form.get('name')?.value,
      version: this.form.get('version')?.value,
      comment: this.form.get('comment')?.value,
    };

    const finalResult = {
      ok: ModalResult.CREATE_BDP_TEST,
      recordPayload,
    };
    this.activeModal.close(finalResult);
  }

  cancel(): void {
    this.activeModal.close(ModalResult.CREATE_BDP_TEST_CANCEL);
  }
}
