import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FileName } from '@core/enums/file-name.enum';
import { System } from '@core/enums/system.enum';
import { AppService } from '@core/services/app.service';
import { I18n } from '@core/services/custom-datepicker-i18n.service';
import { SecurityService } from '@core/services/security.service';
import { SharedSeriesService } from '@core/services/shared-series.service';
import {
  NgbDatepickerConfig,
  NgbTypeahead,
} from '@ng-bootstrap/ng-bootstrap';
import { NgOption } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { LogHistory } from '@shared/models/experttool/LogHistory';
import moment from 'moment';
import {
  Observable,
  OperatorFunction,
  Subject,
  combineLatest,
  merge,
} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';

@Component({
  selector: 'app-fcsc-filter-log-history',
  templateUrl: './fcsc-filter-log-history.component.html',
  styleUrls: ['./fcsc-filter-log-history.component.scss'],
})
export class FcscFilterLogHistoryComponent implements OnInit, AfterContentChecked {
  @ViewChild('instance', { static: true }) instance: NgbTypeahead | undefined;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();
  savedVins: string[] = [];
  form!: UntypedFormGroup;
  systemList!: NgOption[];
  fileNameList!: NgOption[];
  seriesList!: NgOption[];
  formLoaded!: boolean;
  @ViewChild('searchCreatedAtFrom') searchCreatedAtFrom!: ElementRef;
  @ViewChild('searchCreatedAtUntil') searchCreatedAtUnil!: ElementRef;
  translatedAll!: any;
  @Output() refreshVisibleTable = new EventEmitter<any>();
  @Input() data!: LogHistory[];
  @ViewChild('search') search!: ElementRef;

  constructor(
    private cdref: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
    private appService: AppService,
    private translocoService: TranslocoService,
    private _i18n: I18n,
    public config: NgbDatepickerConfig,
    public sharedSeriesService: SharedSeriesService,
    public securityService: SecurityService
  ) {
    this.validateDateLimit();
  }

  ngOnInit(): void {
    this.initialize();
    this.handleTranslationKeys();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  private initialize() {
    const savedVins = localStorage.getItem('savedVins');
    if (savedVins) {
      this.savedVins = JSON.parse(savedVins);
      this.savedVins.reverse();
    }
    setTimeout(() => {
      this.appService.languageSubject.next(localStorage.getItem('language'));
    }, 50);

    if (!this.seriesList?.length) {
      this.sharedSeriesService.getAdminSeriesList();
    }
  }

  private handleTranslationKeys() {
    this.appService.languageSubject.subscribe((lang: any) => {
      this._i18n.language = lang;
      this.appService.translateForAll(
        'modules.data-management.filter.br-placeholder'
      );
    });

    this.appService.seriesSubject.subscribe((series: any) => {
      if (this.translatedAll) {
        this.setSeriesList(this.appService.addAll(series, this.translatedAll));
      } else {
        this.seriesList = series;
      }
    });

    this.appService
      .translateKey('modules.data-management.filter.br-placeholder')
      .subscribe((translation?) => {
        if (this.seriesList?.length) {
          this.setSeriesList(
            this.appService.addAll(this.seriesList, translation)
          );
        }
      });

    this.appService.translationSubject.subscribe((translation) => {
      this.translatedAll = translation;
      this.createFilterForm(translation);
      const systemObs = Object.keys(System).map((key: string) => {
        return this.translocoService.selectTranslate(
          System[key as keyof typeof System]
        );
      });
      combineLatest(systemObs).subscribe((resp) => {
        this.systemList = Object.keys(System).map((key: string, index) => {
          return { value: key, name: resp[index] };
        });
      });

      const fileNamesObs = Object.keys(FileName).map((key: string) => {
        return this.translocoService.selectTranslate(
          FileName[key as keyof typeof FileName]
        );
      });
      combineLatest(fileNamesObs).subscribe((resp) => {
        this.fileNameList = Object.keys(FileName).map((key: string, index) => {
          return { value: key, name: resp[index] };
        });
      });
    });
  }

  private setSeriesList(list) {
    this.seriesList = list;
  }

  public removeCreatedAtFromFocus() {
    this.searchCreatedAtFrom.nativeElement.blur();
  }

  public removeCreatedAtUntilFocus() {
    this.searchCreatedAtUnil.nativeElement.blur();
  }

  validateDateLimit(): void {
    const backDate = moment().subtract(14, 'd');
    this.config.minDate = {
      year: backDate.year(),
      day: backDate.date(),
      month: backDate.month() + 1,
    };
    const today = moment();
    this.config.maxDate = {
      year: today.year(),
      day: today.date(),
      month: today.month() + 1,
    };
  }

  private createFilterForm(translation?) {
    this.form = this.formBuilder.group({
      vin: new UntypedFormControl(''),
      baureihe: new UntypedFormControl(translation),
      request: new UntypedFormControl(''),
      fileName: new UntypedFormControl(
        this.appService.getValueConstant(FileName, FileName.ALL)
      ),
      system: new UntypedFormControl(
        this.appService.getValueConstant(System, System.ALL)
      ),
      createdAtFrom: new UntypedFormControl(''),
      createdAtUntil: new UntypedFormControl(''),
    });
    this.formLoaded = true;
  }

  searchTypeAhead: OperatorFunction<string, readonly string[]> = (
    text$: Observable<string>
  ) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(
      filter(() => !this.instance?.isPopupOpen())
    );
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>
        (term === ''
          ? this.savedVins
          : this.savedVins.filter(
              (v) => v.toLowerCase().indexOf(term.toLowerCase()) > -1
            )
        ).slice(0, 10)
      )
    );
  };

  handleBlur() {
    const vin = this.form.get('vin')?.value;
    this.form.get('vin')?.setValue(vin?.trim());
  }

  public filterRows() {
    const form = this.form;
    const params = {};
    for (const key of Object.keys(form.controls)) {
      if (
        form.get(key) &&
        form.get(key)?.value &&
        form.get(key)?.value !== '' &&
        !Array.isArray(form.get(key)?.value)
      ) {
        let val = form.get(key)?.value;
        if (
          key === 'system' &&
          val === this.appService.getValueConstant(System, System.ALL)
        ) {
          val = '';
        }
        if (
          key === 'fileName' &&
          val === this.appService.getValueConstant(FileName, FileName.ALL)
        ) {
          val = '';
        }
        params[key] =
          val &&
          typeof val !== 'object' &&
          val?.toLowerCase() === this.translatedAll.toLowerCase()
            ? ''
            : val;
        if (
          (key === 'createdAtFrom' && params[key]) ||
          (key === 'createdAtUntil' && params[key])
        ) {
          params[key] =
            params[key].year.toString() +
            params[key].month.toString().padStart(2, '0') +
            params[key].day.toString().padStart(2, '0');
        }
      }
    }

    this.refreshVisibleTable.emit(params);
  }

  OnInput(event: any): void {
    const input = event?.target.value;
    const sanitizedInput = input.replace(/[^0-9]/g, '');
    event.target.value = sanitizedInput;
    this.form.get('request')?.setValue(sanitizedInput, { emitEvent: false });
  }

  OnPaste(event: ClipboardEvent): void {
    event.preventDefault();
    let clipBoardData = event.clipboardData || window['clipBoardData'];
    let pastedInput: string = clipBoardData.getData('text');
    const sanitizedInput = pastedInput.replace(/[^0-9]/g, '');
    this.form.get('request')?.setValue(sanitizedInput, { emitEvent: false });
  }

  public resetFilteredRows() {
    this.initialize();
    this.refreshVisibleTable.emit();
  }

  public removeFocus() {
    this.search.nativeElement.blur();
  }
}
