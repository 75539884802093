import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  forwardRef,
} from '@angular/core';
import {
  DefaultValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';

const TRIM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TrimDirective),
  multi: true,
};
@Directive({
  selector: `
        [appTrim]
      `,
  providers: [TRIM_VALUE_ACCESSOR],
})
export class TrimDirective extends DefaultValueAccessor {
  constructor(renderer: Renderer2, elementRef: ElementRef) {
    super(renderer, elementRef, false);
  }
  @HostListener('input', ['$event.target.value'])
  ngOnChange = (val: string): void => {
    this.onChange(val.trim());
  };

  @HostListener('blur', ['$event.target.value'])
  applyTrim(val: string): void {
    this.writeValue(val.trim());
  }

  writeValue(value: any): void {
    if (typeof value === 'string') {
      value = value.trim();
    }

    super.writeValue(value);
  }
}
