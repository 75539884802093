import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@core/services/env.service';
import { apiPaths } from '@env/paths';

@Injectable({
  providedIn: 'root',
})
export class FileEncryptionService {
  private baseUrl = '';
  public fileName = '';
  constructor(private http: HttpClient, private envService: EnvService) {
    this.baseUrl = `${envService.apiUrl}${apiPaths.fcscCryptoService}/api`;
  }

  public getFileContentEncrypted(content: string) {
    // payload = base64
    const payload = btoa(unescape(encodeURIComponent(content)));
    return this.http.post(
      this.baseUrl + `/encrypt`,
      { payload },
      { observe: 'response' }
    );
  }

  public getFileContentDecrypted(content: string) {
    const payload = btoa(content);
    return this.http.post(
      this.baseUrl + `/decrypt`,
      { payload },
      { observe: 'response' }
    );
  }
}
