import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import {ModalResult} from "@shared/models/ModalResult";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {StorageService} from "@core/services/storage.service";
import {SteuerdatenService} from "@core/services/steuerdaten.service";
import {RcddbLookupBr} from "@shared/models/filetype/RcddbLookupBr";
import {HttpErrorResponse} from "@angular/common/http";
import {ErrorHandlerService} from "@core/services/errorHandler/error-handler.service";

@Component({
  selector: 'app-fcsc-flashware-multiimport',
  templateUrl: './fcsc-flashware-multiimport.component.html',
  styleUrls: ['./fcsc-flashware-multiimport.component.scss']
})
export class FcscFlashwareMultiimportComponent implements OnInit {

  @Input() showImport = {
  current: true,
  next: false
  };
  form!: UntypedFormGroup;
  @Output() sendMultiImportData = new EventEmitter<any>();
  chips: any = [];
  buttonEnabled = false;


  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: UntypedFormBuilder,
    private storageService: StorageService,
    private steuerdatenService : SteuerdatenService,
    private notificationService: ErrorHandlerService,
  ) { }

  onChipsChanged(allGreen: boolean) {
    this.buttonEnabled = allGreen;
  }

  onUpdateChips(chips) {
    this.chips = chips;
  }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(){
    this.form = this.formBuilder.group({
      SG_FITTINGFLASHSW: new UntypedFormControl(""
      )
    })
  }
  backToFirstView() {
    this.showImport.current = true;
    this.showImport.next = false;
  }

  async sendDataToFirstViewFunc() {
    const flashForm =
      this.storageService.getData(sessionStorageKeys.flashForm) || {};
    const sdpName = flashForm.sdpName;
    const xentryRelease = flashForm.xentryReleases;
    let resultArray: string[] = this.chips.map((value) => value.split('_')[0]);
    this.steuerdatenService
      .getRcddbLookup(sdpName, resultArray, xentryRelease)
      .subscribe(
        (data: RcddbLookupBr[]) => {
          if (data) {
            let finalData: RcddbLookupBr[] = [];
            this.chips.forEach((snr) => {
              const dt = data.find((dt) => dt.snr?.includes(snr.split('_')[0]));
              if (dt) {
                dt['snr'] = snr;
                if (!dt.flashFormat?.includes('.')){
                  dt.flashFormat = '.' + dt.flashFormat
                }
                finalData.push({ ...dt });
              } else {
                const newData: RcddbLookupBr = {
                  dioName: '',
                  br: '',
                  xentryUpdate: '',
                  snr: snr,
                };
                finalData.push(newData);
              }
            });

            this.sendMultiImportData.emit(finalData);
            // In order to emit the data to the main modal
            this.backToFirstView();
          }
        },
        (error: HttpErrorResponse) => {
          this.notificationService.handleServerErrorNotification(
            error,
            'modules.admin-section.config-modal.insert-edit-api-error-message',
          );
        },
      );

  }

  save(){
    this.sendDataToFirstViewFunc();
  }
}
