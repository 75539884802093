import { CalculateClient } from './sub-parts/CalculateClient';
import { CalculateDestination } from './sub-parts/CalculateDestination';
import { CalculatePayload } from './sub-parts/CalculatePayload';
import { CalculateTracking } from './sub-parts/CalculateTracking';
import { CalculateUser } from './sub-parts/CalculateUser';

export class ExpertToolCalculation {
  tracking: CalculateTracking;
  client: CalculateClient;
  destination: CalculateDestination;
  user: CalculateUser;
  payload: CalculatePayload;

  constructor(
    tracking: CalculateTracking,
    client: CalculateClient,
    destination: CalculateDestination,
    user: CalculateUser,
    payload: CalculatePayload
  ) {
    this.tracking = tracking;
    this.client = client;
    this.destination = destination;
    this.user = user;
    this.payload = payload;
  }
}
