import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiPaths } from '@env/paths';
import { CalculationLogFiles } from '@shared/models/filetype/CalculationLogFiles';
import { Observable } from 'rxjs';

import { EnvService } from './env.service';

@Injectable({
  providedIn: 'root',
})
export class CalculationLogFilesService {
  private s3BaseUrl = '';

  constructor(private httpClient: HttpClient, private envService: EnvService) {
    this.s3BaseUrl = `${envService.apiUrl}${apiPaths.fcscS3Service}`;
  }

  getCalculationLogFiles(fin: string): Observable<CalculationLogFiles[]> {
    const url = `${this.s3BaseUrl}/files/${fin}`;
    return this.httpClient.get<CalculationLogFiles[]>(url);
  }

  public getExportFile(file: CalculationLogFiles) {
    const url = `${this.s3BaseUrl}/files/${file.name}`;

    return this.httpClient.get(url, {
      responseType: 'blob',
    });
  }

  public getExportFileFromPresignedUrl(url: string) {
    return this.httpClient.get(url, {
      responseType: 'blob',
    });
  }

  public getPresignedUrlForFile(file: CalculationLogFiles) {
    const url = `${this.s3BaseUrl}/files/${file.name}`;

    return this.httpClient.get(url, {
      responseType: 'json',
    });
  }
}
