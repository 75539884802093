<div class="modal-body">
  <form [formGroup]="serieBdpRollbackForm">
    <p></p>
    <div class="row">
      <div class="col-12">
        <div class="custom-margin-b">
          <span class="sub-title">{{
            "modules.data-management.serien-daten.modal.rollback-config-title"
              | transloco
          }}</span>
        </div>

        <div class="form-group">
          <div class="form-group center">
            {{
              "modules.data-management.serien-daten.modal.bdp-activation-reason-title"
                | transloco
            }}
          </div>

          <p></p>
          <div class="custom-rad">
            <div class="col-9">
              <div class="form-group custom-radio">
                <label
                  class="radio-inline checkradio-container custom-radio-lb"
                >
                  <input
                    type="radio"
                    value="FAULTY_BDP"
                    formControlName="reason"
                  />
                  <span class="radiomark"></span>
                  <span class="custom-radio-span">
                    {{ wrongBdpReason | transloco }}
                  </span>
                </label>

                <label
                  class="radio-inline checkradio-container custom-radio-lb"
                >
                  <input type="radio" value="OTHER" formControlName="reason" />
                  <span class="radiomark"></span>
                  <span class="custom-radio-span">
                    {{ otherBdpReason | transloco }}</span
                  >
                </label>
              </div>
            </div>
          </div>

          <p></p>
          <div class="col-12 form-group">
            <label for="note">{{
              "modules.data-management.serien-daten.modal.bdp-activation-comment"
                | transloco
            }}</label>
            <textarea
              class="form-control"
              id="note"
              formControlName="note"
              rows="3"
            ></textarea>
          </div>
          <div class="mt-3">
            <app-upload-duration
            [translationKey]="notificationMessage"
          ></app-upload-duration>
         </div>
         <div class="mt-3" *ngIf="showQueueDiv">
            <app-upload-duration
            [translationKey]="queueTitle"
          ></app-upload-duration>
         </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-start">
          <button type="button" class="btn btn-secondary back" (click)="handleBack()">
            {{ "global.back" | transloco }}
          </button>
        </div>

        <div class="button-area float-end">
          <div class="displayed">

            <button
            type="button"
            class="btn btn btn-secondary"
            (click)="handleClose()"
          >
            {{ "global.cancel" | transloco }}
          </button>
          
            <button
              type="button"
              class="btn btn btn-primary"
              (click)="handleSubmit()"
            >
              {{ "modules.data-management.tooltip.rollback" | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
