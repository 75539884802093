import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StatusBdp } from '@core/enums/status-bdp.enum';
import { Status } from '@core/enums/status-color.enum';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { getStatusColor } from '@core/utils/getStatusColor/getStatusColor';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ConfirmatonModalComponent } from '@shared/components/confirmaton-modal/confirmaton-modal.component';
import { BdpSerieFilter } from '@shared/models/filetype/BdpSerieFilter';
import { BdpStatus } from '@shared/models/filetype/BdpStatus';
import {
  SteuerDaten,
  SteuerDatenMap,
} from '@shared/models/filetype/SteuerDaten';
import { Spinner } from '@shared/models/spinner';
import { SelectionType } from '@swimlane/ngx-datatable';

import { FcscFinlogComponent } from '../fcsc-finlog/fcsc-finlog.component';

@Component({
  selector: 'app-fcsc-bdp-modal',
  templateUrl: './fcsc-bdp-modal.component.html',
  styleUrls: ['./fcsc-bdp-modal.component.scss'],
})
export class FcscBdpModalComponent implements OnInit {
  @Input() title!: string;
  @Input() subText!: string;
  @Input() fieldText!: string;
  @Input() selectedFin!: string;
  @Input() selectedSdpName!: string;
  @Input() ref!: any;
  @Input() finlog!: FcscFinlogComponent;
  @Output() handleActivation = new EventEmitter<any>();
  sortConfig = [{ prop: 'smokeTestStatus', dir: 'asc' }];
  steuerDaten: SteuerDatenMap[] = [];
  dataTableSelection = SelectionType.single;
  bdpSerieFilter: BdpSerieFilter[] = [];
  selected: BdpSerieFilter[] = [];
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  isModalOpen!: boolean;
  sortedData;

  constructor(
    public activeModal: NgbActiveModal,
    private steuerdatenService: SteuerdatenService,
    private translocoService: TranslocoService,
    private notificationService: ErrorHandlerService,
    private appService: AppService,
    private modalService: NgbModal
  ) {}

  onSelect(event: any): void {
    if (!event.selected.length) {
      return;
    }
    if (event.selected.length > 1) {
      const autoSelect = [event.selected[event.selected.length - 1]];
      this.selected = [...autoSelect];
    }
    const currentStatus = this.selected[0]?.bdpStatus;
    if (currentStatus === 'STARTING') {
      this.activeModal.close(this.selected);
      this.ref.handleActivation(this.selected);
      this.finlog.selectedBdpModalRow = this.selected[0];
      this.finlog.selectedBdpName = this.selected[0].bdpName;
      this.finlog.startLoader();
    }
    if (
      currentStatus !== 'ACTIVE' &&
      currentStatus !== 'STARTING' &&
      !this.isModalOpen &&
      event.selected.length >= 1
    ) {
      this.isModalOpen = true;
      this.openConfirmationModal();
    }
  }

  ngOnInit(): void {
    this.loadingTitle = 'global.loading-spinner-fetch-title';
    this.loadingSubtitle = 'global.loading-spinner-fetch-subtitle';
    this.getData();
  }

  openConfirmationModal() {
    const modalRef = this.modalService.open(ConfirmatonModalComponent, {
      size: 'lg',
      windowClass: 'confirmation-modal',
      backdrop: 'static',
      keyboard: false,
    });
    this.activeModal.close([]);
    const message = (modalRef.componentInstance.title =
      this.translocoService.translate(
        'modules.expert-tool.modal-bdp-activation-title'
      ));
    modalRef.componentInstance.subText = this.translocoService.translate(
      'modules.expert-tool.modal-bdp-activation-sub-title'
    );
    modalRef.componentInstance.buttonText = this.translocoService.translate(
      'modules.expert-tool.modal-bdp-activation-ok-btn'
    );

    modalRef.componentInstance.buttonSecondaryText =
      this.translocoService.translate(
        'modules.expert-tool.modal-bdp-activation-cancel-btn'
      );

    modalRef.result.then((result) => {
      this.isModalOpen = false;
      if (result && result.ok) {
        this.activeModal.close(this.selected);
        this.ref.handleActivation(this.selected);
        this.finlog.selectedBdpModalRow = this.selected[0];
        this.finlog.selectedBdpName = this.selected[0].bdpName;
        this.finlog.startLoader();
        this.activateBdp();
      } else {
        this.selected = this.sortedData ? [this.sortedData] : [];
        this.ref.openModal();
        this.finlog.selectedBdpModalRow = null;
        this.finlog.selectedBdpName = '';
        this.ref.handleActivation([]);
        this.activeModal.close([]);
      }
    });
  }

  private activateBdp() {
    const bdpName: string = this.selected[0].bdpName;
    this.finlog.waitForResponse = true;
    this.steuerdatenService.putBdpVorabActivationStatus(bdpName).subscribe(
      () => {
        this.activeModal.close(this.selected);
        this.finlog.waitForResponse = false;
      },
      (error: HttpErrorResponse) => {
        this.finlog.waitForResponse = false;

        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  private getData(): void {
    const filteredSerie = `C${
      this.selectedFin && this.selectedFin.substring(3, 6)
    }`;
    this.isLoading = true;
    this.steuerdatenService.getBdpBySerie(filteredSerie).subscribe(
      (data: BdpSerieFilter[]) => {
        this.isLoading = false;
        this.bdpSerieFilter = data;
        const sorted = this.bdpSerieFilter.sort((a, b) =>
          a.smokeTestStatus < b.smokeTestStatus
            ? 1
            : b.smokeTestStatus < a.smokeTestStatus
            ? -1
            : 0
        );
        const serieIndex = sorted.findIndex((st) =>
          st.bdpName.startsWith('SERIEN_')
        );
        const found = sorted.find((st) => st.sdpName === this.selectedSdpName);
        this.sortedData = found;
        if (found) {
          this.selected = [found];
        } else {
          const autoSelected = [sorted[serieIndex]];
          this.selected = [...autoSelected];
        }
      },
      (error: HttpErrorResponse) => {
        this.isLoading = false;
        this.notificationService.handleServerErrorNotification(
          error,
          'global.bdp-status.service-down'
        );
      }
    );
  }

  onSort(event: Event): void {
    this.selected = [...this.selected];
  }

  cancel(): void {
    this.activeModal.close(null);
  }

  save(): void {
    this.activeModal.close(this.selected);
  }

  public getColor(value: string): string {
    const enumValue = Status[value as keyof typeof Status];
    return getStatusColor(enumValue);
  }

  public getValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(Status[value as keyof typeof Status])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }

  public getBDPValue(value: string): string {
    let translatedText = '';
    this.translocoService
      .selectTranslate(StatusBdp[value as keyof typeof StatusBdp])
      .subscribe((resp) => {
        translatedText = resp;
      });
    return translatedText;
  }
}
