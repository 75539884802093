import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { DateFormat } from '@core/enums/date-format.enum';
import { AppService } from '@core/services/app.service';
import { CsvExportService } from '@core/services/csv-export.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SerialUploadListService } from '@core/services/serial-upload-list.service';
import { UserSettingService } from '@core/services/user-setting.service';
import { entitlements } from '@env/entitlements';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { includedSerialUploadListExport } from '@shared/constants/csvConfigs';
import { userSettingKeys } from '@shared/constants/UsertSettingKeys';
import { ModalResult } from '@shared/models/ModalResult';
import { Page } from '@shared/models/Page';
import {
  FilteredSerialUploadList,
  SerialUploadList,
  SerialUploadListPayload,
  SerialUploadListResponse,
} from '@shared/models/serialdata/SerialUploadList';
import { GeneralPageInfo, SortInfo } from '@shared/models/SortInfo';
import { Spinner } from '@shared/models/spinner';
import * as moment from 'moment';
import { Options, ngxCsv } from 'ngx-csv';

import { FcscEditUploadListComponent } from './components/fcsc-edit-upload-list/fcsc-edit-upload-list.component';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { AdminService } from '@core/services/admin.service';
import { SecurityService } from 'src/app/core/services/security.service';

@Component({
  selector: 'app-fcsc-serien-upload-list',
  templateUrl: './fcsc-serien-upload-list.component.html',
  styleUrls: ['./fcsc-serien-upload-list.component.scss'],
})
export class FcscSerienUploadListComponent implements OnInit {
  tableListEntitlement = [entitlements.VUS.FCSC_UPLOADLIST_ADDINFO];
  page = new Page();
  sortConfig!: Array<{}>;
  paginationDataSetting!: any;
  params: GeneralPageInfo | undefined;
  serialUploadListData: SerialUploadList[] = [];
  response!: SerialUploadListResponse | null;
  emptyMessage!: string;
  selectedFilter!: FilteredSerialUploadList;
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;
  spinnerObject!: Spinner;
  isNaN = Number.isNaN;
  @Input() filteredData: SerialUploadList[] = [];
  uploadListData: any = [];
  uploadListDataForMapping: any = [];
  sortByAsc = true;
  activeTab = 'uploadList';
  productiveSince: string[] = [];
  seriesList: string[] = [];
  tableData: Set<string> = new Set();
  baureiheData: any;
  baureiheDataLength: number;
  constructor(
    private serialUploadListService: SerialUploadListService,
    private translocoService: TranslocoService,
    private modalService: NgbModal,
    private notificationService: ErrorHandlerService,
    private appService: AppService,
    private userSettingService: UserSettingService,
    private csvExportService: CsvExportService,
    private datePipe: DatePipe,
    private steuerdatenService: SteuerdatenService,
    public adminService: AdminService,
    private securityService: SecurityService
  ) {}

  async ngOnInit(): Promise<void> {
    this.spinnerObject = {
      loadingTitle: 'global.loading-spinner-fetch-title',
      loadingSubtitle: 'global.loading-spinner-fetch-subtitle',
      isLoading: false,
    };

    this.appService.spinnerSubject.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });
    this.isLoading = true;
    await this.getSeriesSteuerDaten();
    this.getBaureiheData();

    (await this.userSettingService.fetchUserSetting(
      userSettingKeys.serien.serienUploadListTable
    ).then(
      (response: any) => {
        if (Object.keys(response).length === 0 && response.constructor === Object) {
          this.activeTab = 'uploadList';
          this.isLoading = false;
        } else {
          this.securityService.getEntitlements().includes(entitlements.VUS.FCSC_UPLOADLIST_ADDINFO) ?
            this.activeTab = response.selectedTab :
            this.activeTab = 'uploadList';
          this.isLoading = false;
        }
      }
    ))
    
  }

  getBaureiheData(): any {
    this.adminService.getSeriesConfig().subscribe(
      (data) => {
       this.baureiheData = data;
       this.baureiheDataLength = data.length;
       this.refreshDataTable();
       this.setTranslation();
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  getShadowModelSeries(baureihe): any {
        const result = this.baureiheData.find(obj => obj.brName === baureihe);
        return result;
  }

  createSeriesMap(list) {
    return list.map(item => item.value).join(', ');
  }

  createCommaSeperatedSchattenbaureihe(series: string): string {
    if(this.getShadowModelSeries(series)) {
      const shadowModelList = this.getShadowModelSeries(series)['shadowModelList'];
      return shadowModelList!.length ? `(${this.createSeriesMap(shadowModelList)})` : '';
    }
    else {
      return '';
    }
    
  }

  async initializeTable(data) {
    const productiveSinceSet = new Set<any>();
    const seriesListSet = new Set<string>();
    for (const item of data) {
      productiveSinceSet.add(item.inSeriesSince);
      const obj = await this.getShadowModelSeries(item.baureihe);
      const shadowModelListString = obj.shadowModelList.length ? 
                                    `( ${this.createSeriesMap(obj.shadowModelList)} )` : 
                                    ''
      seriesListSet.add(`${item.baureihe} ${shadowModelListString}`);
      this.tableData.add(`${item.baureihe} ${shadowModelListString}_${item.inSeriesSince}`);
    };
    this.productiveSince = Array.from(productiveSinceSet);
    this.seriesList = Array.from(seriesListSet);
  }

  hasIcon(baureihe: string, inSeriesSince: string): boolean {
    return this.tableData.has(`${baureihe}_${inSeriesSince}`);
  }

  getSDPFromSeriesList(series: string): string {
    for (const data of this.uploadListData) {
      if (data['baureihe'] === series.split(' ')[0]) {
        return data['name'];
      }
    }
    return '';
  }

  switch(activeTab){
    const value = {
      selectedTab: activeTab
    };
    this.saveUserSetting(value);
    this.activeTab = activeTab;
  }

  private getSeriesSteuerDaten(): void {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.steuerdatenService.getSeriesSteuerDaten().subscribe(
      (data: any) => {
        this.uploadListDataForMapping = JSON.parse(JSON.stringify(data));
        data.forEach(item => {
          item.inSeriesSince = this.datePipe.transform(item.inSeriesSince, 'dd.MM.YYYY');
        });
        this.uploadListData = data;
        this.switchSort();
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
      },
      (error) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(error, '');
      }
    );
  }

  parseDate(dateStr: string): Date {
    const [day, month, year] = dateStr.split('.').map(Number);
    return new Date(year, month - 1, day);
  };

  switchSort() {
    this.sortByAsc = !this.sortByAsc;
    this.uploadListData = this.sortByAsc ? this.uploadListData.sort((a, b) => {
      const dateA = this.parseDate(a.inSeriesSince)
      const dateB = this.parseDate(b.inSeriesSince)
      return dateA.getTime() - dateB.getTime();
    }) : this.uploadListData.sort((a, b) => {
      const dateA = this.parseDate(a.inSeriesSince);
      const dateB = this.parseDate(b.inSeriesSince);
      return dateB.getTime() - dateA.getTime();
    });
    this.initializeTable(this.uploadListData);
  }

  truncateNote(value: string): string {
    const isExpanded = value.length > 250;
    return value.substring(0, 250) + (isExpanded ? '...' : '');
  }

  setTranslation(): void {
    this.translocoService
      .selectTranslate(
        'modules.data-management.serien-daten.data-table.empty-table-series'
      )
      .subscribe((resp) => {
        this.emptyMessage = resp;
      });
  }

  editSerialListRecord(row?: SerialUploadList) {
    const modalRef = this.modalService.open(FcscEditUploadListComponent, {
      size: 'lg',
      windowClass: 'details-modal',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.data = row;
    modalRef.result.then((result) => {
      if (result && result.ok === ModalResult.INSERT_SUCCESSFUL) {
        this.editRecordEp(result.recordPayload);
      }
    });
  }

  filterResponse(data) {
    const match =  data.filter(item => {
      const conditions: boolean[] = [];
      if (this.selectedFilter?.baureihe) conditions.push(item.baureihe === this.selectedFilter?.baureihe);
      if (this.selectedFilter?.name) conditions.push(item.name?.toLowerCase().includes(this.selectedFilter?.name.toLowerCase()));
      if (this.selectedFilter?.histDate) conditions.push(this.areDatesEqual(item.histDate, this.selectedFilter?.histDate));
      if (this.selectedFilter?.uploadDate) conditions.push(this.areDatesEqual(item.uploadDate, this.selectedFilter?.uploadDate));
      if (this.selectedFilter?.inSeriesSinceSDP) conditions.push(this.areDatesEqual(item.inSeriesSinceSDP, this.selectedFilter?.inSeriesSinceSDP));
      if (this.selectedFilter?.username) conditions.push(item.username?.toLowerCase().includes(this.selectedFilter?.username.toLowerCase()));
      if (this.selectedFilter?.requirement) conditions.push(item.requirement?.toLowerCase().includes(this.selectedFilter?.requirement.toLowerCase()));
      return conditions.every(condition => condition === true);
    });
    return match;
  }

  loadContent() {
    let data = (this.response && this.response?.content) || [];
    data = this.map(data, this.uploadListDataForMapping);
    this.serialUploadListData = this.filterResponse(data);
    this.spinnerObject.isLoading = false;
    this.appService.spinnerSubject.next(this.spinnerObject);
  }

  map(source, target): SerialUploadList[] {
    const data2Map = new Map<any, any>(target.map(item => [item.baureihe, item]));
    const mergedData = source.map((item: any) => {
      const additionalData = data2Map.get(item.baureihe);
      if(additionalData) {
        item['name'] = additionalData['name'];
        item['inSeriesSinceSDP'] = additionalData['inSeriesSince'];
        item['username'] = additionalData['username'];
      }
      return item;
    });
    return mergedData;
  }

  async getSerialUploadList(params?: FilteredSerialUploadList) {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.serialUploadListService.getSerialUploadList(params).subscribe(
      (data: HttpResponse<SerialUploadListResponse>) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.response = data.body;
        this.loadContent();
      },
      (error: HttpErrorResponse) => {
        this.spinnerObject.isLoading = false;
        this.appService.spinnerSubject.next(this.spinnerObject);
        this.notificationService.handleServerErrorNotification(
          error,
          'global.bdp-status.service-down'
        );
      }
    );
  }

  downloadCSV(): any {
    const now = moment();
    const formula = 'upload_list_' + now.format(DateFormat.CSV_EXPORT_FORMAT);
    const exportIncludeList = includedSerialUploadListExport;
    const exportList: any = [];
    let seriesBdpHistoryDataCsv: any = [];
    let csvResponse: any = {};
    const sortConfArr = [this.page.orderBy, this.page.orderDir].toString();

    const csvParams = {
      sort: sortConfArr,
      size: this.response?.totalElements,
    } as any;

    this.serialUploadListService
      .getSerialUploadList(csvParams)
      .subscribe((data: HttpResponse<SerialUploadListResponse>) => {
        csvResponse = data.body;
        seriesBdpHistoryDataCsv = (csvResponse && csvResponse?.content) || [];
        const serialUploadListData = JSON.parse(
          JSON.stringify(seriesBdpHistoryDataCsv)
        );
        serialUploadListData.forEach((dataContent: SerialUploadList) => {
          dataContent.minDatenstand = dataContent.minDatenstand
            ? this.datePipe.transform(
                dataContent.minDatenstand,
                DateFormat.DAY_MONTH_YEAR
              )
            : '';

          dataContent.histDate = dataContent.histDate
            ? this.datePipe.transform(
                dataContent.histDate,
                DateFormat.DAY_MONTH_YEAR
              )
            : '';

          dataContent.uploadDate = dataContent.uploadDate
            ? this.datePipe.transform(
                dataContent.uploadDate,
                DateFormat.DAY_MONTH_YEAR
              )
            : '';

          const exportObj: any = {};
          exportIncludeList.map((key: any) => {
            exportObj[key] = dataContent[key];
          });
          exportList.push(exportObj);
        });
      });
    setTimeout(() => {
      return new ngxCsv(
        exportList,
        formula,
        this.csvExportService.getOptions()
      );
    }, 1000);
  }

  private editRecordEp(payload: SerialUploadListPayload) {
    this.serialUploadListService.putSerialUploadList(payload).subscribe(
      () => {
        this.refreshDataTable(this.selectedFilter);
      },
      (error: HttpErrorResponse) => {
        this.notificationService.handleServerErrorNotification(
          error,
          'modules.data-management.serien-daten.modal.bdp-activation-error-message'
        );
      }
    );
  }

  /*  pagination functionality */
  private async refreshDataTable(filter?) {
    await this.configPageInfoAfterInit();
    await this.getSerialUploadList(filter ? filter : this.params);
  }

  private async retrieveDataOnPageChange(page) {
    const filter = this.selectedFilter || {};
    const sortConf = [this.page.orderBy, this.page.orderDir].toString();

    if (page) {
      filter.page = (+this.page.pageNumber - 1).toString();
      filter.size = this.page.size.toString();
      filter.sort = sortConf.toString();
      this.selectedFilter = filter;
    } else {
      filter.page = '0';
      filter.size = this.page.size.toString();
      filter.sort = sortConf.toString();
      this.selectedFilter = filter;
    }

    this.appService.resetDatatableScroll();
    await this.getSerialUploadList(filter);

    const value = {
      size: filter.size,
      sort: filter.sort,
    };
    this.saveUserSetting(value);
  }

  convertDateToISO(dateStr: string): string {
    const year = dateStr.substring(0, 4);
    const month = dateStr.substring(4, 6);
    const day = dateStr.substring(6, 8);
    const date = new Date(Date.UTC(Number(year), Number(month) - 1, Number(day)));
    return date.toISOString();
  }

  areDatesEqual(dateStr1: string, dateStr2: string): boolean {
    const isoDate1 = dateStr1;
    const isoDate2 = this.convertDateToISO(dateStr2);
    return isoDate1.slice(0, 10) === isoDate2.slice(0, 10);
  }

  async setFilter(event: FilteredSerialUploadList) {
    this.selectedFilter = event;
    this.loadContent();
  }

  setPageSize(size) {
    this.page.pageNumber = 1;
    this.page.size = size;
    this.retrieveDataOnPageChange(null);
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.page;
    this.retrieveDataOnPageChange(this.page);
  }

  sortCallback(sortInfo: SortInfo) {
    this.page.orderDir = sortInfo.sorts[0].dir;
    this.page.orderBy = sortInfo.sorts[0].prop;
    this.retrieveDataOnPageChange(this.page);
  }

  // Saves user data on user interaction on sort, filter and page change.
  private saveUserSetting(data) {
    this.userSettingService.saveUserSetting(
      userSettingKeys.serien.serienUploadListTable,
      data
    );
  }

  // Gets the user data or loads default ones.
  private async configPageInfoAfterInit() {
    this.spinnerObject.isLoading = true;
    this.appService.spinnerSubject.next(this.spinnerObject);
    this.paginationDataSetting =
      (await this.userSettingService.fetchUserSetting(
        userSettingKeys.serien.serienUploadListTable
      )) || {};

    const { sort, size } = this.paginationDataSetting;
    // Sort
    const sortData = sort || 'histDate,desc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    this.sortConfig = [{ prop: orderBy, dir: orderDir }];

    // Order
    this.page.orderDir = orderDir;
    this.page.orderBy = orderBy;

    // Page
    this.page.pageNumber = 1;

    // Size
    this.page.size = this.page.size = size || 100;

    const sortConfArr = [this.page.orderBy, this.page.orderDir].toString();

    this.params = {
      page: '0',
      size: size || '100',
      sort: sortConfArr,
    };
  }

  private async configPageFilterInfoAfterInit(filter: any) {
    await this.configPageInfoAfterInit();
    const { sort, size } = this.paginationDataSetting;
    const sortData = sort || 'histDate,desc';
    const sortArray = sortData.split(',');
    const orderBy = sortArray[0];
    const orderDir = sortArray[1];
    const sortConfigFilter = [orderBy, orderDir];
    filter.page = '0';
    filter.size = size || '100';
    filter.sort = sortConfigFilter.toString();
  }
}
