<div class="language-selector">
       
    <ng-select
      [searchable]="false"
      [hideSelected]="false"
      [items]="languages"
      bindLabel="name"
      [(ngModel)]="selectedLanguage"
      (change)="updateLocale(selectedLanguage.code)"
    >
    <ng-template ng-header-tmp>
      <h5>{{ 'components.header.lang-title' | transloco }}</h5>
    </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <span><img [src]="item.image"></span> {{ item.name }}
      </ng-template>
      <ng-template
        ng-label-tmp
        ng-option-selected
        let-item="item"
        let-index="index"
      >
        <span><img [src]="item.image"></span>
      </ng-template>
    </ng-select>
  </div>
