import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@core/services/env.service';
import { apiPaths } from '@env/paths';
import { DataPool } from '@shared/models/filetype/Datapool';
import {
  DeltaUpload,
  FileData,
  SdpCreationRequest,
} from '@shared/models/ImportFile';
import * as pako from 'pako';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FileImportService {
  private datamanagerApiBaseUrl = '';
  private steuerungApiBaseUrl = '';

  constructor(private http: HttpClient, private envService: EnvService) {
    this.datamanagerApiBaseUrl = `${envService.apiUrl}${apiPaths.fcscDatamanager}/api`;
    this.steuerungApiBaseUrl = `${envService.apiUrl}${apiPaths.fcscSteuerung}/api`;
  }

  // add here query param
  public createSdp(payload: SdpCreationRequest) {
    const headers = new HttpHeaders({
      'content-type': 'application/json',
    });

    const url = `${this.datamanagerApiBaseUrl}/steuerdaten-paket?source=FCSC`;
    return this.http.put(url, payload, {
      headers,
      responseType: 'json',
    });
  }

  public async getFileData(fileData: FileData) {
    const text = await fileData?.file.arrayBuffer();
    return text;
  }


  public async getFileDataAsText(fileData: FileData) {
    const text = await fileData?.file.text();
    return text;
  }

  public uploadFile(
    sdpName: string,
    fileData: FileData,
    fileDataText: any
  ): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/gzip',
      'Last-Modified': new Date(+fileData.modified).toISOString(),
    });
    const body = pako.gzip(fileDataText).buffer;
    const fileName = fileData.name;
    const url = `${this.datamanagerApiBaseUrl}/file/${sdpName}/${fileName}.gz`;
    return this.http.put(url, body, { headers: headers, responseType: 'text' });
  }

  // steuerung/api/delta-upload/${baureihe}
  public putDeltaUploadFiles(
    serie: string,
    payload: string[]
  ): Observable<HttpResponse<DeltaUpload[]>> {
    const httpHeaders = new HttpHeaders({
      'content-type': 'application/json',
    });

    const url = `${this.steuerungApiBaseUrl}/delta-upload/${serie}`;
    return this.http.put<DeltaUpload[]>(url, payload, {
      responseType: 'json',
      headers: httpHeaders,
      observe: 'response',
    });
  }

  public createBdp(sdp: string): Observable<any> {
    const httpHeaders = new HttpHeaders({ enctype: 'multipart/form-data' });
    const formData = new FormData();
    formData.append('sdp', sdp);
    const url = `${this.steuerungApiBaseUrl}/bdp`;
    return this.http.post(url, formData, {
      headers: httpHeaders,
      observe: 'response',
    });
  }
}
